import { Folder, CreateFolder, UpdateFolder } from '../models';
import { Id } from '../utils';
import service from '../utils/httpClient';

export const getAllFolder = async (
  parentId: number | null,
): Promise<Folder[]> => {
  const path = '/api/folders';
  const url = path + (parentId ? `?parent_id=${parentId}` : '');

  const res = await service({
    method: 'GET',
    url,
  });

  return res.data as Folder[];
};

export const createFolder = async (data: CreateFolder): Promise<Folder> => {
  const url = '/api/folders';

  const res = await service({
    method: 'POST',
    url,
    data,
  });

  return res.data as Folder;
};

export const updateFolder = async (data: UpdateFolder): Promise<Folder> => {
  const url = `/api/folders/${data.id}`;
  const { name, parentId } = data;

  const res = await service({
    method: 'PUT',
    url,
    data: { name, parentId },
  });
  return res.data as Folder;
};

export const deleteFolder = async (data: Id): Promise<void> => {
  const url = `/api/folders/${data.id}`;
  await service({
    method: 'DELETE',
    url,
  });
};
