/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-nested-ternary */
import { createBrowserHistory } from 'history';
import moment from 'moment';

import { BreadcrumpValue } from '../components/Breadcrumb/Breadcrumb.type';
import { PaymentInfo } from '../models/Profile';
import { HEX_OPACITY } from './constants';

/**
 * Convert ISO date
 *
 * @param date
 * @param format
 * @returns
 */
export const converISODateToDateFormat = (date: string, format?: string) => {
  const dateFormat = format || 'DD MMM YYYY hh:mm A';
  if (moment(date).isValid()) {
    return moment(date).format(dateFormat);
  }
  return date;
};

export const lowerCase = (name: string): string => {
  if (name) {
    return name.toLowerCase();
  }

  return name;
};

/**
 * Dynamic sort
 *
 * @param key
 * @param order
 * @returns
 */
export const dynamicSort = (key: string, order: 'ASC' | 'DESC' = 'ASC') => {
  let reverse = 1;
  if (order === 'DESC') {
    reverse = -1;
  }
  return (a: any, b: any) => {
    if (lowerCase(a[key]) < lowerCase(b[key])) {
      return -1 * reverse;
    }

    if (lowerCase(a[key]) > lowerCase(b[key])) {
      return 1 * reverse;
    }

    return 0 * reverse;
  };
};

/**
 * Get time value
 *
 * @param val
 * @returns
 */
export const getTimeValue = (val: number): string => {
  const seconds = val % 60;

  const minutes = (val - seconds) / 60;
  const minutesWithoutHours = minutes % 60;
  const hours = Math.floor((minutes - minutesWithoutHours) / 60);

  return `${hours
    .toString()
    .padStart(2, '0')}:${minutesWithoutHours
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const history = createBrowserHistory();

export const getBreadcrumbs = (
  options: BreadcrumpValue[],
  value: BreadcrumpValue | null,
) => {
  if (!value) {
    return [];
  }

  let isEnd = false;

  const newOptions = options.reduce((acc, cur) => {
    if (!isEnd) {
      if (cur.id === value.id) {
        isEnd = true;
      }
      acc.push(cur);
    }

    return acc;
  }, [] as BreadcrumpValue[]);

  if (!isEnd) {
    newOptions.push(value);
  }

  return newOptions;
};

export const getHexOpacity = (hex: string, hasOpacity: boolean) => {
  return hasOpacity ? `${hex}${HEX_OPACITY}` : hex;
};

export const getPlanColor = (plan: PaymentInfo['plan']) => {
  const colors = {
    bronze:
      'linear-gradient(50.58deg, #B08D81 5.24%, #A8897B 7%, #9E8173 19.29%, #9E8173 27.2%, #C6A090 37.74%, #CFA897 42.13%, #FCD0B9 55.3%, #FCD0B9 68.48%, #BA9683 87.8%, #B3907E 90.44%, #B28E7D 93.07%)',
    silver:
      'linear-gradient(49.3deg, #656565 -14.32%, #5A5A5A -11.71%, rgba(75, 75, 75, 0.36) 6.56%, rgba(75, 75, 75, 0.57) 18.3%, #838383 33.96%, #8F8F8F 40.48%, #CECECE 60.06%, #CECECE 79.63%, #727272 108.34%, #686868 112.25%, #676767 116.16%)',
    gold:
      'linear-gradient(41.15deg, #A88B6D 3.74%, #A08668 5.83%, #947E60 20.46%, #947E60 29.86%, #C0A07D 42.4%, #CAAA84 47.62%, #FCD7A5 63.29%, #FCD7A5 78.96%, #B3976F 101.94%, #AC9069 105.08%, #AB8E68 108.21%)',
  };

  return colors[plan];
};
