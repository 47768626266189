import { useRef } from 'react';

import cn from 'classnames';
import Draggable from 'react-draggable';

import spriteImg from '../../assets/img/sprite.svg';
import style from './PopUpWrapper.module.css';
import { PopUpWrapperType } from './PopUpWrapper.type';

const PopUpWrapper = (props: PopUpWrapperType) => {
  const { className, children, closeHandler, title, mode = 'default' } = props;

  const containerRef = useRef<HTMLDivElement>();

  const handlerClose = () => {
    // eslint-disable-next-line no-unused-expressions
    closeHandler && closeHandler();
  };

  return (
    <Draggable
      {...(mode !== 'miniature' && {
        position: {
          x: 0,
          y: 0,
        },
      })}
      nodeRef={containerRef as any}
      disabled={mode !== 'miniature'}
    >
      <div
        className={cn(style.container, className, style[mode])}
        ref={containerRef as any}
        onClick={handlerClose}
      >
        <div className={style.wrapper} onClick={(e) => e.stopPropagation()}>
          <button className={style.close} onClick={handlerClose}>
            <svg role="img">
              <use xlinkHref={`${spriteImg}#close`}></use>
            </svg>
          </button>
          <div
            className={cn(
              style.content,
              mode === 'default' || style.without_frame,
            )}
          >
            {title && <div className={style.title}>{title}</div>}
            {children}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default PopUpWrapper;
