import {
  Embed,
  GetEmbedVideo,
  EditVideo,
  UploadVideo,
  DeleteVideo,
  Video,
  VideoId,
} from '../../models';
import { SortType, PageType, MetaType, Query } from '../../utils';
import {
  SET_VIDEO_PAGE,
  SET_VIDEO_SORT_TYPE,
  SET_SELECTED_VIDEO,
  SET_SELECT_ALL_VIDEOS,
  SET_SELECTED_VIDEOS_ID,
  SET_SEARCH_STRING,
  SET_USER_ID,
  SET_SORT_NAME,
  GET_VIDEO_LIST_REQUEST,
  GET_VIDEO_LIST_SUCCESS,
  GET_VIDEO_LIST_FAILURE,
  DELETE_VIDEO_FAILURE,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  GET_EMBED_VIDEO_FAILURE,
  GET_EMBED_VIDEO_REQUEST,
  GET_EMBED_VIDEO_SUCCESS,
  GET_VIDEO_FAILURE,
  GET_VIDEO_REQUEST,
  GET_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAILURE,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  UPLOAD_VIDEO_REQUEST,
  UPLOAD_VIDEO_SUCCESS,
  VideoActionsTypes,
  BULK_VIDEOS_REQUEST,
  BULK_VIDEOS_SUCCESS,
  BULK_VIDEOS_FAILURE,
  IBulkPayLoad,
  UPLOAD_VIDEO_IMAGE_REQUEST,
  UPLOAD_VIDEO_IMAGE_SUCCESS,
  UPLOAD_VIDEO_IMAGE_FAILURE,
  UploadVideoImage,
} from './video.type';

export function setVideoSortType(sortType: SortType): VideoActionsTypes {
  return {
    type: SET_VIDEO_SORT_TYPE,
    payload: {
      sortType,
    },
  };
}

export function setVideoPage(pageType: PageType): VideoActionsTypes {
  return {
    type: SET_VIDEO_PAGE,
    payload: {
      pageType,
    },
  };
}

export function setSelectedVideo(video: Video | null): VideoActionsTypes {
  return {
    type: SET_SELECTED_VIDEO,
    payload: {
      video,
    },
  };
}

export function setSelectedVideosId(id: number): VideoActionsTypes {
  return {
    type: SET_SELECTED_VIDEOS_ID,
    payload: {
      id,
    },
  };
}

export function setSelectAllVideos(
  selectAllVideos: boolean,
): VideoActionsTypes {
  return {
    type: SET_SELECT_ALL_VIDEOS,
    payload: {
      selectAllVideos,
    },
  };
}

export function setSearchString(search: string): VideoActionsTypes {
  return {
    type: SET_SEARCH_STRING,
    payload: {
      search,
    },
  };
}

export function getVideoListRequest(query: Query['query']): VideoActionsTypes {
  return {
    type: GET_VIDEO_LIST_REQUEST,
    payload: {
      query,
    },
  };
}

export function getVideoListSuccess(
  videos: Video[],
  pagination: MetaType,
): VideoActionsTypes {
  return {
    type: GET_VIDEO_LIST_SUCCESS,
    payload: {
      videos,
      pagination,
    },
  };
}

export function getVideoListFailure(): VideoActionsTypes {
  return {
    type: GET_VIDEO_LIST_FAILURE,
  };
}

export function getVideoRequest(payload: VideoId): VideoActionsTypes {
  return {
    type: GET_VIDEO_REQUEST,
    payload,
  };
}

export function getVideoSuccess(payload: Video): VideoActionsTypes {
  return {
    type: GET_VIDEO_SUCCESS,
    payload,
  };
}

export function getVideoFailure(): VideoActionsTypes {
  return {
    type: GET_VIDEO_FAILURE,
  };
}

export function getEmbedVideoRequest(
  payload: GetEmbedVideo,
): VideoActionsTypes {
  return {
    type: GET_EMBED_VIDEO_REQUEST,
    payload,
  };
}

export function getEmbedVideoSuccess(payload: Embed): VideoActionsTypes {
  return {
    type: GET_EMBED_VIDEO_SUCCESS,
    payload,
  };
}

export function getEmbedVideoFailure(): VideoActionsTypes {
  return {
    type: GET_EMBED_VIDEO_FAILURE,
  };
}

export function updateVideoRequest(payload: EditVideo): VideoActionsTypes {
  return {
    type: UPDATE_VIDEO_REQUEST,
    payload,
  };
}

export function updateVideoSuccess(payload: Video): VideoActionsTypes {
  return {
    type: UPDATE_VIDEO_SUCCESS,
    payload,
  };
}

export function updateVideoFailure(): VideoActionsTypes {
  return {
    type: UPDATE_VIDEO_FAILURE,
  };
}

export function uploadVideoRequest(
  payload: UploadVideo & Query,
): VideoActionsTypes {
  return {
    type: UPLOAD_VIDEO_REQUEST,
    payload,
  };
}

export function uploadVideoSuccess(): VideoActionsTypes {
  return {
    type: UPLOAD_VIDEO_SUCCESS,
  };
}

export function uploadVideoFailure(): VideoActionsTypes {
  return {
    type: UPLOAD_VIDEO_FAILURE,
  };
}

export function deleteVideoRequest(payload: DeleteVideo): VideoActionsTypes {
  return {
    type: DELETE_VIDEO_REQUEST,
    payload,
  };
}

export function deleteVideoSuccess(): VideoActionsTypes {
  return {
    type: DELETE_VIDEO_SUCCESS,
  };
}

export function deleteVideoFailure(): VideoActionsTypes {
  return {
    type: DELETE_VIDEO_FAILURE,
  };
}

export function bulkVideosRequest(payload: IBulkPayLoad): VideoActionsTypes {
  return {
    type: BULK_VIDEOS_REQUEST,
    payload,
  };
}

export function bulkVideosSuccess(): VideoActionsTypes {
  return {
    type: BULK_VIDEOS_SUCCESS,
  };
}

export function bulkVideosFailure(): VideoActionsTypes {
  return {
    type: BULK_VIDEOS_FAILURE,
  };
}

export function setUserId(userId: number): VideoActionsTypes {
  return {
    type: SET_USER_ID,
    payload: {
      userId,
    },
  };
}

export function setSortName(name: string): VideoActionsTypes {
  return {
    type: SET_SORT_NAME,
    payload: {
      name,
    },
  };
}

export function uploadVideoImageRequest(
  payload: UploadVideoImage,
): VideoActionsTypes {
  return {
    type: UPLOAD_VIDEO_IMAGE_REQUEST,
    payload,
  };
}

export function uploadVideoImageSuccess(): VideoActionsTypes {
  return {
    type: UPLOAD_VIDEO_IMAGE_SUCCESS,
  };
}

export function uploadVideoImageFailure(): VideoActionsTypes {
  return {
    type: UPLOAD_VIDEO_IMAGE_FAILURE,
  };
}
