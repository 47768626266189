import { useMemo } from 'react';

import StaticPage from '../../layout/StaticPage/StaticPage';

const TermOfUsePage = () => {
  const title = 'Term of use';
  const renderText = useMemo(() => {
    return (
      <>
        <ul>
          <li>
            ACCEPTANCE OF TERMS OF SERVICE
            <ul>
              <li>
                As a precondition and requirement to use the services available
                via EasyWebVideo.com, you, for yourself and for the company or
                other person(s), if any, you represent ("Subscriber" or
                "Subscribers", as applicable), hereby accept and agree to be
                legally bound by these Terms of Service ("Terms"). These Terms
                are effective immediately between the Subscriber and
                EasyWebVideo.com, Inc. dba TopNet Solutions, Inc., its
                subsidiaries and affiliates ("EasyWebVideo.com"). Each
                Subscriber is subject to these Terms, and by using
                EasyWebVideo.com's services, network and/or systems
                (collectively the "Services"), Subscriber agrees to be legally
                bound by and subject to all terms and conditions contained in
                these Terms, including as well all usage policies and other
                policies herein. To the extent not inconsistent therewith, these
                Terms are also incorporated into the individual service
                agreement, if any, of each Subscriber.
              </li>
              <li>
                Subscriber represents and warrants that, if an individual,
                Subscriber is at least 18 years old and otherwise legally
                competent in all respects to, or, if an entity, Subscriber is a
                corporation, limited liability company, partnership, or other
                legal entity duly formed and in good standing, as applicable,
                and possesses all legal authority and power to accept and be
                bound by these Terms. Additionally, Subscriber represents and
                warrants that neither it, she, or he (as applicable), nor any
                entity it, she or he represents, is prohibited under any part of
                section 13 of these Terms from registering or signing up with or
                otherwise subscribing to or receiving any of the Services from
                EasyWebVideo.com. Further, Subscriber represents and warrants
                all information provided by Subscriber to EasyWebVideo.com has
                been and is complete, accurate, and current, and that Subscriber
                shall continue to provide complete, accurate and current
                information to EasyWebVideo.com in connection with all
                registration or renewal processes and further agrees to update
                all such information as necessary to maintain complete, accurate
                and current information. Although subscribers of paid services
                offered through EasyWebVideo.com must be at least 18 years of
                age and otherwise legally competent to accept and be legally
                bound by these Term, a parent or legal guardian of a minor may
                obtain an account on the minor's behalf, and by doing so,
                consents to such minor's use of the Services. Subscribing
                parents and legal guardians each for herself or himself, as
                applicable, accept and agree to be legally bound by these Terms,
                and assume full responsibility and liability associated with any
                failure of compliance with the Terms in connection with said
                minor's use of any of the Services.
              </li>
              <li>
                EasyWebVideo.com intends to provide the best possible video
                hosting service to each of its Subscribers. EasyWebVideo.com is
                also dedicated to staying abreast of new and available
                technologies that will better serve our Subscribers. However,
                due to changing technologies, changing laws and the individual
                and collective needs of our Subscribers, EasyWebVideo.com
                reserves the right, in its sole discretion, to change, modify,
                add or remove all or any part of these Terms at any time with or
                without notice.
              </li>
              <li>
                Subscribers may view the most current version of these Terms at
                www.easywebvideo.com/terms. Any use of the Services by
                Subscriber, after changes, modifications, additions or deletions
                to these Terms are posted on the EasyWebVideo.com website, shall
                constitute Subscriber's acceptance of all such changes,
                additions, modifications or deletions. If a Subscriber does not
                agree to any such alterations to these Terms, the Subscriber's
                sole and exclusive remedy is to cancel the Subscriber's account
                as set forth in Paragraph 3 below.
              </li>
              <li>
                Notwithstanding EasyWebVideo.com's right to alter these Terms
                without prior notice, EasyWebVideo.com may, within its sole
                discretion and as a courtesy to all affected Subscribers, make
                an effort to provide Subscribers with 7 days advanced notice of
                any alteration of these Terms if it appears to EasyWebVideo.com,
                in its sole discretion, that said alteration may materially and
                adversely impact said Subscriber's use of the Services.
              </li>
            </ul>
          </li>
          <li>
            TERM - PAYMENT - RENEWAL OF ACCOUNT
            <ul>
              <li>
                <strong>Term of Service</strong>. The term of Subscriber's
                subscription to the Services commences upon Subscriber's
                acceptance of these Terms and terminates as set forth in
                Paragraphs 2.04 and 3.01.
              </li>
              <li>
                <strong>Payment</strong>. Subscriber agrees to pay all
                applicable fees for Services in effect at the time of
                registration and/or renewal, subject to these Terms as same may
                be modified by EasyWebVideo.com from time to time.
              </li>
              <li>
                <strong>Automatic Renewal of Account</strong>. As a courtesy and
                not as an obligation (contractual or otherwise),
                EasyWebVideo.com will automatically renew Subscriber's account
                by charging the applicable fee to Subscriber's current method of
                payment on file.
              </li>
              <li>
                <strong>Cancellation of Automatic Renewal of Account</strong>.
                To cancel Automatic Account Renewal, Subscriber must notify
                EasyWebVideo.com of Subscriber's intent to cancel at least seven
                (7) days prior to the account expiration date, by directing
                Automatic Account Renewal Cancellation Notification to
                EasyWebVideo.com, as applicable, by sending same via email to
                support @ topnetsolutions.net or via the Helpdesk at
                https://app.easywebvideo.com/helpdesk. EasyWebVideo.com agrees
                that on receipt of Automatic Account Renewal Cancellation
                Notification under the conditions stated above, no additional
                charges will be billed to Subscriber, and Subscriber's account
                and videos, as applicable, shall expire on the account
                expiration date and be removed permanently from
                EasyWebVideo.com's servers 30 days after cancellation.
              </li>
              <li>
                <strong>Authority</strong>. In the event Subscriber is a
                corporation, limited liability company, partnership, joint
                venture, other business entity or group of individuals, the
                person registering for or renewing EasyWebVideo.com Services on
                behalf of Subscriber hereby certifies that he/she has the
                authority to and does hereby bind the corporation, LLC,
                partners, joint venture or other individuals in this manner and
                in connection with Subscriber's acceptance of all other Terms
                set forth herein.
              </li>
            </ul>
          </li>
          <li>
            CANCELLATION OF SERVICES - REFUND POLICY
            <ul>
              <li>
                <strong>Cancellations and Refunds</strong>. EasyWebVideo.com
                provides a 60-day money back guarantee for new account
                registrations. Subscriber must direct all cancellation requests
                to EasyWebVideo.com via email sent to support @
                topnetsolutions.net or via the Helpdesk at
                https://app.easywebvideo.com/helpdesk.
              </li>
              <li>
                <strong>Chargebacks and Reversals</strong>. In the event
                Subscriber issues a chargeback or reversal of charges without
                first following the above cancellation procedures, the
                Subscriber will be responsible for a $50.00 billing service fee.
              </li>
            </ul>
          </li>
          <li>
            PROPRIETARY RIGHTS
            <p>
              These Terms do not give Subscriber any rights in EasyWebVideo.com
              intellectual property or technology. EasyWebVideo.com and related
              trademarks and logos are the exclusive property of
              EasyWebVideo.com. EasyWebVideo.com and Subscriber agree that
              neither will, directly or indirectly, reverse engineer or
              decompile object code or execution code, nor otherwise seek to
              obtain source code or trade secrets of the other party.
              Notwithstanding the foregoing, nothing herein shall bar
              EasyWebVideo.com from using any knowledge, information or skills
              that are generally known or that can be learned or otherwise
              acquired in the normal course of business.
            </p>
          </li>
          <li>
            SECURITY AND OWNERSHIP
            <ul>
              <li>
                <strong>Site Security</strong>. EasyWebVideo.com uses
                sophisticated means of security in connection with the Services.
                Notwithstanding the foregoing, it is exclusively Subscriber's
                obligation to maintain and control passwords to Subscriber's
                account(s), and Subscriber exclusively is responsible for all
                activities that occur in connection with Subscriber's user name
                and password. Subscriber agrees to immediately notify
                EasyWebVideo.com of any unauthorized uses of the Service or any
                other breaches of security. EasyWebVideo.com will not be liable
                for any loss or damages of any kind, under any legal theory,
                caused by Subscriber's failure to comply with the foregoing
                security obligations or caused by any person to whom Subscriber
                grants access to Subscriber's Services.
              </li>
              <li>
                <strong>Access and Control Policy</strong>. When a new account
                is registered with EasyWebVideo.com or with one of its
                affiliates, the process requires and results in the creation of
                the following within the records of EasyWebVideo.com, in
                association with said new account: i) Name of person
                registering; ii) email address; iii) user name; and iv)
                password.
                <ul>
                  <li>
                    If a person contacts EasyWebVideo.com and claims that she or
                    he is entitled access to an account registered with
                    EasyWebVideo.com or its affiliate but is without access (for
                    any reason such as but not limited to forgotten password or
                    forgotten user name, data loss or corruption on personal
                    computer, improperly locked out, and so forth),
                    EasyWebVideo.com will give access to the person who
                    acceptably evidences she or he has substantially all of: i)
                    name of the person who originally registered or last paid
                    for said account; ii) email address of the person who
                    originally registered or last paid for said account; iii)
                    the user name of the person who originally registered or
                    last paid for said account, if possible; and, iv) a photo
                    identification evidencing she or he is the rightful
                    possessor and user of said credit card or original payment
                    medium or the one which was last used to pay for said
                    account (mandatory). Otherwise, EasyWebVideo.com will not
                    give access and control to a claimant unless
                    EasyWebVideo.com is served with a valid order of a court,
                    agency, or appropriate internet controlling entity,
                    requiring EasyWebVideo.com to give such access and control
                    to said claimant or unless there is submitted to
                    EasyWebVideo.com at support @ topnetsolutions.net a written
                    statement duly signed by the person who originally
                    registered said account which statement is duly notarized,
                    and in which said originally registering person confirms
                    that said claimant is entitled to access and control of the
                    account, together with a written notarized statement duly
                    signed by claimant which confirms claimant is entitled to
                    access and control of said account and has read and agreed
                    to these Terms of Service. Subscriber acknowledges and
                    accepts that the foregoing access and control policies and
                    procedures are the only way that notice(s) can be given and
                    changes can be effected regarding said access and/or
                    control; and, further, that any other changes made directly
                    by Subscriber or by others within the online records of
                    EasyWebVideo.com or of its affiliates shall not be deemed
                    effective to give notice to EasyWebVideo.com or its
                    affiliates of any changes in said access or control.
                  </li>
                  <li>
                    Subscriber acknowledges and accepts said access and control
                    policy and procedure, agrees not to bring any claim in the
                    form of a lawsuit or otherwise against EasyWebVideo.com
                    arising out of its following said policy and procedure,
                    agrees immediately to dismiss any claim so brought, and
                    hereby releases EasyWebVideo.com from all liability and all
                    claims for damages or any other liability whatsoever that
                    may arise out of EasyWebVideo.com.s following said policy
                    and procedure.
                  </li>
                </ul>
              </li>
              <li>
                <strong>License to EasyWebVideo.com</strong>. EasyWebVideo.com
                claims no ownership interest in the content of Subscriber's web
                site(s). By submitting content and data to EasyWebVideo.com,
                Subscriber grants to EasyWebVideo.com, its successors and
                assigns, the worldwide, royalty-free, and nonexclusive license
                under Subscriber's copyrights and other rights, if any, in all
                material and content displayed in Subscriber's web site to use,
                distribute, display, reproduce, and create derivative works from
                such material in any and all media, in order to maintain such
                content on EasyWebVideo.com's servers during the term of these
                Terms. Subscriber also authorizes the downloading and printing
                of such material, or any portion thereof, by end-users for their
                personal use. This license shall terminate upon Subscriber's
                cancellation of the Services as set forth in Paragraphs 2.01 and
                3.0.
              </li>
              <li>
                <strong>Transfer of Ownership</strong>. EasyWebVideo.com is not
                responsible to determine ownership of accounts opened with
                EasyWebVideo.com or its affiliates. By accessing
                EasyWebVideo.com.s system, registering, or using any of the
                services provided by EasyWebVideo.com, the Subscriber, or any
                other person so accessing, registering or using, hereby
                represents and warrants that the information provided in
                connection with said registration, including as well information
                concerning ownership of accounts, is true, accurate, and
                complete in each material detail. Any disputes arising regarding
                the ownership of any account registered with EasyWebVideo.com or
                any of its affiliates are subject to the terms and conditions of
                Section 5.5 of these Terms.
                <ul>
                  <li>
                    EasyWebVideo.com will recognize a change of ownership of
                    account(s) with EasyWebVideo.com or its affiliates only in
                    the event that at least one of the following occur: i) there
                    is received by the Abuse Manager (support @
                    topnetsolutions.net) a written statement (in a form
                    acceptable to EasyWebVideo.com or applicable affiliate)
                    containing the notarized signature of the original owner of
                    the account confirming that ownership of the account has
                    been transferred to a person (claimant) claiming to own said
                    account along with a written statement containing the
                    notarized signature of the claimant confirming that she or
                    he has acquired said ownership, has read and agrees to be
                    bound by these Terms of Service; or, ii) EasyWebVideo.com is
                    served with a valid order of a court, agency, or appropriate
                    internet controlling entity, requiring EasyWebVideo.com (or
                    affiliate) to transfer such ownership to said claimant.
                  </li>
                  <li>
                    Subscriber acknowledges and accepts that the foregoing
                    policies and procedures regarding transfer of ownership are
                    the only way that notice(s) can be given and changes can be
                    effected regarding said transfer of ownership within
                    EasyWebVideo.com; and, further, that any other changes made
                    directly by Subscriber or by others within the online
                    records of EasyWebVideo.com or of its affiliates shall not
                    be deemed effective to give notice to EasyWebVideo.com or
                    its affiliates of any changes in said ownership.
                  </li>
                  <li>
                    Subscriber acknowledges and accepts that the foregoing
                    policies and procedures regarding transfer of ownership are
                    the only way that notice(s) can be given and changes can be
                    effected regarding said transfer of ownership within
                    EasyWebVideo.com; and, further, that any other changes made
                    directly by Subscriber or by others within the online
                    records of EasyWebVideo.com or of its affiliates shall not
                    be deemed effective to give notice to EasyWebVideo.com or
                    its affiliates of any changes in said ownership.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Disputing Account Ownership</strong>. Disputes sometimes
                arise between or among multiple persons claiming ownership of an
                account hosted by EasyWebVideo.com or in an associated
                EasyWebVideo.com account. EasyWebVideo.com is not obligated to
                resolve any such disputes. If multiple persons are claiming
                ownership of or rights in an account hosted by EasyWebVideo.com
                or in an associated EasyWebVideo.com account, and, in
                EasyWebVideo.com's sole judgment, there is not certainty as to
                the ownership of or rights in said site or account, then
                EasyWebVideo.com will, to the extent of its knowledge and
                ability, notify said persons of the dispute and demand that said
                persons promptly, conclusively, and finally resolve the dispute
                in a manner which makes clear who the owner(s) and/or interest
                holder(s) is/are and in a manner which relieves EasyWebVideo.com
                of all liability or obligations concerning the dispute. If the
                disputing persons fail so to resolve the dispute within what
                EasyWebVideo.com, in its sole judgment, deems to be a reasonable
                time, then EasyWebVideo.com, at its option and without any
                obligation to do so, may, in accordance with and subject to the
                laws of the Province of Ontario, file an interpleader action in
                a court of competent jurisdiction within the Province of Ontario
                for the purpose of allowing the contending persons to resolve
                said dispute and to reach certainty regarding ownership of or
                rights in said site and/or account. The person or persons
                conclusively and finally determined by the interpleader action
                to be the rightful owner(s) or interest holder(s) of said site
                and/or associated account shall be obligated to reimburse
                EasyWebVideo.com for all of its expenses relative to said
                interpleader action including without limit all its court costs
                and reasonable attorney fees. All amounts owed to
                EasyWebVideo.com under this section 5.03 shall be deemed due and
                payable immediately upon thirty (30) days after judgment or
                settlement is reached in said interpleader action. Failure of
                the rightful owner of said site and/or associated account to
                cause EasyWebVideo.com to be timely paid in full all of said
                amounts shall be deemed a breach of these Terms, shall subject
                the account to immediate termination, and shall entitle
                EasyWebVideo.com to a judgment against said rightful owner for
                all costs and all of EasyWebVideo.com's expenses relative to
                said interpleader action including without limit all of
                EasyWebVideo.com's court costs and reasonable attorney fees. No
                rights enumerated in this section 5.03 shall in any way diminish
                any other rights of EasyWebVideo.com enumerated in any other
                section of these Terms, or otherwise available to
                EasyWebVideo.com at law or in equity.
              </li>
            </ul>
          </li>
          <li>
            PRIVACY POLICY
            <ul>
              <li>
                <strong>Commitment</strong>. The EasyWebVideo.com commitment to
                our Subscribers' privacy is set forth in this section. Except as
                required by law or as otherwise provided herein,
                EasyWebVideo.com will take commercially reasonable steps to
                ensure your right to privacy. EasyWebVideo.com is committed to
                developing long lasting relationships that are built on trust
                and will never intentionally violate that trust.
              </li>
              <li>
                <strong>Encryption</strong>. When Subscriber orders from
                EasyWebVideo.com, Personal Data Transmissions are encrypted.
                When Subscriber orders from EasyWebVideo.com, all of
                Subscriber's personal information is protected. EasyWebVideo.com
                uses Secure Sockets Layer (SSL), the industry standard, through
                ClickBank.com for encrypting all personal information, including
                name, address and credit card numbers.
              </li>
              <li>
                <strong>Confidentiality</strong>. With the exception of trusted
                business affiliates and/or associates who work on behalf of or
                in connection with EasyWebVideo.com, subject to specific
                confidentiality agreements, EasyWebVideo.com will not provide or
                sell to any third party your personal information and will keep
                all Subscriber information confidential, subject to the
                following:
                <ul>
                  <li>
                    EasyWebVideo.com will respond to subpoenas, court orders, or
                    other legal process, and will utilize Subscriber information
                    as necessary to establish or exercise EasyWebVideo.com's
                    legal rights or defend against legal claims.
                  </li>
                  <li>
                    EasyWebVideo.com will share information to investigate,
                    prevent, or take action regarding illegal activities,
                    suspected fraud, situations involving potential threats to
                    the physical safety of any person, violations or suspected
                    violations of these Terms, or as otherwise required by law.
                  </li>
                  <li>
                    EasyWebVideo.com may transfer information about Subscribers
                    if EasyWebVideo.com is acquired by or merged with another
                    company, in which event EasyWebVideo.com will notify
                    Subscriber.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            "UNLIMITED" USAGE POLICIES AND DEFINITIONS
            <ul>
              <li>
                <strong>What "Unlimited" means</strong>. In good faith and
                subject to these Terms, EasyWebVideo.com makes every
                commercially reasonable effort to provide its Subscribers with
                all the storage and bandwidth resources needed to power their
                videos successfully, as long as the Subscriber's use of the
                service complies with these Terms. By not setting limits on key
                resources, we are able to provide simple, consistent pricing to
                our Subscribers. As a result, a typical Subscriber website may
                experience periods of great popularity and resulting increased
                storage without experiencing any associated increase in hosting
                charges.
              </li>
              <li>
                <strong>What "Unlimited" DOES NOT mean</strong>.
                EasyWebVideo.com employs mechanisms to protect its Subscribers
                and systems from abuse. EasyWebVideo.com's offering of
                "unlimited" services is not intended to allow the actions of a
                single or few Subscribers to unfairly or adversely impact the
                experience of other Subscribers. EasyWebVideo.com's service is a
                shared hosting service, which means that multiple Subscriber
                videos are hosted from the same server and share server
                resources. EasyWebVideo.com's service is designed to meet the
                typical needs of small business and home business website
                Subscribers. It is NOT intended to support the sustained demand
                of large enterprises, internationally based businesses, or
                non-typical applications better suited to a dedicated server.
                EasyWebVideo.com will make every commercially reasonable effort
                to provide additional resources to Subscribers who are using
                their website(s) consistent with these Terms, including moving
                Subscribers to newer and bigger shared servers as necessary, at
                an increased monthly cost. However, in order to ensure a
                consistent and quality experience for all Subscribers,
                EasyWebVideo.com does employ automated safeguards to protect
                against any one account adversely impacting the system.
              </li>
              <li>
                <strong>Unlimited Video Hosting</strong>. EasyWebVideo.com
                reserves the right to limit processor time, bandwidth, or
                processes, memory, or number of files in cases where it is
                necessary to prevent a user from negatively impacting other
                Subscribers.
              </li>
            </ul>
          </li>
          <li>
            COOKIE POLICY STATEMENT
            <ul>
              <li>
                <strong>Breach of Terms and Account Termination</strong>. The
                uses of EasyWebVideo.com Services set forth in this section 9
                hereinbelow are prohibited. The engaging in any prohibited use
                as set forth herein by Subscriber or by any affiliate or
                subscriber of Subscriber ( as determined in the sole discretion
                of EasyWebVideo.com) constitutes a material breach of these
                Terms and will subject Subscriber's account to immediate
                termination without notice.
              </li>
              <li>
                <strong>Spamming</strong>. Spamming, whether or not it overloads
                the Services or disrupts service to EasyWebVideo.com's
                Subscribers, is prohibited. The term "Spamming" includes, but is
                not limited to, the sending of unsolicited bulk and/or
                commercial messages over the Internet, maintaining an open SMTP
                policy, or sending to any mailing list that is not double opt
                in. EasyWebVideo.com requires that all email messages promoting
                a URL that contains a video hosted by us contain an automated
                opt out . We do not allow purchased lists. EasyWebVideo.com's
                Subscribers are required to follow all rules in the Can Spam Act
                (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.ftc.gov/bcp/edu/pubs/business/ecommerce/bus61.shtm"
                >
                  http://www.ftc.gov/bcp/edu/pubs/business/ecommerce/bus61.shtm
                </a>
                ) as well as our company spam rules. EasyWebVideo.com reserves
                the right to determine, in its sole and absolute discretion,
                whether e-mail recipients were part of an opt-in email list.
              </li>
              <li>
                <strong>Prohibited Offerings</strong>. No Subscriber may utilize
                the Services to provide, sell or offer to sell the following:
                controlled substances; illegal drugs and drug contraband;
                weapons; pirated materials; instructions on making, assembling
                or obtaining illegal goods or weapons to attack others;
                information used to violate the copyright(s) of, violate the
                trademark(s) of or to destroy others' intellectual property or
                information; information used to illegally harm any people or
                animals; pornography, nudity, sexual products, programs or
                services; escort services or other content deemed adult related.
              </li>
              <li>
                <strong>Profanity</strong>. Profanity or profane subject matter
                in the content is prohibited.
              </li>
              <li>
                <strong>Private Information and Images</strong>. Subscribers may
                not post or disclose any personal or private information about
                or images of children or any third party without the consent of
                said party (or a parent's consent in the case of a minor).
              </li>
              <li>
                <strong>Violations of Intellectual Property Rights</strong>. Any
                violation of any person's or entity's intellectual property
                rights, rights of privacy, rights of publicity or other personal
                rights is prohibited. EasyWebVideo.com is required by law to
                remove or block access to content appearing on or through the
                Services upon receipt of proper notice of copyright infringement
                (see "Copyright Infringement Notice Information" below).
              </li>
              <li>
                <strong>Hacking</strong>. "Hacking" and related activities are
                prohibited. "Hacking" includes, but is not limited to, the
                following activities: illegally or without authorization,
                accessing computers, accounts or networks, penetrating or
                attempting to penetrate security measures, port scans, stealth
                scans, and other activities designed to assist in hacking.
              </li>
              <li>
                <strong>Export Control Violations</strong>. The exportation of
                encryption software outside of the United States and/or
                violations of United States law relating to the exportation of
                software is prohibited. Subscriber may not export or transfer,
                directly or indirectly, any regulated product or information to
                anyone outside the United States without complying with all
                applicable statues, codes, ordnances, regulations, and rules
                imposed by U.S. federal, state or local law, or by any other
                applicable law.
              </li>
              <li>
                <strong>Child Pornography</strong>. The use of the Services to
                store, post, display, transmit, advertise or otherwise make
                available child pornography is prohibited. EasyWebVideo.com is
                required by law to, and will, notify law enforcement agencies
                when it becomes aware of the presence of child pornography on,
                or being transmitted through, the Services.
              </li>
              <li>
                <strong>Other Illegal Activities</strong>. The use of the
                Services to engage in any activity that is determined by
                EasyWebVideo.com, in its sole and absolute discretion, to be
                illegal is prohibited. EasyWebVideo.com will cooperate fully
                with appropriate law enforcement agencies in connection with any
                and all illegal activities occurring on or through the Services.
              </li>
              <li>
                <strong>
                  Obscene, Defamatory, Abusive or Threatening Language
                </strong>
                . Use of the Services to store, post, transmit, display or
                otherwise make available obscene, defamatory, harassing, abusive
                or threatening language is prohibited.
              </li>
              <li>
                <strong>Backup Storage</strong>. EasyWebVideo.com offers its
                Services to host videos, not to store data. Using an account as
                an online storage space for archiving electronic files is
                prohibited.
              </li>
              <li>
                <strong>Other Activities</strong>. Engaging in any activity
                that, in EasyWebVideo.com's sole and absolute discretion,
                disrupts, interferes with or is harmful to (or threatens to
                disrupt, interfere with, or be harmful to) the Services,
                EasyWebVideo.com's business, operations, reputation, goodwill,
                Subscribers and/or Subscriber relations, or the ability of
                EasyWebVideo.com's Subscribers to effectively use the Services
                is prohibited. Such prohibited activities include making
                available any program, product or service that is designed to or
                could be used to violate these Terms. In addition, the failure
                of Subscriber to cooperate with EasyWebVideo.com in correcting
                or preventing violations of these Terms by, or that result from
                the activity of, a subscriber, patron, customer, invitee,
                visitor, or guest of the Subscriber constitutes a violation of
                these Terms by Subscriber.
              </li>
            </ul>
          </li>
          <li>
            COPYRIGHT NOTICE INFRINGEMENT INFORMATION In accordance with the
            Digital Millennium Copyright Act, EasyWebVideo.com has adopted a
            policy that provides for termination of accounts hosted by
            EasyWebVideo.com that are found to infringe on copyrights of third
            parties. If a copyright holder believes that there has been a
            violation of his or her copyright on a video that is hosted by
            EasyWebVideo.com or an EasyWebVideo.com subsidiary, and the
            copyright holder wants EasyWebVideo.com to remove the content or
            disable the material in question, EasyWebVideo.com will remove the
            content or disable the material if the copyright holder provides us
            with all of the following information.
            <ul>
              <li>
                A signature of a person authorized to act on behalf of the owner
                of the exclusive right that is allegedly infringed.
                Identification of the copyrighted work that is claimed is being
                infringed, or, in the case of claimed infringement of multiple
                copyrighted works, a representative list of such works.
              </li>
              <li>
                Identification of the material that is claimed to be infringing
                or is the subject of infringing activity and that should be
                removed or access to which should be disabled, with information
                reasonably sufficient to permit us to locate the material.
              </li>
              <li>
                Information reasonably sufficient to permit us to contact the
                person giving the notification, such as an address and
                telephone, and, if available, an electronic mail address at
                which such person may be contacted.
              </li>
              <li>
                A statement that the person giving the notification has a good
                faith belief that use of the material in the manner complained
                of is not authorized by the copyright owner, its agent, or the
                law.
              </li>
              <li>
                A statement that the information in the notification is
                accurate, and under penalty of perjury, that the person giving
                the notification is authorized to act on behalf of the owner of
                the exclusive right that is allegedly infringed.
              </li>
            </ul>
          </li>
          <li>
            GENERAL
            <ul>
              <li>
                <strong>Promotional Pricing</strong>. EasyWebVideo.com may
                periodically offer "free" or discounted services or credits in
                connection with a promotional offer. Such promotional offers are
                honored only in connection with the specific promotional package
                to which they apply. In the event a Subscriber downgrades or
                otherwise changes his/her/its subscription to a subscription to
                which a promotional offer does not apply, Subscriber will
                forfeit any unused free credits offered under the promotional
                packaged and EasyWebVideo.com will charge Subscriber the
                prevailing fees for any free credits redeemed by Subscriber
                under the promotional package.
              </li>
              <li>
                <strong>Backups</strong>. For its own operational efficiencies
                and purposes, EasyWebVideo.com from time to time backs up data
                on its servers, but is under no obligation or duty to Subscriber
                to do so under these Terms. IT IS SOLELY SUBSCRIBER'S DUTY AND
                RESPONSIBILITY TO BACKUP SUBSCRIBER'S FILES AND DATA ON
                EasyWebVideo.com SERVERS, AND under no circumstance will
                EasyWebVideo.com be liable to anyone FOR DAMAGES OF ANY KIND
                under any legal theory for loss of Subscriber FILES AND/or data
                on any EasyWebVideo.com server.
              </li>
              <li>
                <strong>Monitoring and Disclosures</strong>. All activities
                occurring on, in, and/or via the Services or any account hosted
                by EasyWebVideo.com may be monitored, recorded, and examined by
                any authorized person, including as well law enforcement. In
                general, EasyWebVideo.com does not monitor its Subscribers'
                websites or activities to determine whether they are in
                compliance with these Terms. However, when and if
                EasyWebVideo.com becomes aware of any violation of these Terms,
                EasyWebVideo.com may take any lawful action, and in the event of
                illegal activity, will take action, to stop or correct such
                violation, including, but not limited to, shutting down an
                account, denying access to the Services or to the Internet via
                EasyWebVideo.com, and/or removing non-complying information. In
                addition, EasyWebVideo.com may take any lawful action against a
                Subscriber or a subscriber, patron, customer, invitee, visitor,
                or guest of such Subscriber because of the activities of such
                subscriber, patron, customer, invitee, visitor, or guest.
                EasyWebVideo.com reserves the right to take any such action even
                though such action may affect other subscribers, patrons,
                customers, invitees, visitors, or guests of the Subscriber.
                EasyWebVideo.com may disclose any information in its possession,
                including, without limitation, information about Subscribers,
                internet transmissions and website activity in order to comply
                with a court order, subpoena, summons, discovery request,
                warrant, statute, regulation, governmental request, or other
                legal process to protect EasyWebVideo.com or others from harm,
                and/or to ensure the proper operation of the Services.
                EasyWebVideo.com has no obligation to notify any person,
                including the Subscriber about whom information is sought, that
                EasyWebVideo.com has provided the information.
              </li>
              <li>
                <strong>Accurate Account Information</strong>. Subscriber must
                continually update and keep accurate and current Subscriber's
                contact information stored and saved on EasyWebVideo.com in
                order to avoid termination of Subscriber's EasyWebVideo.com
                account(s).
              </li>
              <li>
                <strong>Duty to Notify EasyWebVideo.com of Breach</strong>. If
                Subscriber discovers anyone on the EasyWebVideo.com system
                violating any of these Terms or notices anything suspicious from
                the EasyWebVideo.com network, Subscriber agrees to report the
                violation or suspicious activity to support @
                topnetsolutions.net for investigation.{' '}
              </li>
              <li>
                <strong>Reservation of Rights</strong>. EasyWebVideo.com
                reserves the right to refuse or to cancel service to any
                prospective Subscriber or existing Subscriber for any lawful
                reason at any time during Subscriber's hosting term with
                EasyWebVideo.com.
              </li>
              <li>
                <strong>Severability</strong>. These Terms are binding upon
                EasyWebVideo.com, all existing and prospective Subscribers, and
                upon the assigns, heirs, and successors of each. If any
                provision of these Terms is held by any court of competent
                jurisdiction to be invalid or otherwise unenforceable, the rest
                of these Terms shall, nevertheless, continue to be valid and in
                full force and effect, to the extent said remaining Terms are
                then otherwise consistent with the original intent of the Terms
                of Service.
              </li>
              <li>
                <strong>Governing Law</strong>. These Terms shall be governed by
                the laws of the Province of Ontario, Canada without reference to
                conflict of law principles. The United Nations Convention on the
                International Sale of Goods shall not apply and is hereby
                expressly excluded. By subscribing to or using any of the
                Services of EasyWebVideo.com, Subscriber agrees that all
                disputes, if any, involving EasyWebVideo.com shall be subject
                exclusively to the jurisdiction of the Provincial and Federal
                Courts within the Province of Ontario; provided, further, that
                all action brought against EasyWebVideo.com must be brought in
                Lanark County, Ontario and, if in Federal Court, in Ottawa,
                Ontario. Subscriber hereby agrees that it is subject to the in
                personam jurisdiction of said courts for all purposes in
                connection with these Terms and/or in connection with any claim
                or dispute involving EasyWebVideo.com. Subscriber hereby waives
                any and all objections that it has or might have, known or
                unknown, whether under Ontario long arm statute or otherwise, to
                the existence of said in personam jurisdiction. Subscriber
                agrees that it has no right to and shall not file or otherwise
                bring a lawsuit against EasyWebVideo.com outside the Province of
                Ontario; and, that Subscriber, if involved before a court in a
                lawsuit outside of the Province of Ontario, shall be deemed to
                support and to stipulate to a motion made by EasyWebVideo.com to
                dismiss said lawsuit with respect to EasyWebVideo.com.
              </li>
            </ul>
          </li>
          <li>
            WARRANTY DISCLAIMER - LIMITATIONS - INDEMNIFICATION
            <ul>
              <li>
                <strong>Warranty Disclaimer</strong>. YOU, THE SUBSCRIBER,
                ACKNOWLEDGE THAT THE SERVICES AND THE SOFTWARE ARE PROVIDED "AS
                IS, AS AVAILABLE" WITHOUT ANY WARRANTY OF ANY KIND.
                EASYWEBVIDEO.COM HEREBY DISCLAIMS ANY WARRANTY OR CONDITION WITH
                RESPECT TO THE QUALITY, PERFORMANCE OR FUNCTIONALITY OF THE
                SERVICES AND SOFTWARE, OR WITH RESPECT TO THE QUALITY OR
                ACCURACY OF ANY INFORMATION OBTAINED FROM OR AVAILABLE THROUGH
                USE OF THE SERVICES AND SOFTWARE, OR THAT THE SERVICES AND
                SOFTWARE WILL BE UNINTERRUPTED, ERROR-FREE OR FREE OF VIRUSES OR
                OTHER HARMFUL COMPONENTS. THE SERVICES AND SOFTWARE MAY CONTAIN
                ERRORS. NO ADVICE OR INFORMATION GIVEN BY EASYWEBVIDEO.COM OR
                EASYWEBVIDEO.COM'S REPRESENTATIVES INCLUDING, WITHOUT
                LIMITATION, SUBSCRIBER SUPPORT REPRESENTATIVES, SHALL CREATE A
                WARRANTY. EASYWEBVIDEO.COM DISCLAIMS ALL WARRANTIES OR
                CONDITIONS, EXPRESS, IMPLIED OR STATUTORY INCLUDING, WITHOUT
                LIMITATION, WARRANTIES OR CONDITIONS OF QUIET ENJOYMENT AND
                NON-INFRINGEMENT AND ANY IMPLIED WARRANTIES OR CONDITIONS OF
                MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
                MERCHANTABILITY OF COMPUTER PROGRAMS AND CONTENT.
                EASYWEBVIDEO.COM DOES NOT GUARANTEE THAT USERS WILL BE ABLE TO
                USE THE SERVICES AT TIMES OR LOCATIONS OF THEIR CHOOSING.
                EASYWEBVIDEO.COM DOES NOT WARRANT THAT THE SERVICES ARE
                COMPATIBLE WITH ANY THIRD PARTY SERVICE OR SOFTWARE, EVEN IF
                SUCH THIRD PARTY CLAIMS, REPRESENTS OR WARRANTS THAT SUCH
                SERVICE OR SOFTWARE IS COMPATIBLE WITH ANY SERVICE OR
                EASYWEBVIDEO.COM IN PARTICULAR.
              </li>
              <li>
                <strong>Limitations on EasyWebVideo.com's Liability</strong>.
                EASYWEBVIDEO.COM SHALL NOT BE LIABLE FOR NONPERFORMANCE OR DELAY
                IN PERFORMANCE CAUSED BY ANY REASON, WHETHER WITHIN OR OUTSIDE
                OF ITS CONTROL. IN NO EVENT SHALL EASYWEBVIDEO.COM BE LIABLE
                UNDER CONTRACT, NEGLIGENCE, TORT, CONVERSION, COPYRIGHT
                INFRINGEMENT, TRADEMARK INFRINGEMENT, STRICT LIABILITY OR ANY
                OTHER LEGAL OR EQUITABLE THEORY FOR ANY DIRECT, INDIRECT,
                INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES (INCLUDING,
                WITHOUT LIMITATION, LOSS OF USE, LOST PROFITS, LOSS OF DATA OR
                INFORMATION OF ANY KIND OR LOSS OF BUSINESS GOODWILL OR
                OPPORTUNITY) WHETHER OR NOT EASYWEBVIDEO.COM HAS BEEN ADVISED OF
                THE POSSIBILITY OF SUCH LOSS OR DAMAGES. EASYWEBVIDEO.COM SHALL
                NOT BE LIABLE FOR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS,
                SERVICES OR TECHNOLOGY. EASYWEBVIDEO.COM'S ENTIRE LIABILITY AND
                SUBSCRIBER'S EXCLUSIVE REMEDY WITH RESPECT TO ANY USE OF THE
                SERVICES IS THE CANCELLATION OF SUBSCRIBER'S ACCOUNT AS SET
                FORTH HEREIN. IN NO EVENT SHALL EASYWEBVIDEO.COM'S LIABILITY TO
                YOU, THE SUBSCRIBER, EXCEED THE GREATER OF ONE DOLLAR ($1.00) OR
                ANY AMOUNTS ACTUALLY PAID IN CASH BY YOU, THE SUBSCRIBER, TO
                EASYWEBVIDEO.COM FOR THE PRIOR ONE MONTH PERIOD. NO ACTION,
                REGARDLESS OF FORM, ARISING OUT OF THESE TERMS OR OUT OF THE
                SERVICES MAY BE BROUGHT BY YOU, THE SUBSCRIBER, MORE THAN ONE
                YEAR AFTER THE EVENT WHICH GAVE RISE TO THE CAUSE OF ACTION .
                SOME JURISDICTIONS DO NOT ALLOW A LIMITATION ON LIABILITY FOR
                NEGLIGENCE THAT CAUSES DEATH OR PERSONAL INJURY AND, IN SUCH
                JURISDICTIONS, EASYWEBVIDEO.COM'S LIABILITY SHALL BE LIMITED TO
                THE GREATEST EXTENT PERMITTED BY LAW.
              </li>
              <li>
                <strong>Indemnification of EasyWebVideo.com</strong>. You, the
                Subscriber, agree to defend, indemnify and hold
                EasyWebVideo.com, its affiliates and its sponsors, partners,
                other co-branders and the respective directors, officers and
                employees of each harmless from and against any and all claims,
                losses, damages, liabilities and costs (including, without
                limitation, reasonable attorneys' fees and court costs) arising
                out of or relating to your breach of any of these Terms or use
                by you or any third party of the Services, except to the extent
                the foregoing directly result from EasyWebVideo.com's own gross
                negligence or willful misconduct. EasyWebVideo.com reserves the
                right, at its own expense, to assume the exclusive defense and
                control of any matter otherwise subject to indemnification by
                you, the Subscriber.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          IF YOU, FOR YOURSELF OR ON BEHALF OF ONE OR MORE PERSONS YOU ARE
          REPRESENTING WITH RESPECT TO EASYWEBVIDEO.COM SERVICES, DO NOT AGREE
          TO ANY OF THE FOREGOING TERMS, YOU MUST, FOR YOURSELF AND ON BEHALF
          ANY SUCH PERSON(S), DISCONTINUE THE REGISTRATION PROCESS, DISCONTINUE
          YOUR USE OF THE SERVICES, AND, IF YOU ARE ALREADY A MEMBER, CANCEL
          YOUR EASYWEBVIDEO.COM ACCOUNT. BEGINNING NOW, ANY CONTINUATION BY YOU
          IN USING THE SERVICES CONSTITUTES FOR YOU AND THOSE REPRESENTED BY YOU
          AN EXPRESS AFFIRMATION AND COMMITMENT TO BE (OR TO CONTINUE TO BE, AS
          APPLICABLE) LEGALLY BOUND BY AND TO COMPLY WITH ALL OF THESE TERMS.
        </p>
      </>
    );
  }, []);

  return <StaticPage title={title} children={renderText} />;
};

export default TermOfUsePage;
