import cn from 'classnames';

import style from './CheckBox.module.css';
import { CheckBoxType } from './CheckBox.type';

const CheckBox = ({ setValue, title, value, className }: CheckBoxType) => {
  const clickHandler = () => {
    setValue(!value);
  };

  return (
    <div className={cn(style.container, className)} onClick={clickHandler}>
      <span className={style.i_checkbox}>
        <input
          readOnly
          type="checkbox"
          className={style.i_checkbox__real}
          checked={value}
        />
        <span className={style.i_checkbox__faux} />
      </span>
      {title && <span className={style.checkbox_text}>{title}</span>}
    </div>
  );
};

export default CheckBox;
