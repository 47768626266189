import { FunctionComponent } from 'react';

import cn from 'classnames';

import spriteImg from '../../assets/img/sprite.svg';
import styles from './NotificationComponent.module.css';
import { NotificationComponentProps } from './NotificationComponent.type';

const NotificationComponent: FunctionComponent<NotificationComponentProps> = ({
  className,
  count,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <svg role="img" className={styles.icon}>
        <use xlinkHref={`${spriteImg}#bell`} />
      </svg>
      <span className={styles.info}>
        <span className={styles.count}>{count || 3}</span>
        <span className={styles.text}>notifications</span>
      </span>
    </div>
  );
};

export default NotificationComponent;
