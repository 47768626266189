/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import UserInfo from '../../components/UserInfo/UserInfo';
import Footer from '../../containers/Footer/Footer';
import Header from '../../containers/Header/Header';
import UserVideo from '../../containers/UserVideo/UserVideo';
import { RootState } from '../../store';
import { getUserByIdRequest } from '../../store/users/users.action';
import { IUsers } from '../../store/users/users.type';
import { setUserId, bulkVideosRequest } from '../../store/video/video.action';
import { Params } from '../../utils';

const UserVideoPage = ({
  match: { params },
}: Params<{ videoId: string; userId: string }>) => {
  const {
    page,
    limit,
    selectedVideosId,
    userId,
    search: searchString,
    sortType,
    sortName,
  } = useSelector((state: RootState) => state.video);
  const { selectedFolder } = useSelector((state: RootState) => state.folder);
  const getQuery = useCallback(() => {
    let query = `?limit=${limit}&page=${page}&direction=${sortType}&field=${sortName}`;
    if (userId) {
      query += `&user-id=${userId}`;
    }
    if (selectedFolder) {
      query += `&folder_id=${selectedFolder.id}`;
    }
    if (searchString) {
      query += `&search=${searchString}`;
    }

    return query;
  }, [limit, page, sortType, sortName, userId, selectedFolder, searchString]);

  const dispatch = useDispatch();

  const { user }: IUsers = useSelector(({ users }: { users: IUsers }) => users);

  useEffect(() => {
    dispatch(setUserId(+params.userId));
    if (!user) {
      dispatch(getUserByIdRequest(params.userId));
    }
  }, [dispatch, params.userId, user]);

  const blockVideoHandler = useCallback(
    (value: boolean) => {
      dispatch(
        bulkVideosRequest({
          selectedVideosId,
          action: 'block',
          ban: value,
          query: getQuery(),
        }),
      );
    },
    [dispatch, getQuery, selectedVideosId],
  );

  if (!user) {
    return null;
  }

  const menuItems = [
    [
      {
        handler: () => blockVideoHandler(true),
        iconUrl: `${spriteImg}#videoBlock`,
        title: 'Block video',
      },
      {
        handler: () => blockVideoHandler(false),
        iconUrl: `${spriteImg}#videoUnblock`,
        title: 'Unblock video',
      },
    ],
  ];

  const renderMenu = (items: any, index: number) => {
    return <MenuHeader items={items} key={index} />;
  };

  return (
    <div className="enter_content">
      <Header isAdmin={true} notification={true}>
        <UserInfo user={user} backLink={`/admin/users/${userId}`} />
        {menuItems.map(renderMenu)}
      </Header>
      <UserVideo />
      <Footer />
    </div>
  );
};

export default UserVideoPage;
