import { CreateProfileHandler } from '../../containers/SetPaymentDetail/index.type';
import SetPaymentDetail from '../../containers/SetPaymentDetail/SetPaymentDetail';
import MainLayout from '../../layout/MainLayout/MainLayout';
import { useAppDispatch } from '../../store';
import { authLogoutRequest } from '../../store/auth/auth.action';
import { updateProfileRequest } from '../../store/profile/profile.action';

const SetPaymentDetailPage = () => {
  const dispatch = useAppDispatch();

  const cancelHandler = () => {
    dispatch(authLogoutRequest());
  };

  const approveHandler: CreateProfileHandler = (data) => {
    dispatch(
      updateProfileRequest({
        paymentMethod: data,
      }),
    );
  };

  return (
    <MainLayout>
      <SetPaymentDetail
        cancelHandler={cancelHandler}
        approveHandler={approveHandler}
      />
    </MainLayout>
  );
};

export default SetPaymentDetailPage;
