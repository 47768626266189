import {
  AddTextToGIFType,
  TextInfo,
} from '../containers/AddTextToGIF/AddTextToGIF.type';
import { Gif } from '../models';
import { Query, ResponseType } from '../utils';
import service from '../utils/httpClient';

export const getAllGif = async (
  query: Query['query'],
): Promise<ResponseType<Gif>> => {
  const path = '/api/gifs';
  const { data } = await service({
    method: 'GET',
    url: path + query,
  });
  return data as ResponseType<Gif>;
};

export const createGif = async (
  data: AddTextToGIFType & { textInfo: TextInfo } & Pick<Gif, 'redirectToUrl'>,
): Promise<Pick<Gif, 'id'> & { embed: string }> => {
  const { data: responseData } = await service({
    method: 'POST',
    url: '/api/gifs',
    data,
  });

  return responseData as Pick<Gif, 'id'> & { embed: string };
};

export const deleteGifById = async (id: Gif['id']): Promise<void> => {
  await service({
    method: 'DELETE',
    url: `/api/gifs/${id}`,
  });
};
