/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../../assets/img/sprite.svg';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import { RootState } from '../../../store';
import { updateProfileRequest } from '../../../store/profile/profile.action';
import { PromoCodeProps } from './PromoCode.type';

const PromoCode = (props: PromoCodeProps) => {
  const { profile } = useSelector((state: RootState) => state.profile);

  const dispatch = useDispatch();

  const { title } = props;
  const [promoCode, setPromoCode] = useState<string>(profile?.promoCode || '');

  useEffect(() => {
    setPromoCode(profile?.promoCode || '');
  }, [profile?.promoCode]);

  const updatePromoCodeHandler = () => {
    dispatch(
      updateProfileRequest({
        promoCode,
      }),
    );
  };

  return (
    <div className="promo_edit main_widget">
      <div className="main_widget_header">
        <div className="main_widget_title">
          <span className="main_widget_ico">
            <svg role="img">
              <use xlinkHref={`${spriteImg}#promocode`} />
            </svg>
          </span>
          {title}
        </div>
      </div>
      <div className="promo_edit_wrapper">
        <Input
          key="promocode"
          className="form_item"
          placeholder="Promocode"
          value={promoCode}
          setValue={setPromoCode}
        />
        <Button
          className="submit_btn"
          name="Apply"
          handler={updatePromoCodeHandler}
        />
      </div>
    </div>
  );
};

export default PromoCode;
