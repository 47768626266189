import { FunctionComponent, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import MobileSubMenu from '../../components/MobileSubMenu/MobileSubMenu';
import Footer from '../../containers/Footer/Footer';
import Header from '../../containers/Header/Header';
import UsersList from '../../containers/UsersList/UsersList';
import { setSelectedModal } from '../../store/modal/modal.action';
import { bulkUsersRequest } from '../../store/users/users.action';
import { IBulkData, IUsers } from '../../store/users/users.type';
import { MODAL_CREATE_USER, MODAL_SET_BAN } from '../../utils';

const UsersPage: FunctionComponent<{ inactive?: boolean }> = ({
  inactive = false,
}) => {
  const {
    items,
    itemsPerPage,
    sortType,
    sortColumn,
    page,
    selectedUsersId,
  }: IUsers = useSelector(({ users }: { users: IUsers }) => users);
  const dispatch = useDispatch();
  const query = useCallback(() => {
    let str = `?limit=${itemsPerPage}&page=${page}`;
    if (inactive) {
      str += '&inactive=true';
    } else {
      str += '&isActive=true&ban=false';
    }
    if (sortType) {
      str += `&direction=${sortType}`;
    }
    if (sortColumn) {
      str += `&field=${sortColumn}`;
    }
    return str;
  }, [itemsPerPage, page, inactive, sortType, sortColumn]);

  const usersCancelHandler = useCallback(() => {
    const setCancel: number[] = [];
    const unSetCancel: number[] = [];
    items?.forEach((element) => {
      if (selectedUsersId.indexOf(element.id) !== -1) {
        if (!element.isActive) {
          unSetCancel.push(element.id);
        } else {
          setCancel.push(element.id);
        }
      }
    });
    const data: IBulkData = {
      action: 'cancel',
      ids: selectedUsersId,
      isActive: setCancel < unSetCancel,
    };
    dispatch(bulkUsersRequest(data, query()));
  }, [dispatch, items, query, selectedUsersId]);

  const usersBanHandler = useCallback(() => {
    const setBan: number[] = [];
    const unSetBan: number[] = [];
    items?.forEach((element) => {
      if (selectedUsersId.indexOf(element.id) !== -1) {
        if (element.ban) {
          unSetBan.push(element.id);
        } else {
          setBan.push(element.id);
        }
      }
    });
    const data: IBulkData = {
      action: 'ban',
      ids: selectedUsersId,
      ban: setBan > unSetBan,
      banReason: null,
    };
    if (data.ban) {
      dispatch(
        setSelectedModal(MODAL_SET_BAN, {
          data: {
            id: selectedUsersId,
            query: query(),
          },
        }),
      );
      return;
    }

    dispatch(bulkUsersRequest(data, query()));
  }, [dispatch, items, query, selectedUsersId]);

  const usersDeleteHandler = useCallback(() => {
    dispatch(
      bulkUsersRequest(
        {
          action: 'delete',
          ids: selectedUsersId,
        },
        query(),
      ),
    );
  }, [dispatch, query, selectedUsersId]);

  const createUserHandler = useCallback(() => {
    dispatch(setSelectedModal(MODAL_CREATE_USER, { data: { query: query() } }));
  }, [dispatch, query]);

  const menuItems = [
    [
      {
        handler: usersBanHandler,
        iconUrl: `${spriteImg}#circle-block`,
        title: `${inactive ? 'Unban' : 'Ban'} account`,
      },
      {
        handler: usersDeleteHandler,
        iconUrl: `${spriteImg}#circle-minus`,
        title: 'Delete user',
      },
      {
        handler: createUserHandler,
        iconUrl: `${spriteImg}#circle-plus`,
        title: 'Create user',
        isHide: inactive,
      },
      {
        handler: usersCancelHandler,
        iconUrl: `${spriteImg}#circle-x`,
        title: `${inactive ? 'Uncancel' : 'Cancel'} account`,
      },
    ],
  ];

  const mobileMenuItems = [
    {
      name: 'Actions',
      icon: `${spriteImg}#settings`,
      items: menuItems[0].filter((i) => !i.isHide),
    },
  ];

  const renderMenu = (item: any, index: number) => {
    return <MenuHeader items={item} key={index} />;
  };

  return (
    <div className="enter_content">
      <Header isAdmin={true} notification={true}>
        {menuItems.map((item, index) =>
          renderMenu(
            item.filter((i) => !i.isHide),
            index,
          ),
        )}
      </Header>
      <MobileSubMenu blocks={mobileMenuItems} />
      <UsersList inactive={inactive} />
      <Footer />
    </div>
  );
};

export default UsersPage;
