import { VideoId } from '../../models';
import {
  CreateDropOffStatistics,
  VideoStatistics,
} from '../../models/Analytic';
import {
  AnalyticActionsTypes,
  CREATE_DROP_OFF_STATISTICS_FAILURE,
  CREATE_DROP_OFF_STATISTICS_REQUEST,
  CREATE_DROP_OFF_STATISTICS_SUCCESS,
  GET_VIDEO_ANALYTIC_FAILURE,
  GET_VIDEO_ANALYTIC_REQUEST,
  GET_VIDEO_ANALYTIC_SUCCESS,
} from './analytic.type';

export function getVideoAnalyticRequest(
  payload: VideoId,
): AnalyticActionsTypes {
  return {
    type: GET_VIDEO_ANALYTIC_REQUEST,
    payload,
  };
}

export function getVideoAnalyticSuccess(
  payload: VideoStatistics,
): AnalyticActionsTypes {
  return {
    type: GET_VIDEO_ANALYTIC_SUCCESS,
    payload,
  };
}

export function getVideoAnalyticFailure(): AnalyticActionsTypes {
  return {
    type: GET_VIDEO_ANALYTIC_FAILURE,
  };
}

export function createDropOffStatisticsRequest(
  payload: CreateDropOffStatistics,
): AnalyticActionsTypes {
  return {
    type: CREATE_DROP_OFF_STATISTICS_REQUEST,
    payload,
  };
}

export function createDropOffStatisticsSuccess(): AnalyticActionsTypes {
  return {
    type: CREATE_DROP_OFF_STATISTICS_SUCCESS,
  };
}

export function createDropOffStatisticsFailure(): AnalyticActionsTypes {
  return {
    type: CREATE_DROP_OFF_STATISTICS_FAILURE,
  };
}
