import { PopUpWrapperType } from '../../layout/PopUpWrapper/PopUpWrapper.type';
import { ModalType } from '../../utils';

type Id = string | number;
export type IModalState = {
  selectedModalType: ModalType;
  id?: Id;
  title?: string;
  data?: any;
} & Pick<PopUpWrapperType, 'mode'>;

export const SET_SELECTED_MODAL_TYPE = '@modal/SET_SELECTED_MODAL_TYPE';
export const CHANGE_MODAL_MODE = '@modal/CHANGE_MODAL_MODE';

type ISetModal = {
  type: typeof SET_SELECTED_MODAL_TYPE;
  payload: {
    modalType: ModalType;
    id?: Id;
    title?: string;
    data?: any;
  } & Pick<PopUpWrapperType, 'mode'>;
};

interface IChangeModalMode {
  type: typeof CHANGE_MODAL_MODE;
  payload: Pick<PopUpWrapperType, 'mode'>;
}

export type ModalActionsTypes = ISetModal | IChangeModalMode;
