import { NavLink } from 'react-router-dom';

import logoWhiteImg from '../../assets/img/logo-white.png';
import logoWhite2xImg from '../../assets/img/logo-white2x.png';
import spriteImg from '../../assets/img/sprite.svg';
import style from './Menu.module.css';

const Menu = () => {
  return (
    <aside className={style.container}>
      <div className={style.sidebar_logo}>
        <NavLink to="/" className={style.logo_white}>
          <img src={logoWhiteImg} srcSet={`${logoWhite2xImg} 2x`} alt="logo" />
        </NavLink>
      </div>
      <div className={style.sidebar_widgets}>
        <div className={style.sidebar_widget}>
          <div className={style.sidebar_widget__title}>
            <span className={style.sidebar_widget__ico}>
              <svg role="img">
                <use xlinkHref={`${spriteImg}#myaccount`} />
              </svg>
            </span>
            My account
          </div>
          <div className={style.sidebar_widget__body}>
            <ul className={style.sidebar_widget__menu}>
              <li>
                <NavLink to="/">View Usage</NavLink>
              </li>
              <li>
                <NavLink activeClassName={style.current} to="/settings">
                  Settings
                </NavLink>
              </li>
              <li>
                <NavLink to="/">Analytics</NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className={style.sidebar_widget}>
          <div className={style.sidebar_widget__title}>
            <span className={style.sidebar_widget__ico}>
              <svg role="img">
                <use xlinkHref={`${spriteImg}#video`}></use>
              </svg>
            </span>
            Video
          </div>
          <div className={style.sidebar_widget__body}>
            <ul className={style.sidebar_widget__menu}>
              <li>
                <NavLink exact={true} activeClassName={style.current} to="/">
                  My videos
                </NavLink>
              </li>
              <li>
                <NavLink to="/">How to</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={style.support}>
        <div className={style.sidebar_widget__title}>
          <span className={style.sidebar_widget__ico}>
            <svg role="img">
              <use xlinkHref={`${spriteImg}#support`}></use>
            </svg>
          </span>
          Support
        </div>
        <div className={style.sidebar_widget__body}>
          <ul className={style.sidebar_widget__menu}>
            <li>
              <NavLink to="/">Support</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Menu;
