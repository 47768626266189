import { Folder, CreateFolder, UpdateFolder } from '../../models';
import { SortType } from '../../utils/utilityTypes';

export interface IFolderState {
  folders: Folder[];
  selectedFolder: Folder | null;
  breadcrumbs: Folder[];
  editFolder: Folder | null;
  loading: boolean;
  sortName: string;
  sortType: SortType;
}

export const SET_FOLDER_SORT_TYPE = '@folder/SET_FOLDER_SORT_TYPE';
export const SET_SELECTED_FOLDER = '@folder/SET_SELECTED_FOLDER';
export const SET_EDIT_FOLDER = '@folder/SET_EDIT_FOLDER';

export const GET_FOLDER_LIST_REQUEST = '@folder/GET_FOLDER_LIST_REQUEST';
export const GET_FOLDER_LIST_SUCCESS = '@folder/GET_FOLDER_LIST_SUCCESS';
export const GET_FOLDER_LIST_FAILURE = '@folder/GET_FOLDER_LIST_FAILURE ';

export const CREATE_FOLDER_REQUEST = '@folder/CREATE_FOLDER_REQUEST';
export const CREATE_FOLDER_SUCCESS = '@folder/CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_FAILURE = '@folder/CREATE_FOLDER_FAILURE ';

export const UPDATE_FOLDER_REQUEST = '@folder/UPDATE_FOLDER_REQUEST';
export const UPDATE_FOLDER_SUCCESS = '@folder/UPDATE_FOLDER_SUCCESS';
export const UPDATE_FOLDER_FAILURE = '@folder/UPDATE_FOLDER_FAILURE ';

export const DELETE_FOLDER_REQUEST = '@folder/DELETE_FOLDER_REQUEST';
export const DELETE_FOLDER_SUCCESS = '@folder/DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAILURE = '@folder/DELETE_FOLDER_FAILURE ';

export const INIT_FOLDER_STATE = '@folder/INIT_FOLDER_STATE';

interface ISetFolderSortType {
  type: typeof SET_FOLDER_SORT_TYPE;
  payload: {
    sortType: SortType;
  };
}

interface ISetSelectedFolder {
  type: typeof SET_SELECTED_FOLDER;
  payload: {
    folder: Folder | null;
  };
}

interface ISetEditFolder {
  type: typeof SET_EDIT_FOLDER;
  payload: {
    folder: Folder | null;
  };
}

export interface IGetFolderListRequest {
  type: typeof GET_FOLDER_LIST_REQUEST;
  payload: {
    id: number | null;
  };
}

interface IGetFolderListSuccess {
  type: typeof GET_FOLDER_LIST_SUCCESS;
  payload: {
    folders: Folder[];
  };
}

interface IGetFolderListFailure {
  type: typeof GET_FOLDER_LIST_FAILURE;
}

export interface ICreateFolderRequest {
  type: typeof CREATE_FOLDER_REQUEST;
  payload: {
    folder: CreateFolder;
  };
}

interface ICreateFolderSuccess {
  type: typeof CREATE_FOLDER_SUCCESS;
  payload: {
    folder: Folder;
  };
}

interface ICreateFolderFailure {
  type: typeof CREATE_FOLDER_FAILURE;
}

export interface IUpdateFolderRequest {
  type: typeof UPDATE_FOLDER_REQUEST;
  payload: {
    folder: UpdateFolder;
  };
}

interface IUpdateFolderSuccess {
  type: typeof UPDATE_FOLDER_SUCCESS;
  payload: {
    folder: Folder;
  };
}

interface IUpdateFolderFailure {
  type: typeof UPDATE_FOLDER_FAILURE;
}

export interface IDeleteFolderRequest {
  type: typeof DELETE_FOLDER_REQUEST;
  payload: {
    id: number;
  };
}

interface IDeleteFolderSuccess {
  type: typeof DELETE_FOLDER_SUCCESS;
  payload: {
    id: number;
  };
}

interface IDeleteFolderFailure {
  type: typeof DELETE_FOLDER_FAILURE;
}

interface IInitFolderState {
  type: typeof INIT_FOLDER_STATE;
}

export type FolderActionsTypes =
  | ISetFolderSortType
  | ISetSelectedFolder
  | ISetEditFolder
  | IGetFolderListSuccess
  | IGetFolderListRequest
  | IGetFolderListFailure
  | ICreateFolderRequest
  | ICreateFolderSuccess
  | ICreateFolderFailure
  | IUpdateFolderRequest
  | IUpdateFolderSuccess
  | IUpdateFolderFailure
  | IDeleteFolderRequest
  | IDeleteFolderSuccess
  | IDeleteFolderFailure
  | IInitFolderState;
