import { UserFull, SortColumns, EditUserById, FeatureKeys } from '../../models';
import { MetaType, Query, SortType, PageType } from '../../utils';

export type IUsers = {
  items: UserFull[];
  user: UserFull | null;
  meta: MetaType;
  selectedUsersId: number[];
  page: number;
  itemsPerPage: number;
  onlyCanceled: boolean;
  onlyBaned: boolean;
  sortType: SortType;
  sortColumn: SortColumns;
  loading: boolean;
};

export interface BulkBan {
  action: 'ban';
  ids: number[];
  ban: boolean;
  banReason: string | null;
}

interface BulkCancel {
  action: 'cancel';
  ids: number[];
  isActive: boolean;
}

interface BulkDelete {
  action: 'delete';
  ids: number[];
}

export type IBulkData = BulkBan | BulkDelete | BulkCancel;

export const GET_USERS_LIST_REQUEST = '@user/GET_USERS_LIST_REQUEST';
export const GET_USERS_LIST_SUCCESS = '@user/GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAILURE = '@user/GET_USERS_LIST_FAILURE';

export const GET_USER_BY_ID_REQUEST = '@user/GET_USER_BY_ID_REQUEST';
export const GET_USER_BY_ID_SUCCESS = '@user/GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAILURE = '@user/GET_USER_BY_ID_FAILURE';

export const BULK_USERS_REQUEST = '@user/BULK_USERS_REQUEST';
export const BULK_USERS_SUCCESS = '@user/BULK_USERS_SUCCESS';
export const BULK_USERS_FAILURE = '@user/BULK_USERS_FAILURE';

export const SAVE_CHANGES_REQUEST = '@user/SAVE_CHANGES_REQUEST';
export const SAVE_CHANGES_SUCCESS = '@user/SAVE_CHANGES_SUCCESS';
export const SAVE_CHANGES_FAILURE = '@user/SAVE_CHANGES_FAILURE';

export const UPDATE_USER_BY_ID_REQUEST = '@user/UPDATE_USER_BY_ID_REQUEST';
export const UPDATE_USER_BY_ID_SUCCESS = '@user/UPDATE_USER_BY_ID_SUCCESS';
export const UPDATE_USER_BY_ID_FAILURE = '@user/UPDATE_USER_BY_ID_FAILURE';

export const DELETE_USER_BY_ID_REQUEST = '@user/DELETE_USER_BY_ID_REQUEST';
export const DELETE_USER_BY_ID_SUCCESS = '@user/DELETE_USER_BY_ID_SUCCESS';
export const DELETE_USER_BY_ID_FAILURE = '@user/DELETE_USER_BY_ID_FAILURE';

export const CHANGE_CHECK_BOX_VALUE = '@user/CHANGE_CHECK_BOX_VALUE';
export const SET_VIDEO_GENERATOR_CREDITS = '@user/SET_VIDEO_GENERATOR_CREDITS';

export const SET_SORT_COLUMN = '@user/SET_SORT_COLUMN';
export const SET_SELECT_USER = '@user/SET_SELECT';
export const SET_SELECT_ALL_USERS = '@user/SET_SELECT_ALL_USERS';
export const SET_PAGE = '@user/SET_PAGE';
export const SET_ONLY_BANED = '@user/SET_ONLY_BANED';
export const SET_ONLY_CANCELED = '@user/SET_ONLY_CANCELED';

export interface IGetUserListRequest {
  type: typeof GET_USERS_LIST_REQUEST;
  payload: Query;
}
export interface IGetUserListSuccess {
  type: typeof GET_USERS_LIST_SUCCESS;
  payload: {
    usersList: IUsers;
  };
}
export interface IGetUserListFailure {
  type: typeof GET_USERS_LIST_FAILURE;
}

export interface IGetUserByIdRequest {
  type: typeof GET_USER_BY_ID_REQUEST;
  payload: {
    userId: string;
  };
}
export interface IGetUserByIdSuccess {
  type: typeof GET_USER_BY_ID_SUCCESS;
  payload: {
    userProfile: IUsers['user'];
  };
}

export interface IGetUserByIdFailure {
  type: typeof GET_USER_BY_ID_FAILURE;
}

export interface ISetUserSelect {
  type: typeof SET_SELECT_USER;
  payload: {
    id: UserFull['id'];
  };
}
export interface ISetAllUsersSelect {
  type: typeof SET_SELECT_ALL_USERS;
  payload: {
    selectedAllUsers: boolean;
  };
}
export interface ISetPage {
  type: typeof SET_PAGE;
  payload: PageType;
}
export interface ISetOnlyCanceled {
  type: typeof SET_ONLY_CANCELED;
  payload: { value: boolean };
}
export interface ISetOnlyBaned {
  type: typeof SET_ONLY_BANED;
  payload: { value: boolean };
}
export interface ISetSortColumn {
  type: typeof SET_SORT_COLUMN;
  payload: { sortColumn: SortColumns; sortType: SortType };
}

export interface IBulkUsersRequest {
  type: typeof BULK_USERS_REQUEST;
  payload: {
    data: IBulkData;
    query?: string;
  };
}
export interface IBulkUsersSuccess {
  type: typeof BULK_USERS_SUCCESS;
}
export interface IBulkUsersFailure {
  type: typeof BULK_USERS_FAILURE;
}

export interface ISaveChangesRequest {
  type: typeof SAVE_CHANGES_REQUEST;
  payload: {
    features: UserFull['feature'];
  };
}
export interface ISaveChangesSuccess {
  type: typeof SAVE_CHANGES_SUCCESS;
}
export interface ISaveChangesFailure {
  type: typeof SAVE_CHANGES_FAILURE;
}

export interface IChangeCheckBoxValue {
  type: typeof CHANGE_CHECK_BOX_VALUE;
  payload: {
    featureName: FeatureKeys;
  };
}
export interface ISetVideoGeneratorCredits {
  type: typeof SET_VIDEO_GENERATOR_CREDITS;
  payload: {
    value: number;
  };
}

export interface IUpdateUserByIdRequest {
  type: typeof UPDATE_USER_BY_ID_REQUEST;
  payload: EditUserById;
}
export interface IUpdateUserByIdSuccess {
  type: typeof UPDATE_USER_BY_ID_SUCCESS;
}
export interface IUpdateUserByIdFailure {
  type: typeof UPDATE_USER_BY_ID_FAILURE;
}
export interface IDeleteUserByIdRequest {
  type: typeof DELETE_USER_BY_ID_REQUEST;
  payload: {
    id: UserFull['id'];
  };
}
export interface IDeleteUserByIdSuccess {
  type: typeof DELETE_USER_BY_ID_SUCCESS;
}
export interface IDeleteUserByIdFailure {
  type: typeof DELETE_USER_BY_ID_FAILURE;
}

export type UsersActions =
  | IGetUserListRequest
  | IGetUserListSuccess
  | IGetUserListFailure
  | IGetUserByIdRequest
  | IGetUserByIdSuccess
  | IGetUserByIdFailure
  | IBulkUsersRequest
  | IBulkUsersSuccess
  | IBulkUsersFailure
  | ISetUserSelect
  | ISetPage
  | ISetOnlyCanceled
  | ISetOnlyBaned
  | ISetSortColumn
  | ISaveChangesRequest
  | ISaveChangesSuccess
  | ISaveChangesFailure
  | IChangeCheckBoxValue
  | ISetVideoGeneratorCredits
  | IUpdateUserByIdRequest
  | IUpdateUserByIdSuccess
  | IUpdateUserByIdFailure
  | IDeleteUserByIdRequest
  | IDeleteUserByIdSuccess
  | IDeleteUserByIdFailure
  | ISetAllUsersSelect;
