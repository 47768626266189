import { useMemo } from 'react';

import StaticPage from '../../layout/StaticPage/StaticPage';

const Settings = () => {
  const title = 'Privacy policy';
  const renderText = useMemo(() => {
    return (
      <>
        <h2>Introduction</h2>
        <p>
          Thank you for choosing to be part of our community at [business name]
          (“company”, “we”, “us”, or “our”). We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about our policy, or our practices with regards
          to your personal information, please contact us at [contact email].
        </p>
        <p>
          When you visit our website [website] (“Site”) and use our services,
          you trust us with your personal information. We take your privacy very
          seriously. In this privacy notice, we describe our privacy policy. We
          seek to explain to you in the clearest way possible what information
          we collect, how we use it and what rights you have in relation to it.
          We hope you take some time to read through it carefully, as it is
          important. If there are any terms in this privacy policy that you do
          not agree with, please discontinue use of our site and our services.
        </p>
        <p>
          This privacy policy applies to all information collected through our
          websites (such as [INSERT URL]), [our mobile] [or] [our Facebook
          applications] ("Apps"), and/or any related services, sales, marketing
          or events (we refer to them collectively in this privacy policy as the
          "Sites").
        </p>
        <p>
          <strong>
            Please read this privacy policy carefully as it will help you make
            informed decisions about sharing your personal information with us.
            <br />
            This privacy policy was created using Termly’s Privacy Policy
            Generator.
          </strong>
        </p>
        <ol>
          <li>WHAT INFORMATION DO WE COLLECT?</li>
          <li>HOW DO WE USE YOUR INFORMATION?</li>
          <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
        </ol>
        <h2>WHAT INFORMATION DO WE COLLECT?</h2>
        <p>
          <strong>Personal information you disclose to us</strong>
        </p>

        <p>
          In Short: We collect personal information that you provide to us such
          as name, address, contact information, passwords and security data,
          payment information, and social media login data. We collect personal
          information that you voluntarily provide to us when [registering at
          the Sites or Apps,] expressing an interest in obtaining information
          about us or our products and services, when participating in
          activities on the Sites [(such as posting messages in our online
          forums or entering competitions, contests or giveaways)] or otherwise
          contacting us.
        </p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Sites, the choices you make and the
          products and features you use. The personal information we COLLECT can
          include the following:
        </p>
        <ul>
          <li>Name and Contact Data</li>
          <li>Credentials</li>
          <li>Payment Data</li>
        </ul>
      </>
    );
  }, []);

  return <StaticPage title={title} children={renderText} />;
};

export default Settings;
