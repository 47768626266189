import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { RootState } from '../../store';
import { authForgotPassRequest } from '../../store/auth/auth.action';
import { history } from '../../utils';
import style from './ForgotPassword.module.css';

const ForgotPassword = () => {
  const { loading } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>('');

  const submitHandler = () => {
    dispatch(authForgotPassRequest(email));
  };

  const redirectTo = (url: string): void => {
    history.push(url);
  };

  return (
    <>
      <div
        className={style.close}
        onClick={() => {
          redirectTo('/login');
        }}
      ></div>
      <div className={style.modal_form_title}>Forgot password?</div>
      <div className={style.modal_form_subtitle}>
        Enter your email and we will send you instruction for change password.
      </div>

      <Input
        key="email"
        className={style.form_item}
        placeholder="Email Address"
        value={email}
        setValue={setEmail}
      />

      <div className={style.form_item}>
        <Button
          disabled={loading}
          className={style.submit_btn}
          name="Send"
          handler={submitHandler}
        />
      </div>
    </>
  );
};

export default ForgotPassword;
