import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../store';
import {
  createFolderRequest,
  updateFolderRequest,
} from '../../../store/folder/folder.action';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import Loader from '../../Loader/Loader';
import style from './ModalFolder.module.css';

const ModalFolder = () => {
  const { selectedFolder, editFolder, loading } = useSelector(
    (state: RootState) => state.folder,
  );
  const [name, setName] = useState<string>(
    (editFolder && editFolder.name) || '',
  );
  const dispatch = useDispatch();

  const handler = () => {
    const data = {
      ...(selectedFolder && { parentId: selectedFolder.id }),
      name,
    };

    if (editFolder) {
      dispatch(updateFolderRequest({ id: editFolder.id, ...data }));
    } else {
      dispatch(createFolderRequest(data));
    }
  };

  return (
    <div className={style.container}>
      {
        <>
          <div className={style.item}>
            <Input value={name} setValue={setName} />
          </div>
          <div className={style.item}>
            <Button
              disabled={loading}
              handler={handler}
              name={editFolder ? 'Rename' : 'Create'}
              className={style.button}
            />
          </div>
        </>
      }
      {loading && <Loader />}
    </div>
  );
};

export default ModalFolder;
