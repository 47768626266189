import { useCallback } from 'react';

import { NavLink } from 'react-router-dom';

import logoWhiteImg from '../../assets/img/logo-white.png';
import logoWhite2xImg from '../../assets/img/logo-white2x.png';
import spriteImg from '../../assets/img/sprite.svg';
import { useAppDispatch } from '../../store';
import { setOnlyCanceled, setOnlyBaned } from '../../store/users/users.action';
import style from './AdminMenu.module.css';

const Menu = () => {
  const dispatch = useAppDispatch();
  const resetUserPage = useCallback(() => {
    dispatch(setOnlyCanceled(false));
    dispatch(setOnlyBaned(false));
  }, [dispatch]);

  return (
    <aside className={style.container}>
      <div className={style.sidebar_logo}>
        <NavLink to="/" className={style.logo_white}>
          <img src={logoWhiteImg} srcSet={`${logoWhite2xImg} 2x`} alt="logo" />
        </NavLink>
      </div>
      <div className={style.sidebar_widgets}>
        <div className={style.sidebar_widget}>
          <div className={style.sidebar_widget__title}>
            <span className={style.sidebar_widget__ico}>
              <svg role="img">
                <use xlinkHref={`${spriteImg}#user`} />
              </svg>
            </span>
            Managment
          </div>
          <div className={style.sidebar_widget__body}>
            <ul className={style.sidebar_widget__menu}>
              <li>
                <NavLink
                  activeClassName={style.current}
                  to="/admin/users"
                  onClick={resetUserPage}
                >
                  Users
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName={style.current} to="/admin/inactive">
                  Inactive users
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={style.support}>
        <div className={style.sidebar_widget__title}>
          <span className={style.sidebar_widget__ico}>
            <svg role="img">
              <use xlinkHref={`${spriteImg}#settings`}></use>
            </svg>
          </span>
          Settings
        </div>
        <div className={style.sidebar_widget__body}>
          <ul className={style.sidebar_widget__menu}>
            <li>
              <NavLink to="/">Notifications</NavLink>
            </li>
            <li>
              <NavLink to="/">Support</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Menu;
