import { useEffect, useState } from 'react';

import cn from 'classnames';

import { getTimeValue } from '../../utils';
import style from './Time.module.css';
import { TimeType } from './Time.type';

const Time = ({ name, setValue, value, className }: TimeType) => {
  const [time, setTime] = useState<string>('00:00:00');

  useEffect(() => {
    setTime(getTimeValue(value));
  }, [value]);

  const handlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const EO = event.currentTarget;
    const newValue = EO.value;

    setTime(newValue);
  };

  const setNewValue = (item: number) => {
    setTime(getTimeValue(item));
    setValue(item);
  };

  const updateTime = () => {
    if (!/^\d{1,2}:\d{2}:\d{2}$/.test(time) || !time) {
      setNewValue(0);
      return;
    }

    const times = time.split(':');

    const newTime = +times[2] + +times[1] * 60 + +times[0] * 3600;

    setNewValue(newTime);
  };

  return (
    <div className={cn(style.container, className)}>
      {name && <div className={style.name}>{name}</div>}

      <input
        className={style.input}
        type="text"
        value={time}
        onChange={handlerChange}
        onBlur={updateTime}
      />
    </div>
  );
};

export default Time;
