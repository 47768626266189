import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../../assets/img/sprite.svg';
import CheckBox from '../../../components/CheckBox/CheckBox';
import Input from '../../../components/Input/Input';
import { ColorSchemaType } from '../../../models';
import { RootState } from '../../../store';
import { updateProfileRequest } from '../../../store/profile/profile.action';
import { DEFAULT_COLOR_SCHEME } from '../../../utils';
import { ColorSchemeProps } from './ColorScheme.type';

const ColorScheme = (props: ColorSchemeProps) => {
  const { title } = props;
  const [colorScheme, setColorScheme] = useState<ColorSchemaType>(
    DEFAULT_COLOR_SCHEME,
  );
  const { profile } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile?.profile?.colorScheme) {
      setColorScheme(profile.profile.colorScheme);
    }
  }, [profile]);

  const onChangeHandler = (key: string, value: string) => {
    setColorScheme({
      ...colorScheme,
      [key]: value,
    });
  };

  const onBlurHandler = () => {
    dispatch(updateProfileRequest({ profile: { colorScheme } }));
  };

  const onChangeCheckBox = (key: string, value: string) => {
    const newColorScheme = {
      ...colorScheme,
      [key]: value,
    };

    setColorScheme(newColorScheme);

    dispatch(
      updateProfileRequest({ profile: { colorScheme: newColorScheme } }),
    );
  };

  return (
    <div className="widget_block color_scheme">
      <div className="widget_block_header">
        <span className="widget_block_ico">
          <svg role="img">
            <use xlinkHref={`${spriteImg}#colorsheme`} />
          </svg>
        </span>
        {title}
      </div>
      <div className="widget_block_body">
        <div className="color_change_group">
          <Input
            id="playerCanvas"
            name="Player Canvas Color"
            className="color_change_item"
            type="color"
            value={colorScheme.playerCanvas}
            setValue={(value: string) => {
              onChangeHandler('playerCanvas', value);
            }}
            onBlur={onBlurHandler}
          />
          <CheckBox
            title="Transparent canvas"
            className="color_change_item"
            value={colorScheme.transparentCanvas}
            setValue={(value: string) => {
              onChangeCheckBox('transparentCanvas', value);
            }}
          />
          <Input
            id="playerPlayButton"
            name="Player Play button"
            className="color_change_item"
            type="color"
            value={colorScheme.playerPlayButton}
            setValue={(value: string) => {
              onChangeHandler('playerPlayButton', value);
            }}
            onBlur={onBlurHandler}
          />
          <Input
            id="playerControlBar"
            name="Player Control bar"
            className="color_change_item"
            type="color"
            value={colorScheme.playerControlBar}
            setValue={(value: string) => {
              onChangeHandler('playerControlBar', value);
            }}
            onBlur={onBlurHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default ColorScheme;
