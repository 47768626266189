import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import LogoEditor from '../../components/LogoEditor/LogoEditor';
import { WatermarkInfo } from '../../components/LogoEditor/LogoEditor.type';
import UploadPicture from '../../components/UploadPicture/UploadPicture';
import { RootState } from '../../store';
import { setSelectedModal } from '../../store/modal/modal.action';
import { uploadVideoImageRequest } from '../../store/video/video.action';
import { history } from '../../utils';
import { AddWatermarkType, Mode } from './AddWatermark.type';

const AddWatermark = (props: AddWatermarkType) => {
  const { videoId } = props;

  const dispatch = useDispatch();

  const { video } = useSelector((state: RootState) => state.video);
  const source = video?.videoSource || '';

  const [mode, setMode] = useState<Mode>('upload');
  const [image, setImage] = useState<Blob>();

  const uploadHandler = (blob: Blob | null) => {
    if (!blob) {
      return;
    }

    setImage(blob);
    setMode('position');
  };

  const createLogoHandler = (watermarkInfo: WatermarkInfo) => {
    dispatch(
      uploadVideoImageRequest({
        videoId,
        image: image as Blob,
        type: 'watermark',
        watermarkInfo,
      }),
    );
    dispatch(setSelectedModal(null));
    history.push('/');
  };

  return mode === 'upload' ? (
    <UploadPicture handler={uploadHandler} />
  ) : (
    <>
      <LogoEditor
        logoSrc={URL.createObjectURL(image)}
        videoSrc={source}
        handler={createLogoHandler}
      />
    </>
  );
};

export default AddWatermark;
