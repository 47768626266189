import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import { OptionType } from '../../components/Select/Select.type';
import Time from '../../components/Time/Time';
import VideoInterval from '../../components/VideoInterval/VideoInterval';
import { RootState } from '../../store';
import { setSelectedModal } from '../../store/modal/modal.action';
import { DEFAULT_COLOR_SCHEME, MODAL_ADD_TEXT_TO_GIF } from '../../utils';
import { GIFInfo } from '../AddTextToGIF/AddTextToGIF.type';
import style from './CreateGIF.module.css';
import { CreateGIFType } from './CreateGIF.type';

const CreateGIF = (props: CreateGIFType) => {
  const { videoId } = props;
  const dispatch = useDispatch();

  const { video } = useSelector((state: RootState) => state.video);

  const source = video?.videoSource || '';

  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  const sizes = [
    {
      value: '800:-1',
      name: 'Original (up to 800px)',
    },
    {
      value: '600:-1',
      name: 'Original (up to 600px)',
    },
    {
      value: '540:-1',
      name: '540xAUTO (for Tumblr)',
    },
    {
      value: '500:-1',
      name: '500xAUTO',
    },
    {
      value: '480:-1',
      name: '480xAUTO',
    },
    {
      value: '400:-1',
      name: '480xAUTO',
    },
    {
      value: '320:-1',
      name: '320xAUTO',
    },
    {
      value: '-1:480',
      name: 'AUTOx480',
    },
    {
      value: '-1:320',
      name: 'AUTOx320',
    },
    {
      value: '1200:300',
      name: 'up to 1200x300 (for wide banner)',
    },
    {
      value: '300:1200',
      name: 'up to 300x1200 (for skyscraper banner)',
    },
  ];

  const [curSize, setSize] = useState<OptionType>(sizes[0]);

  const fpss = [
    {
      value: 5,
      name: '5 (max 60 seconds)',
      maxDuration: 60,
    },
    {
      value: 7,
      name: '7 (max 40 seconds)',
      maxDuration: 40,
    },
    {
      value: 10,
      name: '10 (max 30 seconds)',
      maxDuration: 30,
    },
    {
      value: 12,
      name: '12 (max 25 seconds)',
      maxDuration: 25,
    },
    {
      value: 20,
      name: '20 (max 15 seconds)',
      maxDuration: 15,
    },
    {
      value: 25,
      name: '25 (max 10 seconds)',
      maxDuration: 10,
    },
  ];

  const [curFps, setFps] = useState<OptionType & { maxDuration: number }>(
    fpss[0],
  );

  const createGif = () => {
    dispatch(
      setSelectedModal(MODAL_ADD_TEXT_TO_GIF, {
        id: videoId,
        title: 'Create Animated GIF',
        data: {
          endTime,
          startTime,
          fps: curFps.value as GIFInfo['fps'],
          size: curSize.value as GIFInfo['size'],
        },
      }),
    );
  };

  const colorScheme = video?.playerConfig.colorScheme || DEFAULT_COLOR_SCHEME;

  return (
    <div className={style.container}>
      <VideoInterval
        duration={duration}
        endTime={endTime}
        setDuration={setDuration}
        setEndTime={setEndTime}
        setStartTime={setStartTime}
        startTime={startTime}
        videoId={videoId}
        source={source}
        maxDuration={curFps.maxDuration}
        colorScheme={colorScheme}
      />
      <div className={style.time_controls}>
        <Time
          key="start"
          name="Start"
          setValue={setStartTime}
          value={startTime}
          className={style.column}
        />
        <Time
          key="end"
          name="End"
          setValue={setEndTime}
          value={endTime}
          className={style.column}
        />
      </div>
      <div className={style.gif_settings}>
        <Select
          title="Size"
          curValue={curSize}
          setValue={setSize}
          options={sizes}
          defaultValue={sizes[0]}
          className={style.column}
        />
        <Select
          title="Frame rate (FPS)"
          curValue={curFps}
          setValue={setFps}
          options={fpss}
          defaultValue={fpss[0]}
          className={style.column}
        />
      </div>
      <Button
        name="Create"
        handler={createGif}
        className={style.create_button}
      />
    </div>
  );
};

export default CreateGIF;
