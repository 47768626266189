/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import HLS from 'hls.js';
import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import { PlayerConfig } from '../../models';
import { RootState } from '../../store';
import { createDropOffStatisticsRequest } from '../../store/analytic/analytic.action';
import { changeModalModel } from '../../store/modal/modal.action';
import { getVideoRequest } from '../../store/video/video.action';
import {
  APP_URL,
  DEFAULT_COLOR_SCHEME,
  DROP_OFF_STATISTICS_PERIOD,
  getHexOpacity,
  getTimeValue,
  history,
  Id,
} from '../../utils';
import IntervalSlider from '../IntervalSlider/IntervalSlider';
import Loader from '../Loader/Loader';
import SoundBar from '../SoundBar/SoundBar';
import style from './VideoPlayer.module.css';
import { VideoPlayerType } from './VideoPlayer.type';

const VideoPlayer = ({
  videoId,
  poster,
  hasFullScreen,
  hasMiniature,
  popUpHandler,
  startTime,
  isModal,
  sendStatistics,
}: VideoPlayerType) => {
  const { mode } = useSelector((state: RootState) => state.modal);

  const dispatch = useDispatch();

  const { video, loading } = useSelector((state: RootState) => state.video);

  // const isHls = video?.isHlsReady && format === 'hls';
  // TODO: disable hls
  const isHls = false;

  const source =
    (isHls ? `${video?.hlsSource}/master.m3u8` : video?.videoSource) || '';

  const fullBlockRef = useRef<HTMLDivElement>();
  const videoRef = useRef<HTMLVideoElement>();

  const [hls, setHls] = useState<HLS>();

  const [imageUrl, setImageUrl] = useState<string>();
  const [endAction, setEndAction] = useState<PlayerConfig['endAction']>();
  const [nextVideo, setNextVideo] = useState<Id['id']>();

  const [isPlayMode, setPlayMode] = useState<boolean>(false);
  const [curTime, setCurTime] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);
  const [endTime, setEndTime] = useState<number>(60);
  const [isInfinityEnd, setInfinityEnd] = useState<boolean>(false);

  const [isControlHide, setControlHide] = useState<boolean>(true);
  const [isFullscreen, setFullscreen] = useState<boolean>(false);
  const [isMute, setMute] = useState<boolean>(false);

  const endTimeWithFormat = getTimeValue(endTime);

  const fullscreenHandler = useCallback(() => {
    setFullscreen(!isFullscreen);
  }, [isFullscreen, setFullscreen]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', fullscreenHandler);

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenHandler);
    };
  }, [fullscreenHandler]);

  useEffect(() => {
    dispatch(getVideoRequest({ videoId }));
  }, [dispatch, videoId]);

  useEffect(() => {
    if (!sendStatistics) {
      return;
    }
    if (Math.round(curTime) % DROP_OFF_STATISTICS_PERIOD !== 0) {
      return;
    }

    dispatch(
      createDropOffStatisticsRequest({
        videoId,
        curTime,
      }),
    );
  }, [curTime, videoId, dispatch, sendStatistics]);

  useEffect(() => {
    return () => {
      hls?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (video) {
      const { playerConfig, imageUrl: videoImageUrl } = video;
      setImageUrl(videoImageUrl);

      setEndAction(playerConfig.endAction);
      setNextVideo(playerConfig.nextVideo);

      setControlHide(video.playerConfig.autoHideControls);

      if (!isHls) {
        return;
      }

      const newHls = new HLS({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        xhrSetup: (xhr, _url) => {
          xhr.withCredentials = true; // do send cookie
          xhr.setRequestHeader(
            'Access-Control-Allow-Headers',
            'Content-Type, Accept, X-Requested-With',
          );
          xhr.setRequestHeader('Access-Control-Allow-Origin', APP_URL);
          xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true');
        },
      });
      setHls(newHls);

      if (!videoRef.current) {
        return;
      }
      //
      // First check for native browser HLS support
      //
      if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = source;
        //
        // If no native HLS support, check if hls.js is supported
        //
      } else if (HLS.isSupported()) {
        newHls.attachMedia(videoRef.current);
        newHls.loadSource(source);

        newHls.on(HLS.Events.MANIFEST_PARSED, () => {
          if (playerConfig.startAction === 'autoPlay') {
            videoRef.current?.play();
          }
        });
      }

      switch (playerConfig.startAction) {
        case 'autoPlay':
          videoRef.current.play();
          break;
        case 'loadVideo':
          videoRef.current.preload = 'auto';
          break;
        default:
          videoRef.current.preload = 'metadata';
          break;
      }
      if (endAction === 'loop') {
        videoRef.current.loop = true;
      }
    }
  }, [isHls, source, video, endAction]);

  useEffect(() => {
    setImageUrl(poster);
  }, [poster]);

  useEffect(() => {
    if (!isControlHide) {
      setTimeout(() => {
        setControlHide(!!video?.playerConfig.autoHideControls);
      }, 6000);
    }
  }, [isControlHide, video?.playerConfig.autoHideControls]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    if (startTime !== undefined) {
      videoRef.current.currentTime = startTime;
    } else {
      videoRef.current.currentTime = 0;
      videoRef.current.load();
    }
  }, [startTime]);

  const changeInterval = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: number | number[],
  ) => {
    const newValue: number = value as number;

    setCurTime(newValue);
    if (videoRef.current) {
      videoRef.current.currentTime = newValue;
    }
  };

  const handlerMetadata: React.ReactEventHandler<HTMLVideoElement> = (
    event,
  ) => {
    const EO = event.target as any;

    if (EO.duration === Infinity) {
      EO.currentTime = 24 * 60 * 60;
      setInfinityEnd(true);
    } else {
      setEndTime(Math.round(EO.duration));
    }
  };

  const handlerDurationChange = async () => {
    if (videoRef.current) {
      const duration = Math.round(
        videoRef.current.duration === Infinity ? 1 : videoRef.current.duration,
      );

      setEndTime(duration);
      if (video) {
        switch (video.playerConfig.startAction) {
          case 'autoPlay':
            videoRef.current.muted = true;
            setMute(true);
            await videoRef.current.play();
            setPlayMode(true);

            break;
          case 'loadVideo':
            videoRef.current.preload = 'auto';
            break;
          default:
            videoRef.current.preload = 'metadata';
            break;
        }
      }
    }
  };

  const playPauseHandler = () => {
    if (isPlayMode) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }

    setPlayMode(!isPlayMode);
  };

  const timeUpdatedHandler = (event: any) => {
    if (isInfinityEnd) {
      setInfinityEnd(false);
      event.target.currentTime = 0;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const newCurTime = Math.ceil(event.target.currentTime);

    setCurTime(newCurTime);

    if (newCurTime >= endTime) {
      if (isHls) {
        return;
      }

      switch (endAction) {
        case 'pause':
          event.target.pause();
          setPlayMode(false);
          break;
        case 'stop':
          event.target.currentTime = 0;
          setPlayMode(false);

          setCurTime(0);
          event.target.pause();
          break;
        case 'playNext':
          if (nextVideo) {
            history.push(`/embed/:embedType/${nextVideo}`);
          }
          break;
        case 'loop':
          event.target.currentTime = 0;
          setCurTime(0);
          event.target.play();
          setPlayMode(true);
          break;
        default:
      }
    }
  };

  const volumeHandler = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: number | number[],
  ) => {
    const newVolume = value as number;

    setVolume(newVolume);

    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const muteHandler = (value: boolean) => {
    if (videoRef.current) {
      videoRef.current.muted = value;
    }
  };

  const changeMiniatureModeHandler = () => {
    const checkMode = mode || 'withoutFrame';
    const newMode = checkMode === 'withoutFrame' ? 'miniature' : 'withoutFrame';
    if (popUpHandler) popUpHandler();
    dispatch(changeModalModel({ mode: newMode }));
  };

  const changeFullscreenModeHandler = () => {
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      const videoElem: any = fullBlockRef.current;
      if (!videoElem) {
        return;
      }

      if (videoElem.requestFullscreen) {
        videoElem.requestFullscreen();
      } else if (videoElem.webkitRequestFullscreen) {
        /* Safari */
        videoElem.webkitRequestFullscreen();
      } else if (videoElem.msRequestFullscreen) {
        /* IE11 */
        videoElem.msRequestFullscreen();
      }
    }
  };

  const controlHideHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!video?.playerConfig.autoHideControls) {
        setControlHide(false);

        return;
      }

      const state = event.type === 'mouseleave';

      setControlHide(state);
    },
    [video?.playerConfig.autoHideControls],
  );

  const {
    playerCanvas,
    playerControlBar,
    playerPlayButton,
    transparentCanvas,
  } = video?.playerConfig.colorScheme || DEFAULT_COLOR_SCHEME;

  return (
    <div
      className={cn(style.container, isFullscreen && style.fullscreen_mode)}
      ref={fullBlockRef as any}
    >
      <div
        className={style.video_block_wrapper}
        onMouseEnter={controlHideHandler}
        onMouseLeave={controlHideHandler}
        onMouseMove={controlHideHandler}
      >
        <video
          // poster={imageUrl}
          onDurationChange={handlerDurationChange}
          onTimeUpdate={timeUpdatedHandler}
          onLoadedMetadata={handlerMetadata}
          src={source}
          className={cn(style.video, {
            [style.video_modal]: isModal,
            [style.video_miniature]: mode === 'miniature',
          })}
          style={{ backgroundImage: `url(${imageUrl})` }}
          ref={videoRef as any}
        />
        {mode === 'miniature' ? (
          <div
            className={cn(
              style.video_controls_miniature,
              isControlHide && 'hide',
            )}
          >
            <div
              className={style.miniature}
              onClick={changeMiniatureModeHandler}
            >
              <svg role="img" fill={playerPlayButton}>
                <use xlinkHref={`${spriteImg}#normalize`} />
              </svg>
            </div>

            {video?.playerConfig.playPauseButton && (
              <div
                onClick={playPauseHandler}
                className={style.video_player_play}
              >
                <svg role="img" fill={playerPlayButton}>
                  <use
                    xlinkHref={`${spriteImg}#${
                      isPlayMode ? 'pause' : 'play-unfulled'
                    }`}
                  />
                </svg>
              </div>
            )}

            <div className={style.video_player_time}>
              <span
                style={{ color: playerPlayButton }}
                className={style.video_player_time__current}
              >
                {getTimeValue(curTime)}
              </span>
              &nbsp;/&nbsp;
              <span
                style={{ color: playerPlayButton }}
                className={style.video_player_time__full}
              >
                {endTimeWithFormat}
              </span>
            </div>
          </div>
        ) : (
          <div
            className={cn(style.video_controls, isControlHide && 'hide')}
            style={{
              background: getHexOpacity(playerCanvas, transparentCanvas),
            }}
          >
            <div className={style.video_player_timeline}>
              <div
                style={{ color: playerPlayButton }}
                key="startTimer"
                className={style.timer}
              >
                {getTimeValue(curTime)}
              </div>
              <div className={style.interval_wrapper}>
                <IntervalSlider
                  onChange={changeInterval}
                  min={0}
                  name="interval"
                  step={1}
                  max={endTime}
                  value={curTime}
                  source={source}
                  color={playerControlBar}
                />
              </div>
              <div
                key="endTimer"
                style={{ color: playerPlayButton }}
                className={cn(style.timer, style.end_timer)}
              >
                {endTimeWithFormat}
              </div>
            </div>
            <div className={style.video_player_bottom}>
              {video?.playerConfig.playPauseButton && (
                <div
                  onClick={playPauseHandler}
                  className={style.video_player_play}
                >
                  <svg role="img" fill={playerPlayButton}>
                    <use
                      xlinkHref={`${spriteImg}#${
                        isPlayMode ? 'pause' : 'play'
                      }`}
                    />
                  </svg>
                </div>
              )}

              {video?.playerConfig.volumeControl && (
                <SoundBar
                  onMuteChangeHandler={muteHandler}
                  max={1}
                  step={0.001}
                  min={0}
                  onChange={volumeHandler}
                  value={volume}
                  color={playerControlBar}
                  svgColor={playerPlayButton}
                  isMute={isMute}
                  setMute={setMute}
                />
              )}
              <div
                style={{ color: playerPlayButton }}
                className={style.video_player_time}
              >
                <span className={style.video_player_time__current}>
                  {getTimeValue(curTime)}
                </span>
                &nbsp;/&nbsp;
                <span className={style.video_player_time__full}>
                  {endTimeWithFormat}
                </span>
              </div>

              <div className={style.right_blocks}>
                {video?.playerConfig.showLogoInPlayer &&
                  video.playerConfig.logoPatn && (
                    <img
                      alt="logo"
                      src={video.playerConfig.logoPatn}
                      className={style.player_logo}
                    />
                  )}

                {hasMiniature && !isFullscreen && (
                  <div
                    className={style.miniature}
                    onClick={changeMiniatureModeHandler}
                  >
                    <svg role="img" fill={playerPlayButton}>
                      <use xlinkHref={`${spriteImg}#miniature`} />
                    </svg>
                  </div>
                )}
                {hasFullScreen && (
                  <div
                    className={style.fullscreen}
                    onClick={changeFullscreenModeHandler}
                  >
                    <svg role="img" fill={playerPlayButton}>
                      <use
                        xlinkHref={`${spriteImg}#${
                          isFullscreen ? 'smallscreen' : 'fullscreen'
                        }`}
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default VideoPlayer;
