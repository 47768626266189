import { VideoId } from '../models';
import { CreateDropOffStatistics, VideoStatistics } from '../models/Analytic';
import service from '../utils/httpClient';

export const getVideoAnalytic = async ({
  videoId,
}: VideoId): Promise<VideoStatistics> => {
  const { data: responseData } = await service({
    method: 'GET',
    url: `/api/statistics/video/${videoId}`,
  });

  return responseData as VideoStatistics;
};

export const createDropOffAnalitycs = async ({
  videoId,
  curTime,
}: CreateDropOffStatistics): Promise<void> => {
  await service({
    method: 'POST',
    url: `/api/statistics/drop-off/${videoId}`,
    data: {
      curTime,
    },
  });
};
