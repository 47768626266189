import { User, Register, UploadFileType, Card } from '../models';
import { DeepPartial } from '../utils';
import service from '../utils/httpClient';

export const getProfile = async (): Promise<User> => {
  const res = await service({
    method: 'GET',
    url: '/api/profile',
  });

  return res.data as User;
};

export const createProfile = async (data: Register): Promise<void> => {
  await service({
    method: 'POST',
    url: '/api/users',
    data,
  });
};

export const updateProfile = async (
  data: DeepPartial<
    Omit<User, 'paymentMethod'> & {
      paymentMethod: {
        type: 'card' | 'paypal';
        cardInfo: Card;
      };
    }
  >,
): Promise<User> => {
  const res = await service({
    method: 'PUT',
    url: '/api/profile',
    data,
  });

  return res.data as User;
};

export const uploadFile = async (body: UploadFileType): Promise<User> => {
  const data = new FormData();
  data.append('file', body.file);
  data.append('image', body.image);

  const res = await service({
    method: 'POST',
    url: '/api/profile/upload',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data as User;
};
