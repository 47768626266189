import { Folder, CreateFolder, UpdateFolder } from '../../models';
import { SortType } from '../../utils/utilityTypes';
import {
  FolderActionsTypes,
  SET_FOLDER_SORT_TYPE,
  SET_SELECTED_FOLDER,
  SET_EDIT_FOLDER,
  GET_FOLDER_LIST_REQUEST,
  GET_FOLDER_LIST_SUCCESS,
  GET_FOLDER_LIST_FAILURE,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAILURE,
  UPDATE_FOLDER_REQUEST,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAILURE,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAILURE,
  INIT_FOLDER_STATE,
} from './folder.type';

export function setSelectedFolder(folder: Folder | null): FolderActionsTypes {
  return {
    type: SET_SELECTED_FOLDER,
    payload: {
      folder,
    },
  };
}

export function setEditFolder(folder: Folder | null): FolderActionsTypes {
  return {
    type: SET_EDIT_FOLDER,
    payload: {
      folder,
    },
  };
}

export function setFolderSortType(sortType: SortType): FolderActionsTypes {
  return {
    type: SET_FOLDER_SORT_TYPE,
    payload: {
      sortType,
    },
  };
}

export function getFolderListRequest(id: number | null): FolderActionsTypes {
  return {
    type: GET_FOLDER_LIST_REQUEST,
    payload: { id },
  };
}

export function getFolderListSuccess(folders: Folder[]): FolderActionsTypes {
  return {
    type: GET_FOLDER_LIST_SUCCESS,
    payload: {
      folders,
    },
  };
}

export function getFolderListFailure(): FolderActionsTypes {
  return {
    type: GET_FOLDER_LIST_FAILURE,
  };
}

export function createFolderRequest(folder: CreateFolder): FolderActionsTypes {
  return {
    type: CREATE_FOLDER_REQUEST,
    payload: {
      folder,
    },
  };
}

export function createFolderSuccess(folder: Folder): FolderActionsTypes {
  return {
    type: CREATE_FOLDER_SUCCESS,
    payload: {
      folder,
    },
  };
}

export function createFolderFailure(): FolderActionsTypes {
  return {
    type: CREATE_FOLDER_FAILURE,
  };
}

export function updateFolderRequest(folder: UpdateFolder): FolderActionsTypes {
  return {
    type: UPDATE_FOLDER_REQUEST,
    payload: {
      folder,
    },
  };
}

export function updateFolderSuccess(folder: Folder): FolderActionsTypes {
  return {
    type: UPDATE_FOLDER_SUCCESS,
    payload: {
      folder,
    },
  };
}

export function updateFolderFailure(): FolderActionsTypes {
  return {
    type: UPDATE_FOLDER_FAILURE,
  };
}

export function deleteFolderRequest(id: number): FolderActionsTypes {
  return {
    type: DELETE_FOLDER_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteFolderSuccess(id: number): FolderActionsTypes {
  return {
    type: DELETE_FOLDER_SUCCESS,
    payload: {
      id,
    },
  };
}

export function deleteFolderFailure(): FolderActionsTypes {
  return {
    type: DELETE_FOLDER_FAILURE,
  };
}

export function initFolderState(): FolderActionsTypes {
  return {
    type: INIT_FOLDER_STATE,
  };
}
