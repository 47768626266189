import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import { RootState } from '../../store';
import { updateProfileRequest } from '../../store/profile/profile.action';
import Button from '../Button/Button';
import Input from '../Input/Input';

const ChangeEmail = () => {
  const [email, setEmail] = useState('');
  const [isSave, setSave] = useState(false);
  const [isShownInfo, setIsInfoShown] = useState(false);
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const { profile } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile?.email) {
      setEmail(profile.email);
      setIsChangeEmail(profile.isChangeEmail || false);
    }
  }, [profile]);

  const emailHandler = () => {
    if (isChangeEmail) {
      if (isSave && profile && profile.email !== email) {
        dispatch(updateProfileRequest({ email }));
      }
      setSave(!isSave);
    }
  };

  return (
    <>
      <div className="show_info_input wrapper_input">
        <Input
          key="Email"
          className="form_item"
          placeholder="Email"
          value={email}
          setValue={setEmail}
          isDisabled={!isSave}
        />
        <span
          className="info_icon"
          onMouseEnter={() => setIsInfoShown(true)}
          onMouseLeave={() => setIsInfoShown(false)}
        >
          <svg role="img">
            <use xlinkHref={`${spriteImg}#info`} />
          </svg>
          {isShownInfo && (
            <div className="info_message">
              Can be changed once every 6 months
            </div>
          )}
        </span>
      </div>
      <Button
        className="submit_btn submit_change_email"
        name={!isSave ? 'Change email' : 'Save'}
        hasBackground={isSave}
        handler={emailHandler}
        disabled={!isChangeEmail}
      />
    </>
  );
};

export default ChangeEmail;
