import bytes from 'bytes';

import style from './ProgressInfo.module.css';
import { ProgressInfoType } from './ProgressInfo.type';

const ProgressInfo = ({ name, progress, size }: ProgressInfoType) => {
  return (
    <div className={style.container}>
      <div className={style.video_info}>
        <div className={style.video_name}>{name}</div>
        <div className={style.video_size}>
          {size &&
            bytes(size, {
              unit: 'mb',
            })}
        </div>
      </div>

      <div className={style.progress}>
        <div className={style.progress_full} />
        <div
          className={style.progress_done}
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressInfo;
