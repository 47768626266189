import { Register, User, UploadFileType, Card } from '../../models';
import { DeepPartial } from '../../utils';
import {
  ProfileActionsTypes,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  CREATE_PROFILE_REQUEST,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  CLEAR_PROFILE,
  INIT_STATUS,
} from './profile.type';

export function getProfileRequest(): ProfileActionsTypes {
  return {
    type: GET_PROFILE_REQUEST,
  };
}

export function getProfileSuccess(user: User): ProfileActionsTypes {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: {
      user,
    },
  };
}

export function getProfileFailure(): ProfileActionsTypes {
  return {
    type: GET_PROFILE_FAILURE,
  };
}

export function createProfileRequest(payload: {
  userData: Register;
  query?: string;
}): ProfileActionsTypes {
  return {
    type: CREATE_PROFILE_REQUEST,
    payload,
  };
}

export function createProfileSuccess(): ProfileActionsTypes {
  return {
    type: CREATE_PROFILE_SUCCESS,
  };
}

export function createProfileFailure(): ProfileActionsTypes {
  return {
    type: CREATE_PROFILE_FAILURE,
  };
}

export function updateProfileRequest(
  user: DeepPartial<
    Omit<User, 'paymentMethod'> & {
      paymentMethod: {
        type: 'card' | 'paypal';
        cardInfo: Card;
      };
    }
  >,
): ProfileActionsTypes {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: {
      user,
    },
  };
}

export function updateProfileSuccess(user: User): ProfileActionsTypes {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: {
      user,
    },
  };
}

export function updateProfileFailure(): ProfileActionsTypes {
  return {
    type: UPDATE_PROFILE_FAILURE,
  };
}

export function uploadFileRequest(
  payload: UploadFileType,
): ProfileActionsTypes {
  return {
    type: UPLOAD_FILE_REQUEST,
    payload,
  };
}

export function uploadFileSuccess(user: User): ProfileActionsTypes {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload: {
      user,
    },
  };
}

export function uploadFileFailure(): ProfileActionsTypes {
  return {
    type: UPLOAD_FILE_FAILURE,
  };
}

export function clearProfile(): ProfileActionsTypes {
  return {
    type: CLEAR_PROFILE,
  };
}

export function initStatusProfile(): ProfileActionsTypes {
  return {
    type: INIT_STATUS,
  };
}
