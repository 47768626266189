import { takeLatest, fork, call, ForkEffect, put } from 'redux-saga/effects';

import { saveFeatures } from '../../services/features.service';
import {
  getAllUser,
  bulkUsers,
  getUserProfile,
  updateUserById,
  deleteUserById,
} from '../../services/users.service';
import { alertError, alertSuccess } from '../alert/alert.action';
import {
  getUserListRequest,
  getUsersListSuccess,
  getUserListFailure,
  getUserByIdRequest,
  getUserByIdSuccess,
  getUserByIdFailure,
  bulkUsersSuccess,
  bulkUsersFailure,
  saveChangesSuccess,
  saveChangesFailure,
  updateUserByIdSuccess,
  updateUserByIdFailure,
  deleteUserByIdSuccess,
  deleteUserByIdFailure,
  setAllUserSelect,
} from './users.action';
import {
  IUsers,
  GET_USERS_LIST_REQUEST,
  GET_USER_BY_ID_REQUEST,
  UPDATE_USER_BY_ID_REQUEST,
  DELETE_USER_BY_ID_REQUEST,
  IGetUserListRequest,
  IGetUserByIdRequest,
  BULK_USERS_REQUEST,
  SAVE_CHANGES_REQUEST,
  IBulkUsersRequest,
  ISaveChangesRequest,
  IUpdateUserByIdRequest,
  IDeleteUserByIdRequest,
} from './users.type';

function* workerGetAllUsers(action: IGetUserListRequest) {
  const { query } = action.payload;
  try {
    const usersList: IUsers = yield call(getAllUser, query);
    yield put(getUsersListSuccess(usersList));
  } catch (error) {
    yield put(getUserListFailure());
    yield put(alertError(error));
  }
}

function* watchGetAllUsers() {
  yield takeLatest(GET_USERS_LIST_REQUEST, workerGetAllUsers);
}

function* workerGetUserById(action: IGetUserByIdRequest) {
  try {
    const { userId } = action.payload;
    const user: IUsers['user'] = yield call(getUserProfile, userId);
    yield put(getUserByIdSuccess(user));
  } catch (error) {
    yield put(getUserByIdFailure());
    yield put(alertError(error));
  }
}

function* watchGetUserById() {
  yield takeLatest(GET_USER_BY_ID_REQUEST, workerGetUserById);
}

function* workerBulkUsers(action: IBulkUsersRequest) {
  try {
    const { query, data } = action.payload;
    yield call(bulkUsers, data);
    yield put(bulkUsersSuccess());
    yield put(setAllUserSelect(false));
    if (query) yield put(getUserListRequest(query));
  } catch (error) {
    yield put(bulkUsersFailure());
    yield put(alertError(error));
  }
}

function* watchBulkUsers() {
  yield takeLatest(BULK_USERS_REQUEST, workerBulkUsers);
}

function* workerSaveFeature(action: ISaveChangesRequest) {
  try {
    const { features } = action.payload;
    yield call(saveFeatures, features);
    yield put(alertSuccess('Profile updated successfully!'));
    yield put(saveChangesSuccess());
  } catch (error) {
    yield put(alertError(error));
    yield put(saveChangesFailure());
  }
}

function* watchSaveFeature() {
  yield takeLatest(SAVE_CHANGES_REQUEST, workerSaveFeature);
}

function* workerUpdateUserById(action: IUpdateUserByIdRequest) {
  try {
    yield call(updateUserById, action.payload);
    yield put(getUserByIdRequest(`${action.payload.id}`));
    yield put(updateUserByIdSuccess());
    yield put(alertSuccess('Profile updated successfully!'));
  } catch (error) {
    yield put(alertError(error));
    yield put(updateUserByIdFailure());
  }
}

function* watchUpdateUserById() {
  yield takeLatest(UPDATE_USER_BY_ID_REQUEST, workerUpdateUserById);
}

function* workerDeleteUserById(action: IDeleteUserByIdRequest) {
  try {
    const { id } = action.payload;
    yield call(deleteUserById, id);
    yield put(alertSuccess('Profile updated successfully!'));
    yield put(deleteUserByIdSuccess());
  } catch (error) {
    yield put(alertError(error));
    yield put(deleteUserByIdFailure());
  }
}

function* watchDeleteUserById() {
  yield takeLatest(DELETE_USER_BY_ID_REQUEST, workerDeleteUserById);
}

export const usersWatchers: ForkEffect[] = [
  fork(watchGetAllUsers),
  fork(watchGetUserById),
  fork(watchBulkUsers),
  fork(watchSaveFeature),
  fork(watchUpdateUserById),
  fork(watchDeleteUserById),
];
