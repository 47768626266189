import { combineReducers } from 'redux';

import AlertReducer from './alert/alert.reducer';
import AnalyticReducer from './analytic/analytic.reducer';
import AuthReducer from './auth/auth.reducer';
import FolderReducer from './folder/folder.reducer';
import GifReducer from './gif/gif.reducer';
import ModalReducer from './modal/modal.reducer';
import paymentReducer from './payment/payment.reducer';
import playerConfigReducer from './playerConfig/playerConfig.reducer';
import ProfileReducer from './profile/profile.reducer';
import QuestionReducer from './question/question.reducer';
import usersReducer from './users/users.reducer';
import VideoReducer from './video/video.reducer';

export const rootReducer = combineReducers({
  alert: AlertReducer,
  auth: AuthReducer,
  folder: FolderReducer,
  modal: ModalReducer,
  profile: ProfileReducer,
  video: VideoReducer,
  question: QuestionReducer,
  gif: GifReducer,
  playerConfig: playerConfigReducer,
  analytic: AnalyticReducer,
  users: usersReducer,
  payment: paymentReducer,
});
