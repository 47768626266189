import { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store';
import { uploadVideoRequest } from '../../store/video/video.action';
import ProgressInfo from '../ProgressInfo/ProgressInfo';
import style from './UploadProgress.module.css';
import { UploadProgressType } from './UploadProgress.type';

const UploadProgress = (props: UploadProgressType) => {
  const { source, name, progress, size, file } = props;
  const [curProgress, setCurProgress] = useState<number>(progress);
  const { selectedFolder } = useSelector((state: RootState) => state.folder);

  const dispatch = useDispatch();

  const getQuery = useCallback(
    () => (selectedFolder ? `&folder_id=${selectedFolder.id}` : ''),
    [selectedFolder],
  );

  useEffect(() => {
    dispatch(
      uploadVideoRequest({
        video: file,
        setProgress: setCurProgress,
        name,
        query: getQuery(),
      }),
    );
  }, [file, setCurProgress, dispatch, name, getQuery]);

  return (
    <div className={style.container}>
      <video
        className={cn(style.tumb, curProgress === 100 && style.uploaded)}
        src={source}
      />

      <ProgressInfo name={name} progress={curProgress} size={size} />
    </div>
  );
};

export default UploadProgress;
