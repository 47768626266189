import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import CreateUser from '../../components/CreateUser/CreateUser';
import SocialButton from '../../components/SocialButton/SocialButton';
import { SocialNetwork } from '../../components/SocialButton/SocialButton.type';
import { RootState } from '../../store';
import { history } from '../../utils';
import style from './Registration.module.css';

const Registration = () => {
  const socialNetworks: SocialNetwork[] = ['google', 'facebook'];
  const { accessToken } = useSelector((state: RootState) => state.auth);

  const redirectTo = (url: string): void => {
    history.push(url);
  };

  useEffect(() => {
    if (accessToken) {
      redirectTo('/');
    }
  }, [accessToken]);

  return (
    <>
      <div className={style.login_social}>
        {socialNetworks.map((social) => (
          <SocialButton key={social} networkName={social} />
        ))}
      </div>
      <div className={style.text_seporrator}>or</div>
      <CreateUser submitBtnName="Registration" />
      <div className={style.text_login}>
        Already have an account?{' '}
        <span
          onClick={() => {
            redirectTo('/login');
          }}
        >
          Log in
        </span>
      </div>
      <div className={style.information_form}>
        By Creating account You agree to the{' '}
        <a href="/terms-of-use">Terms of use</a> and{' '}
        <a href="/privacy-policy">Privacy Polycy</a>
      </div>
    </>
  );
};

export default Registration;
