import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../../assets/img/sprite.svg';
import { PaymentMethodSelector } from '../../../components/PaymentMethod';
import { RootState } from '../../../store';
import { setSelectedModal } from '../../../store/modal/modal.action';
import { updateProfileRequest } from '../../../store/profile/profile.action';
import { MODAL_ENTER_CARD, PaymentMethod } from '../../../utils';
import { CreateProfileHandler } from '../../SetPaymentDetail/index.type';
import style from './index.module.css';

const SettingPaymentMethod = () => {
  const { profile, loading } = useSelector((state: RootState) => state.profile);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('card');

  const dispatch = useDispatch();

  useEffect(() => {
    setPaymentMethod(profile?.paymentMethod || 'card');
  }, [profile, loading]);

  const cancelHandler = () => {
    dispatch(setSelectedModal(null));
    setPaymentMethod(profile?.paymentMethod || 'card');
  };

  const approveHandler: CreateProfileHandler = (data) => {
    dispatch(
      updateProfileRequest({
        paymentMethod: data,
      }),
    );
  };

  const changePaymentMethod = (val: PaymentMethod) => {
    dispatch(
      setSelectedModal(MODAL_ENTER_CARD, {
        data: {
          cancelHandler,
          paymentMethod: val,
          approveHandler,
        },
      }),
    );
  };

  return (
    <div className="payment_edit main_widget">
      <div className="main_widget_header">
        <div className="main_widget_title">
          <span className="main_widget_ico">
            <svg role="img">
              <use xlinkHref={`${spriteImg}#basket`} />
            </svg>
          </span>
          Payment method
        </div>
      </div>

      <PaymentMethodSelector
        className={style.selector}
        setValue={changePaymentMethod}
        value={paymentMethod}
      />
    </div>
  );
};

export default SettingPaymentMethod;
