import { FunctionComponent, useCallback, useState } from 'react';

import cn from 'classnames';

import spriteImg from '../../../assets/img/sprite.svg';
import { SortColumns } from '../../../models';
import { TableHeaderProps, Column } from '../Table.type';
import styles from './TableHeader.module.css';

const TableHeader: FunctionComponent<TableHeaderProps> = ({
  selectedCount,
  columns = [],
  sortType,
  sortColumn,
  setSortColumn,
  onSelectAll,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const updateTitle = (
    title: string,
    selectableNames: { single: string; many: string },
  ) =>
    `Selected ${selectedCount} ${
      selectedCount === 1 ? selectableNames.single : selectableNames.many
    }`;

  const toggleSortMenu = useCallback(() => setIsOpen((i) => !i), []);

  const selectTh = (key: string) => (
    <th key={key} onClick={onSelectAll}>
      <div className={styles.selected}>
        {selectedCount ? (
          <svg role="img" width="11" height="9">
            <use xlinkHref={`${spriteImg}#check`} />
          </svg>
        ) : null}
      </div>
    </th>
  );

  const MobileSortMenu: FunctionComponent = () => (
    <ul className={styles.mobile_sort_menu}>
      {columns
        .filter((c) => c.sortName)
        .map((column) => (
          <li
            onClick={(e) => {
              e.preventDefault();
              setSortColumn(column.sortName as SortColumns);
            }}
            key={column.title}
            className={styles.mobile_sort_item}
          >
            {column.title}
          </li>
        ))}
    </ul>
  );

  const renderTh = (column: Column, id: number) => {
    if (column.isHide) {
      return null;
    }

    const { title, sortName, selectableNames } = column;
    const newTitle =
      selectedCount && selectableNames
        ? updateTitle(title, selectableNames)
        : title;

    if (column.type === 'select') {
      return selectTh(column.type);
    }

    return (
      <th
        className={cn({
          [styles.sortable_header]: sortName,
          [styles.desktop_hide]: column.leftBottom || column.rightBottom,
          [styles.mobile_hide]: id !== 1,
          [styles.mobile_header]: id === 1,
        })}
        key={column.title}
      >
        <span
          onClick={() => {
            setSortColumn(sortName as SortColumns);
          }}
        >
          {newTitle}
        </span>
        {sortName && (
          <span
            className={cn(styles.icon, {
              [styles.active]: sortColumn === sortName,
              [styles.rotate]: sortColumn === sortName && sortType === 'DESC',
            })}
          >
            <svg role="img" width="8" height="6">
              <use xlinkHref={`${spriteImg}#chevron_down`} />
            </svg>
          </span>
        )}
        {id === 1 && (
          <div
            className={cn(styles.icon, styles.mobile_sort, {
              [styles.active]: isOpen,
              [styles.rotate]: isOpen,
            })}
            onClick={toggleSortMenu}
          >
            <span>Sort</span>
            <svg role="img" width="8" height="6">
              <use xlinkHref={`${spriteImg}#chevron_down`} />
            </svg>
            {isOpen && <MobileSortMenu />}
          </div>
        )}
      </th>
    );
  };

  return (
    <thead className={styles.table_head}>
      <tr>{columns?.length && columns.map(renderTh)}</tr>
    </thead>
  );
};

export default TableHeader;
