import Footer from '../../containers/Footer/Footer';
import Header from '../../containers/Header/Header';
import style from './StaticPage.module.css';
import { StaticPageType } from './StaticPage.type';

const StaticPage = (props: StaticPageType) => {
  const { title, children } = props;

  return (
    <>
      <div className={style.enter_content}>
        <Header children={title} />
        <div className={style.body_full_width}>{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default StaticPage;
