import { QuestionActionsTypes, SET_QUESTION_ANSWER } from './question.type';

export function setQuestionAnswer(answer: boolean): QuestionActionsTypes {
  return {
    type: SET_QUESTION_ANSWER,
    payload: {
      answer,
    },
  };
}
