import { useEffect } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import spriteImg from '../../assets/img/sprite.svg';
import BarChart from '../../components/BarChart/BarChart';
import Loader from '../../components/Loader/Loader';
import Footer from '../../containers/Footer/Footer';
import Header from '../../containers/Header/Header';
import { RootState } from '../../store';
import { getVideoAnalyticRequest } from '../../store/analytic/analytic.action';
import style from './VideoAnalyticsPage.module.css';
import { VideoAnalyticsPageType } from './VideoAnalyticsPage.type';

const VideoAnalyticsPage = ({
  match: {
    params: { videoId },
  },
}: VideoAnalyticsPageType) => {
  const { browser, country, device, loading, dropOff } = useSelector(
    (state: RootState) => state.analytic,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVideoAnalyticRequest({ videoId }));
  }, [dispatch, videoId]);

  return (
    <>
      <div className="enter_content">
        <Header />
        <div className={style.graphic_block}>
          <div className={style.graphic_block_header}>
            <div className={style.graphic_block_title}>
              <span className={style.graphic_block_ico}>
                <svg role="img">
                  <use xlinkHref={`${spriteImg}#graphic`} />
                </svg>
              </span>
              View drop-off rate
            </div>
          </div>
          <div className={style.graphic_block_body}>
            <ResponsiveContainer>
              <AreaChart data={dropOff}>
                <CartesianGrid vertical={false} color="#4C6579" />
                <XAxis dataKey="name" />
                <YAxis dataKey="count" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="count"
                  stroke="#2891c5"
                  fill="#e5ecf5"
                  strokeWidth={6}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className={style.widgets_block_row}>
          <div className={style.widget_block}>
            <div className={style.widget_block_title}>
              Location breakdown of the viewers
            </div>
            <div className={style.widget_block_body}>
              <BarChart data={country} />
            </div>
          </div>
          <div className={style.widget_block}>
            <div className={style.widget_block_title}>Devices</div>
            <div className={style.widget_block_body}>
              <BarChart data={device} />
            </div>
          </div>
          <div className={cn(style.widget_block, style.right_widget)}>
            <div className={style.widget_block_title}>
              Browser used to view video
            </div>
            <div className={style.widget_block_body}>
              <BarChart data={browser} />
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {loading && <Loader />}
    </>
  );
};

export default VideoAnalyticsPage;
