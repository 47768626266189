import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { RootState } from '../../store';
import {
  authResetPassRequest,
  authForgotPassFailure,
} from '../../store/auth/auth.action';
import { history } from '../../utils';
import style from './ResetPassword.module.css';
import { ResetPasswordType } from './ResetPassword.type';

const ResetPassword = (props: ResetPasswordType) => {
  const { token } = props;

  const [password, setPassword] = useState<string>('');
  const { loading, resetPassword } = useSelector(
    (state: RootState) => state.auth,
  );
  const dispatch = useDispatch();

  const redirectTo = (url: string): void => {
    history.push(url);
  };

  useEffect(() => {
    if (resetPassword) {
      dispatch(authForgotPassFailure());
      redirectTo('/login');
    }
  }, [resetPassword, dispatch]);

  const submitHandler = () => {
    dispatch(
      authResetPassRequest({
        password,
        token,
      }),
    );
  };

  return (
    <>
      <div className={style.modal_form_title}>Enter new password</div>
      <Input
        key="password"
        className={style.form_item}
        placeholder="New password"
        type="password"
        value={password}
        setValue={setPassword}
      />

      <div className={style.form_item}>
        <Button
          disabled={loading}
          className={style.submit_btn}
          name="Save"
          handler={submitHandler}
        />
      </div>
    </>
  );
};

export default ResetPassword;
