/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import spriteImg from '../../assets/img/sprite.svg';
import { getTimeValue } from '../../utils';
import IntervalSlider from '../IntervalSlider/IntervalSlider';
import Loader from '../Loader/Loader';
import SoundBar from '../SoundBar/SoundBar';
import style from './VideoInterval.module.css';
import { VideoIntervalType } from './VideoInterval.type';

const VideoInterval = ({
  duration,
  endTime,
  setDuration,
  setEndTime,
  setStartTime,
  startTime,
  source,
  maxDuration,
  colorScheme,
}: VideoIntervalType) => {
  const { playerCanvas, playerControlBar, playerPlayButton } = colorScheme;

  const videoRef = useRef<HTMLMediaElement>();

  const [isLoading, setLoading] = useState<boolean>(true);

  const [isPlayMode, setPlayMode] = useState<boolean>(false);
  const [, setCurTime] = useState<number>(startTime);
  const [volume, setVolume] = useState<number>(1);
  const [isMute, setMute] = useState<boolean>(false);

  const endTimeWithFormat = getTimeValue(endTime);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = startTime;
    }
  }, [startTime]);

  useEffect(() => {
    if (maxDuration) {
      const newEndTime =
        endTime - startTime <= maxDuration ? endTime : startTime + +maxDuration;

      setEndTime(newEndTime);
    }
  }, [endTime, maxDuration, setEndTime, startTime]);

  const changeInterval = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: number | number[],
  ) => {
    const newValue: number[] = value as number[];
    setStartTime(newValue[0]);
    setEndTime(newValue[1]);
  };

  const handlerDurationChange = () => {
    if (videoRef.current) {
      const newDuration = Math.round(videoRef.current.duration);
      setEndTime(newDuration);
      setDuration(newDuration);
    }
    setLoading(false);
  };

  const playPauseHandler = () => {
    if (isPlayMode) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }

    setPlayMode(!isPlayMode);
  };

  const timeUpdatedHandler = (event: any) => {
    const newCurTime = Math.floor(event.target.currentTime);

    setCurTime(newCurTime);

    if (newCurTime >= endTime) {
      videoRef.current?.pause();
      event.target.currentTime = startTime;
      setPlayMode(false);
    }
  };

  const volumeHandler = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: number | number[],
  ) => {
    const newVolume = value as number;

    setVolume(newVolume);

    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const muteHandler = (value: boolean) => {
    if (videoRef.current) {
      videoRef.current.muted = value;
    }
  };

  return (
    <div className={style.container}>
      <div className={style.video_block_wrapper}>
        <video
          onDurationChange={handlerDurationChange}
          onTimeUpdate={timeUpdatedHandler}
          src={source}
          className={style.video}
          ref={videoRef as any}
        />
        <div
          className={style.video_controls}
          style={{ background: playerCanvas }}
        >
          <div className={style.video_player_timeline}>
            <div
              key="startTimer"
              className={style.timer}
              style={{ color: playerPlayButton }}
            >
              {getTimeValue(startTime)}
            </div>
            <div className={style.interval_wrapper}>
              <IntervalSlider
                onChange={changeInterval}
                min={0}
                name="interval"
                step={1}
                max={duration}
                value={[startTime, endTime]}
                source={source}
                color={playerControlBar}
              />
            </div>
            <div
              key="endTimer"
              className={cn(style.timer, style.end_timer)}
              style={{ color: playerCanvas }}
            >
              {endTimeWithFormat}
            </div>
          </div>
          <div className={style.video_player_bottom}>
            <div onClick={playPauseHandler} className={style.video_player_play}>
              <svg role="img" fill={playerPlayButton}>
                <use
                  xlinkHref={`${spriteImg}#${isPlayMode ? 'pause' : 'play'}`}
                ></use>
              </svg>
            </div>

            <SoundBar
              onMuteChangeHandler={muteHandler}
              max={1}
              step={0.001}
              min={0}
              onChange={volumeHandler}
              value={volume}
              color={playerControlBar}
              svgColor={playerPlayButton}
              isMute={isMute}
              setMute={setMute}
            />

            {/* <div className={style.video_player_time}>
              <span className={style.video_player_time__current}>
                {getTimeValue(curTime)}
              </span>
              &nbsp;/&nbsp;
              <span className={style.video_player_time__full}>
                {endTimeWithFormat}
              </span>
            </div> */}
          </div>
        </div>
      </div>

      {isLoading && <Loader />}
    </div>
  );
};

export default VideoInterval;
