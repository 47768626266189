import {
  CHANGE_MODAL_MODE,
  IModalState,
  ModalActionsTypes,
  SET_SELECTED_MODAL_TYPE,
} from './modal.type';

const initialState: IModalState = {
  selectedModalType: null,
  id: undefined,
  title: undefined,
  data: undefined,
  mode: undefined,
};

export default function modalReducer(
  state = initialState,
  action: ModalActionsTypes,
): IModalState {
  switch (action.type) {
    case SET_SELECTED_MODAL_TYPE:
      return {
        ...state,
        selectedModalType: action.payload.modalType,
        id: action.payload.id,
        title: action.payload.title,
        data: action.payload.data,
        mode: action.payload.mode,
      };
    case CHANGE_MODAL_MODE:
      return {
        ...state,
        mode: action.payload.mode,
      };
    default:
      return state;
  }
}
