import { useState } from 'react';

import { useDispatch } from 'react-redux';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { setSelectedModal } from '../../store/modal/modal.action';
import { updateVideoRequest } from '../../store/video/video.action';
import style from './Rename.module.css';
import { RenameType } from './Rename.type';

const Rename = (props: RenameType) => {
  const { videoId, name, query } = props;
  const dispatch = useDispatch();

  const [title, setTitle] = useState<string>(name);

  const handler = () => {
    dispatch(
      updateVideoRequest({
        action: 'updateInfo',
        name: title,
        videoId,
        query,
      }),
    );

    dispatch(setSelectedModal(null));
  };

  return (
    <div className={style.container}>
      <Input setValue={setTitle} value={title} />
      <Button handler={handler} name="Rename" className={style.btn} />
    </div>
  );
};

export default Rename;
