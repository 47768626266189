import { useEffect, useCallback, FunctionComponent } from 'react';

import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import Table from '../../components/Table/Table';
import { Columns } from '../../components/Table/Table.type';
import TableControls from '../../components/Table/TableControls/TableControls';
import { SortColumns, UserFull } from '../../models';
import { useAppDispatch } from '../../store';
import {
  getUserListRequest,
  setUserSelect,
  setPage,
  setSortColumn,
  setAllUserSelect,
} from '../../store/users/users.action';
import { IUsers } from '../../store/users/users.type';
import { PageType } from '../../utils';
import styles from './UserList.module.css';

const UsersListContainer: FunctionComponent<{ inactive?: boolean }> = ({
  inactive = false,
}) => {
  const {
    items,
    meta,
    selectedUsersId,
    itemsPerPage,
    sortType,
    sortColumn,
    loading,
    page,
  }: IUsers = useSelector(({ users }: { users: IUsers }) => users);
  const dispatch = useAppDispatch();
  const query = useCallback(() => {
    let str = `?limit=${itemsPerPage}&page=${page}`;
    if (inactive) {
      str += '&inactive=true';
    } else {
      str += '&isActive=true&ban=false';
    }
    if (sortType) {
      str += `&direction=${sortType}`;
    }
    if (sortColumn) {
      str += `&field=${sortColumn}`;
    }
    return str;
  }, [itemsPerPage, page, inactive, sortType, sortColumn]);

  useEffect((): void => {
    dispatch(getUserListRequest(query()));
  }, [dispatch, itemsPerPage, sortType, sortColumn, inactive, query]);

  const dateFormat = 'MM/DD/YYYY';

  const userList: Columns<UserFull>[] = [
    {
      key: 'id',
      title: 'id',
      type: 'select',
    },
    {
      type: 'links',
      key: 'profile',
      title: 'Name',
      linkAttr: 'id',
      linkTo: 'admin/users/',
      selectableNames: { single: 'user', many: 'users' },
    },
    {
      type: 'string',
      key: 'platforms',
      title: 'Login method',
      rightBottom: true,
    },
    {
      type: 'string',
      key: 'email',
      title: 'Email',
      leftBottom: true,
    },
    {
      type: 'string',
      key: 'plan',
      title: 'Plan',
      sortName: 'plan',
    },
    {
      type: 'string',
      key: 'videoCount',
      title: 'Videos',
      sortName: 'video_count',
    },
    {
      type: 'date',
      key: 'cancellationDate',
      title: 'Cancellation Date',
      sortName: 'cancellation_date',
      dateFormat,
      isHide: !inactive,
    },
    {
      type: 'duration',
      key: 'createdAt',
      title: 'Duration',
      isHide: !inactive,
      sortName: 'created_at',
    },
    {
      type: 'string',
      key: 'storage',
      title: 'Storage',
      sortName: 'storage',
    },
    {
      type: 'string',
      key: 'bandwidth',
      title: 'Bandwidth',
      sortName: 'bandwidth',
    },
    {
      type: 'date',
      key: 'cancellationDate',
      title: 'Cancellation Date',
      sortName: 'cancellation_date',
      dateFormat,
      isHide: inactive,
    },
    {
      type: 'date',
      key: 'nextPaymentDate',
      title: 'Next Payment',
      sortName: 'next_payment_date',
      dateFormat,
      isHide: !inactive,
    },
    {
      type: 'date',
      key: 'lastLoginDate',
      title: 'Last Login',
      dateFormat,
      sortName: 'last_login_date',
    },
    {
      type: 'date',
      key: 'createdAt',
      title: 'Account Created',
      sortName: 'created_at',
      dateFormat,
    },
  ];

  const onSelectUserHandler = useCallback(
    (id: number) => {
      dispatch(setUserSelect(id));
    },
    [dispatch],
  );

  const sortHandler = useCallback(
    (newSortColumn: SortColumns) => {
      if (newSortColumn === sortColumn) {
        dispatch(
          setSortColumn(newSortColumn, sortType === 'ASC' ? 'DESC' : 'ASC'),
        );
      } else {
        dispatch(setSortColumn(newSortColumn, 'ASC'));
      }
    },
    [dispatch, sortColumn, sortType],
  );

  const onChangePage = (data: PageType) => {
    dispatch(setPage(data));
  };

  const onSelectAllHandler = useCallback(() => {
    dispatch(setAllUserSelect(!selectedUsersId.length));
  }, [dispatch, selectedUsersId.length]);

  return (
    <div className={styles.wrap}>
      <Table<UserFull>
        items={items}
        columns={userList}
        selectedItems={selectedUsersId}
        sortType={sortType}
        sortColumn={sortColumn}
        setSortColumn={sortHandler}
        onSelectHandler={onSelectUserHandler}
        onSelectAll={onSelectAllHandler}
        infoIcons={inactive}
      />
      {meta && (
        <TableControls
          onChangePage={onChangePage}
          pagination={meta}
          itemsPerPageOptions={[5, 10, 15, 20]}
          itemsLength={items.length}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default UsersListContainer;
