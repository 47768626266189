import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import { RootState } from '../../store';
import { authVerifyEmailRequest } from '../../store/auth/auth.action';
import { history } from '../../utils';
import { VerifyEmailPageProps } from './VerifyEmailPage.type';

const VerifyEmailPage = (props: VerifyEmailPageProps) => {
  const token = new URLSearchParams(props.location.search).get('token');

  const dispatch = useDispatch();

  const { accessToken, loading, isAdmin } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    if (accessToken) {
      history.push(isAdmin ? '/admin' : '/');
    }
  }, [accessToken, isAdmin]);

  useEffect(() => {
    if (!token) {
      history.push('/');
      return;
    }

    dispatch(authVerifyEmailRequest(token));
  }, [token, dispatch]);

  return loading ? <Loader /> : null;
};

export default VerifyEmailPage;
