import {
  EditUserById,
  SortColumns,
  UserFull,
  Feature,
  FeatureKeys,
} from '../../models';
import { Query, SortType, PageType } from '../../utils';
import {
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  SET_SELECT_USER,
  SET_SELECT_ALL_USERS,
  SET_PAGE,
  BULK_USERS_REQUEST,
  BULK_USERS_SUCCESS,
  BULK_USERS_FAILURE,
  SAVE_CHANGES_REQUEST,
  SAVE_CHANGES_SUCCESS,
  SAVE_CHANGES_FAILURE,
  DELETE_USER_BY_ID_REQUEST,
  DELETE_USER_BY_ID_SUCCESS,
  DELETE_USER_BY_ID_FAILURE,
  UPDATE_USER_BY_ID_REQUEST,
  UPDATE_USER_BY_ID_SUCCESS,
  UPDATE_USER_BY_ID_FAILURE,
  CHANGE_CHECK_BOX_VALUE,
  SET_ONLY_CANCELED,
  SET_ONLY_BANED,
  SET_SORT_COLUMN,
  SET_VIDEO_GENERATOR_CREDITS,
  UsersActions,
  IUsers,
  IBulkData,
} from './users.type';

export const getUserListRequest = (query: Query['query']): UsersActions => ({
  type: GET_USERS_LIST_REQUEST,
  payload: { query },
});

export const getUsersListSuccess = (usersList: IUsers): UsersActions => ({
  type: GET_USERS_LIST_SUCCESS,
  payload: { usersList },
});

export const getUserListFailure = (): UsersActions => ({
  type: GET_USERS_LIST_FAILURE,
});

export const getUserByIdRequest = (userId: string): UsersActions => ({
  type: GET_USER_BY_ID_REQUEST,
  payload: {
    userId,
  },
});

export const getUserByIdSuccess = (
  userProfile: IUsers['user'],
): UsersActions => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: {
    userProfile,
  },
});

export const getUserByIdFailure = (): UsersActions => ({
  type: GET_USER_BY_ID_FAILURE,
});

export const bulkUsersRequest = (
  data: IBulkData,
  query?: string,
): UsersActions => ({
  type: BULK_USERS_REQUEST,
  payload: {
    data,
    query,
  },
});

export const bulkUsersSuccess = (): UsersActions => ({
  type: BULK_USERS_SUCCESS,
});

export const bulkUsersFailure = (): UsersActions => ({
  type: BULK_USERS_FAILURE,
});

export const setUserSelect = (id: UserFull['id']): UsersActions => ({
  type: SET_SELECT_USER,
  payload: { id },
});

export const setAllUserSelect = (selectedAllUsers: boolean): UsersActions => ({
  type: SET_SELECT_ALL_USERS,
  payload: { selectedAllUsers },
});

export const setPage = (data: PageType): UsersActions => ({
  type: SET_PAGE,
  payload: data,
});

export const setOnlyBaned = (value: boolean): UsersActions => ({
  type: SET_ONLY_BANED,
  payload: { value },
});

export const setOnlyCanceled = (value: boolean): UsersActions => ({
  type: SET_ONLY_CANCELED,
  payload: { value },
});

export const setSortColumn = (
  sortColumn: SortColumns,
  sortType: SortType,
): UsersActions => ({
  type: SET_SORT_COLUMN,
  payload: {
    sortColumn,
    sortType,
  },
});

export const saveChangesRequest = (features: Feature): UsersActions => ({
  type: SAVE_CHANGES_REQUEST,
  payload: { features },
});

export const saveChangesSuccess = (): UsersActions => ({
  type: SAVE_CHANGES_SUCCESS,
});

export const saveChangesFailure = (): UsersActions => ({
  type: SAVE_CHANGES_FAILURE,
});

export const setVideoGeneratorCredits = (value: number): UsersActions => ({
  type: SET_VIDEO_GENERATOR_CREDITS,
  payload: { value },
});

export const changeCheckBoxValue = (featureName: FeatureKeys): UsersActions => {
  return {
    type: CHANGE_CHECK_BOX_VALUE,
    payload: { featureName },
  };
};

export const updateUserByIdRequest = (payload: EditUserById): UsersActions => ({
  type: UPDATE_USER_BY_ID_REQUEST,
  payload,
});
export const updateUserByIdSuccess = (): UsersActions => ({
  type: UPDATE_USER_BY_ID_SUCCESS,
});
export const updateUserByIdFailure = (): UsersActions => ({
  type: UPDATE_USER_BY_ID_FAILURE,
});

export const deleteUserByIdRequest = (id: UserFull['id']): UsersActions => ({
  type: DELETE_USER_BY_ID_REQUEST,
  payload: {
    id,
  },
});
export const deleteUserByIdSuccess = (): UsersActions => ({
  type: DELETE_USER_BY_ID_SUCCESS,
});
export const deleteUserByIdFailure = (): UsersActions => ({
  type: DELETE_USER_BY_ID_FAILURE,
});
