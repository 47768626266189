import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import Table from '../../components/Table/Table';
import { Columns } from '../../components/Table/Table.type';
import TableControls from '../../components/Table/TableControls/TableControls';
import { SortColumns, Video } from '../../models';
import { useAppDispatch, RootState } from '../../store';
import { setSelectedModal } from '../../store/modal/modal.action';
import {
  getVideoListRequest,
  setVideoPage,
  setSelectedVideosId,
  setSelectAllVideos,
  setSortName,
  setVideoSortType,
} from '../../store/video/video.action';
import { MODAL_PREVIEW_VIDEO, PageType } from '../../utils';
import styles from './UserVideo.module.css';

const UserVideo = () => {
  const {
    videos,
    page,
    limit,
    pagination,
    selectedVideosId,
    userId,
    search: searchString,
    loading,
    sortName,
    sortType,
  } = useSelector((state: RootState) => state.video);
  const { selectedFolder } = useSelector((state: RootState) => state.folder);
  const getQuery = useCallback(() => {
    let query = `?limit=${limit}&page=${page}&direction=${sortType}&field=${sortName}`;
    if (userId) {
      query += `&user-id=${userId}`;
    }
    if (selectedFolder) {
      query += `&folder_id=${selectedFolder.id}`;
    }
    if (searchString) {
      query += `&search=${searchString}`;
    }

    return query;
  }, [limit, page, sortType, sortName, userId, selectedFolder, searchString]);

  const [query, setQuery] = useState<string>(getQuery());
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId) setQuery(getQuery());
  }, [selectedFolder, page, limit, searchString, sortType, getQuery, userId]);

  useEffect(() => {
    dispatch(getVideoListRequest(query));
  }, [dispatch, selectedFolder, page, limit, searchString, query, sortType]);

  const dateFormat = 'MM/DD/YYYY';

  const showPreviewVideo = (id: Video[keyof Video]) => {
    if (typeof id === 'number') {
      dispatch(
        setSelectedModal(MODAL_PREVIEW_VIDEO, {
          id,
          mode: 'withoutFrame',
        }),
      );
    }
  };

  const videoColumns: Columns<Video>[] = [
    {
      key: 'id',
      title: 'id',
      type: 'select',
    },
    {
      key: 'name',
      title: 'Name',
      type: 'string',
      handlerClick: showPreviewVideo,
      selectableNames: { single: 'video', many: 'videos' },
      rowStyle: { cursor: 'pointer' },
    },
    {
      key: 'ban',
      type: 'boolean',
      title: 'Block Status',
      rowStyle: { color: 'red' },
      booleanToString: (value) => (value ? 'Blocked' : null),
      sortName: 'ban',
    },
    {
      key: 'size',
      type: 'string',
      title: 'Size',
      sortName: 'size',
    },
    {
      key: 'monthlyBandwidthUsage',
      type: 'string',
      title: 'Monthly bandwidth usage',
      sortName: 'monthlyBandwidthUsage',
    },
    {
      key: 'totalBandwidthUsage',
      type: 'string',
      title: 'Total bandwidth usage',
      sortName: 'totalBandwidthUsage',
    },
    {
      key: 'createdAt',
      title: 'Upload date',
      type: 'date',
      dateFormat,
      sortName: 'createdAt',
    },
  ];

  const onChangePage = (data: PageType): void => {
    dispatch(setVideoPage(data));
  };

  const onSelectUserHandler = useCallback(
    (id) => {
      dispatch(setSelectedVideosId(id));
    },
    [dispatch],
  );

  const onSelectAllHandler = useCallback(() => {
    dispatch(setSelectAllVideos(!selectedVideosId.length));
  }, [dispatch, selectedVideosId.length]);

  return (
    <div className={styles.wrap}>
      <Table<Video>
        items={videos}
        columns={videoColumns}
        selectedItems={selectedVideosId}
        sortType={sortType}
        sortColumn={sortName}
        setSortColumn={(name: SortColumns) => {
          if (name === sortName) {
            dispatch(setVideoSortType(sortType === 'ASC' ? 'DESC' : 'ASC'));
          } else {
            dispatch(setSortName(name));
          }
        }}
        onSelectHandler={onSelectUserHandler}
        onSelectAll={onSelectAllHandler}
      />
      {pagination && (
        <TableControls
          pagination={pagination}
          onChangePage={onChangePage}
          itemsLength={videos.length}
          itemsPerPageOptions={[5, 10, 15, 20]}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default UserVideo;
