import { useState } from 'react';

import cn from 'classnames';

import spriteImg from '../../../assets/img/sprite.svg';
import styles from './TableControls.module.css';
import { TableControlsProps } from './TableControls.type';

const TableControls = ({
  itemsPerPageOptions,
  buttons,
  pagination,
  onChangePage,
  itemsLength,
}: TableControlsProps) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const {
    limit,
    currentPage,
    totalItems,
    hasNextPage,
    hasPrevPage,
    prevPage,
    nextPage,
  } = pagination;
  const rangeItems = `${currentPage * limit - limit + 1}-${
    currentPage * limit > itemsLength ? itemsLength : currentPage * limit
  }`;

  return (
    <div className={styles.table_controls}>
      <div className={styles.page_pagination_left}>
        {itemsLength < totalItems && (
          <button
            className={styles.see_more}
            onClick={() =>
              onChangePage({
                limit: limit + limit > totalItems ? totalItems : limit + limit,
                currentPage,
              })
            }
          >
            See more
          </button>
        )}
        <ul className={styles.page_pagination}>
          <li>
            <span>
              {rangeItems} of {totalItems}
            </span>
          </li>
          <li className={styles.selector_block}>
            <span>Rows per page: </span>
            <div
              className={styles.selector}
              onClick={() => setOptionsVisible(!optionsVisible)}
            >
              <span>
                {limit}
                <svg
                  role="img"
                  width="8"
                  height="8"
                  style={{
                    transform: `rotate(${optionsVisible ? '180' : '0'}deg)`,
                  }}
                >
                  <use xlinkHref={`${spriteImg}#down_arrow`} />
                </svg>
              </span>
              <ul
                className={cn(styles.options, {
                  [styles.options_visible]: optionsVisible,
                })}
              >
                {itemsPerPageOptions.map((item) => (
                  <li
                    key={item}
                    onClick={() => {
                      setOptionsVisible(!optionsVisible);
                      onChangePage({ limit: item, currentPage });
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </li>
        </ul>
        {buttons && (
          <ul className={styles.buttons}>
            {buttons.map((item) => (
              <li key={item.title}>
                <button
                  onClick={item.handleClick}
                  className={cn(styles.button, item?.className)}
                >
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
      {limit < totalItems && (
        <div className={styles.page_pagination_right}>
          <ul className={styles.page_navigation}>
            <li>
              <button
                className={cn(styles.button, {
                  [styles.page_pagination_disabled]: !hasPrevPage,
                })}
                onClick={() =>
                  onChangePage({
                    limit,
                    currentPage: hasPrevPage ? prevPage : currentPage,
                  })
                }
              >
                <svg role="img" width="16" height="16">
                  <use xlinkHref={`${spriteImg}#chevron_left`} />
                </svg>
              </button>
            </li>
            <li>
              <button
                className={cn(styles.button, {
                  [styles.page_pagination_disabled]: !hasNextPage,
                })}
                onClick={() =>
                  onChangePage({
                    limit,
                    currentPage: hasNextPage ? nextPage : currentPage,
                  })
                }
              >
                <svg role="img" width="16" height="16">
                  <use xlinkHref={`${spriteImg}#chevron_left`} />
                </svg>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default TableControls;
