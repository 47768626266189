import { withStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';

const CustomSlider = (color = '#FFFFFF') =>
  withStyles({
    root: {
      color,
      height: 3,
      cursor: 'pointer',
      padding: 0,
    },
    thumb: {
      height: '16px',
      width: '16px',
      marginTop: '-7px',
      marginLeft: '-8px',
      backgroundColor: color,
    },
    track: {
      height: 3,
      zIndex: 1,
      color,
    },
    rail: {
      color,
      opacity: 0.5,
      height: 3,
      zIndex: 10,
    },
  })(Slider);

export default CustomSlider;
