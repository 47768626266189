import { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Card } from '../../models';
import { RootState } from '../../store';
import { setSelectedModal } from '../../store/modal/modal.action';
import { createProfileRequest } from '../../store/profile/profile.action';
import { MODAL_PAYMENT_DETAIL, PaymentMethod } from '../../utils';
import Button from '../Button/Button';
import Input from '../Input/Input';
import style from './CreateUser.module.css';
import { CreateUserProps } from './CreateUser.type';

const CreateUser = (props: CreateUserProps) => {
  const { submitBtnName, query } = props;

  const { loading } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState<string>('');
  const [firstNameValid, setFirstNameValid] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>('');
  const [lastNameValid, setLastNameValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordValid, setPasswordValid] = useState<boolean>(false);

  const approveHandler = useCallback(
    (paymentMethod: { cardInfo?: Card; type: PaymentMethod }) => {
      dispatch(
        createProfileRequest({
          userData: {
            firstName,
            lastName,
            email,
            password,
            ...(!query && { paymentMethod }),
          },
          query,
        }),
      );
    },
    [dispatch, email, firstName, lastName, password, query],
  );

  const submitHandler = () => {
    if (query) {
      dispatch(
        createProfileRequest({
          userData: {
            firstName,
            lastName,
            email,
            password,
          },
          query,
        }),
      );
      return;
    }

    dispatch(
      setSelectedModal(MODAL_PAYMENT_DETAIL, {
        data: {
          approveHandler,
        },
      }),
    );
  };

  const validateField = (fieldName: string, value: string): void => {
    switch (fieldName) {
      case 'firstName': {
        const result = value.length >= 1;
        setFirstName(value);
        setFirstNameValid(!!result);
        break;
      }
      case 'lastName': {
        const result = value.length >= 1;
        setLastName(value);
        setLastNameValid(!!result);
        break;
      }
      case 'email': {
        const re = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
        const result = re.exec(value);
        setEmail(value);
        setEmailValid(!!result);
        break;
      }
      case 'password': {
        const result = value.length >= 6;
        setPassword(value);
        setPasswordValid(!!result);
        break;
      }

      default:
        break;
    }
  };

  return (
    <form>
      <Input
        key="firstName"
        className={style.form_item}
        placeholder="First name"
        value={firstName}
        isValid={firstNameValid}
        validateText="Must NOT have fewer than 1 characters."
        setValue={(value: string) => validateField('firstName', value)}
      />
      <Input
        key="lastName"
        className={style.form_item}
        placeholder="Last name"
        value={lastName}
        isValid={lastNameValid}
        validateText="Must NOT have fewer than 1 characters."
        setValue={(value: string) => validateField('lastName', value)}
      />
      <Input
        key="email"
        className={style.form_item}
        placeholder="Email Address"
        value={email}
        isValid={emailValid}
        validateText='Must match format "email".'
        setValue={(value: string) => validateField('email', value)}
      />
      <Input
        key="password"
        className={style.form_item}
        placeholder="Password"
        value={password}
        isValid={passwordValid}
        validateText="Must NOT have fewer than 6 characters."
        setValue={(value: string) => validateField('password', value)}
        type="password"
      />
      <div className={style.form_item}>
        <Button
          disabled={
            loading ||
            !firstNameValid ||
            !passwordValid ||
            !emailValid ||
            !lastNameValid
          }
          className={style.submit_btn}
          name={submitBtnName}
          handler={submitHandler}
        />
      </div>
    </form>
  );
};

export default CreateUser;
