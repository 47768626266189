import Axios, { AxiosError, AxiosResponse } from 'axios';

import StorageSergice from '../services/storage/storage.service';
import { API_URL } from './constants';
import { TokenType } from './utilityTypes';

const defaultConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const clearToken = () => {
  StorageSergice.clearToken();
  window.location.href = '/login';
};

const server = Axios.create(defaultConfig);
const refreshTokenService = Axios.create(defaultConfig);

server.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${StorageSergice.getAccessToken()}`,
  };
  return config;
});

server.interceptors.response.use(undefined, (error: AxiosError) => {
  if (
    error.response?.status === 401 &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    error.response?.data.code === 'TOKEN_EXPIRED_ERROR'
  ) {
    const refreshToken = StorageSergice.getRefreshToken();
    if (refreshToken) {
      const originalRequest = error.config;

      return refreshTokenService({
        method: 'POST',
        url: '/auth/refresh',
        data: {
          refreshToken,
        },
      })
        .then((res: AxiosResponse<TokenType>) => {
          StorageSergice.setToken(res.data);
          return server(originalRequest);
        })
        .catch(() => {
          clearToken();
        });
    }
    clearToken();
  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (error.response?.status === 422 && error.response?.data?.errors) {
    let message = '';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    for (const prop in error.response.data.errors) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        Object.prototype.hasOwnProperty.call(error.response.data.errors, prop)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        message += `${prop}: ${error.response.data.errors[prop]}. `;
      }
    }

    return Promise.reject(message);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return Promise.reject(error.response?.data.message || error.toString());
});

export default server;
