// environment
export const ENV_DEVELOPMENT = 'development';
export const ENV_PRODUCTION = 'production';

export const API_URL = process.env.REACT_APP_API_URL;
export const APP_URL = process.env.REACT_APP_URL as string;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_CLIENT_ID = process.env
  .REACT_APP_GOOGLE_CLIENT_ID as string;
export const PAYPAL_CLIENT_ID = process.env
  .REACT_APP_PAYPAL_CLIENT_ID as string;

export const LIMIT_DEFAULT = 10;
export const PAGE_DEFAULT = 1;
export const LIMIT_PAGE = [
  { name: '5', value: 5 },
  { name: '10', value: 10 },
  { name: '100', value: 100 },
];

// modal
export const MODAL_IMPORT_VIDEO = 'modalImportVideo';
export const MODAL_UPLOAD_VIDEO = 'modalUploadVideo';
export const MODAL_CREATE_VIDEO = 'modalCreateVideo';
export const MODAL_CREATE_PLAYLIST = 'modalСreatePlaylist';
export const MODAL_FOLDER = 'modalFolder';
export const MODAL_ADD_WATERMARK = 'modalAddWatermark';
export const MODAL_SET_VIDEO_TUMBNAIL = 'modalSetVideoTumbnail';
export const MODAL_TRIMMER = 'modalTrimmer';
export const MODAL_CHANGE_PLAN = 'modalChangePlan';
export const MODAL_DELETE_VIDEO = 'modalDeleteVideo';
export const MODAL_CREATE_GIF = 'modalCreateGif';
export const MODAL_ADD_TEXT_TO_GIF = 'modalAddTextToGif';
export const MODAL_RENAME_VIDEO = 'modalRenameVideo';
export const MODAL_PREVIEW_VIDEO = 'modalPreviewVideo';
export const MODAL_SELECT_VIDEO_FOLDER = 'modalSelectVideoFolder';
export const MODAL_SET_BAN = 'modalSetBan';
export const MODAL_CREATE_USER = 'modalCreateUser';
export const MODAL_ENTER_CARD = 'modalEnterCard';
export const MODAL_PAYMENT_DETAIL = 'modalPaymentDetail';

export const DEFAULT_COLOR_SCHEME = {
  playerCanvas: '#1974BB',
  transparentCanvas: false,
  playerPlayButton: '#22A9E0',
  playerControlBar: '#43C3D7',
};

// send statistics every X seconds of length
export const DROP_OFF_STATISTICS_PERIOD = +(process.env
  .REACT_APP_DROP_OFF_STATISTICS_PERIOD as string);

export const HEX_OPACITY = '4d';
