/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../../assets/img/sprite.svg';
import CheckBox from '../../../components/CheckBox/CheckBox';
import ImageCrop from '../../../components/ImageCrop/ImageCrop';
import ModalFile from '../../../components/ModalFile/ModalFile';
import PopUpWrapper from '../../../layout/PopUpWrapper/PopUpWrapper';
import { RootState } from '../../../store';
import {
  updateProfileRequest,
  uploadFileRequest,
} from '../../../store/profile/profile.action';
import { PlayerLogoProps } from './PlayerLogo.type';

const PlayerLogo = (props: PlayerLogoProps) => {
  const { title } = props;
  const [logoOnPlayer, setLogoOnPlayer] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState(false);
  const [logoPlayerImg, setLogoPlayerImg] = useState('');
  const [isEditImage, setIsEditImage] = useState<boolean>(false);
  const { profile, loading } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile) {
      setLogoOnPlayer(profile.profile.showLogoOnPlayer);
      setLogoPlayerImg(profile.profile.playerLogo);
    }
  }, [profile, dispatch]);

  useEffect(() => {
    if (!loading) {
      setIsUpload(false);
    }
  }, [loading]);

  const handlerLogoOnPlayer = (showLogoOnPlayer: boolean) => {
    dispatch(updateProfileRequest({ profile: { showLogoOnPlayer } }));
  };

  const uploadHandler = (file: Blob | null) => {
    if (file) {
      dispatch(uploadFileRequest({ file, image: 'playerLogo' }));
    }
  };

  const modalHandler = () => {
    setIsUpload(!isUpload);
  };

  return (
    <>
      <div className="widget_block player_logo">
        <div className="widget_block_header">
          <span className="widget_block_ico">
            <svg role="img">
              <use xlinkHref={`${spriteImg}#playerlogo`} />
            </svg>
          </span>
          {title}
        </div>
        <div className="widget_block_body">
          {logoPlayerImg && (
            <img alt={'name'} src={logoPlayerImg} className="img_logo_player" />
          )}
        </div>
        <div className="widget_block_panel">
          <div className="player_logo_panel">
            <CheckBox
              setValue={handlerLogoOnPlayer}
              value={logoOnPlayer}
              title="Show Logo On Player"
            />
            <div className="player_logo_panel_action">
              <div className="player_logo_panel_action__item">
                <button
                  onClick={() => {
                    setIsEditImage(true);
                  }}
                  className="player_logo_btn"
                >
                  <span className="player_logo_ico">
                    <svg role="img">
                      <use xlinkHref={`${spriteImg}#pencil`} />
                    </svg>
                  </span>
                </button>
              </div>
              <div className="player_logo_panel_action__item">
                <button onClick={modalHandler} className="player_logo_btn">
                  <span className="player_logo_ico">
                    <svg role="img">
                      <use xlinkHref={`${spriteImg}#upload`} />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isUpload && (
        <ModalFile handler={uploadHandler} closeHandler={modalHandler} />
      )}
      {isEditImage && (
        <PopUpWrapper closeHandler={() => setIsEditImage(false)}>
          <ImageCrop
            src={logoPlayerImg}
            closeHandler={() => setIsEditImage(false)}
            uploadHandler={uploadHandler}
          />
        </PopUpWrapper>
      )}
    </>
  );
};

export default PlayerLogo;
