import { VideoId } from '../../models';
import {
  CreateDropOffStatistics,
  VideoStatistics,
} from '../../models/Analytic';

export type IAnalyticState = {
  loading: boolean;
} & VideoStatistics;

export const GET_VIDEO_ANALYTIC_REQUEST =
  '@analytic/GET_VIDEO_ANALYTIC_REQUEST';
export const GET_VIDEO_ANALYTIC_SUCCESS =
  '@analytic/GET_VIDEO_ANALYTIC_SUCCESS';
export const GET_VIDEO_ANALYTIC_FAILURE =
  '@analytic/GET_VIDEO_ANALYTIC_FAILURE';

export const CREATE_DROP_OFF_STATISTICS_REQUEST =
  '@analytic/CREATE_DROP_OFF_STATISTICS_REQUEST';
export const CREATE_DROP_OFF_STATISTICS_SUCCESS =
  '@analytic/CREATE_DROP_OFF_STATISTICS_SUCCESS';
export const CREATE_DROP_OFF_STATISTICS_FAILURE =
  '@analytic/CREATE_DROP_OFF_STATISTICS_FAILURE';

export interface IGetVideoAnalyticRequest {
  type: typeof GET_VIDEO_ANALYTIC_REQUEST;
  payload: VideoId;
}

interface IGetVideoAnalyticSuccess {
  type: typeof GET_VIDEO_ANALYTIC_SUCCESS;
  payload: VideoStatistics;
}

interface IGetVideoAnalyticFailure {
  type: typeof GET_VIDEO_ANALYTIC_FAILURE;
}

export interface ICreateDropOffStatisticsRequest {
  type: typeof CREATE_DROP_OFF_STATISTICS_REQUEST;
  payload: CreateDropOffStatistics;
}

interface ICreateDropOffStatisticsSuccess {
  type: typeof CREATE_DROP_OFF_STATISTICS_SUCCESS;
}

interface ICreateDropOffStatisticsFailure {
  type: typeof CREATE_DROP_OFF_STATISTICS_FAILURE;
}

export type AnalyticActionsTypes =
  | IGetVideoAnalyticRequest
  | IGetVideoAnalyticSuccess
  | IGetVideoAnalyticFailure
  | ICreateDropOffStatisticsRequest
  | ICreateDropOffStatisticsSuccess
  | ICreateDropOffStatisticsFailure;
