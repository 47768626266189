import style from './MenuHeader.module.css';
import { MenuHeaderType } from './MenuHeader.type';

const MenuHeader = ({ items }: MenuHeaderType) => {
  return (
    <ul className={style.menu}>
      {items.map(({ handler, iconUrl, title }, i) => (
        <li key={i}>
          <button onClick={handler}>
            <span className={style.menu_ico}>
              <svg role="img">
                <use xlinkHref={iconUrl} />
              </svg>
            </span>
            {title}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default MenuHeader;
