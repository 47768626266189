import { EditPlayerConfig } from '../../models';
import {
  PlayerConfigActionsTypes,
  UPDATE_PLAYER_CONFIG_FAILURE,
  UPDATE_PLAYER_CONFIG_REQUEST,
  UPDATE_PLAYER_CONFIG_SUCCESS,
  UploadPlayerConfigLogo,
  UPLOAD_PLAYER_CONFIG_LOGO_FAILURE,
  UPLOAD_PLAYER_CONFIG_LOGO_REQUEST,
  UPLOAD_PLAYER_CONFIG_LOGO_SUCCESS,
} from './playerConfig.type';

export function updatePlayerConfigRequest(
  payload: EditPlayerConfig,
): PlayerConfigActionsTypes {
  return {
    type: UPDATE_PLAYER_CONFIG_REQUEST,
    payload,
  };
}

export function updatePlayerConfigSuccess(): PlayerConfigActionsTypes {
  return {
    type: UPDATE_PLAYER_CONFIG_SUCCESS,
  };
}

export function updatePlayerConfigFailure(): PlayerConfigActionsTypes {
  return {
    type: UPDATE_PLAYER_CONFIG_FAILURE,
  };
}

export function uploadPlayerConfigLogoRequest(
  payload: UploadPlayerConfigLogo,
): PlayerConfigActionsTypes {
  return { type: UPLOAD_PLAYER_CONFIG_LOGO_REQUEST, payload };
}

export function uploadPlayerConfigLogoSuccess(): PlayerConfigActionsTypes {
  return {
    type: UPLOAD_PLAYER_CONFIG_LOGO_SUCCESS,
  };
}

export function uploadPlayerConfigLogoFailure(): PlayerConfigActionsTypes {
  return {
    type: UPLOAD_PLAYER_CONFIG_LOGO_FAILURE,
  };
}
