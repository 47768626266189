import { useEffect, useRef, useState } from 'react';

import Draggable, {
  DraggableData,
  DraggableEvent,
  DraggableBounds,
} from 'react-draggable';

import Button from '../Button/Button';
import Input from '../Input/Input';
import SettingSlider from '../SettingSlider/SettingSlider';
import style from './LogoEditor.module.css';
import {
  LogoEditorType,
  PositionInfo,
  RelativeCoefficient,
} from './LogoEditor.type';

const LogoEditor = ({ logoSrc, videoSrc, handler }: LogoEditorType) => {
  const editAreaRef = useRef<HTMLVideoElement>();
  const imgWrapperRef = useRef<HTMLDivElement>();
  const imgRef = useRef<HTMLImageElement>();

  const [bounds, setBounds] = useState<DraggableBounds>();

  const [opacity, setOpacity] = useState<number>(100);
  const [position, setPosition] = useState<PositionInfo>({ x: 0, y: 0 });

  const [
    relativeCoefficient,
    setRelativeCoefficient,
  ] = useState<RelativeCoefficient>({
    kx: 1,
    ky: 1,
  });
  const [scale, setScale] = useState<number>(0);

  useEffect(() => {
    const image = imgRef.current;

    if (image) {
      image.width = image.naturalWidth * scale * relativeCoefficient.kx;
      image.height = image.naturalHeight * scale * relativeCoefficient.ky;
    }
  }, [scale, relativeCoefficient]);

  const resizeEditAreaHandler = () => {
    if (!editAreaRef.current) {
      return;
    }

    const kx = editAreaRef.current.clientWidth / editAreaRef.current.videoWidth;
    const ky =
      editAreaRef.current.clientHeight / editAreaRef.current.videoHeight;

    setRelativeCoefficient({ kx, ky });
  };

  useEffect(() => {
    window.addEventListener('resize', resizeEditAreaHandler);

    return () => window.removeEventListener('resize', resizeEditAreaHandler);
  });

  const calculatePosition = (a: number | undefined, b: number | undefined) => {
    return a && b ? a - b - 2 : 0;
  };

  // eslint-disable-next-line consistent-return
  const dragStartHandler = (e: DraggableEvent) => {
    setBounds({
      left: -2,
      top: -2,
      right: calculatePosition(
        editAreaRef.current?.clientWidth,
        imgRef.current?.clientWidth,
      ),
      bottom: calculatePosition(
        editAreaRef.current?.clientHeight,
        imgRef.current?.clientHeight,
      ),
    });

    if (e.target !== imgRef.current) {
      return false;
    }
  };

  const createVideoWithWatermark = async () => {
    if (!editAreaRef.current || !imgRef.current) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/await-thenable
    await handler({
      position: {
        y: Math.round(position.y / relativeCoefficient.ky),
        x: Math.round(position.x / relativeCoefficient.kx),
      },
      size: {
        height: Math.round(imgRef.current.naturalHeight * scale),
        width: Math.round(imgRef.current.naturalWidth * scale),
      },
      opacity: Math.round(opacity * 10) / 1000,
    });
  };

  const dragPointHandler = (e: DraggableEvent, data: DraggableData) => {
    setPosition({ x: data.x, y: data.y });
  };

  const onLoadImageHandler = () => {
    setScale(1);
  };

  return (
    <div className={style.container}>
      <div className={style.edit_area}>
        <video
          src={videoSrc}
          ref={editAreaRef as any}
          className={style.video}
          onDurationChange={resizeEditAreaHandler}
        />
        <Draggable
          bounds={bounds}
          nodeRef={imgWrapperRef as any}
          // onDrag={dragPointHandler}
          onStart={dragStartHandler}
          onStop={dragPointHandler}
        >
          <div
            className={style.img_wrapper}
            style={{
              opacity: `${opacity}%`,
              left: 0,
              top: 0,
              // ...imgSize,
            }}
            ref={imgWrapperRef as any}
          >
            <img
              ref={imgRef as any}
              src={logoSrc}
              alt="logo"
              className={style.logo}
              // style={curImgSize}
              onLoad={onLoadImageHandler}
            />
            {/* {['leftUp', 'rightUp', 'leftDown', 'rightDown'].map(pointName => (
              <Point
                position={pointName as PointType['position']}
                setValue={dragPointHandler}
                key={pointName}
                positionInfo={position}
                scaleInfo={scale}
                sizeInfo={{
                  height: imgRef.current?.clientHeight || 1,
                  width: imgRef.current?.clientHeight || 1,
                }}
              />
            ))} */}
          </div>
        </Draggable>
      </div>
      <div className={style.settings}>
        <SettingSlider
          min={0}
          step={1}
          max={100}
          value={opacity}
          onChange={setOpacity}
          name="Opacity"
        />
        <Input name="Scale" type="number" setValue={setScale} value={scale} />
      </div>
      <Button
        name="Save and continue"
        className={style.save_button}
        handler={createVideoWithWatermark}
      />
    </div>
  );
};

export default LogoEditor;
