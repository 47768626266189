import { User, Register, UploadFileType, Card } from '../../models';
import { DeepPartial, Status } from '../../utils';

export interface IProfileState {
  profile: User | null;
  loading: boolean;
  status: Status;
}

export const GET_PROFILE_REQUEST = '@profile/GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = '@profile/GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = '@profile/GET_PROFILE_FAILURE';

export const CREATE_PROFILE_REQUEST = '@profile/CREATE_PROFILE_REQUEST';
export const CREATE_PROFILE_SUCCESS = '@profile/CREATE_PROFILE_SUCCESS';
export const CREATE_PROFILE_FAILURE = '@profile/CREATE_PROFILE_FAILURE';

export const UPDATE_PROFILE_REQUEST = '@profile/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = '@profile/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = '@profile/UPDATE_PROFILE_FAILURE';

export const UPLOAD_FILE_REQUEST = '@profile/UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = '@profile/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = '@profile/UPLOAD_FILE_FAILURE';

export const CLEAR_PROFILE = '@profile/CLEAR_PROFILE';
export const INIT_STATUS = '@profile/INIT_STATUS';

interface IGetProfileRequest {
  type: typeof GET_PROFILE_REQUEST;
}

interface IGetProfileSuccess {
  type: typeof GET_PROFILE_SUCCESS;
  payload: {
    user: User;
  };
}

interface IGetProfileFailure {
  type: typeof GET_PROFILE_FAILURE;
}

export interface ICreateProfileRequest {
  type: typeof CREATE_PROFILE_REQUEST;
  payload: {
    userData: Register;
    query?: string;
  };
}

interface ICreateProfileSuccess {
  type: typeof CREATE_PROFILE_SUCCESS;
}

interface ICreateProfileFailure {
  type: typeof CREATE_PROFILE_FAILURE;
}

export interface IUpdateProfileRequest {
  type: typeof UPDATE_PROFILE_REQUEST;
  payload: {
    user: DeepPartial<
      Omit<User, 'paymentMethod'> & {
        paymentMethod: {
          type: 'card' | 'paypal';
          cardInfo: Card;
        };
      }
    >;
  };
}

interface IUpdateProfileSuccess {
  type: typeof UPDATE_PROFILE_SUCCESS;
  payload: {
    user: User;
  };
}

interface IUpdateProfileFailure {
  type: typeof UPDATE_PROFILE_FAILURE;
}

export interface IUploadFileRequest {
  type: typeof UPLOAD_FILE_REQUEST;
  payload: UploadFileType;
}

interface IUploadFileSuccess {
  type: typeof UPLOAD_FILE_SUCCESS;
  payload: {
    user: User;
  };
}

interface IUploadFileFailure {
  type: typeof UPLOAD_FILE_FAILURE;
}

interface IClearProfile {
  type: typeof CLEAR_PROFILE;
}

interface IInitStatus {
  type: typeof INIT_STATUS;
}

export type ProfileActionsTypes =
  | IGetProfileRequest
  | IGetProfileSuccess
  | IGetProfileFailure
  | ICreateProfileRequest
  | ICreateProfileSuccess
  | ICreateProfileFailure
  | IUpdateProfileRequest
  | IUpdateProfileSuccess
  | IUpdateProfileFailure
  | IUploadFileRequest
  | IUploadFileSuccess
  | IUploadFileFailure
  | IClearProfile
  | IInitStatus;
