import { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import CheckBox from '../../components/CheckBox/CheckBox';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import VideoItem from '../../components/Video/VideoItem/VideoItem';
import { Video } from '../../models';
import { RootState } from '../../store';
import { setSelectedModal } from '../../store/modal/modal.action';
import {
  bulkVideosRequest,
  getVideoListRequest,
  setSelectAllVideos,
  setVideoPage,
} from '../../store/video/video.action';
import {
  MODAL_DELETE_VIDEO,
  MODAL_SELECT_VIDEO_FOLDER,
  PageType,
  SortType,
} from '../../utils';
import style from './VideoList.module.css';

const VideoList = () => {
  const [isSelectAll, setSelectAll] = useState<boolean>(false);
  const [isSelectVideo, setSelectVideo] = useState<boolean>(false);
  const {
    videos,
    page,
    limit,
    pagination,
    selectedVideosId,
    search: searchString,
    loading: videoLoading,
    imageLoading,
  } = useSelector((state: RootState) => state.video);
  const { selectedFolder, loading: folderLoading } = useSelector(
    (state: RootState) => state.folder,
  );
  const { profile } = useSelector((state: RootState) => state.profile);

  const [sortType, setSortType] = useState<SortType>('ASC');

  const getQuery = useCallback(
    (curPage = 1) => {
      let query = `?limit=${limit}&page=${curPage}&direction=${sortType}`;
      if (selectedFolder) {
        query += `&folder_id=${selectedFolder.id}`;
      }
      if (searchString) {
        query += `&search=${searchString}`;
      }

      return query;
    },
    [selectedFolder, limit, searchString, sortType],
  );

  const [query, setQuery] = useState<string>(getQuery(page));

  const dispatch = useDispatch();

  const selectAllHandler = () => {
    dispatch(setSelectAllVideos(!isSelectAll));
    setSelectAll(!isSelectAll);
  };

  useEffect(() => {
    setSelectVideo(selectedVideosId.length > 0);
  }, [selectedVideosId]);

  useEffect(() => {
    setQuery(getQuery(page));
  }, [page, limit, searchString, sortType, getQuery]);

  useEffect(() => {
    setQuery(getQuery());
  }, [getQuery, selectedFolder]);

  useEffect(() => {
    dispatch(getVideoListRequest(query));
  }, [dispatch, selectedFolder, page, limit, searchString, query, sortType]);

  const onChangePage = (data: PageType): void => {
    dispatch(setVideoPage(data));
  };

  const renderVideoItem = (video: Video, i: number) => {
    return <VideoItem video={video} key={i} />;
  };

  const dublicateVideosHandler = () => {
    dispatch(bulkVideosRequest({ selectedVideosId, query, action: 'copy' }));
  };

  const addToFolderHandler = () => {
    dispatch(
      setSelectedModal(MODAL_SELECT_VIDEO_FOLDER, {
        data: query,
      }),
    );
  };

  const deleteVideosHandler = () => {
    dispatch(
      setSelectedModal(MODAL_DELETE_VIDEO, {
        data: {
          acceptHandler: () => {
            dispatch(
              bulkVideosRequest({ selectedVideosId, query, action: 'delete' }),
            );
          },
        },
      }),
    );
  };

  const sortTypeHandler = () => {
    const newSortType = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(newSortType);
  };

  return (
    <div className={style.folder_content}>
      <div
        className={cn(
          style.folder_content_header,
          isSelectVideo && style.header_active,
        )}
      >
        <div className={style.folder_content_header_left}>
          <CheckBox setValue={selectAllHandler} value={isSelectAll} />
          <div className={style.folder_content_header_title}>Video</div>
          <div className={style.folder_content_header_subtitle}>
            {(pagination && pagination.totalItems) || 0} videos
          </div>
          {profile?.isActive && (
            <>
              <div
                className={cn(
                  style.folder_header_action,
                  style.folder_action_add_to,
                )}
              >
                <div className={style.action_video}>
                  <div className={style.action_video_title}>
                    Add to
                    <div className={style.arrow}>
                      <svg role="img">
                        <use xlinkHref={`${spriteImg}#down-arrow`} />
                      </svg>
                    </div>
                  </div>
                  <ul className={style.ul_action_video}>
                    <li key="add-to-folder" onClick={addToFolderHandler}>
                      Add to folder
                    </li>
                    {/* <li key="add-to-playlist">Add to playlist</li> */}
                  </ul>
                </div>
              </div>
              <div
                className={cn(
                  style.folder_header_action,
                  style.folder_action_others,
                )}
              >
                <div className={style.action_video}>
                  <div className={style.action_video_title}>
                    Other&nbsp;<span>actions</span>
                    <div className={style.arrow}>
                      <svg role="img">
                        <use xlinkHref={`${spriteImg}#down-arrow`} />
                      </svg>
                    </div>
                  </div>
                  <ul className={style.ul_action_video}>
                    <li key="delete" onClick={deleteVideosHandler}>
                      Delete
                    </li>
                    {/* <li>Download</li> */}
                    {/* <li>Interactive GIF</li> */}
                    <li key="dublicate" onClick={dublicateVideosHandler}>
                      Dublicate video
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
        {!isSelectVideo && (
          <div
            onClick={sortTypeHandler}
            className={cn(style.sort_video, sortType === 'DESC' && style.desc)}
          >
            Date
          </div>
        )}
      </div>

      {!!videos.length && (
        <>
          <div className={style.videos}>{videos.map(renderVideoItem)}</div>
          <Pagination
            pagination={pagination}
            onChangePage={onChangePage}
            className={style.pagination}
            showButtonNavigation={pagination.totalItems > limit}
          />
        </>
      )}

      {(folderLoading || videoLoading || imageLoading) && <Loader />}
    </div>
  );
};

export default VideoList;
