import { useDispatch } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { ENV_DEVELOPMENT } from '../utils';
import { rootReducer } from './root.reducer';
import { rootSagas } from './root.saga';

const sagaMiddleware = createSagaMiddleware();

const getMiddleware = () => {
  if (process.env.NODE_ENV === ENV_DEVELOPMENT) {
    return applyMiddleware(sagaMiddleware, logger);
  }
  return applyMiddleware(sagaMiddleware);
};

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, getMiddleware());

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

sagaMiddleware.run(rootSagas);

export default store;
