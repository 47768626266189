import { Gif } from '../../models';
import { Id, MetaType, PageType, Query } from '../../utils';
import {
  CLEAR_EMBED,
  CreateGif,
  CREATE_GIF_FAILURE,
  CREATE_GIF_REQUEST,
  CREATE_GIF_SUCCESS,
  DELETE_GIF_FAILURE,
  DELETE_GIF_REQUEST,
  DELETE_GIF_SUCCESS,
  GET_GIF_LIST_FAILURE,
  GET_GIF_LIST_REQUEST,
  GET_GIF_LIST_SUCCESS,
  GifActionsTypes,
  SET_GIF_PAGE,
} from './gif.type';

export function setGifPage(pageType: PageType): GifActionsTypes {
  return {
    type: SET_GIF_PAGE,
    payload: {
      pageType,
    },
  };
}

export function getGifListRequest(query: Query['query']): GifActionsTypes {
  return {
    type: GET_GIF_LIST_REQUEST,
    payload: { query },
  };
}

export function getGifListSuccess(
  gifs: Gif[],
  pagination: MetaType,
): GifActionsTypes {
  return {
    type: GET_GIF_LIST_SUCCESS,
    payload: {
      gifs,
      pagination,
    },
  };
}

export function getGifListFailure(): GifActionsTypes {
  return {
    type: GET_GIF_LIST_FAILURE,
  };
}

export function createGifRequest(payload: CreateGif & Query): GifActionsTypes {
  return { type: CREATE_GIF_REQUEST, payload };
}

export function createGifSuccess(payload: Id & { embed: string }) {
  return {
    type: CREATE_GIF_SUCCESS,
    payload,
  };
}

export function createGifFailure(): GifActionsTypes {
  return { type: CREATE_GIF_FAILURE };
}

export function deleteGifRequest(
  payload: Pick<Gif, 'id'> & Query,
): GifActionsTypes {
  return {
    type: DELETE_GIF_REQUEST,
    payload,
  };
}

export function deleteGifSuccess(): GifActionsTypes {
  return {
    type: DELETE_GIF_SUCCESS,
  };
}

export function deleteGifFailure(): GifActionsTypes {
  return {
    type: DELETE_GIF_FAILURE,
  };
}

export function clearEmbed(): GifActionsTypes {
  return {
    type: CLEAR_EMBED,
  };
}
