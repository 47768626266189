import cn from 'classnames';
import { useDispatch } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import { setSelectedFolder } from '../../store/folder/folder.action';
import style from './Breadcrumb.module.css';
import { BreadcrumbType, BreadcrumpValue } from './Breadcrumb.type';

const BreadcrumbItem = ({
  option,
  handler,
  isLast,
}: {
  option: BreadcrumpValue;
  handler: () => void;
  isLast: boolean;
}) => {
  return (
    <>
      <span
        className={style.breadcrumb_separator}
        key={`seperatior${option.id}`}
      />
      <span className={style.breadcrumb_item} onClick={handler} key={option.id}>
        <span className={style.breadcrumb_back}>
          <svg role="img" className={style.breadcrumb_back_icon}>
            <use xlinkHref={`${spriteImg}#back`} />
          </svg>
        </span>
        {isLast ? <span>{option.name}</span> : <a>{option.name}</a>}
      </span>
    </>
  );
};

const BreadcrumbComponent = (props: BreadcrumbType) => {
  const { className, options, type } = props;
  const optionsSize = options.length - 1;

  const dispatch = useDispatch();

  const setBreadcrumb = (newBreadCrumb: BreadcrumpValue | null) => {
    switch (type) {
      case 'folder':
      default:
        dispatch(setSelectedFolder(newBreadCrumb));
    }
  };

  const breadCrumbRender = (option: BreadcrumpValue, i: number) => {
    const handler = () => {
      if (window.innerWidth <= 991) {
        if (i === 0) {
          setBreadcrumb(null);
          return;
        }
        setBreadcrumb(options[i - 1]);
        return;
      }
      setBreadcrumb(option);
    };

    return (
      <BreadcrumbItem
        handler={handler}
        isLast={i === optionsSize}
        option={option}
        key={`breadcrumb-${i}`}
      />
    );
  };

  return (
    <div className={cn(style.container, className)}>
      <span
        className={style.breadcrumb_item}
        onClick={() => setBreadcrumb(null)}
      >
        <a className={style.text}>
          <svg role="img" className={style.home_icon}>
            <use xlinkHref={`${spriteImg}#home`} />
          </svg>
          Home
        </a>
      </span>

      {options.map(breadCrumbRender)}
    </div>
  );
};

export default BreadcrumbComponent;
