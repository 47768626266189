import { parse } from 'query-string';

import ResetPasswordContainer from '../../containers/ResetPassword/ResetPassword';
import MainLayout from '../../layout/MainLayout/MainLayout';
import { ResetPasswordPageType } from './ResetPasswordPage.type';

const ResetPasswordPage = (props: ResetPasswordPageType) => {
  const {
    location: { search },
  } = props;
  const { token } = parse(search);

  return (
    <MainLayout>
      <ResetPasswordContainer token={token as string} />
    </MainLayout>
  );
};

export default ResetPasswordPage;
