import { useMemo } from 'react';

import style from './Avatar.module.css';
import { AvatarProps } from './Avatar.type';

const AvatarComponent = (props: AvatarProps) => {
  const { img } = props;

  const renderDefault = useMemo(() => {
    return <div className={style.default}></div>;
  }, []);

  const renderAvatar = useMemo(() => {
    return <img src={img} alt="avatar" />;
  }, [img]);

  return (
    <div className={style.avatar}>{img ? renderAvatar : renderDefault}</div>
  );
};

export default AvatarComponent;
