/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import BreadcrumbComponent from '../../components/Breadcrumb/Breadcrumb';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import MobileSubMenu from '../../components/MobileSubMenu/MobileSubMenu';
import FolderList from '../../containers/FolderList/FolderList';
import Footer from '../../containers/Footer/Footer';
import Header from '../../containers/Header/Header';
import VideoList from '../../containers/VideoList/VideoList';
import { RootState } from '../../store';
import { initFolderState } from '../../store/folder/folder.action';
import { setSelectedModal } from '../../store/modal/modal.action';
import {
  ModalType,
  MODAL_FOLDER,
  MODAL_IMPORT_VIDEO,
  MODAL_UPLOAD_VIDEO,
} from '../../utils';
import style from './MyVideosPage.module.css';

const MyVideosPage = () => {
  const { breadcrumbs } = useSelector((state: RootState) => state.folder);
  const { profile } = useSelector((state: RootState) => state.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initFolderState());
  }, [dispatch]);

  const modalHandler = (modal: ModalType) => {
    dispatch(setSelectedModal(modal));
  };

  const menuItems = [
    {
      name: 'video',
      icon: `${spriteImg}#create`,
      items: [
        {
          handler: () => modalHandler(MODAL_UPLOAD_VIDEO),
          iconUrl: `${spriteImg}#upload`,
          title: 'Upload video',
        },
        // TODO: EWV-177
        // {
        //   handler: () => {},
        //   iconUrl: `${spriteImg}#create`,
        //   title: 'Create video',
        // },
        {
          handler: () => modalHandler(MODAL_IMPORT_VIDEO),
          iconUrl: `${spriteImg}#import`,
          title: 'Import video',
        },
      ],
    },
    {
      name: 'Create',
      icon: `${spriteImg}#create_folder`,
      items: [
        {
          handler: () => modalHandler(MODAL_FOLDER),
          iconUrl: `${spriteImg}#create_folder`,
          title: 'Create folder',
        },
        // TODO: EWV-177
        // {
        //   handler: () => {},
        //   iconUrl: `${spriteImg}#create_playlist`,
        //   title: 'Create playlist',
        // },
      ],
    },
  ];

  const renderMenu = ({ items }: any, index: number) => {
    return <MenuHeader items={items} key={index} />;
  };

  return (
    <>
      <div className="enter_content">
        <Header>{profile?.isActive && menuItems.map(renderMenu)}</Header>
        <div className={style.mobile_breadcrumb}>
          <BreadcrumbComponent
            className="mobile"
            options={breadcrumbs}
            type="folder"
          />
        </div>

        <MobileSubMenu blocks={profile?.isActive ? menuItems : []} />

        <FolderList></FolderList>
        <VideoList></VideoList>
      </div>
      <Footer />
    </>
  );
};

export default MyVideosPage;
