import {
  AlertActionsTypes,
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_CLEAR,
} from './alert.type';

export function alertSuccess(message: string): AlertActionsTypes {
  return {
    type: ALERT_SUCCESS,
    payload: {
      message,
    },
  };
}

export function alertError(message: string): AlertActionsTypes {
  return {
    type: ALERT_ERROR,
    payload: {
      message,
    },
  };
}

export function alertClear(): AlertActionsTypes {
  return {
    type: ALERT_CLEAR,
  };
}
