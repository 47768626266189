/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useRef, useState } from 'react';

import CustomSlider from '../CustomSlider/CustomSlider';
import style from './IntervalSlider.module.css';
import { IntervalSliderType, FragmentType } from './IntervalSlider.type';

// FIXME: BAD SOLUTION
let globalSource: string;

const Fragment = ({ startTime, position }: FragmentType) => {
  const videoRef = useRef<HTMLMediaElement>();

  const setCurrentTime = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = startTime;
    }
  };

  useEffect(() => {
    setCurrentTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  return (
    <video
      className={style.fragment}
      {...(position && { style: { left: position - 65, bottom: 33 } })}
      src={globalSource}
      ref={videoRef as any}
      onDurationChange={setCurrentTime}
    />
  );
};

const IntervalThumbComponent = (props: any) => {
  const [isShowFragment, setShowFragment] = useState<boolean>(false);

  const mouseDownHandler = () => {
    setShowFragment(true);
  };

  const mouseUpHandler = () => {
    setShowFragment(false);
  };

  return (
    <div
      {...props}
      onMouseEnter={mouseDownHandler}
      onMouseLeave={mouseUpHandler}
    >
      {isShowFragment && <Fragment startTime={props['aria-valuenow']} />}
      <span
        className={style.thumb}
        style={{
          background: props.color,
        }}
      />
    </div>
  );
};

const IntervalSlider = ({
  onChange,
  max,
  min,
  name,
  step,
  value,
  source,
  color,
}: IntervalSliderType) => {
  globalSource = source;

  const sliderRef = useRef<HTMLSpanElement>();

  const [isShowFragment, setShowFragment] = useState<boolean>(false);
  const [position, setPosition] = useState<FragmentType['position']>();
  const [startTime, setStartTime] = useState<number>(0);

  const [Slider, setSlider] = useState<any>(CustomSlider(color));

  useEffect(() => {
    setSlider(CustomSlider(color));
  }, [color]);

  const mouseEnterHandler = () => {
    setShowFragment(true);
  };

  const mouseLeaveHandler = () => {
    setShowFragment(false);
  };

  const mouseMoveHandler = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (event.target.classList.contains('MuiSlider-thumb')) {
      setShowFragment(false);

      return;
    }
    const dx = Math.max(event.nativeEvent.layerX, 0);

    setShowFragment(true);

    setPosition(dx);
    setStartTime(
      Math.round((dx * max) / (sliderRef.current?.clientWidth || 1)),
    );
  };

  return (
    <>
      <Slider
        ref={sliderRef as any}
        ThumbComponent={(props: any) =>
          IntervalThumbComponent({ ...props, color })
        }
        onChange={onChange}
        min={min}
        name={name}
        step={step}
        max={max}
        value={value}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        onMouseMove={mouseMoveHandler}
      />
      {isShowFragment && <Fragment startTime={startTime} position={position} />}
    </>
  );
};
export default IntervalSlider;
