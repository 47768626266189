export default {
  validation: {
    firstName: {
      required: 'Your first name is required',
    },
    lastName: {
      required: 'Your last name is required',
    },
    cardNumber: {
      required: 'Card number is required',
      valid: 'Enter a valid card number',
    },
    cvc: {
      required: 'CVC/CWV is required',
      valid: 'Enter a valid CWV/CVC',
    },
    expMonth: {
      required: 'Exp month is required',
      valid: 'Enter a valid Exp month',
    },
    expYear: {
      required: 'Exp year is required',
      valid: 'Enter a valid Exp year',
    },
  },
};
