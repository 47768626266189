import easyColorImg from '../../assets/img/easy-color.png';
import style from './MainLayout.module.css';
import { MainLayoutType } from './MainLayout.type';

const MainLayout = (props: MainLayoutType) => {
  const { children } = props;

  return (
    <div className={style.container}>
      <div className={style.content}>
        <img
          src={easyColorImg}
          srcSet={`${easyColorImg} 2x`}
          className={style.form_logo}
          alt="logo"
        />
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
