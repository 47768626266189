import { put, takeLatest, fork, call, ForkEffect } from 'redux-saga/effects';

import {
  createGif,
  deleteGifById,
  getAllGif,
} from '../../services/gif.service';
import { ALERT_ERROR } from '../alert/alert.type';
import {
  CREATE_GIF_FAILURE,
  CREATE_GIF_REQUEST,
  CREATE_GIF_SUCCESS,
  DELETE_GIF_FAILURE,
  DELETE_GIF_REQUEST,
  DELETE_GIF_SUCCESS,
  GET_GIF_LIST_FAILURE,
  GET_GIF_LIST_REQUEST,
  GET_GIF_LIST_SUCCESS,
  GifActionsTypes,
  ICreateGifRequest,
  IDeleteGifRequest,
  IGetGifListRequest,
} from './gif.type';

function* workerGetAllGif(action: GifActionsTypes) {
  try {
    const { payload } = action as IGetGifListRequest;
    const { items, meta } = yield call(getAllGif, payload.query);

    yield put({
      type: GET_GIF_LIST_SUCCESS,
      payload: { gifs: items, pagination: meta },
    });
  } catch (error) {
    yield put({ type: GET_GIF_LIST_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* watchGetAllGif() {
  yield takeLatest(GET_GIF_LIST_REQUEST, workerGetAllGif);
}

function* workerCreateGif(action: GifActionsTypes) {
  try {
    const {
      payload: { query, ...gifParams },
    } = action as ICreateGifRequest;
    const { id, embed } = yield call(createGif, gifParams);

    yield put({
      type: CREATE_GIF_SUCCESS,
      payload: { id, embed },
    });

    yield put({
      type: GET_GIF_LIST_REQUEST,
      payload: { query },
    });
  } catch (error) {
    yield put({ type: CREATE_GIF_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { error } });
  }
}

function* watchCreateGif() {
  yield takeLatest(CREATE_GIF_REQUEST, workerCreateGif);
}

function* worderDeleteGif(action: GifActionsTypes) {
  try {
    const { payload } = action as IDeleteGifRequest;

    yield call(deleteGifById, payload.id);

    yield put({
      type: DELETE_GIF_SUCCESS,
    });

    yield put({
      type: GET_GIF_LIST_REQUEST,
      payload: { query: payload.query },
    });
  } catch (error) {
    yield put({ type: DELETE_GIF_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { error } });
  }
}

function* watchDeleteGif() {
  yield takeLatest(DELETE_GIF_REQUEST, worderDeleteGif);
}

export const gifWatchers: ForkEffect[] = [
  fork(watchGetAllGif),
  fork(watchCreateGif),
  fork(watchDeleteGif),
];
