import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import MobileSubMenu from '../../components/MobileSubMenu/MobileSubMenu';
import UserInfo from '../../components/UserInfo/UserInfo';
import Footer from '../../containers/Footer/Footer';
import Header from '../../containers/Header/Header';
import UserSettings from '../../containers/UserSettings/UserSettings';
import { setSelectedModal } from '../../store/modal/modal.action';
import {
  getUserByIdRequest,
  updateUserByIdRequest,
  deleteUserByIdRequest,
} from '../../store/users/users.action';
import { IUsers } from '../../store/users/users.type';
import { MODAL_SET_BAN, Params } from '../../utils';

const UserSettingsPage = ({
  match: {
    params: { userId },
  },
}: Params<{ userId: string }>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserByIdRequest(userId));
  }, [dispatch, userId]);

  const { user }: IUsers = useSelector(({ users }: { users: IUsers }) => users);

  const userCancelHandler = useCallback(
    (id, isActive): void => {
      dispatch(
        updateUserByIdRequest({
          id,
          data: {
            isActive: !isActive,
          },
        }),
      );
    },
    [dispatch],
  );

  const userBanHandler = useCallback(
    (id, ban): void => {
      if (ban) {
        dispatch(
          updateUserByIdRequest({
            id,
            data: {
              ban: false,
              banReason: null,
            },
          }),
        );
        return;
      }

      dispatch(
        setSelectedModal(MODAL_SET_BAN, {
          data: {
            id,
          },
        }),
      );
    },
    [dispatch],
  );

  const userDeleteHandler = useCallback(
    (id): void => {
      dispatch(deleteUserByIdRequest(id));
    },
    [dispatch],
  );

  if (!user) {
    return null;
  }

  const { id, isActive, ban } = user;

  const menuItems = [
    [
      {
        handler: () => userBanHandler(id, ban),
        iconUrl: `${spriteImg}#circle-block`,
        title: 'Ban',
      },
      {
        handler: () => userDeleteHandler(id),
        iconUrl: `${spriteImg}#circle-minus`,
        title: 'Delete user',
      },
      {
        handler: () => userCancelHandler(id, isActive),
        iconUrl: `${spriteImg}#circle-x`,
        title: 'Cancel account',
      },
    ],
  ];
  const mobileMenuItems = [
    {
      name: 'Actions',
      icon: `${spriteImg}#settings`,
      items: menuItems[0],
    },
  ];

  const renderMenu = (items: any, index: number) => {
    return <MenuHeader items={items} key={index} />;
  };

  return (
    <div className="enter_content">
      <Header isAdmin={true} notification={true}>
        <UserInfo user={user} backLink="/admin/users" />
        {menuItems.map(renderMenu)}
      </Header>
      <MobileSubMenu blocks={mobileMenuItems}>
        <UserInfo user={user} />
      </MobileSubMenu>
      <UserSettings />
      <Footer />
    </div>
  );
};

export default UserSettingsPage;
