/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState, useRef, useCallback } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import Button from '../../components/Button/Button';
import CheckBox from '../../components/CheckBox/CheckBox';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import MobileSubMenu from '../../components/MobileSubMenu/MobileSubMenu';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import Footer from '../../containers/Footer/Footer';
import GifList from '../../containers/GifList/GifList';
import Header from '../../containers/Header/Header';
import PlayerConfig from '../../containers/PlayerConfig/PlayerConfig';
import { RootState } from '../../store';
import { alertError } from '../../store/alert/alert.action';
import { setSelectedModal } from '../../store/modal/modal.action';
import {
  deleteVideoRequest,
  getEmbedVideoRequest,
  getVideoRequest,
} from '../../store/video/video.action';
import {
  ModalType,
  MODAL_ADD_WATERMARK,
  MODAL_CREATE_GIF,
  MODAL_DELETE_VIDEO,
  MODAL_RENAME_VIDEO,
  MODAL_SET_VIDEO_TUMBNAIL,
  MODAL_TRIMMER,
  history,
} from '../../utils';
import style from './VideoPage.module.css';
import { VideoPageType } from './VideoPage.type';

const VideoPage = ({
  match: {
    params: { videoId },
  },
}: VideoPageType) => {
  const dispatch = useDispatch();

  const {
    video: { video, loading },
    profile: { profile },
  } = useSelector((state: RootState) => state);

  const [poster, setPoster] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isPopUpVideo, setIsPopUpVideo] = useState<boolean>(false);

  const modalHandler = (
    modal: ModalType,
    data?: { title?: string; acceptHandler?: () => void },
  ) => {
    const { title, acceptHandler } = data || {};
    dispatch(
      setSelectedModal(modal, {
        id: videoId,
        title,
        data: { name, acceptHandler },
      }),
    );
  };

  const [embedWidth, setEmbedWidth] = useState<number>(600);
  const [embedHeight, setEmbedHeight] = useState<number>(600);
  const [embedRedirectUrl, setEmbedRedirectUrl] = useState<string>('');
  const [embedRedirectUrlValid, setEmbedRedirectUrlValid] = useState<boolean>(
    true,
  );
  const [isEmbedAspectRation, setEmbedAspectRation] = useState<boolean>(true);

  const [isEmbedNewWindow, setEmbedNewWindow] = useState<boolean>(false);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(getVideoRequest({ videoId }));
  }, [dispatch, videoId]);

  useEffect(() => {
    if (video) {
      setName(video.name);
      setPoster(video.imageUrl || '');
    }
  }, [video]);

  const handleDisableAlert = useCallback(() => {
    dispatch(
      alertError('This action is disabled. Contact your administrator.'),
    );
  }, [dispatch]);

  const menuItems = [
    {
      name: 'Actions',
      icon: `${spriteImg}#settings`,
      items: [
        // TODO: EWV-177
        // {
        //   handler: () => {},
        //   iconUrl: `${spriteImg}#globe`,
        //   title: 'Create web page',
        // },
        // {
        //   handler: () => {},
        //   iconUrl: `${spriteImg}#mail`,
        //   title: 'Email video',
        // },
        // {
        //   handler: () => {},
        //   iconUrl: `${spriteImg}#file-plus`,
        //   title: 'Post to ebay',
        // },
      ],
    },
  ];

  const videoActionBtns = [
    {
      handler: () => modalHandler(MODAL_TRIMMER),
      iconUrl: `${spriteImg}#cut`,
      isActive: profile?.feature.videoTrimmer,
    },
    {
      handler: () => modalHandler(MODAL_RENAME_VIDEO),
      iconUrl: `${spriteImg}#rename`,
    },
    // {
    //   handler: () => {},
    //   iconUrl: `${spriteImg}#import`,
    // },
    {
      handler: () =>
        modalHandler(MODAL_DELETE_VIDEO, {
          acceptHandler: () => {
            history.push('/');
            dispatch(
              deleteVideoRequest({ videoId, query: '?limit=10&page=1' }),
            );
          },
        }),
      iconUrl: `${spriteImg}#circle-delete`,
    },
  ];

  // const socialShares = ['youtube', 'facebook'];

  const videoActions: {
    name: string;
    handler: () => void;
    isActive?: boolean;
  }[] = [
    // TODO: EWV-177
    // {
    //   name: 'Set Intro/Outro Video',
    //   handler: () => {},
    // },
    // {
    //   name: 'Add Hover Video',
    //   handler: () => {},
    // },
    // {
    //   name: 'Add Related Videos',
    //   handler: () => {},
    // },
    {
      name: 'Create Interactive GIF',
      handler: () =>
        modalHandler(MODAL_CREATE_GIF, { title: 'Create Animated GIF' }),
      isActive: profile?.feature?.createGif,
    },
    {
      name: 'Add Watermark',
      handler: () => modalHandler(MODAL_ADD_WATERMARK),
      isActive: profile?.feature?.addWatermark,
    },
    // TODO: EWV-177
    // {
    //   name: 'Generate sitemap',
    //   handler: () => {},
    // },
    // {
    //   name: 'Add Facebook Requirement',
    //   handler: () => {},
    // },
    // {
    //   name: 'Add Text & Images',
    //   handler: () => {},
    // },
    {
      name: 'Analytics',
      handler: () => history.push(`/video-analytics/${videoId}`),
    },
  ];

  const backHandler = () => {
    history.push('/');
  };

  const getEmbedHandler = () => {
    if (embedRedirectUrlValid) {
      dispatch(
        getEmbedVideoRequest({
          height: embedHeight,
          newWindow: isEmbedNewWindow,
          redirect: embedRedirectUrl,
          videoId,
          width: embedWidth,
        }),
      );
    } else if (refInput && refInput.current) {
      refInput.current.focus();
    }
  };

  const renderMenu = ({ items }: any, index: number) => {
    return <MenuHeader items={items} key={index} />;
  };

  const validateField = (fieldName: string, value: string): void => {
    switch (fieldName) {
      case 'redirectLick': {
        // const re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        // const result = re.exec(value);
        setEmbedRedirectUrl(value);
        setEmbedRedirectUrlValid(true);

        // setEmbedRedirectUrlValid(!!result);
        break;
      }

      default:
        break;
    }
  };

  const setEmbedHeightHandler = (val: number) => {
    setEmbedHeight(val);

    if (isEmbedAspectRation && video?.metadata) {
      const aspectRatio =
        video.metadata.video.width / (video.metadata.video.height || 1);

      setEmbedWidth(Math.round(val * aspectRatio));
    }
  };

  const setEmbedWidthHandler = (val: number) => {
    setEmbedWidth(val);

    if (isEmbedAspectRation && video?.metadata) {
      const aspectRatio =
        video.metadata.video.height / (video.metadata.video.width || 1);

      setEmbedHeight(Math.round(val * aspectRatio));
    }
  };

  return (
    <>
      <div className="enter_content">
        <Header>{menuItems.map(renderMenu)}</Header>

        <MobileSubMenu blocks={menuItems} className={style.submenu_solo} />

        <div className="body_with_right_sidebar">
          <div className="body_block">
            <div className={style.video_block}>
              <div className={style.video_block_header}>
                <div
                  className={cn(
                    style.video_block_header_block,
                    style.block_left,
                  )}
                >
                  <div className={style.back_step} onClick={backHandler}>
                    <svg role="img">
                      <use xlinkHref={`${spriteImg}#back`} />
                    </svg>
                  </div>
                  <div className={style.video_block_title}>{name}</div>
                </div>
                <div className={style.video_block_header_block}>
                  <div className={style.video_action_panel}>
                    {videoActionBtns.map(
                      ({ iconUrl, handler, isActive = true }, i) => (
                        <button
                          key={i}
                          className={style.video_action_panel__item}
                          onClick={isActive ? handler : handleDisableAlert}
                        >
                          <span className={style.video_action_btn}>
                            <svg role="img">
                              <use xlinkHref={iconUrl} />
                            </svg>
                          </span>
                        </button>
                      ),
                    )}
                  </div>
                  <button
                    className={style.set_thumbinal}
                    onClick={
                      profile?.feature.setVideoImage
                        ? () => modalHandler(MODAL_SET_VIDEO_TUMBNAIL)
                        : handleDisableAlert
                    }
                  >
                    <svg role="img">
                      <use xlinkHref={`${spriteImg}#thumbnail`} />
                    </svg>
                    Set Video Thumbnail
                  </button>
                  {/* // TODO: EWV-177 */}
                  {/* <div className={style.video_share_panel}>
                    <div className={style.video_share_title}>Post to</div>
                    {socialShares.map((socialNetwork, i) => (
                      <button
                        key={i}
                        className={cn(style.social_share, style[socialNetwork])}
                      >
                        <svg role="img">
                          <use
                            xlinkHref={`${socialImg}#${socialNetwork}`}
                          ></use>
                        </svg>
                      </button>
                    ))}
                  </div> */}
                </div>
              </div>
              <div className={isPopUpVideo ? style.popup : ''}>
                <VideoPlayer
                  format="mp4"
                  videoId={videoId}
                  poster={poster}
                  hasFullScreen={true}
                  hasMiniature={false}
                  popUpHandler={() => {
                    setIsPopUpVideo(!isPopUpVideo);
                  }}
                />
              </div>
            </div>

            <ul className={cn(style.video_action_ul, style.no_mobile)}>
              {videoActions.map(
                ({ name: nameVideo, handler, isActive = true }, i) => (
                  <li key={i} onClick={isActive ? handler : handleDisableAlert}>
                    <span>{nameVideo}</span>
                  </li>
                ),
              )}
            </ul>
          </div>

          <div className="right_block">
            <div className={style.video_edit_block}>
              <div className={style.widget_embedding}>
                <div className={style.widget_embedding__title}>Embedding</div>
                <div className={style.widget_embedding__info}>
                  Copy and paste one of the snippets of code below (not both) to
                  embed this video anywhere on the web.
                </div>
                <div className={style.widget_embedding__inputs}>
                  <Input
                    key="width"
                    setValue={setEmbedWidthHandler}
                    value={embedWidth}
                    type="number"
                    placeholder="Width"
                  />
                  <Input
                    key="height"
                    setValue={setEmbedHeightHandler}
                    value={embedHeight}
                    type="number"
                    placeholder="Height"
                  />
                  <Input
                    ref={refInput}
                    key="redirect"
                    value={embedRedirectUrl}
                    placeholder="Current Redirect at End"
                    isValid={embedRedirectUrlValid}
                    validationTextPosition="top"
                    // validateText='Must match format "http:// or https://".'
                    setValue={(value: string) =>
                      validateField('redirectLick', value)
                    }
                  />
                </div>
                <div className={style.widget_embedding__checkboxes}>
                  <CheckBox
                    setValue={setEmbedAspectRation}
                    value={isEmbedAspectRation}
                    key="aspectRatio"
                    title="Maintain aspect ratio"
                  />
                  <CheckBox
                    setValue={setEmbedNewWindow}
                    value={isEmbedNewWindow}
                    key="newWindow"
                    title="Open in new window"
                  />
                </div>
                <Button
                  handler={getEmbedHandler}
                  name="Copy embed code"
                  className={style.widget_embedding__btn}
                />
              </div>

              <PlayerConfig
                className={style.player_configs}
                videoId={videoId}
              />

              <GifList videoId={videoId} />

              <ul className={cn(style.video_action_ul, style.no_desctop)}>
                {videoActions.map(({ name: nameVideo, handler }, i) => (
                  <li key={i} onClick={handler}>
                    <span>{nameVideo}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {loading && <Loader />}
      </div>
      <Footer />
    </>
  );
};

export default VideoPage;
