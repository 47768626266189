import { PAGE_DEFAULT, LIMIT_DEFAULT } from '../../utils';
import {
  SET_SELECTED_VIDEOS_ID,
  SET_SELECT_ALL_VIDEOS,
  SET_SELECTED_VIDEO,
  SET_VIDEO_SORT_TYPE,
  SET_VIDEO_PAGE,
  SET_SEARCH_STRING,
  SET_USER_ID,
  SET_SORT_NAME,
  GET_VIDEO_LIST_REQUEST,
  GET_VIDEO_LIST_SUCCESS,
  GET_VIDEO_LIST_FAILURE,
  DELETE_VIDEO_FAILURE,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  GET_EMBED_VIDEO_FAILURE,
  GET_EMBED_VIDEO_REQUEST,
  GET_EMBED_VIDEO_SUCCESS,
  GET_VIDEO_FAILURE,
  GET_VIDEO_REQUEST,
  GET_VIDEO_SUCCESS,
  IVideoState,
  UPLOAD_VIDEO_REQUEST,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  UPDATE_VIDEO_FAILURE,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  VideoActionsTypes,
  BULK_VIDEOS_SUCCESS,
  BULK_VIDEOS_FAILURE,
  BULK_VIDEOS_REQUEST,
  UPLOAD_VIDEO_IMAGE_REQUEST,
  UPLOAD_VIDEO_IMAGE_SUCCESS,
  UPLOAD_VIDEO_IMAGE_FAILURE,
} from './video.type';

const initialState: IVideoState = {
  videos: [],
  video: null,
  videoSelected: null,
  loading: false,
  imageLoading: false,
  uploadVideos: [],
  selectedVideosId: [],
  limit: LIMIT_DEFAULT,
  page: PAGE_DEFAULT,
  search: '',
  pagination: {
    limit: LIMIT_DEFAULT,
    currentPage: PAGE_DEFAULT,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: 1,
    prevPage: 1,
    totalPages: 1,
    totalItems: 0,
  },
  sortName: 'createdAt',
  sortType: 'ASC',
  userId: null,
};

export default function videoReducer(
  state = initialState,
  action: VideoActionsTypes,
): IVideoState {
  switch (action.type) {
    case GET_VIDEO_LIST_REQUEST:
    case GET_VIDEO_REQUEST:
    case GET_EMBED_VIDEO_REQUEST:
    case DELETE_VIDEO_REQUEST:
    case UPDATE_VIDEO_REQUEST:
    case BULK_VIDEOS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_VIDEO_IMAGE_REQUEST:
      return {
        ...state,
        imageLoading: true,
      };
    case SET_VIDEO_SORT_TYPE:
      return {
        ...state,
        sortType: action.payload.sortType,
      };
    case SET_VIDEO_PAGE:
      return {
        ...state,
        limit: action.payload.pageType.limit || state.limit,
        page: action.payload.pageType.currentPage,
      };
    case SET_SELECTED_VIDEO:
      return {
        ...state,
        videoSelected: action.payload.video,
      };
    case SET_SELECTED_VIDEOS_ID: {
      const newSelectedVideos = state.selectedVideosId.filter(
        (item) => item !== action.payload.id,
      );
      return {
        ...state,
        selectedVideosId:
          state.selectedVideosId.length === newSelectedVideos.length
            ? [...state.selectedVideosId, action.payload.id]
            : newSelectedVideos,
      };
    }
    case SET_SELECT_ALL_VIDEOS: {
      return {
        ...state,
        selectedVideosId: action.payload.selectAllVideos
          ? state.videos.map((item) => item.id)
          : [],
      };
    }
    case SET_SEARCH_STRING: {
      return {
        ...state,
        search: action.payload.search,
      };
    }
    case GET_VIDEO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        videos: action.payload.videos,
        pagination: action.payload.pagination,
        limit:
          (action.payload.pagination && action.payload.pagination.limit) ||
          LIMIT_DEFAULT,
        page:
          (action.payload.pagination &&
            action.payload.pagination.currentPage) ||
          PAGE_DEFAULT,
      };
    case UPLOAD_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
        uploadVideos: [...state.uploadVideos, action.payload],
      };
    case GET_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        video: action.payload,
      };
    case UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        videos: [...state.videos, action.payload],
        video: action.payload,
      };
    case SET_USER_ID: {
      const { userId } = action.payload;
      return {
        ...state,
        userId,
      };
    }
    case SET_SORT_NAME: {
      const { name } = action.payload;
      return {
        ...state,
        sortName: name,
      };
    }
    case BULK_VIDEOS_SUCCESS:
    case BULK_VIDEOS_FAILURE:
    case GET_VIDEO_LIST_FAILURE:
    case UPLOAD_VIDEO_SUCCESS:
    case UPLOAD_VIDEO_FAILURE:
    case UPDATE_VIDEO_FAILURE:
    case GET_EMBED_VIDEO_SUCCESS:
    case GET_VIDEO_FAILURE:
    case DELETE_VIDEO_SUCCESS:
    case DELETE_VIDEO_FAILURE:
    case GET_EMBED_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_VIDEO_IMAGE_SUCCESS:
    case UPLOAD_VIDEO_IMAGE_FAILURE:
      return {
        ...state,
        imageLoading: false,
      };
    default:
      return state;
  }
}
