import { EditPlayerConfig, VideoId } from '../../models';
import { Id } from '../../utils';

export type UploadPlayerConfigLogo = Id & { image: Blob } & VideoId;

export interface IPlayerConfigState {
  loading: boolean;
}

export const UPDATE_PLAYER_CONFIG_REQUEST =
  '@playerConfig/UPDATE_PLAYER_CONFIG_REQUEST';
export const UPDATE_PLAYER_CONFIG_SUCCESS =
  '@playerConfig/UPDATE_PLAYER_CONFIG_SUCCESS';
export const UPDATE_PLAYER_CONFIG_FAILURE =
  '@playerConfig/UPDATE_PLAYER_CONFIG_FAILURE';

export const UPLOAD_PLAYER_CONFIG_LOGO_REQUEST =
  '@playerConfig/UPLOAD_PLAYER_CONFIG_LOGO_REQUEST';
export const UPLOAD_PLAYER_CONFIG_LOGO_SUCCESS =
  '@playerConfig/UPLOAD_PLAYER_CONFIG_LOGO_SUCCESS';
export const UPLOAD_PLAYER_CONFIG_LOGO_FAILURE =
  '@playerConfig/UPLOAD_PLAYER_CONFIG_LOGO_FAILURE';

export interface IUpdatePlayerConfigRequest {
  type: typeof UPDATE_PLAYER_CONFIG_REQUEST;
  payload: EditPlayerConfig;
}

interface IUpdatePlayerConfigSuccess {
  type: typeof UPDATE_PLAYER_CONFIG_SUCCESS;
}

interface IUpdatePlayerConfigFailure {
  type: typeof UPDATE_PLAYER_CONFIG_FAILURE;
}

export interface IUploadPlayerConfigLogoRequest {
  type: typeof UPLOAD_PLAYER_CONFIG_LOGO_REQUEST;
  payload: UploadPlayerConfigLogo;
}

interface IUploadPlayerConfigLogoSuccess {
  type: typeof UPLOAD_PLAYER_CONFIG_LOGO_SUCCESS;
}

interface IUploadPlayerConfigLogoFailure {
  type: typeof UPLOAD_PLAYER_CONFIG_LOGO_FAILURE;
}

export type PlayerConfigActionsTypes =
  | IUpdatePlayerConfigRequest
  | IUpdatePlayerConfigSuccess
  | IUpdatePlayerConfigFailure
  | IUploadPlayerConfigLogoRequest
  | IUploadPlayerConfigLogoSuccess
  | IUploadPlayerConfigLogoFailure;
