import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Card } from '../../../models';
import { RootState } from '../../../store';
import { setSelectedModal } from '../../../store/modal/modal.action';
import { initStatusProfile } from '../../../store/profile/profile.action';
import { Status } from '../../../utils';
import Loader from '../../Loader/Loader';
import PaymentMethodModalCard from './Card';
import { PaymentMethodModalProps } from './index.type';
import PaymentMethodModalPayPal from './PayPal';

const PaymentMethodModal = (
  props: Omit<PaymentMethodModalProps, 'onSubmit'>,
) => {
  const { paymentMethod, approveHandler, ...data } = props;

  const { loading, status } = useSelector((state: RootState) => state.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (status === Status.SUCCESS) {
      dispatch(setSelectedModal(null));
      dispatch(initStatusProfile());
    }
  }, [dispatch, status]);

  const submitHandler = useCallback(
    (cardInfo?: Card) => {
      approveHandler({
        type: paymentMethod,
        ...(paymentMethod === 'card' && { cardInfo }),
      });
    },
    [approveHandler, paymentMethod],
  );

  return (
    <>
      {paymentMethod === 'paypal' ? (
        <PaymentMethodModalPayPal {...data} onSubmit={submitHandler} />
      ) : (
        <PaymentMethodModalCard {...data} onSubmit={submitHandler} />
      )}
      {loading && <Loader />}
    </>
  );
};

export default PaymentMethodModal;
