type FeatureAdditional = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
};

// eslint-disable-next-line no-shadow
export enum Features {
  'popOutVideo' = 'Pop Out Video',
  // 'addEmailForm' = 'Add email form',
  'videoTrimmer' = 'Video Trimmer',
  // 'qrImage' = 'QR Image',
  // 'createWebPageTab' = 'Create Web page tab',
  // 'postToWordPressTab' = 'Post to Wordpress tab',
  // 'downloadVideo' = 'Download video',
  'createGif' = 'Create GIF',
  // 'setPrePostVideo' = 'Set pre/Post video',
  // 'postToFacebook' = 'Post to Facebook',
  // 'addFacebookRequirement' = 'Add Facebook Requirement',
  // 'enableVideoTracking' = 'Enable video tracking',
  // 'emailVideoTab' = 'Email video tab',
  // 'generateSitemap' = 'Generate sitemap',
  // 'addLableImageLabel' = 'Add label/Image Label',
  // 'postToEbay' = 'Post to eBay',
  // 'createExitPopUp' = 'Create Exit Popup',
  // 'showCreateVideo' = 'Show create Video',
  // 'SoftwareDownload' = 'Software Download', // --------------
  'setVideoImage' = 'Set Video Image',
  // 'addRelatedVideo' = 'Add Related Video',
  // 'postToYouTube' = 'Post to YouTube',
  // 'secureVideo' = 'Secure Video',
  // 'eventDelayEventTab' = 'Event delay event tab',
  'uploadPlayerLogo' = 'Upload Player logo',
  'addWatermark' = 'Add Watermak',
  // 'createClickMagnet' = 'Create click magnet',
  // 'circularPlayer' = 'Circular Player',
  // 'videoGenerator' = 'Video Generator',
}

export type FeatureKeys = keyof typeof Features;

export type FeatureList = {
  [key in FeatureKeys]: boolean;
};

export type Feature = FeatureAdditional & FeatureList;
