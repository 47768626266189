import { useState } from 'react';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { useAppDispatch } from '../../store';
import {
  bulkUsersRequest,
  updateUserByIdRequest,
} from '../../store/users/users.action';
import style from './SetBan.module.css';
import { SetBanProps } from './SetBan.type';

const SetBan = (props: SetBanProps) => {
  const [banReason, setBanReason] = useState<string>('');

  const dispatch = useAppDispatch();

  const handler = () => {
    const { id, closeHandler, query } = props;
    const data = {
      ban: true,
      banReason: banReason || null,
    };
    if (typeof id === 'number') {
      dispatch(
        updateUserByIdRequest({
          id,
          data,
        }),
      );
    } else {
      dispatch(bulkUsersRequest({ ...data, action: 'ban', ids: id }, query));
    }
    closeHandler();
  };

  return (
    <div className={style.container}>
      <>
        <div className={style.title}>Ban</div>
        <span className={style.sub_title}>Reason</span>
        <div className={style.item}>
          <Input
            value={banReason}
            setValue={setBanReason}
            placeholder="Write ban reason here"
          />
        </div>
        <div className={style.item}>
          <Button handler={handler} name="Ban" className={style.button} />
        </div>
      </>
    </div>
  );
};

export default SetBan;
