import { ReactNode, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import SetPaymentDetailPage from '../../pages/SetPaymentDetailPage/SetPaymentDetailPage';
import { RootState } from '../../store';
import { getProfileRequest } from '../../store/profile/profile.action';
import AdminMenu from '../AdminMenu/AdminMenu';
import MenuContainer from '../Menu/Menu';

type PrivateRouteProps = {
  admin?: boolean;
  canceled?: boolean;
} & RouteProps;

const PrivateRoute = ({ component, admin, ...rest }: PrivateRouteProps) => {
  const {
    auth: { accessToken, isPaymentMethodSet },
    profile: { profile, loading },
  } = useSelector((state: RootState) => state);

  if (!component) {
    throw Error('component is undefined');
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (accessToken && !isPaymentMethodSet && !profile) {
      dispatch(getProfileRequest());
    }
  }, [accessToken, dispatch, isPaymentMethodSet, profile]);

  const Component = component;
  const render = (props: RouteComponentProps<any>): ReactNode => {
    if (accessToken) {
      if (admin) {
        return (
          <div className="body-container-app">
            <AdminMenu />
            <Component {...props} />
          </div>
        );
      }

      if (!isPaymentMethodSet && !profile?.isPaymentMethodSet) {
        return loading ? <Loader /> : <SetPaymentDetailPage />;
      }

      return (
        <div className="body-container-app">
          <MenuContainer />
          <Component {...props} />
        </div>
      );
    }
    return <Redirect to={{ pathname: '/login' }} />;
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
