import { Link } from 'react-router-dom';

import style from './Footer.module.css';

const Footer = () => {
  return (
    <div className={style.container}>
      <ul className={style.menu}>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/term-of-use">Terms of Use</Link>
        </li>
        <li>
          <Link to="/">Contact Us</Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
