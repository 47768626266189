import { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import ProgressInfo from '../../components/ProgressInfo/ProgressInfo';
import { VideoId } from '../../models';
import {
  getImportVideoProgressInfo,
  importVideo,
} from '../../services/video.service';
import { RootState } from '../../store';
import { getVideoListRequest } from '../../store/video/video.action';
import style from './ImportVideo.module.css';

const ImportVideo = () => {
  const [mode, setMode] = useState<'link' | 'progress'>('link');

  const dispatch = useDispatch();
  const { selectedFolder } = useSelector((state: RootState) => state.folder);

  const getQuery = useCallback(
    () => (selectedFolder ? `&folder_id=${selectedFolder.id}` : ''),
    [selectedFolder],
  );

  const [url, setUrl] = useState<string>('');

  const [progress, setProgress] = useState<number>(0);
  const [videoId, setVideoId] = useState<VideoId['videoId']>();
  const [name, setName] = useState<string>('');

  const getProgressInfo = async (data: VideoId) => {
    const item = await getImportVideoProgressInfo({ videoId: data.videoId });
    setProgress(item.progress);
    setName(item.name);
  };

  useEffect(() => {
    let getInfoInterval: NodeJS.Timeout;
    if (mode === 'progress' && videoId) {
      if (progress !== 100) {
        getInfoInterval = setInterval(() => getProgressInfo({ videoId }), 1000);
      } else {
        dispatch(getVideoListRequest(getQuery()));
      }
    }

    return () => {
      if (getInfoInterval) {
        clearInterval(getInfoInterval);
      }
    };
  }, [mode, videoId, progress, dispatch, getQuery]);

  const handler = async () => {
    const { id } = await importVideo({ url });

    setVideoId(id);
    setMode('progress');
  };

  return (
    <div className={style.container}>
      {mode === 'link' ? (
        <>
          <div className={style.title}>Import from YouTube or Vimeo</div>
          <div className={style.item}>
            <Input value={url} setValue={setUrl} />
          </div>
          <div className={style.item}>
            <Button handler={handler} name="Import" className={style.button} />
          </div>
        </>
      ) : (
        <ProgressInfo progress={progress} name={name} />
      )}
    </div>
  );
};

export default ImportVideo;
