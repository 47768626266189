import { useEffect, useState } from 'react';

import cn from 'classnames';

import Select from '../Select/Select';
import { OptionType } from '../Select/Select.type';
import style from './TextEditor.module.css';
import { TextEditorType } from './TextEditor.type';

const TextEditor = ({ className, setValue }: TextEditorType) => {
  const [text, setText] = useState<string>('');
  const [color, setColor] = useState<string>('#000000');

  const fontFamilies = [
    { name: 'Dancing Script', value: 'DancingScript' },
    { name: 'Roboto', value: 'Roboto' },
  ];
  const [fontFamily, setFontFamily] = useState<OptionType>(fontFamilies[1]);

  const fontWeights = [
    { name: 'Thin', value: 200 },
    { name: 'Light', value: 300 },
    { name: 'Normal', value: 400 },
    { name: 'Dark', value: 500 },
    { name: 'Bold', value: 600 },
    { name: 'UltraBold', value: 700 },
    { name: 'Black', value: 800 },
  ];
  const [fontWeight, setFontWeight] = useState<OptionType>(fontWeights[2]);

  const fontSizes = [
    { name: '8', value: 8 },
    { name: '9', value: 9 },
    { name: '10', value: 10 },
    { name: '11', value: 11 },
    { name: '12', value: 12 },
    { name: '13', value: 13 },
    { name: '14', value: 14 },
    { name: '15', value: 15 },
    { name: '16', value: 16 },
    { name: '18', value: 18 },
    { name: '24', value: 24 },
    { name: '30', value: 30 },
    { name: '36', value: 36 },
    { name: '48', value: 48 },
    { name: '60', value: 60 },
    { name: '72', value: 72 },
    { name: '96', value: 96 },
  ];
  const [fontSize, setFontSize] = useState<OptionType>(fontSizes[0]);

  useEffect(() => {
    setValue({
      text,
      color,
      fontFamily: fontFamily.value,
      fontWeight: fontWeight.value,
      fontSize: fontSize.value,
    });
  }, [text, color, fontFamily, fontWeight, fontSize, setValue]);

  const handlerText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const EO = event.target;
    setText(EO.value);
  };

  const handlerColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const EO = event.target;

    setColor(EO.value);
  };

  return (
    <div className={cn(style.container, className)}>
      <div className={style.style_block}>
        <input className={style.color} type="color" onChange={handlerColor} />
        <Select
          defaultValue={fontFamily}
          curValue={fontFamily}
          setValue={setFontFamily}
          options={fontFamilies}
          className={style.font_family}
        />
        <Select
          defaultValue={fontWeight}
          curValue={fontWeight}
          setValue={setFontWeight}
          options={fontWeights}
          className={style.font_weight}
        />
        <Select
          defaultValue={fontSize}
          curValue={fontSize}
          setValue={setFontSize}
          options={fontSizes}
          className={style.font_size}
        />
      </div>
      <hr />
      <textarea className={style.text} value={text} onChange={handlerText} />
    </div>
  );
};

export default TextEditor;
