import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { PaymentPlanItem } from '../../models/Payment';
import {
  createPayment,
  getPaymentPlanList,
} from '../../services/payment.service';
import { ResponseType } from '../../utils';
import { alertError } from '../alert/alert.action';
import { ALERT_ERROR } from '../alert/alert.type';
import { SET_SELECTED_MODAL_TYPE } from '../modal/modal.type';
import { getPaymentPlanFailure, getPaymentPlanSuccess } from './payment.action';
import {
  GET_PAYMENT_PLAN_REQUEST,
  IPaymentRequest,
  PaymentActionsTypes,
  PAYMENT_FAILURE,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
} from './payment.type';

function* workerPayment(action: PaymentActionsTypes) {
  try {
    const { payload } = action as IPaymentRequest;

    const { paymentPlanId } = payload;
    yield call(createPayment, {
      paymentPlanId,
    });

    yield put({
      type: PAYMENT_SUCCESS,
    });
    yield put({
      type: SET_SELECTED_MODAL_TYPE,
      payload: {
        modalType: null,
      },
    });
  } catch (error) {
    yield put({ type: PAYMENT_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* watchPayment() {
  yield takeLatest(PAYMENT_REQUEST, workerPayment);
}

function* workerGetPaymentPlanList() {
  try {
    const { items: plans }: ResponseType<PaymentPlanItem> = yield call(
      getPaymentPlanList,
    );

    yield put(getPaymentPlanSuccess(plans));
  } catch (error) {
    yield put(getPaymentPlanFailure());
    yield put(alertError(error));
  }
}

function* watchGetPaymentPlanList() {
  yield takeLatest(GET_PAYMENT_PLAN_REQUEST, workerGetPaymentPlanList);
}

export const paymentWatchers: ForkEffect[] = [
  fork(watchPayment),
  fork(watchGetPaymentPlanList),
];
