import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { VideoStatistics } from '../../models/Analytic';
import {
  createDropOffAnalitycs,
  getVideoAnalytic,
} from '../../services/analytic';
import { ALERT_ERROR } from '../alert/alert.type';
import {
  AnalyticActionsTypes,
  CREATE_DROP_OFF_STATISTICS_FAILURE,
  CREATE_DROP_OFF_STATISTICS_REQUEST,
  CREATE_DROP_OFF_STATISTICS_SUCCESS,
  GET_VIDEO_ANALYTIC_FAILURE,
  GET_VIDEO_ANALYTIC_REQUEST,
  GET_VIDEO_ANALYTIC_SUCCESS,
  ICreateDropOffStatisticsRequest,
  IGetVideoAnalyticRequest,
} from './analytic.type';

function* workerGetVideoAnalytic(action: AnalyticActionsTypes) {
  try {
    const { payload } = action as IGetVideoAnalyticRequest;

    const data: VideoStatistics = yield call(getVideoAnalytic, payload);

    yield put({
      type: GET_VIDEO_ANALYTIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: GET_VIDEO_ANALYTIC_FAILURE,
    });

    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* watchGetVideoAnalytic() {
  yield takeLatest(GET_VIDEO_ANALYTIC_REQUEST, workerGetVideoAnalytic);
}

function* workerCreateDropOffStatistics(action: AnalyticActionsTypes) {
  try {
    const { payload } = action as ICreateDropOffStatisticsRequest;

    yield call(createDropOffAnalitycs, payload);

    yield put({
      type: CREATE_DROP_OFF_STATISTICS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: CREATE_DROP_OFF_STATISTICS_FAILURE,
    });
  }
}

function* watchCreateDropOffStatistics() {
  yield takeLatest(
    CREATE_DROP_OFF_STATISTICS_REQUEST,
    workerCreateDropOffStatistics,
  );
}

export const analiticWatchers: ForkEffect[] = [
  fork(watchGetVideoAnalytic),
  fork(watchCreateDropOffStatistics),
];
