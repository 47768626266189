import {
  GetEmbedVideo,
  Video,
  VideoId,
  FullVideo,
  EditVideo,
  UploadVideo,
} from '../models';
import { IBulkPayLoad } from '../store/video/video.type';
import { Query, ResponseType, Url } from '../utils';
import service from '../utils/httpClient';

export const getAllVideo = async (
  query: Query['query'],
): Promise<ResponseType<Video>> => {
  const path = '/api/videos';
  const { data } = await service({
    method: 'GET',
    url: path + query,
  });
  return data as ResponseType<Video>;
};

export const deleteVideoById = async (
  id: VideoId['videoId'],
): Promise<void> => {
  const path = `/api/videos/${id}`;
  await service({
    method: 'DELETE',
    url: path,
  });
};

export const getVideoInfoById = async ({
  videoId,
}: VideoId): Promise<FullVideo> => {
  const path = `/api/videos/${videoId}`;

  const { data: responseData } = await service({
    method: 'GET',
    url: path,
  });

  return responseData as FullVideo;
};

export const getEmbedVideo = async ({
  videoId,
  height,
  newWindow,
  redirect,
  width,
}: GetEmbedVideo): Promise<FullVideo> => {
  const path = `/api/videos/${videoId}/embed?width=${width}&height=${height}&redirect=${redirect}&new-window=${+newWindow}`;

  const { data: responseData } = await service({
    method: 'GET',
    url: path,
  });

  return responseData as FullVideo;
};

export const editVideo = async (editInfo: EditVideo): Promise<Video> => {
  const { videoId, ...data } = editInfo;

  const { data: responseData } = await service({
    method: 'PUT',
    url: `/api/videos/${videoId}/edit`,
    data,
  });

  return responseData as Video;
};

export const sendVideo = ({ video, setProgress, name }: UploadVideo) => {
  const data = new FormData();

  data.append('video', video);
  data.append('name', name);
  return service({
    method: 'POST',
    url: '/api/videos',
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (progressEvent.loaded * 100) / progressEvent.total,
      );

      setProgress(percentCompleted);
    },
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getImportVideoProgressInfo = async ({
  videoId,
}: VideoId): Promise<{ progress: number } & Pick<Video, 'name'>> => {
  const { data: responseData } = await service({
    method: 'GET',
    url: `/api/videos/${videoId}/import`,
  });

  return responseData as { progress: number } & Pick<Video, 'name'>;
};

export const importVideo = async (data: Url): Promise<Pick<Video, 'id'>> => {
  const { data: responseData } = await service({
    method: 'POST',
    url: '/api/videos/import',
    data,
  });

  return responseData as Pick<Video, 'id'>;
};

export const bulkVideos = async (data: Omit<IBulkPayLoad, 'query'>) => {
  const { data: responseData } = await service({
    method: 'POST',
    url: '/api/videos/bulk',
    data,
  });

  return responseData as Video;
};
