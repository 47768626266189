import { useEffect, useCallback } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import { RootState } from '../../store';
import { alertClear } from '../../store/alert/alert.action';
import style from './Alert.module.css';

const AlertComponent = () => {
  const dispatch = useDispatch();
  const { message, type } = useSelector((state: RootState) => state.alert);

  const handlerClose = useCallback(() => {
    dispatch(alertClear());
  }, [dispatch]);

  useEffect(() => {
    if (type) {
      setTimeout(() => handlerClose(), 6000);
    }
  }, [type, handlerClose]);

  return type ? (
    <div
      className={cn(
        style.container,
        type === 'error' ? style.warning : style.info,
      )}
    >
      <strong>{message}</strong>
      <div className={style.alert_close} onClick={handlerClose}>
        <svg role="img">
          <use xlinkHref={`${spriteImg}#close`} />
        </svg>
      </div>
    </div>
  ) : null;
};

export default AlertComponent;
