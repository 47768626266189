import { useState } from 'react';

import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import ButtonComponent from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import UploadPicture from '../../components/UploadPicture/UploadPicture';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import PopUpWrapper from '../../layout/PopUpWrapper/PopUpWrapper';
import { createVideoImage, uploadVideoImage } from '../../services/api.service';
import { RootState } from '../../store';
import { alertSuccess, alertError } from '../../store/alert/alert.action';
import { setSelectedModal } from '../../store/modal/modal.action';
import { getVideoRequest } from '../../store/video/video.action';
import style from './SetVideoImage.module.css';
import { Mode, SetVideoImageType } from './SetVideoImage.type';

const SetVideoImage = ({ videoId, saveHandler }: SetVideoImageType) => {
  const dispatch = useDispatch();

  const { video } = useSelector((state: RootState) => state.video);
  const source = video?.videoSource || '';

  const [mode, setMode] = useState<Mode>('main');
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  // const [currentImageId, setCurrentImageId] = useState<number>(200);
  const [currentImage, setCurrentImage] = useState<{
    id: number;
    image: number | Blob | null;
  }>();
  // const [posterCurrentTime, setPosterCurrentTime] = useState<
  //   number | undefined
  // >(undefined);

  const durationChangeHandler = (
    event: React.SyntheticEvent<HTMLVideoElement, Event>,
  ) => {
    const EO: HTMLMediaElement = event.target as HTMLMediaElement;

    const duration = Math.round(EO.duration);
    const delta = Math.round(duration / 3);
    const dataIndex = EO.attributes.getNamedItem('data-index');

    if (dataIndex) {
      const index: number = +dataIndex.value;

      EO.currentTime = delta * index;
    }
  };

  const addImageHandler = () => {
    setMode('upload');
  };

  const closeHandler = () => {
    dispatch(getVideoRequest({ videoId: `${videoId}` }));
    setMode('main');
    setSelectedModal(null);
    if (saveHandler) {
      saveHandler();
    }
  };

  const closePopUpHandler = () => {
    setMode('main');
  };

  const uploadHandler = (image: Blob | null) => {
    if (image) {
      setImageUrl(URL.createObjectURL(image));
      setCurrentImage({ id: 100, image });
    }
    closePopUpHandler();
  };

  const setVideoImageFromVideo = (
    event: React.MouseEvent<HTMLVideoElement, MouseEvent>,
    id: number,
  ) => {
    try {
      const EO: HTMLMediaElement = event.target as HTMLMediaElement;
      setCurrentImage({ id, image: EO.currentTime });
    } catch (error) {
      dispatch(alertError(error));
    }
  };

  const handleSaveClick = async () => {
    try {
      if (currentImage) {
        const { id, image } = currentImage;
        setLoading(true);
        let item;
        if (id !== 100 && typeof image === 'number') {
          item = await createVideoImage({
            videoId,
            time: image,
          });
        } else if (typeof image === 'object' && image !== null) {
          item = await uploadVideoImage({ videoId, image });
        }
        setImageUrl(item?.imageUrl);
        dispatch(alertSuccess('Thumbnail updated successfully!'));
      }
    } catch (error) {
      dispatch(alertError(error));
    }
    setLoading(false);
    closeHandler();
  };

  return (
    <div className={style.container}>
      <VideoPlayer
        poster={imageUrl}
        format="mp4"
        videoId={videoId}
        startTime={
          typeof currentImage?.image === 'number'
            ? currentImage.image
            : undefined
        }
      />

      <div className={style.images}>
        {[0, 1, 2, 3].map((index) => (
          <video
            data-index={index}
            key={index}
            className={cn(style.img, {
              [style.img_current]: index === currentImage?.id,
            })}
            src={source}
            onDurationChange={durationChangeHandler}
            onClick={(event) => setVideoImageFromVideo(event, index)}
          />
        ))}

        <div
          className={cn(style.img, {
            [style.img_current]: currentImage?.id === 100,
          })}
          onClick={addImageHandler}
        >
          <svg role="img">
            <use xlinkHref={`${spriteImg}#circle-plus`} />
          </svg>
        </div>
      </div>

      {mode === 'upload' && (
        <PopUpWrapper closeHandler={closePopUpHandler}>
          <UploadPicture
            handler={uploadHandler}
            aspectWidth={video?.metadata?.video.width}
            aspectHeight={video?.metadata?.video.height}
          />
        </PopUpWrapper>
      )}
      <div className={style.buttons}>
        <ButtonComponent handler={handleSaveClick} name="save" />
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default SetVideoImage;
