import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import { OptionType } from '../../components/Select/Select.type';
import { RootState } from '../../store';
import { bulkVideosRequest } from '../../store/video/video.action';
import style from './SelectFolder.module.css';
import { SelectFolderType } from './SelectFolder.type';

const SelectFolder = ({ query }: SelectFolderType) => {
  const defaultFolder: OptionType = {
    name: '',
    id: null,
    value: undefined,
  };

  const { selectedVideosId } = useSelector((state: RootState) => state.video);
  const { folders } = useSelector((state: RootState) => state.folder);

  const dispatch = useDispatch();

  const [curFolder, setCurFolder] = useState<OptionType>(defaultFolder);

  const handler = () => {
    dispatch(
      bulkVideosRequest({
        action: 'addToFolder',
        folderId: curFolder.id as number,
        selectedVideosId,
        query,
      }),
    );
  };

  return (
    <div className={style.container}>
      <Select
        curValue={curFolder}
        defaultValue={defaultFolder}
        options={folders as OptionType[]}
        setValue={setCurFolder}
      />
      <Button
        className={style.button}
        name="Save to this folder"
        handler={handler}
      />
    </div>
  );
};
export default SelectFolder;
