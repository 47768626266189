import { put, takeLatest, fork, call, ForkEffect } from 'redux-saga/effects';

import { User } from '../../models';
import {
  getProfile,
  createProfile,
  updateProfile,
  uploadFile,
} from '../../services/profile.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../alert/alert.type';
import { setSelectedModal } from '../modal/modal.action';
import { getUserListRequest } from '../users/users.action';
import {
  ProfileActionsTypes,
  ICreateProfileRequest,
  IUpdateProfileRequest,
  IUploadFileRequest,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  CREATE_PROFILE_REQUEST,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
} from './profile.type';

function* workerGetProfile() {
  try {
    const user: User = yield call(getProfile);

    yield put({ type: GET_PROFILE_SUCCESS, payload: { user } });
  } catch (error) {
    yield put({ type: GET_PROFILE_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerCreateProfile(action: ProfileActionsTypes) {
  try {
    const {
      payload: { query, userData },
    } = action as ICreateProfileRequest;
    yield call(createProfile, userData);

    if (query) {
      yield put(getUserListRequest(query));
    }

    yield put(setSelectedModal(null));

    yield put({ type: CREATE_PROFILE_SUCCESS });
    yield put({
      type: ALERT_SUCCESS,
      payload: { message: 'Registration completed successfully!' },
    });
    yield put({
      type: ALERT_SUCCESS,
      payload: {
        message:
          'An email with verification instructions has been sent to your email address.',
      },
    });
  } catch (error) {
    yield put({ type: CREATE_PROFILE_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerUpdateProfile(action: ProfileActionsTypes) {
  try {
    const { payload } = action as IUpdateProfileRequest;
    const user: User = yield call(updateProfile, payload.user);

    yield put({ type: UPDATE_PROFILE_SUCCESS, payload: { user } });
    yield put({
      type: ALERT_SUCCESS,
      payload: { message: 'Profile updated successfully!' },
    });
  } catch (error) {
    yield put({ type: UPDATE_PROFILE_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerUploadFile(action: ProfileActionsTypes) {
  try {
    const { payload } = action as IUploadFileRequest;
    const user: User = yield call(uploadFile, payload);

    yield put({ type: UPLOAD_FILE_SUCCESS, payload: { user } });
    yield put({
      type: ALERT_SUCCESS,
      payload: { message: 'Profile updated successfully!' },
    });
  } catch (error) {
    yield put({ type: UPLOAD_FILE_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* watchGetProfile() {
  yield takeLatest(GET_PROFILE_REQUEST, workerGetProfile);
}

function* watchCreateProfile() {
  yield takeLatest(CREATE_PROFILE_REQUEST, workerCreateProfile);
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE_REQUEST, workerUpdateProfile);
}

function* watchUploadFile() {
  yield takeLatest(UPLOAD_FILE_REQUEST, workerUploadFile);
}

export const profileWatchers: ForkEffect[] = [
  fork(watchGetProfile),
  fork(watchCreateProfile),
  fork(watchUpdateProfile),
  fork(watchUploadFile),
];
