import { CircularProgress } from '@material-ui/core';

import style from './Loader.module.css';

const Loader = () => {
  return (
    <div className={style.container}>
      <CircularProgress disableShrink />
    </div>
  );
};

export default Loader;
