import {
  IQuestionState,
  QuestionActionsTypes,
  SET_QUESTION_ANSWER,
} from './question.type';

const initialState: IQuestionState = {
  answer: false,
};

export default function questionReducer(
  state = initialState,
  action: QuestionActionsTypes,
): IQuestionState {
  switch (action.type) {
    case SET_QUESTION_ANSWER:
      return {
        ...state,
        answer: action.payload.answer,
      };
    default:
      return state;
  }
}
