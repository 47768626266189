import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import {
  updatePlayerConfig,
  uploadPlayerConfigLogo,
} from '../../services/playerConfig';
import { ALERT_ERROR } from '../alert/alert.type';
import { GET_VIDEO_REQUEST } from '../video/video.type';
import {
  IUpdatePlayerConfigRequest,
  IUploadPlayerConfigLogoRequest,
  PlayerConfigActionsTypes,
  UPDATE_PLAYER_CONFIG_FAILURE,
  UPDATE_PLAYER_CONFIG_REQUEST,
  UPDATE_PLAYER_CONFIG_SUCCESS,
  UPLOAD_PLAYER_CONFIG_LOGO_FAILURE,
  UPLOAD_PLAYER_CONFIG_LOGO_REQUEST,
  UPLOAD_PLAYER_CONFIG_LOGO_SUCCESS,
} from './playerConfig.type';

function* workerUpdatePlayerConfig(action: PlayerConfigActionsTypes) {
  try {
    const { payload } = action as IUpdatePlayerConfigRequest;

    const { videoId, ...playerData } = payload;
    yield call(updatePlayerConfig, playerData);

    yield put({
      type: UPDATE_PLAYER_CONFIG_SUCCESS,
    });

    yield put({
      type: GET_VIDEO_REQUEST,
      payload: { videoId },
    });
  } catch (error) {
    yield put({ type: UPDATE_PLAYER_CONFIG_FAILURE });
    yield put({
      type: ALERT_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* watchUpdatePlayerConfig() {
  yield takeLatest(UPDATE_PLAYER_CONFIG_REQUEST, workerUpdatePlayerConfig);
}

function* workerUploadPlayerConfigLogo(action: PlayerConfigActionsTypes) {
  try {
    const { payload } = action as IUploadPlayerConfigLogoRequest;

    const { videoId, ...playerData } = payload;

    yield call(uploadPlayerConfigLogo, playerData);

    yield put({
      type: UPLOAD_PLAYER_CONFIG_LOGO_SUCCESS,
    });

    yield put({
      type: GET_VIDEO_REQUEST,
      payload: { videoId },
    });
  } catch (error) {
    yield put({ type: UPLOAD_PLAYER_CONFIG_LOGO_FAILURE });
    yield put({
      type: ALERT_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* watchUploadPlayerConfigLogo() {
  yield takeLatest(
    UPLOAD_PLAYER_CONFIG_LOGO_REQUEST,
    workerUploadPlayerConfigLogo,
  );
}

export const playerConfigWatchers: ForkEffect[] = [
  fork(watchUpdatePlayerConfig),
  fork(watchUploadPlayerConfigLogo),
];
