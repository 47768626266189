export interface IQuestionState {
  answer: boolean;
}

export const SET_QUESTION_ANSWER = '@question/SET_QUESTION_ANSWER';

interface ISetQuestion {
  type: typeof SET_QUESTION_ANSWER;
  payload: {
    answer: boolean;
  };
}

export type QuestionActionsTypes = ISetQuestion;
