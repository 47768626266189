import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { EmbedPageType } from './EmbedPage.type';

const EmbedPage = ({
  match: {
    params: { videoId },
  },
}: EmbedPageType) => {
  return (
    <div>
      <VideoPlayer
        hasFullScreen={true}
        videoId={videoId}
        format="hls"
        sendStatistics={true}
      />
    </div>
  );
};

export default EmbedPage;
