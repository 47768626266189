import Login from '../../containers/Login/Login';
import MainLayout from '../../layout/MainLayout/MainLayout';

const LogInPage = () => {
  return (
    <MainLayout>
      <Login />
    </MainLayout>
  );
};

export default LogInPage;
