export interface IAlertState {
  message: string;
  type: 'success' | 'error' | null;
}

export const ALERT_SUCCESS = '@alert/ALERT_SUCCESS';
export const ALERT_ERROR = '@alert/ALERT_ERROR';
export const ALERT_CLEAR = '@alert/ALERT_CLEAR';

interface IAlertSuccess {
  type: typeof ALERT_SUCCESS;
  payload: {
    message: string;
  };
}

interface IAlertError {
  type: typeof ALERT_ERROR;
  payload: {
    message: string;
  };
}

interface IAlertClear {
  type: typeof ALERT_CLEAR;
}

export type AlertActionsTypes = IAlertSuccess | IAlertError | IAlertClear;
