import { useCallback, useEffect, useState } from 'react';

import {
  PayPalButtons,
  PayPalButtonsComponentProps,
  PayPalScriptProvider,
} from '@paypal/react-paypal-js';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { createPayment } from '../../services/payment.service';
import { RootState } from '../../store';
import { alertError, alertSuccess } from '../../store/alert/alert.action';
import {
  getPaymentPlanRequest,
  paymentRequest,
} from '../../store/payment/payment.action';
import { PaymentPlan, PAYPAL_CLIENT_ID, Period } from '../../utils';
import Loader from '../Loader/Loader';
import RadioButton from '../RadioButton/RadioButton';
import style from './ChangeTariff.module.css';

const ChangeTariff = () => {
  const dispatch = useDispatch();

  const {
    payment: { plans, loading },
    profile: { profile },
  } = useSelector((state: RootState) => state);

  const [planId, setPlanId] = useState<number>(0);

  const handler = useCallback(
    (paymentPlanId: number) => {
      dispatch(
        paymentRequest({
          paymentPlanId,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getPaymentPlanRequest());
  }, [dispatch]);

  const createOrderPayPalHandler = async (paymentPlanId: number) => {
    const data = (await createPayment({
      paymentPlanId,
    })) as { id: string };

    return data.id;
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const approveHandler: PayPalButtonsComponentProps['onApprove'] = async (
    _data,
    actions,
  ) => {
    try {
      const details = await actions.order.capture();

      dispatch(
        alertSuccess(
          `Transaction completed by ${details.payer.name.given_name}`,
        ),
      );
    } catch (err) {
      dispatch(alertError(err));
    }
  };

  const renderPaymentBtn = (type: PaymentPlan) => {
    const [plan] = plans.filter(({ name }) => name === type);

    if (!plan) {
      return null;
    }

    return plan.variants.map(({ duration, id, price }) => {
      const tariff = `${price}$ / ${Period[duration]}`;
      return profile?.paymentMethod === 'card' ? (
        <button
          key={id}
          onClick={() => handler(id)}
          className={style.btn_submit}
        >
          {tariff}
        </button>
      ) : (
        <div key={id} className={style.btn_paypal}>
          <RadioButton
            name="tariff"
            setValue={setPlanId}
            options={[
              {
                name: tariff,
                value: id.toString(),
              },
            ]}
            value={`${planId}`}
          />
        </div>
      );
    });
  };

  return (
    <div
      className={cn(
        style.container,
        profile?.paymentMethod === 'paypal' && style.paypal,
      )}
    >
      <div className={style.tariff_plans}>
        <div className={style.tariff_plans_item}>
          <div className={cn(style.tariff_plans_item_ico, style.bronze)}>B</div>
          <div className={style.tariff_plans_item_description}>
            For most businesses that want to otpimize web queries
          </div>
          <ul className={style.tariff_plans_item_ul}>
            <li>All limited links</li>
            <li>Own analytics platform</li>
            <li>Chat support</li>
            <li>Optimize hashtags</li>
            <li>Unlimited users</li>
          </ul>

          <div className={style.tariff_plans_btns}>
            {renderPaymentBtn(PaymentPlan.BRONZE)}
          </div>
        </div>
        <div className={cn(style.tariff_plans_item, style.popular_item)}>
          <div className={cn(style.tariff_plans_item_ico, style.silver)}>S</div>
          <div className={style.tariff_plans_item_description}>
            For most businesses that want to otpimize web queries
          </div>
          <ul className={style.tariff_plans_item_ul}>
            <li>All limited links</li>
            <li>Own analytics platform</li>
            <li>Chat support</li>
            <li>Optimize hashtags</li>
            <li>Unlimited users</li>
          </ul>

          <div className={style.tariff_plans_btns}>
            {renderPaymentBtn(PaymentPlan.SILVER)}
          </div>
        </div>
        <div className={style.tariff_plans_item}>
          <div className={cn(style.tariff_plans_item_ico, style.gold)}>G</div>
          <div className={style.tariff_plans_item_description}>
            For most businesses that want to otpimize web queries
          </div>
          <ul className={style.tariff_plans_item_ul}>
            <li>All limited links</li>
            <li>Own analytics platform</li>
            <li>Chat support</li>
            <li>Optimize hashtags</li>
            <li>Unlimited users</li>
          </ul>

          <div className={style.tariff_plans_btns}>
            {renderPaymentBtn(PaymentPlan.GOLD)}
          </div>
        </div>

        {loading && <Loader />}
      </div>
      {profile?.paymentMethod === 'paypal' && (
        <PayPalScriptProvider
          key={planId}
          options={{
            'client-id': PAYPAL_CLIENT_ID,
          }}
        >
          <PayPalButtons
            disabled={!planId}
            style={{
              layout: 'horizontal',
              color: 'blue',
              shape: 'pill',
              height: 30,
            }}
            createOrder={() => createOrderPayPalHandler(planId)}
            onApprove={approveHandler}
          />
        </PayPalScriptProvider>
      )}
    </div>
  );
};

export default ChangeTariff;
