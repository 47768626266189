import ButtonComponent from '../../../Button/Button';
import { PaymentMethodModalProps } from '../index.type';
import style from './index.module.css';

const PaymentMethodModalPayPal = (
  props: Omit<PaymentMethodModalProps, 'paymentMethod' | 'approveHandler'>,
) => {
  const { cancelHandler, onSubmit } = props;

  return (
    <div className={style.container}>
      <div className={style.info}>
        Click "Submit" if you want to choose a PayPal payment method.
      </div>
      <div className={style.btns}>
        <ButtonComponent handler={onSubmit} name="Submit" />
        <ButtonComponent
          handler={cancelHandler}
          name="Cancel"
          hasBackground={false}
        />
      </div>
    </div>
  );
};

export default PaymentMethodModalPayPal;
