import { Feature } from '../models';
import service from '../utils/httpClient';

export const saveFeatures = async (features: Feature): Promise<void> => {
  const { id, ...data } = features;
  const url = `/api/feature/${id}`;

  await service({
    method: 'PUT',
    url,
    data,
  });
};
