import { useState } from 'react';

import cn from 'classnames';

import spriteImg from '../../assets/img/sprite.svg';
import style from './Select.module.css';
import { OptionType, SelectType } from './Select.type';

const Select = (props: SelectType) => {
  const { options, setValue, className, curValue, defaultValue, title } = props;
  const [isDisplay, setDisplay] = useState<boolean>(false);

  const valueFieldName = curValue.value ? 'value' : 'id';

  const handlerDisplayClick = () => {
    setDisplay(!isDisplay);
  };

  const handlerClick = (value: OptionType['value']) => {
    if (value === curValue[valueFieldName]) {
      setValue(defaultValue);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setValue(options.find((option: any) => option[valueFieldName] === value));
    }
    setDisplay(false);
  };

  const renderOption = (item: OptionType, index: number) => {
    return (
      <span
        key={index}
        className={style.option_container}
        onClick={() =>
          handlerClick(item[valueFieldName] as OptionType['value'])
        }
      >
        {item.name}
      </span>
    );
  };

  return (
    <div className={cn(style.container, className)}>
      {title && <div className={style.title}>{title}</div>}
      <div
        className={cn(style.bg_overlay, isDisplay && style.active_overlay)}
        onClick={handlerDisplayClick}
      ></div>
      <div className={style.main} onClick={handlerDisplayClick}>
        <div
          className={cn(
            style.name,
            isDisplay && style.opened,
            className && `${className}-name`,
          )}
        >
          <span className={cn(className && `${className}-span`)}>
            {curValue.name}
          </span>
          <div className={cn(style.carpet, isDisplay && style.carpet_open)}>
            <svg role="img">
              <use xlinkHref={`${spriteImg}#down-arrow`}></use>
            </svg>
          </div>
        </div>

        <div
          className={cn(
            style.options,
            isDisplay && style.open,
            className && `${className}-open`,
          )}
        >
          {options.map(renderOption)}
        </div>
      </div>
    </div>
  );
};

export default Select;
