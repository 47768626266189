export type Params<P> = {
  location: {
    search: string;
  };
  match: {
    params: P;
  };
};

export type ModalType =
  | 'modalImportVideo'
  | 'modalUploadVideo'
  | 'modalCreateVideo'
  | 'modalСreatePlaylist'
  | 'modalFolder'
  | 'modalAddWatermark'
  | 'modalSetVideoTumbnail'
  | 'modalTrimmer'
  | 'modalDeleteVideo'
  | 'modalChangePlan'
  | 'modalCreateGif'
  | 'modalAddTextToGif'
  | 'modalRenameVideo'
  | 'modalPreviewVideo'
  | 'modalSelectVideoFolder'
  | 'modalBookPlan'
  | 'modalSetBan'
  | 'modalCreateUser'
  | 'modalBookCard'
  | 'modalEnterCard'
  | 'modalPaymentDetail'
  | null;

export type PageType = {
  limit: number;
  currentPage: number;
};

export type MetaType = PageType & {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  nextPage: number;
  prevPage: number;
  totalPages: number;
  totalItems: number;
};

export type ResponseType<T> = {
  items: T[];
  meta: MetaType;
};

export type Id = {
  id: number;
};

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type SortType = 'ASC' | 'DESC';

export type TokenType = {
  tokenType: string;
  accessToken: string;
  refreshToken: string;
  isAdmin: boolean;
  isPaymentMethodSet: boolean;
};

export type RestorePasswordType = {
  password: string;
  token: string;
};

export type Url = {
  url: string;
};

export type Query = {
  query: string;
};

// eslint-disable-next-line no-shadow
export enum PaymentPlanDuration {
  ONE_MONTH = '1m',
  ONE_YEAR = '1y',
}

// eslint-disable-next-line no-shadow
export enum PaymentPlan {
  BRONZE = 'bronze',
  SILVER = 'silver',
  GOLD = 'gold',
}

// eslint-disable-next-line no-shadow
export enum Period {
  '1m' = 'Month',
  '1y' = 'Year',
}

export type PaymentMethod = 'card' | 'paypal';

// eslint-disable-next-line no-shadow
export enum Status {
  INITIAL = 'initial',
  INPROGRESS = 'inprogress',
  FAIL = 'fail',
  SUCCESS = 'success',
}
