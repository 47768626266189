import { useEffect, useState } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import easyColorImg from '../../assets/img/easy-color.png';
import spriteImg from '../../assets/img/sprite.svg';
import AvatarComponent from '../../components/Avatar/Avatar';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';
import NotificationComponent from '../../components/NotificationComponent/NotificationComponent';
import { RootState } from '../../store';
import { alertError } from '../../store/alert/alert.action';
import { authLogoutRequest } from '../../store/auth/auth.action';
import { getProfileRequest } from '../../store/profile/profile.action';
import { setSearchString as setSearchStringAction } from '../../store/video/video.action';
import { history } from '../../utils';
import style2 from '../Menu/Menu.module.css';
import style from './Header.module.css';
import { HeaderType } from './Header.type';

const Header = (props: HeaderType) => {
  const { children, className, isAdmin, notification } = props;
  const { accessToken, loading: authLoading } = useSelector(
    (state: RootState) => state.auth,
  );
  const { profile, loading: profileLoading } = useSelector(
    (state: RootState) => state.profile,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (profile?.ban) {
      dispatch(authLogoutRequest());
      dispatch(alertError('User was baned'));
    }
  }, [accessToken, dispatch, profile?.ban]);

  useEffect(() => {
    if (accessToken) {
      dispatch(getProfileRequest());
    }
  }, [accessToken, dispatch]);

  const logoutHandler = () => {
    dispatch(authLogoutRequest());
  };

  const [isDisplay, setDisplay] = useState<boolean>(false);

  const showMenuHandler = () => {
    setDisplay(!isDisplay);
  };

  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const searchButtonHandler = () => {
    setSearchOpen(!isSearchOpen);
  };

  const searchAction = () => {
    dispatch(setSearchStringAction(searchString));
    if (!isAdmin) {
      history.push('/');
    }
  };

  const onKeyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchAction();
    }
  };

  return profile ? (
    <div className={cn(style.container, className)}>
      <div className={cn(style.mobile_menu, isDisplay && style.active_menu)}>
        <div className={style.mobile_menu_header}>
          <div className={style.mobile_menu_header_left}>
            <button className={style.back_menu} onClick={showMenuHandler}>
              <svg role="img">
                <use xlinkHref={`${spriteImg}#circle-right`} />
              </svg>
            </button>
            <div className={style.name_user}>
              {profile?.profile?.firstName} {profile?.profile?.lastName}
            </div>
          </div>
          <div className={style.avatar_user}>
            <AvatarComponent img={profile?.profile?.avatar || ''} />
          </div>
        </div>

        {isAdmin ? (
          <div className={cn(style2.sidebar_widgets, style2.mobile_widgets)}>
            <div className={style2.sidebar_widget}>
              <div className={style2.sidebar_widget__title}>
                <span className={style2.sidebar_widget__ico}>
                  <svg role="img">
                    <use xlinkHref={`${spriteImg}#user`} />
                  </svg>
                </span>
                Managment
              </div>
              <div className={style2.sidebar_widget__body}>
                <ul className={style2.sidebar_widget__menu}>
                  <li>
                    <NavLink activeClassName={style.current} to="/admin/users">
                      Users
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName={style.current}
                      to="/admin/inactive"
                    >
                      Inactive users
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style2.sidebar_widget}>
              <div className={style2.sidebar_widget__title}>
                <span className={style2.sidebar_widget__ico}>
                  <svg role="img">
                    <use xlinkHref={`${spriteImg}#settings`}></use>
                  </svg>
                </span>
                Settings
              </div>
              <div className={style2.sidebar_widget__body}>
                <ul className={style2.sidebar_widget__menu}>
                  <li>
                    <NavLink to="/">Notifications</NavLink>
                  </li>
                  <li>
                    <NavLink to="/">Support</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className={cn(style2.sidebar_widgets, style2.mobile_widgets)}>
            <div className={style2.sidebar_widget}>
              <div className={style2.sidebar_widget__title}>
                <span className={style2.sidebar_widget__ico}>
                  <svg role="img">
                    <use xlinkHref={`${spriteImg}#myaccount`} />
                  </svg>
                </span>
                My account
              </div>
              <div className={style2.sidebar_widget__body}>
                <ul className={style2.sidebar_widget__menu}>
                  <li>
                    <NavLink to="/">View Usage</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName={style2.current} to="/settings">
                      Settings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/">Analytics</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style2.sidebar_widget}>
              <div className={style2.sidebar_widget__title}>
                <span className={style2.sidebar_widget__ico}>
                  <svg role="img">
                    <use xlinkHref={`${spriteImg}#video`}></use>
                  </svg>
                </span>
                Video
              </div>
              <div className={style2.sidebar_widget__body}>
                <ul className={style2.sidebar_widget__menu}>
                  <li>
                    <NavLink
                      exact={true}
                      activeClassName={style2.current}
                      to="/"
                    >
                      My videos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/">How to</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style2.sidebar_widget}>
              <div className={style2.sidebar_widget__title}>
                <span className={style2.sidebar_widget__ico}>
                  <svg role="img">
                    <use xlinkHref={`${spriteImg}#support`}></use>
                  </svg>
                </span>
                Support
              </div>
              <div className={style2.sidebar_widget__body}>
                <ul className={style2.sidebar_widget__menu}>
                  <li>
                    <NavLink to="/">Support</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className={style.logout_button} onClick={logoutHandler}>
          <span className={style.user_menu__ico}>
            <svg role="img">
              <use xlinkHref={`${spriteImg}#logout`} />
            </svg>
          </span>
          Logout
        </div>
      </div>
      <div className={style.mobile_header}>
        <div className={style.mobile_logo}>
          <img
            src={easyColorImg}
            srcSet={`${easyColorImg} 2x`}
            className={style.mobile_logo}
            alt="logo"
          />
        </div>
        <button className={style.toggler_button} onClick={showMenuHandler}>
          <svg role="img">
            <use xlinkHref={`${spriteImg}#burger`} />
          </svg>
        </button>
      </div>
      <div className={style.left}>{children}</div>
      <div>
        <div className={style.user_block}>
          <div className={style.user_menu}>
            <div
              className={cn(
                style.search_button,
                isSearchOpen && style.active_search,
              )}
            >
              <span className={style.user_menu__ico}>
                <Input
                  setValue={setSearchString}
                  value={searchString}
                  placeholder="Search..."
                  icon={`${spriteImg}#search`}
                  onKeyPress={onKeyPressHandler}
                  onIconClick={searchAction}
                />
                <span
                  className={style.user_menu__ico_input}
                  onClick={searchButtonHandler}
                >
                  <svg role="img">
                    <use
                      xlinkHref={`${spriteImg}#${
                        isSearchOpen ? 'close' : 'search'
                      }`}
                    />
                  </svg>
                </span>
              </span>
            </div>
            {notification && !isSearchOpen && (
              <NotificationComponent className={style.notification} />
            )}
            <div className={style.user_profile}>
              <AvatarComponent img={profile?.profile?.avatar || ''} />
              <div>
                {profile?.profile?.firstName} {profile?.profile?.lastName}
                <span className={style.user_status}>
                  {profile?.isActive || 'Canceled'}
                </span>
              </div>
            </div>
            <div className={style.logout_button} onClick={logoutHandler}>
              <span className={style.user_menu__ico}>
                <svg role="img">
                  <use xlinkHref={`${spriteImg}#logout`} />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      {(authLoading || profileLoading) && <Loader />}
    </div>
  ) : null;
};

export default Header;
