import { useCallback, useEffect } from 'react';

import cn from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';

import { Card } from '../../../../models';
import { alertError } from '../../../../store/alert/alert.action';
import ButtonComponent from '../../../Button/Button';
import Input from '../../../Input/Input';
import { PaymentMethodModalProps } from '../index.type';
import { INITIAL_VALUE } from './index.constant';
import { validationSchema } from './index.schema';
import style from './style.module.css';

const PaymentMethodModalCard = (
  props: Omit<PaymentMethodModalProps, 'paymentMethod' | 'approveHandler'>,
) => {
  const { onSubmit, className, cancelHandler } = props;

  const dispatch = useDispatch();

  const submitHandler = useCallback(
    (val: Card) => {
      onSubmit(val);
    },

    [onSubmit],
  );

  const form = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUE,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (!form.isValid) {
      dispatch(alertError('Check the card'));
    }
  }, [dispatch, form.isValid]);

  return (
    <FormikProvider value={form}>
      <form
        className={cn(style.container, className)}
        onSubmit={form.handleSubmit}
      >
        <Input
          placeholder="Card number"
          onChangeHandler={form.handleChange}
          value={form.values.cardNumber}
          isValid={form.touched.cardNumber && !form.errors.cardNumber}
          validateText={form.errors.cardNumber}
          id="cardNumber"
          validationTextPosition="top"
          isValidUnfocus
        />
        <div className={style.back}>
          <Input
            isValidUnfocus
            className={style.date}
            placeholder="MM"
            onChangeHandler={form.handleChange}
            value={form.values.expMonth}
            isValid={form.touched.expMonth && !form.errors.expMonth}
            id="expMonth"
            validateText={form.errors.expMonth}
            validationTextPosition="top"
          />
          <Input
            isValidUnfocus
            className={style.date}
            placeholder="YY"
            validateText={form.errors.expYear}
            onChangeHandler={form.handleChange}
            value={form.values.expYear}
            id="expYear"
            isValid={form.touched.expYear && !form.errors.expYear}
            validationTextPosition="top"
          />
          <Input
            validateText={form.errors.cvc}
            placeholder="CWV/CVC"
            isValidUnfocus
            onChangeHandler={form.handleChange}
            value={form.values.cvc}
            isValid={form.touched.cvc && !form.errors.cvc}
            id="cvc"
            validationTextPosition="top"
          />
        </div>
        <Input
          placeholder="First name"
          validateText={form.errors.firstName}
          isValidUnfocus
          onChangeHandler={form.handleChange}
          isValid={form.touched.firstName && !form.errors.firstName}
          value={form.values.firstName}
          id="firstName"
          validationTextPosition="top"
        />
        <Input
          placeholder="Last name"
          isValidUnfocus
          validateText={form.errors.lastName}
          onChangeHandler={form.handleChange}
          value={form.values.lastName}
          isValid={form.touched.lastName && !form.errors.lastName}
          validationTextPosition="top"
          id="lastName"
        />

        <div className={style.btns}>
          <ButtonComponent className={style.btn} type="submit" name="Submit" />
          <ButtonComponent
            handler={cancelHandler}
            name="Cancel"
            hasBackground={false}
          />
        </div>
      </form>
    </FormikProvider>
  );
};

export default PaymentMethodModalCard;
