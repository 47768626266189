/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ChangeEvent, useRef, useState } from 'react';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Button from '../Button/Button';
import style from './UploadPicture.module.css';
import { Mode, UploadPictureType, RefType } from './UploadPicture.type';

const UploadPicture = ({
  handler,
  aspectWidth,
  aspectHeight,
}: UploadPictureType) => {
  const fileInputRef = useRef<HTMLInputElement>();

  const [mode, setMode] = useState<Mode>('upload');
  const [fileUrl, setFileUrl] = useState<string>('');
  const [crop, setCrop] = useState<ReactCrop.Crop>({});
  const [imageType, setImageType] = useState<'svg' | 'png'>('png');

  const original = useRef<RefType>(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handlerUploadFile = () => {
    fileInputRef?.current?.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.length) {
      const file = event.target.files[0];

      if (file.type === 'image/svg+xml') {
        setImageType('svg');
      }

      setFileUrl(URL.createObjectURL(file));
      setMode('edit');
    }
  };

  const handlerCrop = (newCrop: ReactCrop.Crop) => {
    if (aspectWidth && aspectHeight) {
      setCrop({ ...newCrop, aspect: aspectWidth / aspectHeight });
    } else {
      setCrop(newCrop);
    }
  };

  const saveAndContinueHandler = () => {
    const image = new Image();
    image.src = fileUrl;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      if (original && original.current && original.current.componentRef) {
        const { clientWidth, clientHeight } = original.current.componentRef;
        const originalWidth = clientWidth;
        const originalHeight = clientHeight;
        const scaleX = image.naturalWidth / originalWidth;
        const scaleY = image.naturalHeight / originalHeight;
        canvas.width = crop.width || image.width;
        canvas.height = crop.height || image.height;

        const ctx = canvas.getContext('2d');

        ctx?.drawImage(
          image,
          (crop.x || 0) * scaleX,
          (crop.y || 0) * scaleY,
          (crop.width || image.width) * scaleX,
          (crop.height || image.height) * scaleY,
          0,
          0,
          crop.width || image.width,
          crop.height || image.height,
        );

        // As a blob
        return new Promise((resolve) => {
          canvas.toBlob(
            (blob) => {
              handler(blob);
              resolve(blob);
            },
            `image/${imageType}`,
            1,
          );
        });
      }
      return new Promise(() => null);
    };
  };

  const comeBackHandler = () => {
    setMode('upload');
    setCrop({});
  };

  return mode === 'upload' ? (
    <div className={style.container_upload}>
      <div className={style.header}>Upload picture</div>
      <div className={style.support_info}>
        We support JPG, GIF or PNG files.
      </div>
      <Button
        className={style.upload_button}
        name="Select file"
        handler={handlerUploadFile}
      />
      <input
        type="file"
        ref={fileInputRef as any}
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <div className={style.info}>
        If you have any problems with your upload, try using a smaller picture.
      </div>
    </div>
  ) : (
    <div className={style.container_edit}>
      <div className={style.header}>
        The selected area will be shown IN COVER VIEW
      </div>
      <ReactCrop
        ref={original}
        src={fileUrl}
        imageStyle={{ borderRadius: 12 }}
        crop={crop}
        onChange={handlerCrop}
      />
      <div className={style.controls}>
        <Button
          key="back"
          name="Go back"
          handler={comeBackHandler}
          hasBackground={false}
        />
        <Button
          key="save"
          name="Save and continue"
          handler={saveAndContinueHandler}
        />
      </div>
    </div>
  );
};

export default UploadPicture;
