import { useRef } from 'react';

import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import style from './SettingSlider.module.css';
import { SettingSliderType } from './SettingSlider.type';

const CustomSlider = withStyles({
  root: {
    color: '#EAEDF5',
    height: 3,
    cursor: 'pointer',
    padding: 0,
  },
  thumb: {
    height: '32px',
    width: '50px',
    marginTop: '-15px',
    marginLeft: '-25px',
    borderRadius: '16px',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '150%',
    background:
      'radial-gradient(123.09% 40.07% at -29.26% -2.31%, #22A9E0 0%, rgba(34, 169, 224, 0) 100%), linear-gradient(7.28deg, #43C3D7 -40.25%, rgba(67, 195, 215, 0) 105.8%), #1974BB;',
  },
  active: {},
  track: {
    height: 3,
    background:
      'radial-gradient(123.09% 40.07% at -29.26% -2.31%, #22A9E0 0%, rgba(34, 169, 224, 0) 100%), linear-gradient(7.28deg, #43C3D7 -40.25%, rgba(67, 195, 215, 0) 105.8%), #1974BB',
  },
  rail: {
    color: '#EAEDF5',
    opacity: 0.5,
    height: 3,
  },
})(Slider);

const ThumbComponent = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return <div {...props}>{props['aria-valuenow']}</div>;
};

const SettingSlider = ({
  max,
  min,
  step,
  className,
  onChange,
  value,
  name,
}: SettingSliderType) => {
  const sliderRef = useRef<HTMLSpanElement>();

  const changeHandler = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    // eslint-disable-next-line no-shadow
    value: number | number[],
  ) => {
    onChange(value);
  };

  return (
    <div className={cn(style.container, className)}>
      {name && <div className={style.name}>Opacity</div>}
      <CustomSlider
        ThumbComponent={ThumbComponent}
        ref={sliderRef as any}
        onChange={changeHandler}
        min={min}
        step={step}
        max={max}
        value={value}
      />
    </div>
  );
};

export default SettingSlider;
