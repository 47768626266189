import { useDispatch } from 'react-redux';

import Button from '../../components/Button/Button';
import { setSelectedModal } from '../../store/modal/modal.action';
import { setQuestionAnswer } from '../../store/question/question.action';
import style from './Question.module.css';
import { QuestionType } from './Question.type';

const Question = ({ text, acceptHandler }: QuestionType) => {
  const dispatch = useDispatch();

  const handler = (answer: boolean) => {
    dispatch(setQuestionAnswer(answer));
    dispatch(setSelectedModal(null));
    if (answer && acceptHandler) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      acceptHandler();
    }
  };

  return (
    <div className={style.container}>
      <div className={style.text}>{text}</div>
      <div className={style.buttons}>
        <Button key="ok" handler={() => handler(true)} name="OK" />
        <Button
          key="cancel"
          handler={() => handler(false)}
          name="Cancel"
          hasBackground={false}
        />
      </div>
    </div>
  );
};

export default Question;
