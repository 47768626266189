import { useEffect } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import BreadcrumbComponent from '../../components/Breadcrumb/Breadcrumb';
import FolderItem from '../../components/Folder/FolderItem/FolderItem';
import Loader from '../../components/Loader/Loader';
import { Folder } from '../../models';
import { RootState } from '../../store';
import {
  getFolderListRequest,
  setSelectedFolder,
  setEditFolder,
  setFolderSortType,
  deleteFolderRequest,
} from '../../store/folder/folder.action';
import { setSelectedModal } from '../../store/modal/modal.action';
import { MODAL_FOLDER } from '../../utils';
import style from './FolderList.module.css';

const FolderList = () => {
  const {
    folders,
    selectedFolder,
    sortType,
    loading,
    breadcrumbs,
  } = useSelector((state: RootState) => state.folder);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getFolderListRequest((selectedFolder && selectedFolder.id) || null),
    );
  }, [dispatch, selectedFolder]);

  const onShow = (folder: Folder) => {
    dispatch(setSelectedFolder(folder));
  };

  const onDelete = (id: number) => {
    dispatch(deleteFolderRequest(id));
  };

  const onEdit = (folder: Folder) => {
    dispatch(setEditFolder(folder));
    dispatch(setSelectedModal(MODAL_FOLDER));
  };

  const onSort = () => {
    dispatch(setFolderSortType(sortType === 'ASC' ? 'DESC' : 'ASC'));
  };

  const renderFolderItem = (folder: Folder, i: number) => {
    return (
      <FolderItem
        key={`folder-item-${i}`}
        folder={folder}
        onEdit={onEdit}
        onShow={onShow}
        onDelete={onDelete}
      />
    );
  };

  return (
    <div className={style.folders_panel}>
      <div className={style.folders_panel__header}>
        <div className={style.folders_panel__title}>
          <span className={style.folders_panel__ico}>
            <svg role="img">
              <use xlinkHref={`${spriteImg}#folder`} />
            </svg>
          </span>
          Folders
        </div>
        <div
          className={cn(style.select, { [style.desc]: sortType === 'DESC' })}
          onClick={onSort}
        >
          {sortType === 'ASC' ? 'A - z' : 'Z - a'}
        </div>
      </div>
      <div className={style.desctop_breadcrumb}>
        <BreadcrumbComponent options={breadcrumbs} type="folder" />
      </div>
      {folders.length ? (
        <div className={style.folders_panel__body}>
          {folders.map(renderFolderItem)}
        </div>
      ) : null}

      {loading && <Loader />}
    </div>
  );
};

export default FolderList;
