import { VideoId, FullVideo } from '../models';
import { UploadVideoImage } from '../store/video/video.type';
import service from '../utils/httpClient';

interface IMeta {
  limit: number;
  currentPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  nextPage: number;
  prevPage: number;
  totalPages: number;
  totalItems: number;
}

type ResponseOkType<T> = {
  meta?: IMeta;
} & T;

export const uploadVideoImage = async ({
  videoId,
  image,
  type,
  watermarkInfo,
}: UploadVideoImage): Promise<ResponseOkType<FullVideo>> => {
  const data = new FormData();

  data.append('image', image, `${videoId}.jpg`);

  if (type === 'watermark' && watermarkInfo) {
    data.append('type', type);
    data.append('positionX', watermarkInfo.position.x.toString());
    data.append('positionY', watermarkInfo.position.y.toString());
    data.append('width', watermarkInfo.size.width.toString());
    data.append('height', watermarkInfo.size.height.toString());
    data.append('opacity', watermarkInfo.opacity.toString());
  }
  const { data: responseData } = await service({
    method: 'POST',
    url: `/api/videos/${videoId}/upload/image`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return responseData as ResponseOkType<FullVideo>;
};

export const createVideoImage = async ({
  time,
  videoId,
}: VideoId & {
  time: number;
}): Promise<ResponseOkType<FullVideo>> => {
  const { data: responseData } = await service({
    method: 'POST',
    url: `/api/videos/${videoId}/create/image`,
    data: { time },
  });

  return responseData as ResponseOkType<FullVideo>;
};
