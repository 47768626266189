import {
  PositionInfo,
  GIFInfo,
  TextInfo,
} from '../../containers/AddTextToGIF/AddTextToGIF.type';
import { Gif, VideoId } from '../../models';
import { MetaType, PageType, Query } from '../../utils';

export type CreateGif = VideoId &
  GIFInfo & {
    textInfo: TextInfo & {
      position: PositionInfo;
    };
    redirectToUrl?: string;
  };

export interface IGifState {
  gifs: Gif[];
  loading: boolean;
  embed: string | null;
  pagination: MetaType;
  page: number;
  limit: number;
}

export const SET_GIF_PAGE = '@video/SET_GIF_PAGE';

export const GET_GIF_LIST_REQUEST = '@gif/GET_GIF_LIST_REQUEST';
export const GET_GIF_LIST_SUCCESS = '@gif/GET_GIF_LIST_SUCCESS';
export const GET_GIF_LIST_FAILURE = '@gif/GET_GIF_LIST_FAILURE';

export const CREATE_GIF_REQUEST = '@gif/CREATE_GIF_REQUEST';
export const CREATE_GIF_SUCCESS = '@gif/CREATE_GIF_SUCCESS';
export const CREATE_GIF_FAILURE = '@gif/CREATE_GIF_FAILURE';

export const DELETE_GIF_REQUEST = '@gif/DELETE_GIF_REQUEST';
export const DELETE_GIF_SUCCESS = '@gif/DELETE_GIF_SUCCESS';
export const DELETE_GIF_FAILURE = '@gif/DELETE_GIF_FAILURE';

export const CLEAR_EMBED = '@gif/CLEAR_EMBED';

interface ISetGifPage {
  type: typeof SET_GIF_PAGE;
  payload: {
    pageType: PageType;
  };
}

export interface IGetGifListRequest {
  type: typeof GET_GIF_LIST_REQUEST;
  payload: Query;
}

interface IGetGifListSuccess {
  type: typeof GET_GIF_LIST_SUCCESS;
  payload: {
    gifs: Gif[];
    pagination: MetaType;
  };
}

interface IGetGifListFailure {
  type: typeof GET_GIF_LIST_FAILURE;
}

export interface ICreateGifRequest {
  type: typeof CREATE_GIF_REQUEST;
  payload: CreateGif & Query;
}

interface ICreateGifSuccess {
  type: typeof CREATE_GIF_SUCCESS;
  payload: { embed: string };
}

interface ICreateGifFailure {
  type: typeof CREATE_GIF_FAILURE;
}

export interface IDeleteGifRequest {
  type: typeof DELETE_GIF_REQUEST;
  payload: Pick<Gif, 'id'> & Query;
}

interface IDeleteGifSuccess {
  type: typeof DELETE_GIF_SUCCESS;
}

interface IDeleteGifFailure {
  type: typeof DELETE_GIF_FAILURE;
}

interface IClearEmbed {
  type: typeof CLEAR_EMBED;
}

export type GifActionsTypes =
  | ISetGifPage
  | IGetGifListRequest
  | IGetGifListSuccess
  | IGetGifListFailure
  | ICreateGifRequest
  | ICreateGifSuccess
  | ICreateGifFailure
  | IDeleteGifRequest
  | IDeleteGifSuccess
  | IDeleteGifFailure
  | IClearEmbed;
