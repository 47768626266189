import { LIMIT_DEFAULT, PAGE_DEFAULT } from '../../utils';
import {
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  UPDATE_USER_BY_ID_REQUEST,
  UPDATE_USER_BY_ID_SUCCESS,
  UPDATE_USER_BY_ID_FAILURE,
  DELETE_USER_BY_ID_REQUEST,
  DELETE_USER_BY_ID_SUCCESS,
  DELETE_USER_BY_ID_FAILURE,
  BULK_USERS_REQUEST,
  BULK_USERS_SUCCESS,
  BULK_USERS_FAILURE,
  SET_ONLY_CANCELED,
  SET_SELECT_USER,
  SET_SELECT_ALL_USERS,
  SET_ONLY_BANED,
  SET_SORT_COLUMN,
  SET_PAGE,
  CHANGE_CHECK_BOX_VALUE,
  SET_VIDEO_GENERATOR_CREDITS,
  IUsers,
  UsersActions,
} from './users.type';

const initialState: IUsers = {
  items: [],
  meta: {
    limit: LIMIT_DEFAULT,
    currentPage: PAGE_DEFAULT,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: 1,
    prevPage: 1,
    totalPages: 1,
    totalItems: 0,
  },
  user: null,
  selectedUsersId: [],
  page: PAGE_DEFAULT,
  itemsPerPage: LIMIT_DEFAULT,
  onlyCanceled: false,
  onlyBaned: false,
  sortType: 'ASC',
  sortColumn: 'created_at',
  loading: false,
};

export default function usersReducer(
  state = initialState,
  action: UsersActions,
): IUsers {
  switch (action.type) {
    case GET_USERS_LIST_REQUEST:
    case GET_USER_BY_ID_REQUEST:
    case UPDATE_USER_BY_ID_REQUEST:
    case DELETE_USER_BY_ID_REQUEST:
    case BULK_USERS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_USERS_LIST_SUCCESS: {
      const { usersList } = action.payload;
      return {
        ...state,
        ...usersList,
        loading: false,
      };
    }
    case GET_USER_BY_ID_SUCCESS: {
      const { userProfile } = action.payload;
      return { ...state, user: userProfile, loading: false };
    }
    case SET_SELECT_USER: {
      const newSelectedUser = state.selectedUsersId.filter(
        (item) => item !== action.payload.id,
      );
      return {
        ...state,
        selectedUsersId:
          state.selectedUsersId.length === newSelectedUser.length
            ? [...state.selectedUsersId, action.payload.id]
            : newSelectedUser,
      };
    }

    case SET_SELECT_ALL_USERS: {
      return {
        ...state,
        selectedUsersId: action.payload.selectedAllUsers
          ? state.items.map((item) => item.id)
          : [],
      };
    }
    case SET_ONLY_BANED: {
      const { value } = action.payload;
      return {
        ...state,
        onlyBaned: value,
        onlyCanceled: false,
      };
    }
    case SET_ONLY_CANCELED: {
      const { value } = action.payload;
      return {
        ...state,
        onlyBaned: false,
        onlyCanceled: value,
      };
    }
    case SET_SORT_COLUMN: {
      const { sortColumn, sortType } = action.payload;
      return {
        ...state,
        sortColumn,
        sortType,
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        itemsPerPage: action.payload.limit,
        page: action.payload.currentPage,
      };
    }
    case CHANGE_CHECK_BOX_VALUE: {
      const { featureName } = action.payload;
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            feature: {
              ...state.user?.feature,
              [featureName]: !state.user?.feature[featureName],
            },
          },
        };
      }
      return state;
    }
    case SET_VIDEO_GENERATOR_CREDITS: {
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            videoGeneratorCredits: action.payload.value,
          },
        };
      }
      return state;
    }
    case GET_USERS_LIST_FAILURE:
    case GET_USER_BY_ID_FAILURE:
    case BULK_USERS_SUCCESS:
    case BULK_USERS_FAILURE:
    case UPDATE_USER_BY_ID_SUCCESS:
    case UPDATE_USER_BY_ID_FAILURE:
    case DELETE_USER_BY_ID_SUCCESS:
    case DELETE_USER_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
