/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState, useRef, useEffect } from 'react';

import copy from 'copy-to-clipboard';
import Draggable, {
  DraggableData,
  DraggableEvent,
  DraggableBounds,
} from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';
import TextEditor from '../../components/TextEditor/TextEditor';
import { RootState } from '../../store';
import { alertSuccess } from '../../store/alert/alert.action';
import { clearEmbed, createGifRequest } from '../../store/gif/gif.action';
import style from './AddTextToGIF.module.css';
import {
  AddTextToGIFType,
  TextInfo,
  PositionInfo,
  RelativeCoefficient,
} from './AddTextToGIF.type';

const AddTextToGIF = (props: AddTextToGIFType) => {
  const { endTime, fps, size, startTime, videoId } = props;
  const { embed, loading, limit, page } = useSelector(
    (state: RootState) => state.gif,
  );
  const { video } = useSelector((state: RootState) => state.video);

  const dispatch = useDispatch();

  const source = video?.videoSource || '';

  const editAreaRef = useRef<HTMLVideoElement>();
  const textRef = useRef<HTMLDivElement>();

  const [redirectToUrl, setRedirectToUrl] = useState<string>('');
  const [redirectToUrlValid, setRedirectToUrlValid] = useState<boolean>(false);

  const [bounds, setBounds] = useState<DraggableBounds>();
  const [position, setPosition] = useState<PositionInfo>({ x: 0, y: 0 });

  const [textInfo, setTextInfo] = useState<TextInfo>({
    color: '#000000',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 12,
    text: '',
    position,
  });

  const [
    relativeCoefficient,
    setRelativeCoefficient,
  ] = useState<RelativeCoefficient>({
    kx: 1,
    ky: 1,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(clearEmbed());
  }, [dispatch]);

  const fonts: any = {
    DancingScript: {
      link:
        'https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap',
      name: 'Dancing Script',
    },
    Roboto: {
      link:
        'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
      name: 'Roboto',
    },
  };

  const resizeEditAreaHandler = () => {
    if (!editAreaRef.current) {
      return;
    }

    const [outputSizeWidth, outputSizeHeight] = size
      .split(':')
      .map((s: any) => Number(s));

    const kx =
      outputSizeWidth === -1
        ? editAreaRef.current.clientHeight / outputSizeHeight
        : editAreaRef.current.clientWidth / outputSizeWidth;

    const ky =
      outputSizeHeight === -1
        ? editAreaRef.current.clientWidth / outputSizeWidth
        : editAreaRef.current.clientHeight / outputSizeHeight;

    setRelativeCoefficient({ kx, ky });
  };

  useEffect(() => {
    window.addEventListener('resize', resizeEditAreaHandler);

    return () => window.removeEventListener('resize', resizeEditAreaHandler);
  });

  const createGif = () => {
    const query = `?limit=${limit}&page=${page}&video-id=${videoId}`;

    dispatch(
      createGifRequest({
        endTime,
        fps,
        size,
        startTime,
        textInfo: {
          ...textInfo,
          position: {
            x: Math.round(position.x / relativeCoefficient.kx),
            y: Math.round(position.y / relativeCoefficient.ky),
          },
        },
        videoId,
        ...(redirectToUrl.trim() && { redirectToUrl }),
        query,
      }),
    );
  };

  const calculatePosition = (a: number | undefined, b: number | undefined) => {
    return a && b ? a - b + 2 : 0;
  };

  const dragStartHandler = () => {
    setBounds({
      left: -2,
      top: -2,
      right: calculatePosition(
        editAreaRef.current?.clientWidth,
        textRef.current?.clientWidth,
      ),
      bottom: calculatePosition(
        editAreaRef.current?.clientHeight,
        textRef.current?.clientHeight,
      ),
    });
  };

  const dragPointHandler = (e: DraggableEvent, data: DraggableData) => {
    setPosition({ x: data.x >= 0 ? data.x : 0, y: data.y >= 0 ? data.y : 0 });
  };

  const validateField = (fieldName: string, value: string): void => {
    switch (fieldName) {
      case 'redirectLick': {
        const re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const result = re.exec(value);
        setRedirectToUrl(value);
        setRedirectToUrlValid(!!result);
        break;
      }

      default:
        break;
    }
  };

  const copyToClipboard = () => {
    if (!embed) {
      return;
    }
    copy(embed.toString(), {
      format: 'text/html',
    });
    dispatch(alertSuccess('Copied!'));
  };

  return (
    <div className={style.container}>
      <link href={fonts[textInfo.fontFamily].link} rel="stylesheet" />
      <div className={style.video_block}>
        <video
          ref={editAreaRef as any}
          className={style.video}
          src={source}
          onDurationChange={resizeEditAreaHandler}
        />
        <Draggable
          bounds={bounds}
          onStart={dragStartHandler}
          onStop={dragPointHandler}
          nodeRef={textRef as any}
        >
          <div
            ref={textRef as any}
            style={{
              color: textInfo.color,
              fontFamily: fonts[textInfo.fontFamily].name,
              fontWeight: textInfo.fontWeight,
              fontSize: `${Math.round(
                textInfo.fontSize * relativeCoefficient.ky,
              )}px`,
            }}
            className={style.text}
          >
            {textInfo.text}
          </div>
        </Draggable>
      </div>
      <TextEditor className={style.editor} setValue={setTextInfo} />
      <Input
        name="Redirect to url"
        value={redirectToUrl}
        className={style.redirect_url}
        isValid={redirectToUrlValid}
        validationTextPosition="top"
        validateText='Must match format "http:// or https://".'
        setValue={(value: string) => validateField('redirectLick', value)}
      />
      <div className={style.code_block}>
        <Button
          name="Save and genegate code"
          handler={createGif}
          className={style.save_and_generate_code}
        />
        {embed && (
          <div className={style.embed_text}>
            <div>{embed}</div>
          </div>
        )}
      </div>
      {embed && (
        <Button
          handler={copyToClipboard}
          className={style.copy_btn}
          name="Copy embed code"
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default AddTextToGIF;
