/* eslint-disable @typescript-eslint/no-unsafe-return */
import { TokenType } from '../../utils';

export default (() => {
  let service: any;

  const getService = () => {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  };

  const setToken = (body: TokenType): void => {
    localStorage.setItem('accessToken', body.accessToken);
    localStorage.setItem('refreshToken', body.refreshToken);
  };

  const getAccessToken = (): string | null => {
    try {
      return localStorage.getItem('accessToken');
    } catch {
      return null;
    }
  };

  const getRefreshToken = (): string | null => {
    try {
      return localStorage.getItem('refreshToken');
    } catch {
      return null;
    }
  };

  const clearToken = (): void => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  return {
    getService,
    setToken,
    getAccessToken,
    getRefreshToken,
    clearToken,
  };
})();
