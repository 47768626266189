import {
  IPlayerConfigState,
  PlayerConfigActionsTypes,
  UPDATE_PLAYER_CONFIG_FAILURE,
  UPDATE_PLAYER_CONFIG_REQUEST,
  UPDATE_PLAYER_CONFIG_SUCCESS,
  UPLOAD_PLAYER_CONFIG_LOGO_FAILURE,
  UPLOAD_PLAYER_CONFIG_LOGO_REQUEST,
  UPLOAD_PLAYER_CONFIG_LOGO_SUCCESS,
} from './playerConfig.type';

const initialState: IPlayerConfigState = {
  loading: false,
};

export default function playerConfigReducer(
  state = initialState,
  action: PlayerConfigActionsTypes,
): IPlayerConfigState {
  switch (action.type) {
    case UPDATE_PLAYER_CONFIG_REQUEST:
    case UPLOAD_PLAYER_CONFIG_LOGO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PLAYER_CONFIG_SUCCESS:
    case UPDATE_PLAYER_CONFIG_FAILURE:
    case UPLOAD_PLAYER_CONFIG_LOGO_FAILURE:
    case UPLOAD_PLAYER_CONFIG_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
