import { IPayment, PaymentPlanItem } from '../../models/Payment';

export interface IPaymentState {
  loading: boolean;
  plans: PaymentPlanItem[];
}

export const PAYMENT_REQUEST = '@payment/PAYMENT_REQUEST';
export const PAYMENT_SUCCESS = '@payment/PAYMENT_SUCCESS';
export const PAYMENT_FAILURE = '@payment/PAYMENT_FAILURE';

export const GET_PAYMENT_PLAN_REQUEST = '@payment/GET_PAYMENT_PLAN_REQUEST';
export const GET_PAYMENT_PLAN_SUCCESS = '@payment/GET_PAYMENT_PLAN_SUCCESS';
export const GET_PAYMENT_PLAN_FAILURE = '@payment/GET_PAYMENT_PLAN_FAILURE';

export interface IPaymentRequest {
  type: typeof PAYMENT_REQUEST;
  payload: IPayment;
}

interface IPaymentSuccess {
  type: typeof PAYMENT_SUCCESS;
}

interface IPaymentFailure {
  type: typeof PAYMENT_FAILURE;
}

export interface IGetPaymentPlanRequest {
  type: typeof GET_PAYMENT_PLAN_REQUEST;
}

interface IGetPaymentPlanSuccess {
  type: typeof GET_PAYMENT_PLAN_SUCCESS;
  payload: {
    plans: PaymentPlanItem[];
  };
}

interface IGetPaymentPlanFailure {
  type: typeof GET_PAYMENT_PLAN_FAILURE;
}

export type PaymentActionsTypes =
  | IPaymentRequest
  | IPaymentSuccess
  | IPaymentFailure
  | IGetPaymentPlanRequest
  | IGetPaymentPlanSuccess
  | IGetPaymentPlanFailure;
