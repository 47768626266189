import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ChangeTariff from '../../components/ChangeTariff/ChangeTariff';
import CreateUser from '../../components/CreateUser/CreateUser';
import ModalFolder from '../../components/Folder/ModalFolder/ModalFolder';
import { PaymentMethodModal } from '../../components/PaymentMethod';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import PopUpWrapper from '../../layout/PopUpWrapper/PopUpWrapper';
import { RootState } from '../../store';
import { setEditFolder } from '../../store/folder/folder.action';
import { setSelectedModal } from '../../store/modal/modal.action';
import {
  MODAL_IMPORT_VIDEO,
  MODAL_UPLOAD_VIDEO,
  MODAL_FOLDER,
  MODAL_ADD_WATERMARK,
  MODAL_SET_VIDEO_TUMBNAIL,
  MODAL_TRIMMER,
  MODAL_DELETE_VIDEO,
  MODAL_CHANGE_PLAN,
  MODAL_CREATE_GIF,
  MODAL_ADD_TEXT_TO_GIF,
  MODAL_RENAME_VIDEO,
  MODAL_PREVIEW_VIDEO,
  MODAL_SELECT_VIDEO_FOLDER,
  MODAL_SET_BAN,
  MODAL_CREATE_USER,
  MODAL_ENTER_CARD,
  MODAL_PAYMENT_DETAIL,
} from '../../utils';
import AddTextToGIF from '../AddTextToGIF/AddTextToGIF';
import AddWatermark from '../AddWatermark/AddWatermark';
import CreateGIF from '../CreateGIF/CreateGIF';
import ImportVideo from '../ImportVideo/ImportVideo';
import Question from '../Question/Question';
import Rename from '../Rename/Rename';
import SelectFolder from '../SelectFolder/SelectFolder';
import SetBan from '../SetBan/SetBan';
import SetPaymentDetail from '../SetPaymentDetail/SetPaymentDetail';
import SetVideoImage from '../SetVideoImage/SetVideoImage';
import UploadVideo from '../UploadVideo/UploadVideo';
import VideoTrimmer from '../VideoTrimmer/VideoTrimmer';

const InitModal = () => {
  const { selectedModalType, id = '', title, data, mode } = useSelector(
    (state: RootState) => state.modal,
  );
  const dispatch = useDispatch();

  const closeHandler = useCallback(() => {
    dispatch(setSelectedModal(null));
    dispatch(setEditFolder(null));
  }, [dispatch]);

  const renderModal = useMemo(() => {
    switch (selectedModalType) {
      case MODAL_IMPORT_VIDEO:
        return <ImportVideo />;
      case MODAL_UPLOAD_VIDEO:
        return <UploadVideo />;
      case MODAL_CHANGE_PLAN:
        return <ChangeTariff />;
      case MODAL_FOLDER:
        return <ModalFolder />;
      case MODAL_ADD_WATERMARK:
        return <AddWatermark videoId={id} />;
      case MODAL_SET_VIDEO_TUMBNAIL:
        return <SetVideoImage videoId={id} saveHandler={closeHandler} />;
      case MODAL_TRIMMER:
        return <VideoTrimmer videoId={id} />;
      case MODAL_DELETE_VIDEO:
        return (
          <Question
            {...data}
            text="Are you sure you want to permanently delete this video? This cannot be undone."
          />
        );
      case MODAL_CREATE_GIF:
        return <CreateGIF videoId={id} />;
      case MODAL_ADD_TEXT_TO_GIF:
        return <AddTextToGIF videoId={id} {...data} />;
      case MODAL_RENAME_VIDEO:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return <Rename videoId={id} name={data.name} query={data.query} />;
      case MODAL_PREVIEW_VIDEO:
        return (
          <VideoPlayer
            key={id}
            format="mp4"
            videoId={id}
            hasFullScreen={true}
            hasMiniature={true}
            isModal={true}
          />
        );
      case MODAL_SELECT_VIDEO_FOLDER:
        return <SelectFolder query={data} />;
      case MODAL_SET_BAN:
        return <SetBan {...data} closeHandler={closeHandler} />;
      case MODAL_CREATE_USER:
        return <CreateUser submitBtnName="Create" {...data} />;
      case MODAL_ENTER_CARD:
        return <PaymentMethodModal {...data} />;
      case MODAL_PAYMENT_DETAIL:
        return <SetPaymentDetail cancelHandler={closeHandler} {...data} />;
      default:
        return <div />;
    }
  }, [closeHandler, data, id, selectedModalType]);

  return (
    selectedModalType && (
      <PopUpWrapper title={title} closeHandler={closeHandler} mode={mode}>
        {renderModal}
      </PopUpWrapper>
    )
  );
};

export default InitModal;
