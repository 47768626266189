import copy from 'copy-to-clipboard';
import { put, takeLatest, fork, call, ForkEffect } from 'redux-saga/effects';

import { uploadVideoImage } from '../../services/api.service';
import {
  getAllVideo,
  deleteVideoById,
  editVideo,
  getEmbedVideo,
  getVideoInfoById,
  sendVideo,
  bulkVideos,
} from '../../services/video.service';
import { ALERT_ERROR } from '../alert/alert.type';
import { setSelectedModal } from '../modal/modal.action';
import { getVideoListRequest } from './video.action';
import {
  IGetVideoListRequest,
  GET_VIDEO_LIST_REQUEST,
  GET_VIDEO_LIST_SUCCESS,
  GET_VIDEO_LIST_FAILURE,
  GET_EMBED_VIDEO_FAILURE,
  GET_EMBED_VIDEO_REQUEST,
  GET_EMBED_VIDEO_SUCCESS,
  GET_VIDEO_FAILURE,
  GET_VIDEO_REQUEST,
  GET_VIDEO_SUCCESS,
  IGetEmbedVideoRequest,
  IGetVideoRequest,
  IUpdateVideoRequest,
  IUploadVideoRequest,
  UPDATE_VIDEO_FAILURE,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  UPLOAD_VIDEO_REQUEST,
  UPLOAD_VIDEO_SUCCESS,
  VideoActionsTypes,
  DELETE_VIDEO_FAILURE,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  IDeleteVideoRequest,
  BULK_VIDEOS_FAILURE,
  BULK_VIDEOS_REQUEST,
  IBulkVideosRequest,
  BULK_VIDEOS_SUCCESS,
  SET_SELECT_ALL_VIDEOS,
  IUploadVideoImageRequest,
  UPLOAD_VIDEO_IMAGE_REQUEST,
  UPLOAD_VIDEO_IMAGE_SUCCESS,
  UPLOAD_VIDEO_IMAGE_FAILURE,
} from './video.type';

function* workerGetAllVideo(action: VideoActionsTypes) {
  try {
    const { payload } = action as IGetVideoListRequest;
    const { items, meta } = yield call(getAllVideo, payload.query);

    yield put({
      type: GET_VIDEO_LIST_SUCCESS,
      payload: { videos: items, pagination: meta },
    });
  } catch (error) {
    yield put({ type: GET_VIDEO_LIST_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* watchGetAllVideo() {
  yield takeLatest(GET_VIDEO_LIST_REQUEST, workerGetAllVideo);
}

function* workerGetVideo(action: VideoActionsTypes) {
  try {
    const { payload } = action as IGetVideoRequest;
    const { ...item } = yield call(getVideoInfoById, payload);

    yield put({
      type: GET_VIDEO_SUCCESS,
      payload: item,
    });
  } catch (error) {
    yield put({ type: GET_VIDEO_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { error } });
  }
}

function* watchGetVideo() {
  yield takeLatest(GET_VIDEO_REQUEST, workerGetVideo);
}

function* workerGetEmbedVideo(action: VideoActionsTypes) {
  try {
    const { payload } = action as IGetEmbedVideoRequest;
    const { embed } = yield call(getEmbedVideo, payload);

    copy(embed);

    yield put({
      type: GET_EMBED_VIDEO_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: GET_EMBED_VIDEO_FAILURE,
    });
    yield put({
      type: ALERT_ERROR,
      payload: { error },
    });
  }
}

function* watchGetEmbedVideo() {
  yield takeLatest(GET_EMBED_VIDEO_REQUEST, workerGetEmbedVideo);
}

function* workerUpdateVideo(action: VideoActionsTypes) {
  try {
    const {
      payload: { query, ...payload },
    } = action as IUpdateVideoRequest;
    const { ...video } = yield call(editVideo, payload);
    yield put({
      type: UPDATE_VIDEO_SUCCESS,
      payload: video,
    });

    if (query) {
      yield put(getVideoListRequest(query));
    }
  } catch (error) {
    yield put({ type: UPDATE_VIDEO_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { error } });
  }
}

function* watchUpdateVideo() {
  yield takeLatest(UPDATE_VIDEO_REQUEST, workerUpdateVideo);
}

function* workerUploadVideo(action: VideoActionsTypes) {
  try {
    const { payload } = action as IUploadVideoRequest;
    yield call(sendVideo, payload);

    yield put({
      type: UPLOAD_VIDEO_SUCCESS,
    });

    if (payload.query !== undefined) {
      yield put({
        type: GET_VIDEO_LIST_REQUEST,
        payload: {
          query: payload.query,
        },
      });
    }
  } catch (error) {
    yield put({ type: UPLOAD_VIDEO_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { error } });
  }
}

function* watchUploadVideo() {
  yield takeLatest(UPLOAD_VIDEO_REQUEST, workerUploadVideo);
}

function* workerDeleteVideo(action: VideoActionsTypes) {
  try {
    const { payload } = action as IDeleteVideoRequest;
    yield call(deleteVideoById, payload.videoId);

    yield put({
      type: DELETE_VIDEO_SUCCESS,
    });

    if (payload.query) {
      yield put({
        type: GET_VIDEO_LIST_REQUEST,
        payload: {
          query: payload.query,
        },
      });
    }
  } catch (error) {
    yield put({ type: DELETE_VIDEO_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { error } });
  }
}

function* watchDeleteVideo() {
  yield takeLatest(DELETE_VIDEO_REQUEST, workerDeleteVideo);
}

function* workerBulkVideos(action: VideoActionsTypes) {
  try {
    const { payload } = action as IBulkVideosRequest;

    const { query, ...data } = payload;

    yield call(bulkVideos, data);

    yield put({
      type: BULK_VIDEOS_SUCCESS,
    });
    yield put({
      type: SET_SELECT_ALL_VIDEOS,
      payload: { selectAllVideos: false },
    });

    yield put({
      type: GET_VIDEO_LIST_REQUEST,
      payload: { query },
    });
    yield put(setSelectedModal(null));
  } catch (error) {
    yield put({
      type: BULK_VIDEOS_FAILURE,
    });
    yield put({ type: ALERT_ERROR, payload: { error } });
  }
}

function* watchBulkVideos() {
  yield takeLatest(BULK_VIDEOS_REQUEST, workerBulkVideos);
}

function* workerUploadVideoImage(action: VideoActionsTypes) {
  try {
    const { payload } = action as IUploadVideoImageRequest;

    yield call(uploadVideoImage, payload);

    yield put({
      type: UPLOAD_VIDEO_IMAGE_SUCCESS,
    });

    yield put(getVideoListRequest(''));
  } catch (error) {
    yield put({
      type: UPLOAD_VIDEO_IMAGE_FAILURE,
    });
    yield put({ type: ALERT_ERROR, payload: { error } });
  }
}

function* watchAddWatermark() {
  yield takeLatest(UPLOAD_VIDEO_IMAGE_REQUEST, workerUploadVideoImage);
}

export const videoWatchers: ForkEffect[] = [
  fork(watchGetAllVideo),
  fork(watchDeleteVideo),
  fork(watchGetVideo),
  fork(watchGetEmbedVideo),
  fork(watchUpdateVideo),
  fork(watchUploadVideo),
  fork(watchBulkVideos),
  fork(watchAddWatermark),
];
