import { PropsWithChildren } from 'react';

import styles from './Table.module.css';
import { TableProps, ObjectTypeExtends } from './Table.type';
import TableHeader from './TableHeader/TableHeader';
import TableRow from './TableRow/TableRow';

const Table = <ObjectType extends ObjectTypeExtends>({
  items,
  columns,
  sortType,
  sortColumn,
  setSortColumn,
  onSelectHandler,
  selectedItems,
  infoIcons = true,
  onSelectAll,
}: PropsWithChildren<TableProps<ObjectType>>) => {
  return (
    <table className={styles.table}>
      <TableHeader
        selectedCount={selectedItems.length}
        columns={columns}
        sortType={sortType}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        onSelectAll={onSelectAll}
      />
      <tbody className={styles.table_body}>
        {items.map((item) => (
          <TableRow
            key={`${item.id}${item.profile?.lastName}`}
            item={item}
            columns={columns}
            selectedItems={selectedItems}
            onSelectHandler={onSelectHandler}
            infoIcons={infoIcons}
          />
        ))}
      </tbody>
    </table>
  );
};
export default Table;
