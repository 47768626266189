import { EditPlayerConfig, PlayerConfig } from '../models/PlayerConfig';
import { UploadPlayerConfigLogo } from '../store/playerConfig/playerConfig.type';
import service from '../utils/httpClient';

export const updatePlayerConfig = async (
  editInfo: Omit<EditPlayerConfig, 'videoId'>,
): Promise<PlayerConfig> => {
  const { id, ...data } = editInfo;
  const { data: responseData } = await service({
    method: 'PUT',
    url: `/api/player-config/${id}`,
    data,
  });

  return responseData as PlayerConfig;
};

export const uploadPlayerConfigLogo = ({
  id,
  image,
}: Omit<UploadPlayerConfigLogo, 'videoId'>) => {
  const data = new FormData();

  data.append('image', image);
  return service({
    method: 'PUT',
    url: `/api/player-config/${id}/image`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
