import { IPayment, PaymentPlanItem } from '../models/Payment';
import { ResponseType } from '../utils';
import service from '../utils/httpClient';

const baseUrl = '/api/payment';

export const createPayment = async (data: IPayment) => {
  const response = await service({
    method: 'POST',
    url: baseUrl,
    data,
  });

  return response.data as { id: string };
};

export const getPaymentPlanList = async () => {
  const { data } = await service({
    method: 'GET',
    url: `${baseUrl}/plan`,
  });

  return data as ResponseType<PaymentPlanItem>;
};
