import { Login, Social } from '../models';
import { TokenType, RestorePasswordType } from '../utils';
import service from '../utils/httpClient';

export const login = async (data: Login): Promise<TokenType> => {
  const res = await service({
    method: 'POST',
    url: '/api/auth/login',
    data,
  });

  return res.data as TokenType;
};

export const social = async (data: Social): Promise<TokenType> => {
  const res = await service({
    method: 'POST',
    url: '/api/auth/platform',
    data,
  });

  return res.data as TokenType;
};

export const logout = async (): Promise<void> => {
  await service({
    method: 'POST',
    url: '/api/auth/logout',
  });
};

export const forgotPassword = async (email: string): Promise<void> => {
  await service({
    method: 'POST',
    url: '/api/auth/forgot-password',
    data: { email },
  });
};

export const resetPassword = async (
  data: RestorePasswordType,
): Promise<void> => {
  await service({
    method: 'POST',
    url: '/api/auth/reset-password',
    data,
  });
};

export const verifyEmail = async (token: string): Promise<TokenType> => {
  const { data } = await service({
    method: 'POST',
    url: '/api/auth/verify-email',
    data: {
      token,
    },
  });

  return data as TokenType;
};
