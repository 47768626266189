import { Login, Social } from '../../models';
import { TokenType, RestorePasswordType } from '../../utils';

export interface IAuthState {
  accessToken: string | null;
  resetPassword: boolean;
  loading: boolean;
  isAdmin: boolean;
  isPaymentMethodSet: boolean;
}

export const AUTH_LOGIN_REQUEST = '@auth/AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = '@auth/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = '@auth/AUTH_LOGIN_FAILURE';

export const AUTH_SOCIAL_REQUEST = '@auth/AUTH_SOCIAL_REQUEST';
export const AUTH_SOCIAL_SUCCESS = '@auth/AUTH_SOCIAL_SUCCESS';
export const AUTH_SOCIAL_FAILURE = '@auth/AUTH_SOCIAL_FAILURE';

export const AUTH_LOGOUT_REQUEST = '@auth/AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = '@auth/AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = '@auth/AUTH_LOGOUT_FAILURE';

export const AUTH_FORGOT_PASS_REQUEST = '@auth/AUTH_FORGOT_PASS_REQUEST';
export const AUTH_FORGOT_PASS_SUCCESS = '@auth/AUTH_FORGOT_PASS_SUCCESS';
export const AUTH_FORGOT_PASS_FAILURE = '@auth/AUTH_FORGOT_PASS_FAILURE';

export const AUTH_RESET_PASS_REQUEST = '@auth/AUTH_RESET_PASS_REQUEST';
export const AUTH_RESET_PASS_SUCCESS = '@auth/AUTH_RESET_PASS_SUCCESS';
export const AUTH_RESET_PASS_FAILURE = '@auth/AUTH_RESET_PASS_FAILURE';

export const AUTH_VERIFY_EMAIL_REQUEST = '@auth/AUTH_VERIFY_EMAIL_REQUEST';
export const AUTH_VERIFY_EMAIL_SUCCESS = '@auth/AUTH_VERIFY_EMAIL_SUCCESS';
export const AUTH_VERIFY_EMAIL_FAILURE = '@auth/AUTH_VERIFY_EMAIL_FAILURE';

export interface IAuthLoginRequest {
  type: typeof AUTH_LOGIN_REQUEST;
  payload: Login;
}

interface IAuthLoginSuccess {
  type: typeof AUTH_LOGIN_SUCCESS;
  payload: TokenType;
}

interface IAuthLoginFailure {
  type: typeof AUTH_LOGIN_FAILURE;
}
export interface IAuthSocialRequest {
  type: typeof AUTH_SOCIAL_REQUEST;
  payload: Social;
}

interface IAuthSocialSuccess {
  type: typeof AUTH_SOCIAL_SUCCESS;
  payload: TokenType;
}

interface IAuthSocialFailure {
  type: typeof AUTH_SOCIAL_FAILURE;
}

interface IAuthLogoutRequest {
  type: typeof AUTH_LOGOUT_REQUEST;
}

interface IAuthLogoutSuccess {
  type: typeof AUTH_LOGOUT_SUCCESS;
}

interface IAuthLogoutFailure {
  type: typeof AUTH_LOGOUT_FAILURE;
}

export interface IAuthForgotPassRequest {
  type: typeof AUTH_FORGOT_PASS_REQUEST;
  payload: {
    email: string;
  };
}

interface IAuthForgotPassSuccess {
  type: typeof AUTH_FORGOT_PASS_SUCCESS;
}

interface IAuthForgotPassFailure {
  type: typeof AUTH_FORGOT_PASS_FAILURE;
}

export interface IAuthResetPassRequest {
  type: typeof AUTH_RESET_PASS_REQUEST;
  payload: RestorePasswordType;
}

interface IAuthResetPassSuccess {
  type: typeof AUTH_RESET_PASS_SUCCESS;
}

interface IAuthResetPassFailure {
  type: typeof AUTH_RESET_PASS_FAILURE;
}

export interface IAuthVerifyEmailRequest {
  type: typeof AUTH_VERIFY_EMAIL_REQUEST;
  payload: { token: string };
}

interface IAuthVerifyEmailSuccess {
  type: typeof AUTH_VERIFY_EMAIL_SUCCESS;
  payload: TokenType;
}

interface IAuthVerifyEmailFailure {
  type: typeof AUTH_VERIFY_EMAIL_FAILURE;
}

export type AuthActionsTypes =
  | IAuthLoginRequest
  | IAuthLoginSuccess
  | IAuthLoginFailure
  | IAuthSocialRequest
  | IAuthSocialSuccess
  | IAuthSocialFailure
  | IAuthLogoutRequest
  | IAuthLogoutSuccess
  | IAuthLogoutFailure
  | IAuthForgotPassRequest
  | IAuthForgotPassSuccess
  | IAuthForgotPassFailure
  | IAuthResetPassRequest
  | IAuthResetPassSuccess
  | IAuthResetPassFailure
  | IAuthVerifyEmailRequest
  | IAuthVerifyEmailSuccess
  | IAuthVerifyEmailFailure;
