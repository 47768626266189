import PopUpWrapper from '../../layout/PopUpWrapper/PopUpWrapper';
import UploadPicture from '../UploadPicture/UploadPicture';
import { ModalFileProps } from './ModalFile.type';

const ModalFile = (props: ModalFileProps) => {
  const { closeHandler, handler } = props;

  return (
    <PopUpWrapper closeHandler={closeHandler}>
      <UploadPicture handler={handler} />
    </PopUpWrapper>
  );
};

export default ModalFile;
