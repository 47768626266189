import { useCallback } from 'react';

import cn from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import spriteImg from '../../assets/img/sprite.svg';
import ButtonComponent from '../../components/Button/Button';
import CheckBox from '../../components/CheckBox/CheckBox';
import Loader from '../../components/Loader/Loader';
import { Video, Features, FeatureKeys } from '../../models';
import { useAppDispatch } from '../../store';
import { setSelectedModal } from '../../store/modal/modal.action';
import {
  changeCheckBoxValue,
  saveChangesRequest,
} from '../../store/users/users.action';
import { IUsers } from '../../store/users/users.type';
import { MODAL_PREVIEW_VIDEO } from '../../utils';
import styles from './UserSettings.module.css';

const UserSettings = () => {
  const dispatch = useAppDispatch();

  const { user, loading }: IUsers = useSelector(
    ({ users }: { users: IUsers }) => users,
  );

  const saveChangesHandler = useCallback(
    (feature): void => {
      dispatch(saveChangesRequest(feature));
    },
    [dispatch],
  );

  if (!user) {
    return null;
  }

  const showPreviewVideo = (id: Video[keyof Video]) => {
    if (typeof id === 'number') {
      dispatch(
        setSelectedModal(MODAL_PREVIEW_VIDEO, {
          id,
          mode: 'withoutFrame',
        }),
      );
    }
  };

  const {
    id,
    email,
    createdAt,
    nextPaymentDate,
    cancellationDate,
    paymentMethod,
    paymentRef,
    feature,
    videoCount,
    platforms,
    startDate,
    lastUploadedVideo,
    usedInTheStorage,
    lastLoginDate,
    isConfirmedEmail,
    banReason,
  } = user;

  const changeCheckBoxHandler = (name: FeatureKeys): void => {
    if (name) {
      dispatch(changeCheckBoxValue(name));
    }
  };

  function FeatureList() {
    return (
      <ul className={styles.section_columns}>
        {Object.entries(Features).map((item) => {
          const [name, title] = item;
          const value = feature[name as FeatureKeys];
          if (value !== undefined) {
            return (
              <li className={styles.section_item} key={name}>
                <CheckBox
                  setValue={() => changeCheckBoxHandler(name as FeatureKeys)}
                  title={title}
                  value={value}
                />
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  }

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <header className={styles.section_header}>
          <span>Info</span>
        </header>
        <table className={styles.table}>
          <tbody>
            <tr className={cn(styles.table_row, styles.email)}>
              <td className={styles.table_title}>Email</td>
              <td className={styles.table_value}>
                {email}
                {isConfirmedEmail && (
                  <div className={styles.confirmed}>
                    <svg
                      role="img"
                      className={styles.confirmed_icon}
                      width="16"
                      height="16"
                    >
                      <use xlinkHref={`${spriteImg}#confirmed`} />
                    </svg>
                    <span className={styles.confirmed_text}>
                      email verified
                    </span>
                  </div>
                )}
              </td>
            </tr>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Login method</td>
              <td className={styles.table_value}>{platforms}</td>
            </tr>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Account Created</td>
              <td className={styles.table_value}>
                {createdAt && moment(createdAt).format('MM/DD/YYYY')}
              </td>
            </tr>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Last Login</td>
              <td className={styles.table_value}>
                {lastLoginDate && moment(lastLoginDate).format('MM/DD/YYYY')}
              </td>
            </tr>
            {banReason && (
              <tr className={styles.table_row}>
                <td className={styles.table_title}>Notes</td>
                <td className={styles.table_value}>{banReason}</td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <section className={styles.section}>
        <header className={styles.section_header}>
          <span>Payments</span>
        </header>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Start date</td>
              <td className={styles.table_value}>{startDate}</td>
            </tr>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Next payment date</td>
              <td className={styles.table_value}>
                {nextPaymentDate &&
                  moment(nextPaymentDate).format('MM/DD/YYYY')}
              </td>
            </tr>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Сancellation date</td>
              <td className={styles.table_value}>
                {cancellationDate &&
                  moment(cancellationDate).format('MM/DD/YYYY')}
              </td>
            </tr>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Payment method</td>
              <td className={styles.table_value}>{paymentMethod}</td>
            </tr>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Payment Ref</td>
              <td className={cn(styles.table_value, styles.long_text)}>
                <a href={paymentRef}>{paymentRef}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className={styles.section}>
        <header className={styles.section_header}>
          <span>Analytics</span>
          <Link to={`/admin/users/${id}/videos/${id}`}>DETAILED</Link>
        </header>
        <table className={styles.table}>
          <tbody>
            <tr
              className={cn([styles.table_row, styles.action])}
              onClick={() => showPreviewVideo(lastUploadedVideo?.id)}
            >
              <td className={styles.table_title}>Last uploaded video</td>
              <td className={styles.table_value}>{lastUploadedVideo?.name}</td>
            </tr>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Upload date</td>
              <td className={styles.table_value}>
                {lastUploadedVideo?.date &&
                  moment(lastUploadedVideo?.date).format('MM/DD/YYYY')}
              </td>
            </tr>
            <tr className={cn(styles.table_row, styles.link)}>
              <td className={styles.table_title}>
                <Link to={`/admin/users/${id}/videos/${id}`}>
                  Number of videos
                </Link>
              </td>
              <td className={styles.table_value}>
                <Link to={`/admin/users/${id}/videos/${id}`}>{videoCount}</Link>
              </td>
            </tr>
            <tr className={styles.table_row}>
              <td className={styles.table_title}>Used in the storage</td>
              <td className={styles.table_value}>{usedInTheStorage}</td>
            </tr>
          </tbody>
        </table>
      </section>
      {feature && (
        <section className={styles.section}>
          <header className={styles.section_header}>
            <span>Features</span>
          </header>
          <FeatureList />
          <div className={styles.button_container}>
            <ButtonComponent
              name="Save changes"
              handler={() => {
                saveChangesHandler(feature);
              }}
              className={styles.button}
            />
          </div>
        </section>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default UserSettings;
