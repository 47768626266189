import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import spriteImg from '../../assets/img/sprite.svg';
import CustomSlider from '../CustomSlider/CustomSlider';
import style from './SoundBar.module.css';
import { SoundBarType } from './SoundBar.type';

const SoundBar = ({
  className,
  max,
  min,
  onChange,
  value,
  step,
  onMuteChangeHandler,
  color,
  svgColor,
  isMute,
  setMute,
}: SoundBarType) => {
  const sliderRef = useRef<HTMLSpanElement>();

  const [Slider, setSlider] = useState<any>(CustomSlider(color));

  useEffect(() => {
    setSlider(CustomSlider(color));
  }, [color]);

  const getSoundLevel = (item: number) => {
    if (item <= 0.3) {
      return 0;
    }
    if (item <= 0.7) {
      return 1;
    }

    return 2;
  };

  const muteHandler = () => {
    const newMuteStatus = !isMute;
    setMute(newMuteStatus);
    // eslint-disable-next-line no-unused-expressions
    onMuteChangeHandler && onMuteChangeHandler(newMuteStatus);
  };

  return (
    <div className={cn(style.container, className)}>
      <div className={style.icon} onClick={muteHandler}>
        <svg role="img" fill={svgColor}>
          <use
            xlinkHref={`${spriteImg}#${
              isMute ? 'mute' : `sound-${getSoundLevel(value)}`
            }`}
          ></use>
        </svg>
      </div>
      <div className={style.player_sound_bar}>
        <Slider
          ref={sliderRef as any}
          onChange={onChange}
          min={min}
          step={step}
          max={max}
          value={value}
        />
      </div>
    </div>
  );
};

export default SoundBar;
