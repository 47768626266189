import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { updateProfileRequest } from '../../store/profile/profile.action';
import Button from '../Button/Button';
import Input from '../Input/Input';

const ChangePaasword = () => {
  const [password, setPassword] = useState('');
  const [isSave, setSave] = useState(false);
  const dispatch = useDispatch();

  const passwordHandler = () => {
    if (isSave && password) {
      dispatch(updateProfileRequest({ password }));
    }
    setSave(!isSave);
  };

  return (
    <>
      <div className="wrapper_input">
        <Input
          key="password"
          type="password"
          className="form_item"
          placeholder="Password"
          value={password}
          setValue={setPassword}
          isDisabled={!isSave}
        />
      </div>
      <Button
        className="submit_btn"
        name={!isSave ? 'Change password' : 'Save'}
        hasBackground={isSave}
        handler={passwordHandler}
      />
    </>
  );
};

export default ChangePaasword;
