import {
  BarChart as BarChartCore,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import { BarChartType } from './BarChart.type';

const BarChart = (props: BarChartType) => {
  const { data } = props;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChartCore
        data={data}
        margin={{
          top: 30,
        }}
      >
        <defs>
          <linearGradient
            id="columnColor"
            gradientUnits="userSpaceOnUse"
            x1="36.5004"
            y1="0"
            x2="36.5004"
            y2="237"
          >
            <stop offset="0" stopColor="#1F92CF" />
            <stop offset="1" stopColor="#36AACE" />
          </linearGradient>
        </defs>
        <XAxis fontSize={12} dataKey="name" />
        <Tooltip />
        <Bar radius={4} dataKey="count" fill="url(#columnColor)">
          <LabelList dataKey="count" position="top" />
        </Bar>
      </BarChartCore>
    </ResponsiveContainer>
  );
};

export default BarChart;
