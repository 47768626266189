import { dynamicSort, getBreadcrumbs } from '../../utils';
import {
  IFolderState,
  FolderActionsTypes,
  SET_FOLDER_SORT_TYPE,
  SET_SELECTED_FOLDER,
  SET_EDIT_FOLDER,
  GET_FOLDER_LIST_FAILURE,
  GET_FOLDER_LIST_REQUEST,
  GET_FOLDER_LIST_SUCCESS,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAILURE,
  UPDATE_FOLDER_REQUEST,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAILURE,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAILURE,
  INIT_FOLDER_STATE,
} from './folder.type';

const initialState: IFolderState = {
  folders: [],
  selectedFolder: null,
  breadcrumbs: [],
  editFolder: null,
  loading: false,
  sortName: 'name',
  sortType: 'ASC',
};

export default function folderReducer(
  state = initialState,
  action: FolderActionsTypes,
): IFolderState {
  const { sortType } = state;
  switch (action.type) {
    case SET_FOLDER_SORT_TYPE:
      return {
        ...state,
        sortType: action.payload.sortType,
        folders: state.folders.sort(
          dynamicSort(state.sortName, action.payload.sortType),
        ),
      };
    case SET_SELECTED_FOLDER:
      return {
        ...state,
        selectedFolder: action.payload.folder,
        breadcrumbs: getBreadcrumbs(state.breadcrumbs, action.payload.folder),
      };
    case SET_EDIT_FOLDER:
      return {
        ...state,
        editFolder: action.payload.folder,
      };
    case GET_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        folders: action.payload.folders.sort(
          dynamicSort(state.sortName, sortType),
        ),
      };
    case CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        folders: [...state.folders, action.payload.folder].sort(
          dynamicSort(state.sortName, sortType),
        ),
      };
    case UPDATE_FOLDER_SUCCESS: {
      const folders = state.folders.map((folder) => {
        return folder.id === action.payload.folder.id
          ? { ...folder, name: action.payload.folder.name }
          : folder;
      });
      return {
        ...state,
        loading: false,
        folders: folders.sort(dynamicSort(state.sortName, sortType)),
      };
    }
    case GET_FOLDER_LIST_REQUEST:
    case CREATE_FOLDER_REQUEST:
    case UPDATE_FOLDER_REQUEST:
    case DELETE_FOLDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FOLDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        folders: state.folders.filter(
          (folder) => folder.id !== action.payload.id,
        ),
      };
    }
    case GET_FOLDER_LIST_FAILURE:
    case CREATE_FOLDER_FAILURE:
    case UPDATE_FOLDER_FAILURE:
    case DELETE_FOLDER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case INIT_FOLDER_STATE:
      return {
        ...state,
        folders: [],
        selectedFolder: null,
        breadcrumbs: [],
        editFolder: null,
        loading: false,
        sortName: 'name',
        sortType: 'ASC',
      };
    default:
      return state;
  }
}
