import Registration from '../../containers/Registration/Registration';
import MainLayout from '../../layout/MainLayout/MainLayout';

const RegistrationPage = () => {
  return (
    <MainLayout>
      <Registration />
    </MainLayout>
  );
};

export default RegistrationPage;
