import { forwardRef } from 'react';

import cn from 'classnames';

import style from './Input.module.css';
import { InputType } from './Input.type';

const Input = forwardRef<HTMLInputElement, InputType>((props, ref) => {
  const {
    name,
    setValue,
    onBlur,
    value,
    className,
    type = 'text',
    placeholder,
    isDisabled = false,
    onChangeHandler,
    id,
    icon,
    onKeyPress,
    onIconClick,
    isValid = true,
    validateText,
    validationTextPosition = 'right',
    isValidUnfocus,
  } = props;

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeHandler) {
      onChangeHandler(event);
    }

    if (setValue) {
      const EO = event.target;

      setValue(EO.value);
    }
  };

  const onBlurHandler = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const onKeyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  const onIconClickHandler = () => {
    if (onIconClick) {
      onIconClick();
    }
  };

  const renderColor = () => {
    return (
      <div className={className}>
        <input
          ref={ref}
          type={type}
          name={id}
          value={value}
          id={id}
          onChange={changeHandler}
          onBlur={onBlurHandler}
        />
        <label htmlFor={id}>{name}</label>
      </div>
    );
  };

  const renderText = () => {
    return (
      <div className={cn(style.container, className)}>
        {name && <div className={style.name}>{name}</div>}
        {icon && (
          <svg
            role="img"
            className={style.input_icon}
            onClick={onIconClickHandler}
          >
            <use xlinkHref={icon} />
          </svg>
        )}
        <input
          ref={ref}
          className={cn(style.input, {
            [style.validate]: !isValid,
            [style.validate_without_focus]: isValidUnfocus && !isValid,
          })}
          type={type}
          value={value}
          id={id}
          onChange={changeHandler}
          placeholder={placeholder}
          disabled={isDisabled}
          onKeyPress={onKeyPressHandler}
        />
        {isValid || (
          <span
            className={cn(style.validate_text, {
              [style.validate_right]: validationTextPosition === 'right',
              [style.validate_top]: validationTextPosition === 'top',
              [style.validate_left]: validationTextPosition === 'left',
              [style.validate_bottom]: validationTextPosition === 'bottom',
            })}
          >
            {validateText}
          </span>
        )}
      </div>
    );
  };

  switch (type) {
    case 'color':
      return renderColor();
    default:
      return renderText();
  }
});

export default Input;
