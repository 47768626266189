/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect } from 'react';

import cn from 'classnames';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleLogin } from 'react-use-googlelogin';

import socialImg from '../../assets/img/social/social.svg';
import { RootState } from '../../store';
import { authSocialRequest } from '../../store/auth/auth.action';
import { history, FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from '../../utils';
import style from './SocialButton.module.css';
import { SocialButtonType } from './SocialButton.type';

const SocialButton = ({ networkName }: SocialButtonType) => {
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const redirectTo = (url: string): void => {
    history.push(url);
  };

  const { signIn } = useGoogleLogin({
    clientId: GOOGLE_CLIENT_ID,
  });

  useEffect(() => {
    if (accessToken) {
      redirectTo('/');
    }
  }, [accessToken]);

  const handleFacebookLogin = (response: any) => {
    const { accessToken: token } = response;
    if (token) {
      dispatch(
        authSocialRequest({
          token,
          platform: 'facebook',
        }),
      );
    }
  };

  const renderFacebook = () => {
    if (FACEBOOK_APP_ID) {
      return (
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          fields="name,email,picture"
          callback={handleFacebookLogin}
          render={(renderProps: any) => (
            <button
              onClick={renderProps.onClick}
              className={cn(style.btn_social, style.facebook)}
            >
              <svg role="img">
                <use xlinkHref={`${socialImg}#facebook`}></use>
              </svg>
            </button>
          )}
        />
      );
    }

    return null;
  };

  const renderGoogle = () => {
    if (GOOGLE_CLIENT_ID) {
      const authHandler = async () => {
        const googleUser = await signIn();

        dispatch(
          authSocialRequest({
            platform: 'google',
            token: googleUser?.tokenId as string,
          }),
        );
      };

      return (
        <button
          onClick={authHandler}
          className={cn(style.btn_social, style.google)}
        >
          <svg role="img">
            <use xlinkHref={`${socialImg}#google`}></use>
          </svg>
        </button>
      );
    }

    return null;
  };

  const renderSocila = () => {
    switch (networkName) {
      case 'facebook':
        return renderFacebook();
      case 'google':
        return renderGoogle();
      default:
        return (
          <button className={cn(style.btn_social, style[networkName])}>
            <svg role="img">
              <use xlinkHref={`${socialImg}#${networkName}`}></use>
            </svg>
          </button>
        );
    }
  };

  return renderSocila();
};

export default SocialButton;
