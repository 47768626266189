import { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import CheckBox from '../../components/CheckBox/CheckBox';
import Input from '../../components/Input/Input';
import SocialButton from '../../components/SocialButton/SocialButton';
import { SocialNetwork } from '../../components/SocialButton/SocialButton.type';
import { RootState } from '../../store';
import { authLoginRequest } from '../../store/auth/auth.action';
import { history } from '../../utils';
import style from './Login.module.css';

const Login = () => {
  const socialNetworks: SocialNetwork[] = ['google', 'facebook'];
  const { accessToken, loading, isAdmin } = useSelector(
    (state: RootState) => state.auth,
  );
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const redirectTo = (url: string): void => {
    history.push(url);
  };

  useEffect(() => {
    if (accessToken) {
      redirectTo(isAdmin ? '/admin' : '/');
    }
  }, [accessToken, isAdmin]);

  const submitHandler = useCallback(() => {
    dispatch(
      authLoginRequest({
        email,
        password,
        rememberMe,
      }),
    );
  }, [email, password, rememberMe, dispatch]);

  return (
    <>
      <div className={style.login_social_title}>Connect with</div>

      <div className={style.login_social}>
        {socialNetworks.map((social) => (
          <SocialButton key={social} networkName={social} />
        ))}
      </div>
      <div className={style.text_seporrator}>or</div>
      <form>
        <Input
          key="email"
          className={style.form_item}
          placeholder="Email Address"
          value={email}
          setValue={setEmail}
        />
        <Input
          key="password"
          className={style.form_item}
          placeholder="Password"
          value={password}
          setValue={setPassword}
          type="password"
        />

        <div className={style.two_form_items}>
          <div
            key="forgotPassword"
            onClick={() => {
              redirectTo('/forgot-password');
            }}
          >
            <span className={style.forgot_link}>Forgot Password?</span>
          </div>
          <div key="remembrerMe">
            <CheckBox
              setValue={setRememberMe}
              value={rememberMe}
              title="Remember Me"
            />
          </div>
        </div>
        <div className={style.form_item}>
          <Button
            disabled={loading}
            className={style.submit_btn}
            name="Login"
            handler={submitHandler}
          />
        </div>
      </form>
      <div className={style.text_login}>
        Don’t have an account?{' '}
        <span
          onClick={() => {
            redirectTo('/registration');
          }}
        >
          Sign up
        </span>
      </div>
    </>
  );
};

export default Login;
