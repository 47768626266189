import { useState, useEffect, useCallback } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../../assets/img/sprite.svg';
import { RootState } from '../../../store';
import { setSelectedModal } from '../../../store/modal/modal.action';
import {
  deleteVideoRequest,
  setSelectedVideo,
  setSelectedVideosId,
} from '../../../store/video/video.action';
import {
  history,
  converISODateToDateFormat,
  MODAL_PREVIEW_VIDEO,
  MODAL_DELETE_VIDEO,
  MODAL_RENAME_VIDEO,
} from '../../../utils';
import CheckBox from '../../CheckBox/CheckBox';
import style from './VideoItem.module.css';
import { VideoItemType } from './VideoItem.type';

const VideoItem = (props: VideoItemType) => {
  const { video } = props;
  const [isChecked, setChecked] = useState<boolean>(false);
  const {
    video: { videoSelected: videoState, selectedVideosId, limit, page },
    folder: { selectedFolder },
    profile: { profile },
  } = useSelector((state: RootState) => state);

  const [name, setName] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    setChecked(selectedVideosId.includes(video.id));
  }, [video, selectedVideosId]);

  useEffect(() => {
    if (video) {
      setName(video.name);
    }
  }, [video]);

  const setCheckedHandler = () => {
    dispatch(setSelectedVideosId(video.id));
  };

  const setSelectedHandler = () => {
    dispatch(setSelectedVideo(videoState?.id === video.id ? null : video));
  };

  const onEditHandler = () => {
    dispatch(setSelectedVideo(null));

    history.push(`/${video.id}`);
  };

  const getQuery = useCallback(() => {
    let query = `?limit=${limit}&page=${page}`;
    if (selectedFolder) {
      query += `&folder_id=${selectedFolder.id}`;
    }

    return query;
  }, [limit, page, selectedFolder]);

  const deleteHandler = () => {
    const query = getQuery();

    dispatch(
      setSelectedModal(MODAL_DELETE_VIDEO, {
        id: video.id,
        data: {
          acceptHandler: () => {
            dispatch(
              deleteVideoRequest({
                videoId: video.id,
                query,
              }),
            );
          },
        },
      }),
    );
  };

  const showPreviewVideo = () => {
    dispatch(
      setSelectedModal(MODAL_PREVIEW_VIDEO, {
        id: video.id,
        mode: 'withoutFrame',
      }),
    );
  };

  const renameHandler = () => {
    const query = getQuery();

    dispatch(
      setSelectedModal(MODAL_RENAME_VIDEO, {
        id: video.id,
        data: { name, query },
      }),
    );
  };

  return (
    <div
      className={cn(
        style.container,
        videoState?.id === video.id && style.video_active,
      )}
    >
      <div className={style.video_label}>
        <div className={style.video_checkbox}>
          <CheckBox setValue={setCheckedHandler} value={isChecked} />
        </div>
        <div className={style.video_label_container}>
          <div
            className={style.video_label_selected}
            onClick={setSelectedHandler}
          ></div>
          <div className={style.video_label_left}>
            <div className={style.video_label_thumb} onClick={showPreviewVideo}>
              <video poster={video.imageUrl} src={video.videoSource} />
            </div>
            <div className={style.video_label_modify}>
              <div className={style.video_label__name}>{video.name}</div>
              {profile?.isActive && (
                <div className={style.video_label__panel}>
                  <div
                    className={style.video_label_panel__item}
                    onClick={onEditHandler}
                  >
                    <span className={style.video_edit_btn}>
                      <svg role="img">
                        <use xlinkHref={`${spriteImg}#pencil`} />
                      </svg>
                    </span>
                  </div>
                  <div
                    className={style.video_label_panel__item}
                    onClick={renameHandler}
                  >
                    <span className={style.video_edit_btn}>
                      <svg role="img">
                        <use xlinkHref={`${spriteImg}#rename`} />
                      </svg>
                    </span>
                  </div>

                  {/* TODO: EWV-177 */}
                  {/* <div className={style.video_label_panel__item}>
                    <span className={style.video_edit_btn}>
                      <svg role="img">
                        <use xlinkHref={`${spriteImg}#new-tab`} />
                      </svg>
                    </span>
                  </div> */}
                  <div
                    className={style.video_label_panel__item}
                    onClick={deleteHandler}
                  >
                    <span className={style.video_edit_btn}>
                      <svg role="img">
                        <use xlinkHref={`${spriteImg}#circle-delete`} />
                      </svg>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.video_label_right}>
            <div className={style.video_label_type}>
              <div
                className={
                  video.isMainReady
                    ? style.video_label_type__ready
                    : style.video_label_type__load
                }
              >
                Main
              </div>
              <div
                className={
                  video.isHlsReady
                    ? style.video_label_type__ready
                    : style.video_label_type__load
                }
              >
                HLS
              </div>
            </div>
            <div className={style.video_label_size}>
              {video.metadata?.video.width}x{video.metadata?.video.height}
            </div>
            <div className={style.video_label_date}>
              {video.createdAt && converISODateToDateFormat(video.createdAt)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
