import { useState } from 'react';

import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import {
  PaymentMethodModal,
  PaymentMethodSelector,
} from '../../components/PaymentMethod';
import { RootState } from '../../store';
import { Props } from './index.type';
import style from './SetPaymentDetail.module.css';

const SetPaymentDetail = (props: Props) => {
  const { cancelHandler, approveHandler } = props;

  const { loading } = useSelector((state: RootState) => state.profile);

  const [paymentMethod, setPaymentMethod] = useState<'card' | 'paypal'>('card');

  return (
    <div className={style.container}>
      <PaymentMethodSelector
        setValue={setPaymentMethod}
        value={paymentMethod}
      />

      <PaymentMethodModal
        cancelHandler={cancelHandler}
        paymentMethod={paymentMethod}
        approveHandler={approveHandler}
      />
      {loading && <Loader />}
    </div>
  );
};

export default SetPaymentDetail;
