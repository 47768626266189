import StarogeService from '../../services/storage/storage.service';
import {
  IAuthState,
  AuthActionsTypes,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_SOCIAL_REQUEST,
  AUTH_SOCIAL_SUCCESS,
  AUTH_SOCIAL_FAILURE,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_FORGOT_PASS_REQUEST,
  AUTH_FORGOT_PASS_SUCCESS,
  AUTH_FORGOT_PASS_FAILURE,
  AUTH_RESET_PASS_REQUEST,
  AUTH_RESET_PASS_SUCCESS,
  AUTH_RESET_PASS_FAILURE,
  AUTH_VERIFY_EMAIL_REQUEST,
  AUTH_VERIFY_EMAIL_SUCCESS,
  AUTH_VERIFY_EMAIL_FAILURE,
} from './auth.type';

const initialState: IAuthState = {
  accessToken: StarogeService.getAccessToken(),
  resetPassword: false,
  loading: false,
  isAdmin: false,
  isPaymentMethodSet: false,
};

export default function authReducer(
  state = initialState,
  action: AuthActionsTypes,
): IAuthState {
  switch (action.type) {
    case AUTH_RESET_PASS_REQUEST:
    case AUTH_LOGIN_REQUEST:
    case AUTH_LOGOUT_REQUEST:
    case AUTH_FORGOT_PASS_REQUEST:
    case AUTH_SOCIAL_REQUEST:
    case AUTH_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AUTH_LOGIN_SUCCESS:
    case AUTH_SOCIAL_SUCCESS:
    case AUTH_VERIFY_EMAIL_SUCCESS: {
      StarogeService.setToken(action.payload);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        isAdmin: action.payload.isAdmin,
        isPaymentMethodSet: action.payload.isPaymentMethodSet,
        loading: false,
      };
    }
    case AUTH_LOGOUT_SUCCESS: {
      StarogeService.clearToken();
      return {
        accessToken: null,
        resetPassword: false,
        isPaymentMethodSet: false,
        isAdmin: false,
        loading: false,
      };
    }
    case AUTH_RESET_PASS_SUCCESS: {
      return {
        ...state,
        resetPassword: true,
      };
    }
    case AUTH_FORGOT_PASS_SUCCESS:
    case AUTH_LOGIN_FAILURE:
    case AUTH_LOGOUT_FAILURE:
    case AUTH_FORGOT_PASS_FAILURE:
    case AUTH_SOCIAL_FAILURE:
    case AUTH_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AUTH_RESET_PASS_FAILURE:
      return {
        ...state,
        resetPassword: false,
        loading: false,
      };
    default:
      return state;
  }
}
