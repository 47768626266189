import {
  AnalyticActionsTypes,
  CREATE_DROP_OFF_STATISTICS_FAILURE,
  CREATE_DROP_OFF_STATISTICS_REQUEST,
  CREATE_DROP_OFF_STATISTICS_SUCCESS,
  GET_VIDEO_ANALYTIC_FAILURE,
  GET_VIDEO_ANALYTIC_REQUEST,
  GET_VIDEO_ANALYTIC_SUCCESS,
  IAnalyticState,
} from './analytic.type';

const initialState: IAnalyticState = {
  browser: [],
  country: [],
  device: [],
  dropOff: [],
  loading: false,
};

export default function analyticReducer(
  state = initialState,
  action: AnalyticActionsTypes,
): IAnalyticState {
  switch (action.type) {
    case GET_VIDEO_ANALYTIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VIDEO_ANALYTIC_SUCCESS:
      return {
        ...state,
        browser: action.payload.browser,
        country: action.payload.country,
        device: action.payload.device,
        dropOff: action.payload.dropOff,
        loading: false,
      };
    case CREATE_DROP_OFF_STATISTICS_REQUEST:
    case CREATE_DROP_OFF_STATISTICS_SUCCESS:
    case CREATE_DROP_OFF_STATISTICS_FAILURE:
    case GET_VIDEO_ANALYTIC_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
