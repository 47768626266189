import * as yup from 'yup';

import i18n from '../../../../i18n/i18n';

const {
  cardNumber,
  cvc,
  expMonth,
  expYear,
  firstName,
  lastName,
} = i18n.en.validation;

export const validationSchema = yup.object({
  cardNumber: yup
    .string()
    .trim()
    .length(16)
    .matches(/\d+/, cardNumber.valid)
    .required(cardNumber.required),
  cvc: yup.string().trim().matches(/\d+/, cvc.valid).required(cvc.required),
  expMonth: yup
    .string()
    .trim()
    .matches(/0[1-9]|1[0-2]/, expMonth.valid)
    .required(expMonth.required),
  expYear: yup
    .string()
    .trim()
    .matches(/[0-9]{2}/, expYear.valid)
    .required(expYear.required),
  firstName: yup.string().trim().required(firstName.required),
  lastName: yup.string().trim().required(lastName.required),
});
