import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import './App.css';
import AlerComponent from './components/Alert/Alert';
import InitModalContainer from './containers/InitModal/InitModal';
import PrivateRoute from './containers/PrivateRoute/PrivateRoute';
import EmbedPage from './pages/EmbedPage/EmbedPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import InactiveAccountsPage from './pages/InactiveAccounts/InactiveAccountsPage';
import LogInPage from './pages/LogInPage/LogInPage';
import MyVideosPage from './pages/MyVideosPage/MyVideosPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import RegistrationPage from './pages/RegistrationPage/RegistrationPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import TermOfUsePage from './pages/TermOfUsePage/TermOfUsePage';
import UserSettingsPage from './pages/UserSettingsPage/UserSettingsPage';
import UsersPage from './pages/UsersPage/UsersPage';
import UserVideoPage from './pages/UserVideoPage/UserVideoPage';
import VerifyEmailPage from './pages/VerifyEmailPage/ValidateEmailPage';
import VideoAnalyticsPage from './pages/VideoAnalyticsPage/VideoAnalyticsPage';
import VideoPage from './pages/VideoPage/VideoPage';
import store from './store';

function Admin() {
  return (
    <Switch>
      <PrivateRoute
        path="/admin/users/:userId/videos/:videoId"
        admin={true}
        component={UserVideoPage}
      />
      <PrivateRoute
        path="/admin/users/:userId"
        admin={true}
        component={UserSettingsPage}
      />
      <PrivateRoute path="/admin/users" admin={true} component={UsersPage} />
      <PrivateRoute
        path="/admin/inactive"
        admin={true}
        component={InactiveAccountsPage}
      />
      <Redirect from="/admin" to="/admin/users" />
    </Switch>
  );
}

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Switch>
          <Route path="/registration" component={RegistrationPage} />
          <Route path="/login" component={LogInPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/verify-email" component={VerifyEmailPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route path="/embed/:embedType/:videoId" component={EmbedPage} />
          <PrivateRoute path="/settings" component={SettingsPage} />
          <PrivateRoute path="/privacy-policy" component={PrivacyPolicyPage} />
          <PrivateRoute path="/term-of-use" component={TermOfUsePage} />
          <Route path="/admin" component={Admin} />
          <PrivateRoute
            path="/video-analytics/:videoId"
            component={VideoAnalyticsPage}
          />
          <PrivateRoute path="/:videoId" component={VideoPage} />
          <PrivateRoute path="/" component={MyVideosPage} />
        </Switch>
        <AlerComponent />
        <InitModalContainer />
      </Provider>
    </div>
  );
}

export default App;
