import cn from 'classnames';

import style from './MobileSubMenu.module.css';
import {
  MobileSubMenuBlock,
  MobileSubMenuItem,
  MobileSubMenuType,
} from './MobileSubMenu.type';

const MobileSubMenu = (props: MobileSubMenuType) => {
  const { blocks, className } = props;

  const renderItem = (
    { title, iconUrl, handler }: MobileSubMenuItem,
    i: number,
  ) => {
    return (
      <li key={i} onClick={handler}>
        <span className={style.mobile_icon}>
          <svg role="img">
            <use xlinkHref={iconUrl} />
          </svg>
        </span>
        {title}
      </li>
    );
  };

  const renderBlock = (
    { icon, items, name }: MobileSubMenuBlock,
    i: number,
  ) => {
    return (
      <div className={style.mobile_submenu} key={i}>
        <span className={style.mobile_title}>
          <span className={style.mobile_icon}>
            <svg role="img">
              <use xlinkHref={icon} />
            </svg>
          </span>
          <span>{name}</span>
        </span>
        <ul>{items.map(renderItem)}</ul>
      </div>
    );
  };

  return (
    <div className={cn(style.mobile_row, className)}>
      {props?.children}
      {blocks.map(renderBlock)}
    </div>
  );
};

export default MobileSubMenu;
