/* eslint-disable @typescript-eslint/no-unsafe-return */
import cn from 'classnames';

import style from './RadioButton.module.css';
import { RadioButtonType } from './RadioButton.type';

const RadioButton = ({
  className,
  setValue,
  value,
  options,
  name,
}: RadioButtonType) => {
  return (
    <div className={cn(style.container, className)}>
      {options.map((option) => {
        const checked = value === option.value;
        return (
          <div
            key={option.value}
            className={style.option}
            onClick={() => setValue(option.value)}
          >
            <input
              checked={checked}
              type="radio"
              value={option.name}
              name={name}
              className={style.form_radio}
              readOnly={true}
            />
            <span className={style.radio_checked} />

            <label>{option.name}</label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioButton;
