import { PAGE_DEFAULT } from '../../utils';
import {
  CLEAR_EMBED,
  CREATE_GIF_FAILURE,
  CREATE_GIF_REQUEST,
  CREATE_GIF_SUCCESS,
  DELETE_GIF_FAILURE,
  DELETE_GIF_REQUEST,
  DELETE_GIF_SUCCESS,
  GET_GIF_LIST_FAILURE,
  GET_GIF_LIST_REQUEST,
  GET_GIF_LIST_SUCCESS,
  GifActionsTypes,
  IGifState,
  SET_GIF_PAGE,
} from './gif.type';

const PAGE_LIMIT = 6;

const initialState: IGifState = {
  gifs: [],
  loading: false,
  embed: null,
  limit: PAGE_LIMIT,
  page: PAGE_DEFAULT,
  pagination: {
    limit: PAGE_LIMIT,
    currentPage: PAGE_DEFAULT,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: 1,
    prevPage: 1,
    totalPages: 1,
    totalItems: 0,
  },
};

export default function gifReducer(
  state = initialState,
  action: GifActionsTypes,
): IGifState {
  switch (action.type) {
    case SET_GIF_PAGE:
      return {
        ...state,
        limit: action.payload.pageType.limit || state.limit,
        page: action.payload.pageType.currentPage,
      };
    case GET_GIF_LIST_REQUEST:
    case CREATE_GIF_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_GIF_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        gifs: action.payload.gifs,
        pagination: action.payload.pagination,
      };
    case CREATE_GIF_SUCCESS:
      return {
        ...state,
        loading: false,
        embed: action.payload.embed,
      };
    case DELETE_GIF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GIF_SUCCESS:
    case DELETE_GIF_FAILURE:
    case GET_GIF_LIST_FAILURE:
    case CREATE_GIF_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_EMBED:
      return {
        ...state,
        embed: null,
      };
    default:
      return state;
  }
}
