import { Login, Social } from '../../models';
import { TokenType, RestorePasswordType } from '../../utils';
import {
  AuthActionsTypes,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_SOCIAL_FAILURE,
  AUTH_SOCIAL_REQUEST,
  AUTH_SOCIAL_SUCCESS,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_FORGOT_PASS_REQUEST,
  AUTH_FORGOT_PASS_SUCCESS,
  AUTH_FORGOT_PASS_FAILURE,
  AUTH_RESET_PASS_REQUEST,
  AUTH_RESET_PASS_SUCCESS,
  AUTH_RESET_PASS_FAILURE,
  AUTH_VERIFY_EMAIL_REQUEST,
  AUTH_VERIFY_EMAIL_SUCCESS,
  AUTH_VERIFY_EMAIL_FAILURE,
} from './auth.type';

export function authLoginRequest(payload: Login): AuthActionsTypes {
  return {
    type: AUTH_LOGIN_REQUEST,
    payload,
  };
}

export function authLoginSuccess(payload: TokenType): AuthActionsTypes {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload,
  };
}

export function authLoginFailure(): AuthActionsTypes {
  return {
    type: AUTH_LOGIN_FAILURE,
  };
}

export function authSocialRequest(payload: Social): AuthActionsTypes {
  return {
    type: AUTH_SOCIAL_REQUEST,
    payload,
  };
}

export function authSocialSuccess(payload: TokenType): AuthActionsTypes {
  return {
    type: AUTH_SOCIAL_SUCCESS,
    payload,
  };
}

export function authSocialFailure(): AuthActionsTypes {
  return {
    type: AUTH_SOCIAL_FAILURE,
  };
}

export function authLogoutRequest(): AuthActionsTypes {
  return {
    type: AUTH_LOGOUT_REQUEST,
  };
}

export function authLogoutSuccess(): AuthActionsTypes {
  return {
    type: AUTH_LOGOUT_SUCCESS,
  };
}

export function authLogoutFailure(): AuthActionsTypes {
  return {
    type: AUTH_LOGOUT_FAILURE,
  };
}

export function authForgotPassRequest(email: string): AuthActionsTypes {
  return {
    type: AUTH_FORGOT_PASS_REQUEST,
    payload: {
      email,
    },
  };
}

export function authForgotPassSuccess(): AuthActionsTypes {
  return {
    type: AUTH_FORGOT_PASS_SUCCESS,
  };
}

export function authForgotPassFailure(): AuthActionsTypes {
  return {
    type: AUTH_FORGOT_PASS_FAILURE,
  };
}

export function authResetPassRequest(
  payload: RestorePasswordType,
): AuthActionsTypes {
  return {
    type: AUTH_RESET_PASS_REQUEST,
    payload,
  };
}

export function authResetPassSuccess(): AuthActionsTypes {
  return {
    type: AUTH_RESET_PASS_SUCCESS,
  };
}

export function authResetPassFailure(): AuthActionsTypes {
  return {
    type: AUTH_RESET_PASS_FAILURE,
  };
}

export function authVerifyEmailRequest(token: string): AuthActionsTypes {
  return {
    type: AUTH_VERIFY_EMAIL_REQUEST,
    payload: {
      token,
    },
  };
}

export function authVerifyEmailSuccess(payload: TokenType): AuthActionsTypes {
  return {
    type: AUTH_VERIFY_EMAIL_SUCCESS,
    payload,
  };
}

export function authVerifyEmailFailure(): AuthActionsTypes {
  return {
    type: AUTH_VERIFY_EMAIL_FAILURE,
  };
}
