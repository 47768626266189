import { IPayment, PaymentPlanItem } from '../../models/Payment';
import {
  GET_PAYMENT_PLAN_FAILURE,
  GET_PAYMENT_PLAN_REQUEST,
  GET_PAYMENT_PLAN_SUCCESS,
  PaymentActionsTypes,
  PAYMENT_FAILURE,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
} from './payment.type';

export function paymentRequest(payload: IPayment): PaymentActionsTypes {
  return {
    type: PAYMENT_REQUEST,
    payload,
  };
}

export function paymentSuccess(): PaymentActionsTypes {
  return {
    type: PAYMENT_SUCCESS,
  };
}

export function paymentFailure(): PaymentActionsTypes {
  return {
    type: PAYMENT_FAILURE,
  };
}

export function getPaymentPlanRequest(): PaymentActionsTypes {
  return {
    type: GET_PAYMENT_PLAN_REQUEST,
  };
}

export function getPaymentPlanSuccess(
  plans: PaymentPlanItem[],
): PaymentActionsTypes {
  return {
    type: GET_PAYMENT_PLAN_SUCCESS,
    payload: {
      plans,
    },
  };
}

export function getPaymentPlanFailure(): PaymentActionsTypes {
  return {
    type: GET_PAYMENT_PLAN_FAILURE,
  };
}
