import { PopUpWrapperType } from '../../layout/PopUpWrapper/PopUpWrapper.type';
import { ModalType } from '../../utils';
import {
  CHANGE_MODAL_MODE,
  ModalActionsTypes,
  SET_SELECTED_MODAL_TYPE,
} from './modal.type';

export function setSelectedModal(
  modalType: ModalType,
  info?: {
    id?: string | number;
    title?: string;
    data?: any;
  } & Pick<PopUpWrapperType, 'mode'>,
): ModalActionsTypes {
  return {
    type: SET_SELECTED_MODAL_TYPE,
    payload: {
      modalType,
      id: info?.id,
      title: info?.title,
      data: info?.data,
      mode: info?.mode,
    },
  };
}

export function changeModalModel(
  payload: Pick<PopUpWrapperType, 'mode'>,
): ModalActionsTypes {
  return {
    type: CHANGE_MODAL_MODE,
    payload,
  };
}
