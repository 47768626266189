/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../../assets/img/sprite.svg';
import Button from '../../../components/Button/Button';
import ChangeEmail from '../../../components/ChangeEmail/ChangeEmail';
import ChangePassword from '../../../components/ChangePassword/ChangePaasword';
import ModalFile from '../../../components/ModalFile/ModalFile';
import { RootState } from '../../../store';
import { setSelectedModal } from '../../../store/modal/modal.action';
import { uploadFileRequest } from '../../../store/profile/profile.action';
import { MODAL_CHANGE_PLAN, ModalType, getPlanColor } from '../../../utils';

const Profile = () => {
  const [isUpload, setIsUpload] = useState(false);
  const { loading, profile } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      setIsUpload(false);
    }
  }, [loading]);

  const uploadHandler = (file: Blob | null) => {
    if (file) {
      dispatch(uploadFileRequest({ file, image: 'avatar' }));
    }
  };

  const modalHenadler = () => {
    setIsUpload(!isUpload);
  };

  const modalHandler = (modal: ModalType) => {
    dispatch(setSelectedModal(modal, {}));
  };

  return (
    <>
      <div className="profile_edit main_widget">
        <div className="main_widget_header">
          <div className="main_widget_title">
            <span className="main_widget_ico">
              <svg role="img">
                <use xlinkHref={`${spriteImg}#myaccount`} />
              </svg>
            </span>
            Profile
          </div>
          <div className="profile_edit_action">
            <button
              className="change_btn"
              onClick={() => modalHandler(MODAL_CHANGE_PLAN)}
            >
              Change level
            </button>
            {profile?.plan && (
              <div
                className="icon_plans"
                style={{
                  background: getPlanColor(profile?.plan),
                }}
              >
                {profile.plan[0]}
              </div>
            )}
          </div>
        </div>
        <div className="profile_edit_block">
          <div className="change_avatar">
            <div className="change_avatar_title">Avatar image</div>
            <Button
              className="submit_btn"
              name="Browse"
              hasBackground={true}
              handler={modalHenadler}
            />
          </div>
        </div>
        <div className="profile_edit_block">
          <div className="profile_edit_block_item">
            <ChangeEmail />
          </div>
          <div className="profile_edit_block_item">
            <ChangePassword />
          </div>
        </div>
      </div>
      {isUpload && (
        <ModalFile handler={uploadHandler} closeHandler={modalHenadler} />
      )}
    </>
  );
};

export default Profile;
