import spriteImg from '../../../assets/img/sprite.svg';
import { converISODateToDateFormat } from '../../../utils';
import style from './FolderItem.module.css';
import { FolderItemType } from './FolderItem.type';

const FolderItem = (props: FolderItemType) => {
  const { folder, onShow, onDelete, onEdit } = props;

  const showHandler = () => {
    onShow(folder);
  };

  const editHandler = () => {
    onEdit(folder);
  };

  const deleteHandler = () => {
    onDelete(folder.id);
  };

  return (
    <div className={style.container}>
      <div className={style.show} onDoubleClick={showHandler} />
      <div className={style.title}>
        {folder.name}
        <span className={style.ico} onClick={editHandler}>
          <svg role="img">
            <use xlinkHref={`${spriteImg}#pencil`} />
          </svg>
        </span>
      </div>
      <div className={style.bottom}>
        <div className={style.date}>
          {converISODateToDateFormat(folder.createdAt)}
        </div>
        <div className={style.count}>{folder.countVideo} videos</div>
      </div>
      <span className={style.remove_folder} onClick={deleteHandler}>
        <svg role="img">
          <use xlinkHref={`${spriteImg}#close_bold`} />
        </svg>
      </span>
    </div>
  );
};

export default FolderItem;
