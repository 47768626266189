import { PaymentMethodCardForm } from './index.type';

export const INITIAL_VALUE: PaymentMethodCardForm = {
  cardNumber: '',
  cvc: '',
  expMonth: '',
  expYear: '',
  firstName: '',
  lastName: '',
};
