import { useSelector } from 'react-redux';

import './SettingsPage.css';
import LoaderComponent from '../../components/Loader/Loader';
import Footer from '../../containers/Footer/Footer';
import Header from '../../containers/Header/Header';
import ColorSchemeContainer from '../../containers/Setting/ColorScheme/ColorScheme';
import SettingPaymentMethod from '../../containers/Setting/PaymentMethod';
import PlayerLogoContainer from '../../containers/Setting/PlayerLogo/PlayerLogo';
import ProfileContainer from '../../containers/Setting/Profile/Profile';
import PromoCodeContainer from '../../containers/Setting/PromoCode/PromoCode';
import { RootState } from '../../store';

const SettingsPage = () => {
  const { loading } = useSelector((state: RootState) => state.profile);

  return (
    <>
      <div className="enter_content">
        <Header />
        <div className="body_with_right_sidebar">
          <div className="body_block">
            <ProfileContainer />
            <PromoCodeContainer title="Promo code" />
            <SettingPaymentMethod />
          </div>
          <div className="right_block">
            <div className="video_settings">
              <PlayerLogoContainer title={'Default Player Logo'} />
              <ColorSchemeContainer title={'Default Color Scheme'} />
            </div>
          </div>
          {loading && <LoaderComponent />}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SettingsPage;
