import { ChangeEvent, useRef, useState } from 'react';

import cn from 'classnames';

import spriteImg from '../../assets/img/sprite.svg';
import UploadProgress from '../../components/UploadProgress/UploadProgress';
import style from './UploadVideo.module.css';
import { FilesInfo } from './UploadVideo.type';

const UploadVideo = () => {
  const dropRef = useRef<HTMLDivElement>();
  const fileInputRef = useRef<HTMLInputElement>();

  const [isDrag, setDrag] = useState<boolean>(false);
  const [files, setFiles] = useState<FilesInfo>([]);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };

  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  };

  const addFile = (file: File) => {
    const source = URL.createObjectURL(file);

    // const { id } = await addVideoOnServer({ name: file.name });

    return {
      source,
      name: file.name,
      size: file.size,
      progress: 0,
      file,
    };
  };

  const addFiles = (fileList: FileList) => {
    const fileListSize = fileList.length;

    const filesProgress: FilesInfo = [];

    for (let i = 0; i < fileListSize; i++) {
      const file = fileList[i];

      if (/^video\//.test(file.type)) {
        const fileProgress = addFile(file);

        filesProgress.push(fileProgress);
      }
    }

    setFiles([...files, ...filesProgress]);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      addFiles(e.dataTransfer.files);

      e.dataTransfer.clearData();
    }
  };

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handlerUploadFile = () => {
    fileInputRef?.current?.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files) {
      addFiles(event.target?.files);
    }
  };

  return (
    <div
      className={style.container}
      ref={dropRef as any}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onClick={handlerUploadFile}
    >
      <div
        className={style.files_progress}
        style={{ display: files.length > 0 ? 'block' : 'none' }}
      >
        {files.map((file, i) => (
          <UploadProgress key={i} {...file} />
        ))}
      </div>

      <div
        className={cn(
          style.drag_place,
          files.length > 0 ? style.drag_smart : style.drag__full,
        )}
      >
        <span className={style.drag_ico}>
          <svg role="img">
            <use xlinkHref={`${spriteImg}#import`}></use>
          </svg>
        </span>
        <div className={style.drag_text}>
          {isDrag ? (
            <>Drop here</>
          ) : (
            <>
              Drag files here or <span>browse</span>
            </>
          )}
        </div>
        <input
          type="file"
          ref={fileInputRef as any}
          accept="video/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          multiple
        />
      </div>
    </div>
  );
};

export default UploadVideo;
