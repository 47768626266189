import MasterCardImg from '../../../assets/img/payment/payment-master-card.svg';
import PaypalImg from '../../../assets/img/payment/payment-paypal.svg';
import VisaImg from '../../../assets/img/payment/payment-visa.svg';
import RadioButton from '../../RadioButton/RadioButton';
import { PaymentMethodSelectorProps } from './index.type';

const PaymentMethodSelector = (props: PaymentMethodSelectorProps) => {
  const { setValue, value, className } = props;

  const card = [
    {
      value: 'card',
      name: (
        <>
          <img src={VisaImg} alt="visa" />
          <img src={MasterCardImg} alt="mastercard" />
        </>
      ),
    },
    {
      value: 'paypal',
      name: <img src={PaypalImg} alt="paypal" />,
    },
  ];

  return (
    <RadioButton
      className={className}
      setValue={setValue}
      options={card}
      value={value}
      name="Payment method"
    />
  );
};

export default PaymentMethodSelector;
