import {
  GET_PAYMENT_PLAN_FAILURE,
  GET_PAYMENT_PLAN_REQUEST,
  GET_PAYMENT_PLAN_SUCCESS,
  IPaymentState,
  PaymentActionsTypes,
  PAYMENT_FAILURE,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
} from './payment.type';

const initialState: IPaymentState = {
  loading: false,
  plans: [],
};

export default function paymentReducer(
  state = initialState,
  action: PaymentActionsTypes,
): IPaymentState {
  switch (action.type) {
    case PAYMENT_REQUEST:
    case GET_PAYMENT_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PAYMENT_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        plans: action.payload.plans,
      };
    case PAYMENT_SUCCESS:
    case PAYMENT_FAILURE:
    case GET_PAYMENT_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
