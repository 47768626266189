import { FunctionComponent, SyntheticEvent, useState } from 'react';

import cn from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';

import spriteImg from '../../../assets/img/sprite.svg';
import { ObjectTypeExtends, TableRowProps, Columns } from '../Table.type';
import styles from './TableRow.module.css';

const TableRow = <ObjectType extends ObjectTypeExtends>({
  item,
  onSelectHandler,
  infoIcons,
  columns,
  selectedItems,
}: TableRowProps<ObjectType>) => {
  const [isShowMobileTable, setIsShowMobileTable] = useState<boolean>(false);
  const { id, ban = false, isActive = true, banReason = '' } = item;
  const isSelected = selectedItems.find((i) => i === id);

  const onClickIconHandler = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const bottomLeft = columns.find((i) => i.leftBottom);
  const bottomRight = columns.find((i) => i.rightBottom);

  const RenderColumnType: FunctionComponent<{
    column: Columns<ObjectType>;
  }> = ({ column }) => {
    switch (column.type) {
      case 'string': {
        return <>{item[column.key]}</>;
      }

      case 'links': {
        if (column.key === 'profile') {
          return (
            <Link
              to={`/${column.linkTo}${item[column.linkAttr]}`}
            >{`${item.profile?.firstName} ${item.profile?.lastName}`}</Link>
          );
        }
        return (
          <>
            <Link to={`/${column.linkTo}${item[column.linkAttr]}`}>
              {item[column.key]}
            </Link>
          </>
        );
      }

      case 'date': {
        return (
          <>
            {item[column.key] &&
              moment(item[column.key]).format(column.dateFormat)}
          </>
        );
      }

      case 'duration': {
        return (
          <>{`${Math.round(
            moment.duration(moment().diff(item[column.key])).asDays(),
          )} days`}</>
        );
      }

      case 'boolean': {
        return <>{column.booleanToString(!!item[column.key])}</>;
      }

      default:
        return null;
    }
  };

  const InfoContainer: FunctionComponent<{ className?: string }> = ({
    className,
  }) => (
    <div
      onClick={onClickIconHandler}
      className={cn(
        styles.info_block,
        {
          [styles.info_block_select]: isSelected,
        },
        className,
      )}
    >
      <svg role="img" width="16" height="16">
        <use xlinkHref={`${spriteImg}#${'circle-block'}`} />
      </svg>
      {banReason && (
        <div className={styles.info_container}>
          <div className={styles.info_block_text}>{banReason}</div>
        </div>
      )}
    </div>
  );

  const RenderSelect: FunctionComponent<{
    title: string;
    idItem: number;
  }> = ({ title, idItem }) => (
    <td key={`${title}${id}${idItem}`}>
      <div
        className={styles.selected}
        onClick={(e) => {
          e.stopPropagation();
          onSelectHandler(id);
        }}
      >
        {isSelected && (
          <svg role="img" width="11" height="9">
            <use xlinkHref={`${spriteImg}#check`} />
          </svg>
        )}
        {infoIcons && ban && <InfoContainer />}
      </div>
    </td>
  );

  const RenderMobileTable = () => {
    return (
      <table className={cn({ [styles.mobile_table]: isShowMobileTable })}>
        <tbody>
          {columns
            .slice(3)
            .filter((i) => !i.isHide)
            .map((column) => (
              <tr key={column.title}>
                <td>{column.title} </td>
                <td>
                  <RenderColumnType column={column} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const renderTd = (col: Columns<ObjectType>, idItem: number) => {
    if (col.isHide) {
      return null;
    }

    if (col.type === 'select') {
      return (
        <RenderSelect
          key={`${id}${col.title}${idItem}`}
          title={col.title}
          idItem={idItem}
        />
      );
    }
    return (
      <td
        key={`${id}${col.title}${idItem}`}
        className={cn(styles.table_name_column, {
          [styles.desktop_hide]: col.leftBottom || col.rightBottom,
          [styles.mobile_hide]: idItem !== 1,
        })}
      >
        <div className={styles.item_info}>
          <div
            onClick={() => col.handlerClick && col.handlerClick(item.id)}
            style={col.rowStyle}
          >
            <RenderColumnType column={col} />{' '}
          </div>
          {idItem === 1 && infoIcons && (ban || !isActive) && (
            <InfoContainer className={styles.info_mobile} />
          )}
        </div>
        {bottomLeft && idItem === 1 && (
          <p className={cn([styles.bottom])}>
            <b>{bottomLeft.title}</b> {item[bottomLeft.key]}
          </p>
        )}
        {bottomRight && idItem === columns.length - 1 && (
          <p className={cn([styles.bottom])}>
            <b>{bottomRight.title}</b> {item[bottomRight.key]}
          </p>
        )}
        {idItem === 1 && isShowMobileTable && <RenderMobileTable />}
      </td>
    );
  };

  return (
    <tr
      className={cn({ [styles.selected_row]: isSelected })}
      onClick={() => setIsShowMobileTable(!isShowMobileTable)}
    >
      {columns.map(renderTd)}
    </tr>
  );
};

export default TableRow;
