import { FunctionComponent } from 'react';

import spriteImg from '../../assets/img/sprite.svg';
import { history } from '../../utils';
import styles from './UserInfo.module.css';
import { UserInfoProps } from './UserInfo.type';

const UserInfo: FunctionComponent<UserInfoProps> = ({
  user,
  backLink = '/',
}) => {
  const { id, profile } = user;

  const backHandler = () => {
    history.push(backLink);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.back_step} onClick={backHandler}>
        <svg role="img">
          <use xlinkHref={`${spriteImg}#back`} />
        </svg>
      </div>
      <div className={styles.about_user}>
        {profile && (
          <span
            className={styles.user_name}
          >{`${profile?.firstName} ${profile?.lastName}`}</span>
        )}
        <span className={styles.user_id}>ID: {id}</span>
      </div>
    </div>
  );
};

export default UserInfo;
