import { Status } from '../../utils';
import {
  IProfileState,
  ProfileActionsTypes,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  CREATE_PROFILE_REQUEST,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  CLEAR_PROFILE,
  INIT_STATUS,
} from './profile.type';

const initialState: IProfileState = {
  profile: null,
  loading: false,
  status: Status.INITIAL,
};

export default function profileReducer(
  state = initialState,
  action: ProfileActionsTypes,
): IProfileState {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
    case CREATE_PROFILE_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        status: Status.INPROGRESS,
      };
    case GET_PROFILE_SUCCESS:
    case UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        profile: action.payload.user,
      };
    }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.user,
        status: Status.SUCCESS,
      };
    case CREATE_PROFILE_SUCCESS:
    case GET_PROFILE_FAILURE:
    case CREATE_PROFILE_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        status: Status.FAIL,
      };
    case INIT_STATUS: {
      return {
        ...state,
        status: Status.INITIAL,
      };
    }
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
      };
    default:
      return state;
  }
}
