/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo, useCallback } from 'react';

import cn from 'classnames';

import spriteImg from '../../assets/img/sprite.svg';
import { LIMIT_PAGE } from '../../utils';
import Select from '../Select/Select';
import { OptionType } from '../Select/Select.type';
import style from './Pagination.module.css';
import { PaginationType } from './Pagination.type';

const Pagination = (props: PaginationType) => {
  const {
    pagination,
    onChangePage,
    hasSettings = true,
    className,
    showButtonNavigation = true,
  } = props;

  const handlerLimit = useCallback(
    (data: OptionType) => {
      if (onChangePage) {
        const { value } = data as any;
        onChangePage({ currentPage: 1, limit: +value });
      }
    },
    [onChangePage],
  );

  const handlerPage = (page: number) => {
    if (onChangePage) {
      onChangePage({ currentPage: page, limit: pagination.limit });
    }
  };

  const handlerMobilePage = (page: number) => {
    if (onChangePage) {
      onChangePage({
        currentPage: 1,
        limit: pagination.limit + (page - 1) * 5,
      });
    }
  };

  const renderCount = useMemo(() => {
    const startItem = pagination.currentPage > 1 ? pagination.limit + 1 : 1;
    const endItem =
      pagination.limit > pagination.totalItems
        ? pagination.totalItems
        : pagination.currentPage * pagination.limit;

    return (
      <div className={style.videos_count}>
        {startItem}-{endItem} of {pagination.totalItems}
      </div>
    );
  }, [pagination]);

  const renderSelect = useMemo(() => {
    return (
      <div className={style.videos_show}>
        Rows per page :
        <Select
          key="pagination-select"
          defaultValue={{
            name: `${pagination.limit}`,
            value: pagination.limit,
          }}
          curValue={{ name: `${pagination.limit}`, value: pagination.limit }}
          setValue={handlerLimit}
          options={LIMIT_PAGE}
          className={'pagination'}
        />
      </div>
    );
  }, [handlerLimit, pagination.limit]);

  return (
    <div className={cn(style.container, className)}>
      <div className={style.mobile_pagination}>
        {pagination.hasNextPage && (
          <button
            className={style.see_more}
            onClick={() => handlerMobilePage(pagination.nextPage)}
          >
            See more
          </button>
        )}
      </div>
      {hasSettings && (
        <div className={style.left}>
          {renderCount}
          {renderSelect}
        </div>
      )}
      {showButtonNavigation && (
        <div className={style.right}>
          <button
            className={style.prev_page}
            disabled={!pagination.hasPrevPage}
            onClick={() => handlerPage(pagination.prevPage)}
          >
            <svg role="img">
              <use xlinkHref={`${spriteImg}#circle-right`} />
            </svg>
          </button>
          <button
            className={style.next_page}
            disabled={!pagination.hasNextPage}
            onClick={() => handlerPage(pagination.nextPage)}
          >
            <svg role="img">
              <use xlinkHref={`${spriteImg}#circle-right`} />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default Pagination;
