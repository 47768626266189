/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FunctionComponent, useRef, useState } from 'react';

import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import style from './ImageCrop.module.css';
import { ImageCropProps, RefType } from './ImageCrop.type';

const ImageCrop: FunctionComponent<ImageCropProps> = ({
  src = '',
  uploadHandler,
  closeHandler,
}) => {
  const original = useRef<RefType>(null);
  const [crop, setCrop] = useState<ReactCrop.Crop>({});

  function getCroppedImg(): void {
    const canvas: HTMLCanvasElement = document.getElementById(
      'canvas',
    ) as HTMLCanvasElement;
    const image = new Image();
    image.setAttribute('crossorigin', 'anonymous');
    image.src = src;
    image.onload = () => {
      if (
        canvas &&
        original &&
        crop.x &&
        crop.y &&
        crop.width &&
        crop.height &&
        original &&
        original.current
      ) {
        const { clientWidth, clientHeight } = original.current.componentRef;
        const originalWidth = clientWidth;
        const originalHeight = clientHeight;
        const scaleX = image.naturalWidth / originalWidth;
        const scaleY = image.naturalHeight / originalHeight;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
          );
        }
      }
      return new Promise(() => {
        canvas.toBlob(
          (blob) => {
            uploadHandler(blob);
            closeHandler();
          },
          'image/png',
          1,
        );
      });
    };
  }

  const handlerCrop = (newCrop: ReactCrop.Crop) => {
    setCrop(newCrop);
  };

  return (
    <div className={style.container}>
      <button className={style.button_save} onClick={getCroppedImg}>
        Save and continue
      </button>
      <ReactCrop ref={original} src={src} crop={crop} onChange={handlerCrop} />
      <canvas className={style.hidden_canvas} id="canvas" />
    </div>
  );
};

export default ImageCrop;
