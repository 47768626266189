import { all } from 'redux-saga/effects';

import { analiticWatchers } from './analytic/analytic.saga';
import { authWatchers } from './auth/auth.saga';
import { folderWatchers } from './folder/folder.saga';
import { gifWatchers } from './gif/gif.saga';
import { paymentWatchers } from './payment/payment.saga';
import { playerConfigWatchers } from './playerConfig/playerConfig.saga';
import { profileWatchers } from './profile/profile.saga';
import { usersWatchers } from './users/users.saga';
import { videoWatchers } from './video/video.saga';

export function* rootSagas() {
  const watchers = [
    ...authWatchers,
    ...folderWatchers,
    ...profileWatchers,
    ...gifWatchers,
    ...videoWatchers,
    ...playerConfigWatchers,
    ...analiticWatchers,
    ...usersWatchers,
    ...paymentWatchers,
  ];

  yield all(watchers);
}
