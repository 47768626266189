/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect } from 'react';

import bytes from 'bytes';
import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import { Gif } from '../../models';
import { RootState } from '../../store';
import { alertSuccess } from '../../store/alert/alert.action';
import {
  deleteGifRequest,
  getGifListRequest,
  setGifPage,
} from '../../store/gif/gif.action';
import { PageType } from '../../utils';
import style from './GifList.module.css';
import { GifListType } from './GifList.type';

const GifList = (props: GifListType) => {
  const { videoId } = props;

  const { gifs, loading, limit, page, pagination } = useSelector(
    (state: RootState) => state.gif,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const query = `?limit=${limit}&page=${page}&video-id=${videoId}`;

    dispatch(getGifListRequest(query));
  }, [dispatch, page, limit, videoId]);

  const deleteHandler = (id: Gif['id']) => {
    const query = `?limit=${limit}&page=${page}&video-id=${videoId}`;

    dispatch(
      deleteGifRequest({
        id,
        query,
      }),
    );
  };

  const copyEmbedHandler = (embed: Gif['embed']) => {
    copy(embed, {
      format: 'text/html',
    });

    dispatch(alertSuccess('Copied!'));
  };

  const onChangePage = (data: PageType): void => {
    dispatch(setGifPage(data));
  };

  const BodyItem = ({ source, id, embed, size }: Gif, i: number) => {
    return (
      <div className={style.body_item} key={i}>
        <img src={source} alt="logo" />
        <div className={style.widget_gif_panel}>
          <button
            className={style.widget_gif_panel_btn}
            onClick={() => copyEmbedHandler(embed)}
          >
            <svg role="img">
              <use xlinkHref={`${spriteImg}#copy`} />
            </svg>
          </button>
          <a className={style.widget_gif_panel_btn} href={source}>
            <svg role="img">
              <use xlinkHref={`${spriteImg}#import`} />
            </svg>
          </a>
          <div className={style.panel_size}>
            {bytes(size, {
              unit: 'mb',
            })}
          </div>
        </div>
        <span className={style.remove_gif} onClick={() => deleteHandler(id)}>
          <svg role="img">
            <use xlinkHref={`${spriteImg}#close_bold`} />
          </svg>
        </span>
      </div>
    );
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.title}>GIFs</div>

        {pagination?.totalPages > 1 ? (
          <Pagination
            onChangePage={onChangePage}
            pagination={pagination}
            hasSettings={false}
          />
        ) : null}
      </div>

      <div className={style.body}>{gifs.map(BodyItem)}</div>

      {loading && <Loader />}
    </div>
  );
};
export default GifList;
