import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';
import RadioButton from '../../components/RadioButton/RadioButton';
import Time from '../../components/Time/Time';
import VideoInterval from '../../components/VideoInterval/VideoInterval';
import { RootState } from '../../store';
import { setSelectedModal } from '../../store/modal/modal.action';
import {
  getVideoRequest,
  updateVideoRequest,
} from '../../store/video/video.action';
import { DEFAULT_COLOR_SCHEME, history } from '../../utils';
import style from './VideoTrimmer.module.css';
import { VideoTrimmerType } from './VideoTrimmer.type';

const VideoTrimmer = ({ videoId }: VideoTrimmerType) => {
  const dispatch = useDispatch();

  const { video, loading } = useSelector((state: RootState) => state.video);

  const source = video?.videoSource || '';

  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [saveOption, setSaveOption] = useState<'new' | 'overwrite'>('new');
  const [videoTitle, setVideoTitle] = useState<string>('');

  const options = [
    { name: 'Save As New Video', value: 'new' },
    { name: 'Overwrite Original', value: 'overwrite' },
  ];

  useEffect(() => {
    dispatch(getVideoRequest({ videoId }));
  }, [dispatch, videoId]);

  useEffect(() => {
    if (video) {
      setVideoTitle(video.name);
    }
  }, [video]);

  const saveVideo = () => {
    dispatch(
      updateVideoRequest({
        action: 'trimm',
        endTime,
        startTime,
        name: videoTitle,
        saveType: saveOption,
        videoId: +videoId,
      }),
    );

    dispatch(setSelectedModal(null));

    history.push('/');
  };

  const colorScheme = video?.playerConfig.colorScheme || DEFAULT_COLOR_SCHEME;

  return (
    <div className={style.container}>
      <VideoInterval
        duration={duration}
        endTime={endTime}
        setDuration={setDuration}
        setEndTime={setEndTime}
        setStartTime={setStartTime}
        startTime={startTime}
        videoId={videoId}
        source={source}
        colorScheme={colorScheme}
      />
      <div className={style.save_crop_controls}>
        <div className={style.timeline_controls}>
          <Time
            key="start"
            name="Start"
            setValue={setStartTime}
            value={startTime}
          />
          <Time key="end" name="End" setValue={setEndTime} value={endTime} />
        </div>
        <RadioButton
          name="save"
          options={options}
          value={saveOption}
          setValue={setSaveOption}
        />
      </div>
      <Input
        placeholder="Video Title:"
        setValue={setVideoTitle}
        value={videoTitle}
      />
      <Button name="Save" handler={saveVideo} className={style.save_button} />
      {loading && <Loader />}
    </div>
  );
};

export default VideoTrimmer;
