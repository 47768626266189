import { WatermarkInfo } from '../../components/LogoEditor/LogoEditor.type';
import {
  Embed,
  GetEmbedVideo,
  DeleteVideo,
  EditVideo,
  VideoId,
  Video,
  UploadVideo,
} from '../../models';
import { MetaType, SortType, PageType, Query } from '../../utils';

export interface IVideoState {
  videos: Video[];
  selectedVideosId: number[];
  video: Video | null;
  videoSelected: Video | null;
  loading: boolean;
  imageLoading: boolean;
  uploadVideos: UploadVideo[];
  pagination: MetaType;
  page: number;
  limit: number;
  search: string;
  sortName: string;
  sortType: SortType;
  userId: number | null;
}

export type IDublicateVideo = Pick<IVideoState, 'selectedVideosId'> &
  Query & { action: 'copy' };
export type IDeleteVideo = Pick<IVideoState, 'selectedVideosId'> &
  Query & { action: 'delete' };
export type IAddVideoToFolder = Pick<IVideoState, 'selectedVideosId'> &
  Query & { action: 'addToFolder'; folderId: number };
export type IBanVideo = Pick<IVideoState, 'selectedVideosId'> &
  Query & { action: 'block'; ban: boolean };

export type IBulkPayLoad =
  | IDublicateVideo
  | IAddVideoToFolder
  | IDeleteVideo
  | IBanVideo;

export type UploadVideoImage = {
  videoId: VideoId['videoId'];
  image: Blob;
  type?: 'thumbnail' | 'watermark';
  watermarkInfo?: WatermarkInfo;
};

export const SET_VIDEO_PAGE = '@video/SET_VIDEO_PAGE';
export const SET_VIDEO_SORT_TYPE = '@video/SET_VIDEO_SORT_TYPE';
export const SET_SELECTED_VIDEO = '@video/SET_SELECTED_VIDEO';
export const SET_SELECTED_VIDEOS_ID = '@video/SET_SELECTED_VIDEOS_ID';
export const SET_SELECT_ALL_VIDEOS = '@video/SET_SELECT_ALL_VIDEOS';
export const SET_SEARCH_STRING = '@video/SET_SEARCH_STRING';
export const SET_USER_ID = '@video/SET_USER_ID';
export const SET_SORT_NAME = '@video/SET_SORT_NAME';

export const GET_VIDEO_LIST_REQUEST = '@video/GET_VIDEO_LIST_REQUEST';
export const GET_VIDEO_LIST_SUCCESS = '@video/GET_VIDEO_LIST_SUCCESS';
export const GET_VIDEO_LIST_FAILURE = '@video/GET_VIDEO_LIST_FAILURE';

export const GET_VIDEO_REQUEST = '@video/GET_VIDEO_REQUEST';
export const GET_VIDEO_SUCCESS = '@video/GET_VIDEO_SUCCESS';
export const GET_VIDEO_FAILURE = '@video/GET_VIDEO_FAILURE';

export const GET_EMBED_VIDEO_REQUEST = '@video/GET_EMBED_VIDEO_REQUEST';
export const GET_EMBED_VIDEO_SUCCESS = '@video/GET_EMBED_VIDEO_SUCCESS';
export const GET_EMBED_VIDEO_FAILURE = '@video/GET_EMBED_VIDEO_FAILURE';

export const UPDATE_VIDEO_REQUEST = '@video/UPDATE_VIDEO_REQUEST';
export const UPDATE_VIDEO_SUCCESS = '@video/UPDATE_VIDEO_SUCCESS';
export const UPDATE_VIDEO_FAILURE = '@video/UPDATE_VIDEO_FAILURE';

export const UPLOAD_VIDEO_REQUEST = '@video/UPLOAD_VIDEO_REQUEST';
export const UPLOAD_VIDEO_SUCCESS = '@video/UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_FAILURE = '@video/UPLOAD_VIDEO_FAILURE';

export const DELETE_VIDEO_REQUEST = '@video/DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_SUCCESS = '@video/DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAILURE = '@video/DELETE_VIDEO_FAILURE';

export const BULK_VIDEOS_REQUEST = '@video/BULK_VIDEOS_REQUEST';
export const BULK_VIDEOS_SUCCESS = '@video/BULK_VIDEOS_SUCCESS';
export const BULK_VIDEOS_FAILURE = '@video/BULK_VIDEOS_FAILURE';

export const UPLOAD_VIDEO_IMAGE_REQUEST = '@video/UPLOAD_VIDEO_IMAGE_REQUEST';
export const UPLOAD_VIDEO_IMAGE_SUCCESS = '@video/UPLOAD_VIDEO_IMAGE_SUCCESS';
export const UPLOAD_VIDEO_IMAGE_FAILURE = '@video/UPLOAD_VIDEO_IMAGE_FAILURE';

interface ISetVideoSortType {
  type: typeof SET_VIDEO_SORT_TYPE;
  payload: {
    sortType: SortType;
  };
}

interface ISetVideoPage {
  type: typeof SET_VIDEO_PAGE;
  payload: {
    pageType: PageType;
  };
}

interface ISetSelectedVideo {
  type: typeof SET_SELECTED_VIDEO;
  payload: {
    video: Video | null;
  };
}

interface ISetSelectedVideosId {
  type: typeof SET_SELECTED_VIDEOS_ID;
  payload: {
    id: number;
  };
}

interface ISetSelectdAllVideos {
  type: typeof SET_SELECT_ALL_VIDEOS;
  payload: {
    selectAllVideos: boolean;
  };
}

interface ISetSearchString {
  type: typeof SET_SEARCH_STRING;
  payload: {
    search: string;
  };
}

export interface IGetVideoListRequest {
  type: typeof GET_VIDEO_LIST_REQUEST;
  payload: Query;
}

interface IGetVideoListSuccess {
  type: typeof GET_VIDEO_LIST_SUCCESS;
  payload: {
    videos: Video[];
    pagination: MetaType;
  };
}

interface IGetVideoListFailure {
  type: typeof GET_VIDEO_LIST_FAILURE;
}

export interface IGetVideoRequest {
  type: typeof GET_VIDEO_REQUEST;
  payload: VideoId;
}

interface IGetVideoSuccess {
  type: typeof GET_VIDEO_SUCCESS;
  payload: Video;
}

interface IGetVideoFailure {
  type: typeof GET_VIDEO_FAILURE;
}

export interface IGetEmbedVideoRequest {
  type: typeof GET_EMBED_VIDEO_REQUEST;
  payload: GetEmbedVideo;
}

interface IGetEmbedVideoSuccess {
  type: typeof GET_EMBED_VIDEO_SUCCESS;
  payload: Embed;
}

interface IGetEmbedVideoFailure {
  type: typeof GET_EMBED_VIDEO_FAILURE;
}

export interface IUpdateVideoRequest {
  type: typeof UPDATE_VIDEO_REQUEST;
  payload: EditVideo;
}

interface IUpdateVideoSuccess {
  type: typeof UPDATE_VIDEO_SUCCESS;
  payload: Video;
}

interface IUpdateVideoFailure {
  type: typeof UPDATE_VIDEO_FAILURE;
}

export interface IUploadVideoRequest {
  type: typeof UPLOAD_VIDEO_REQUEST;
  payload: UploadVideo & Query;
}

interface IUploadVideoSuccess {
  type: typeof UPLOAD_VIDEO_SUCCESS;
}

interface IUploadVideoFailure {
  type: typeof UPLOAD_VIDEO_FAILURE;
}

export interface IDeleteVideoRequest {
  type: typeof DELETE_VIDEO_REQUEST;
  payload: DeleteVideo;
}

interface IDeleteVideoSuccess {
  type: typeof DELETE_VIDEO_SUCCESS;
}

interface IDeleteVideoFailure {
  type: typeof DELETE_VIDEO_FAILURE;
}

export interface IBulkVideosRequest {
  type: typeof BULK_VIDEOS_REQUEST;
  payload: IBulkPayLoad;
}

interface IBulkVideosSuccess {
  type: typeof BULK_VIDEOS_SUCCESS;
}

interface IBulkVideosFailure {
  type: typeof BULK_VIDEOS_FAILURE;
}

interface ISetUserId {
  type: typeof SET_USER_ID;
  payload: {
    userId: number;
  };
}

interface ISetSortName {
  type: typeof SET_SORT_NAME;
  payload: {
    name: string;
  };
}

export interface IUploadVideoImageRequest {
  type: typeof UPLOAD_VIDEO_IMAGE_REQUEST;
  payload: UploadVideoImage;
}

interface IUploadVideoImageSuccess {
  type: typeof UPLOAD_VIDEO_IMAGE_SUCCESS;
}

interface IUploadVideoImageFailure {
  type: typeof UPLOAD_VIDEO_IMAGE_FAILURE;
}

export type VideoActionsTypes =
  | ISetVideoSortType
  | ISetVideoPage
  | ISetSelectedVideo
  | ISetSelectedVideosId
  | ISetSelectdAllVideos
  | ISetSearchString
  | IGetVideoListRequest
  | IGetVideoListSuccess
  | IGetVideoListFailure
  | IGetVideoRequest
  | IGetVideoSuccess
  | IGetVideoFailure
  | IGetEmbedVideoRequest
  | IGetEmbedVideoSuccess
  | IGetEmbedVideoFailure
  | IUpdateVideoRequest
  | IUpdateVideoSuccess
  | IUpdateVideoFailure
  | IUploadVideoRequest
  | IUploadVideoSuccess
  | IUploadVideoFailure
  | IDeleteVideoRequest
  | IDeleteVideoSuccess
  | IDeleteVideoFailure
  | IBulkVideosRequest
  | IBulkVideosSuccess
  | IBulkVideosFailure
  | IUploadVideoImageRequest
  | IUploadVideoImageSuccess
  | IUploadVideoImageFailure
  | ISetUserId
  | ISetSortName;
