import { UserFull, EditUserById } from '../models';
import { IBulkData } from '../store/users/users.type';
import { ResponseType } from '../utils';
import service from '../utils/httpClient';

export const getAllUser = async (
  query: string,
): Promise<ResponseType<UserFull>> => {
  const path = '/api/users';
  const { data } = await service({
    method: 'GET',
    url: path + query,
  });
  return data as ResponseType<UserFull>;
};

export const getUserProfile = async (
  userID: string,
): Promise<ResponseType<UserFull>> => {
  const path = '/api/users/';
  const { data } = await service({
    method: 'GET',
    url: path + userID,
  });
  return data as ResponseType<UserFull>;
};

export const bulkUsers = async (data: IBulkData): Promise<void> => {
  await service({
    method: 'POST',
    url: '/api/users/bulk',
    data,
  });
};

export const deleteUserById = async (id: UserFull['id']): Promise<void> => {
  const path = `/api/users/${id}`;
  await service({
    method: 'DELETE',
    url: path,
  });
};

export const updateUserById = async (
  editInfo: EditUserById,
): Promise<UserFull> => {
  const { id, data } = editInfo;

  const { data: responseData } = await service({
    method: 'PUT',
    url: `/api/users/${id}`,
    data,
  });

  return responseData as UserFull;
};
