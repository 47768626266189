import cn from 'classnames';

import style from './Button.module.css';
import { ButtonType } from './Button.type';

const ButtonComponent = (props: ButtonType) => {
  const {
    handler,
    name,
    className,
    hasBackground = true,
    disabled = false,
    type = 'button',
  } = props;

  return (
    <div className={cn(style.container, className)}>
      <button
        disabled={disabled}
        className={cn(style.button, hasBackground || style.no_bg)}
        onClick={handler}
        type={type}
      >
        {name}
      </button>
    </div>
  );
};

export default ButtonComponent;
