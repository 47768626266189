/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState, useCallback } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import spriteImg from '../../assets/img/sprite.svg';
import Button from '../../components/Button/Button';
import CheckBox from '../../components/CheckBox/CheckBox';
import Loader from '../../components/Loader/Loader';
import ModalFile from '../../components/ModalFile/ModalFile';
import Select from '../../components/Select/Select';
import { OptionType } from '../../components/Select/Select.type';
import { RootState } from '../../store';
import { alertError } from '../../store/alert/alert.action';
import {
  updatePlayerConfigRequest,
  uploadPlayerConfigLogoRequest,
} from '../../store/playerConfig/playerConfig.action';
import style from './PlayerConfig.module.css';
import { PlayerConfigType } from './PlayerConfig.type';

const PlayerConfig = (props: PlayerConfigType) => {
  const { className, videoId } = props;
  const dispatch = useDispatch();

  const {
    profile: { profile },
    playerConfig: { loading: playerConfigLoading },
    video: { video, loading: videoLoading },
  } = useSelector((state: RootState) => state);

  const playerConfig = video?.playerConfig;

  const startActions = [
    {
      value: 'none',
      name: 'none',
    },
    {
      value: 'loadVideo',
      name: 'load video',
    },
    {
      value: 'autoPlay',
      name: 'auto play',
    },
  ];

  const endActions = [
    {
      value: 'pause',
      name: 'pause',
    },
    {
      value: 'stop',
      name: 'stop',
    },
    // {
    //   value: 'playNext',
    //   name: 'play next',
    // },
    {
      value: 'loop',
      name: 'loop',
    },
  ];

  const [isUpload, setIsUpload] = useState(false);

  const updateHandler = (
    value: boolean | string,
    field:
      | 'startAction'
      | 'endAction'
      | 'autoHideControls'
      | 'volumeControl'
      | 'playPauseButton'
      | 'scrubber'
      | 'videoPopOut'
      | 'showLogoInPlayer'
      | 'showLogoInPlayer'
      | 'addSocialMediaButtons',
  ) => {
    if (!playerConfig) {
      return;
    }

    dispatch(
      updatePlayerConfigRequest({
        id: playerConfig.id,
        videoId,
        [field]: value,
      }),
    );
  };

  useEffect(() => {
    if (!(playerConfigLoading && videoLoading)) {
      setIsUpload(false);
    }
  }, [videoLoading, playerConfigLoading]);

  const checkBoxes = [
    {
      value: !!playerConfig?.autoHideControls,
      handler: (value: boolean) => updateHandler(value, 'autoHideControls'),
      title: 'Auto Hide Video Controls',
    },
    {
      value: !!playerConfig?.volumeControl,
      handler: (value: boolean) => updateHandler(value, 'volumeControl'),
      title: 'Volume Control',
    },
    {
      value: !!playerConfig?.playPauseButton,
      handler: (value: boolean) => updateHandler(value, 'playPauseButton'),
      title: 'Play/Pause Button',
    },
    // TODO: EWV-177
    // {
    //   value: scrubber,
    //   handler: setScrubber,
    //   title: 'Scrubber (Scroll Bar)',
    // },
    {
      value: !!playerConfig?.videoPopOut,
      handler: (value: boolean) => updateHandler(value, 'videoPopOut'),
      title: 'Video Pop Out',
    },
    {
      value: !!playerConfig?.showLogoInPlayer,
      handler: (value: boolean) => updateHandler(value, 'showLogoInPlayer'),
      title: 'Show Logo On Player',
    },
    // TODO: EWV-177
    // {
    //   value: addSocialMediaButtons,
    //   handler: setAddSocialMediaButtons,
    //   title: 'Add Social Media Buttons',
    // },
  ];

  const uploadHandler = (image: Blob | null) => {
    if (image && playerConfig && playerConfig?.id) {
      dispatch(
        uploadPlayerConfigLogoRequest({ image, id: playerConfig.id, videoId }),
      );
    }
  };

  const modalHandler = () => {
    setIsUpload(!isUpload);
  };

  const handleDisableAlert = useCallback(() => {
    dispatch(
      alertError('This action is disabled. Contact your administrator.'),
    );
  }, [dispatch]);

  return (
    <div className={cn(style.container, className)}>
      <div className={style.actions}>
        <Select
          key="startAction"
          curValue={
            startActions.find(
              ({ value }) => value === playerConfig?.startAction,
            ) || startActions[0]
          }
          defaultValue={startActions[0]}
          setValue={({ value }: OptionType) =>
            updateHandler(value as string, 'startAction')
          }
          options={startActions}
          title={
            <>
              Start <br /> Action
            </>
          }
          className={style.actions_select}
        />
        <Select
          key="endAction"
          curValue={
            endActions.find(({ value }) => value === playerConfig?.endAction) ||
            endActions[0]
          }
          defaultValue={endActions[0]}
          setValue={({ value }: OptionType) =>
            updateHandler(value as string, 'endAction')
          }
          title={
            <>
              End <br /> Action
            </>
          }
          className={style.actions_select}
          options={endActions}
        />
      </div>

      <div className={style.info}>
        Note: Video will muted in Chrome Browser and Mobile Device if Autoplay
        selected.
      </div>

      <div className={style.checkbox_settings}>
        {checkBoxes.map(({ handler, title, value }, i) => (
          <CheckBox
            key={i}
            className={style.checkbox_settings__checkbox}
            setValue={handler}
            value={value}
            title={title}
          />
        ))}
        <div className={style.button_settings}>
          <Button
            handler={
              profile?.feature.uploadPlayerLogo
                ? modalHandler
                : handleDisableAlert
            }
            hasBackground={false}
            name={
              <div className={style.upload_logo_btn}>
                <svg role="img">
                  <use xlinkHref={`${spriteImg}#upload`} />
                </svg>
                <span>Upload Logo For Player</span>
              </div>
            }
          />
        </div>
      </div>
      {isUpload && (
        <ModalFile handler={uploadHandler} closeHandler={modalHandler} />
      )}
      {(playerConfigLoading || videoLoading) && <Loader />}
    </div>
  );
};

export default PlayerConfig;
