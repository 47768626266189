import { put, takeLatest, fork, call, ForkEffect } from 'redux-saga/effects';

import { Folder } from '../../models';
import {
  getAllFolder,
  createFolder,
  updateFolder,
  deleteFolder,
} from '../../services/folder.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../alert/alert.type';
import {
  FolderActionsTypes,
  IGetFolderListRequest,
  ICreateFolderRequest,
  IUpdateFolderRequest,
  IDeleteFolderRequest,
  GET_FOLDER_LIST_REQUEST,
  GET_FOLDER_LIST_SUCCESS,
  GET_FOLDER_LIST_FAILURE,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAILURE,
  UPDATE_FOLDER_REQUEST,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAILURE,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAILURE,
} from './folder.type';

function* workerGetAllFolder(action: FolderActionsTypes) {
  try {
    const { payload } = action as IGetFolderListRequest;
    const folders: Folder[] = yield call(getAllFolder, payload.id);

    yield put({ type: GET_FOLDER_LIST_SUCCESS, payload: { folders } });
  } catch (error) {
    yield put({ type: GET_FOLDER_LIST_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerCreateFolder(action: FolderActionsTypes) {
  try {
    const { payload } = action as ICreateFolderRequest;
    const folder: Folder = yield call(createFolder, payload.folder);

    yield put({ type: CREATE_FOLDER_SUCCESS, payload: { folder } });
    yield put({
      type: ALERT_SUCCESS,
      payload: { message: 'Folder created successfully!' },
    });
  } catch (error) {
    yield put({ type: CREATE_FOLDER_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerUpdateFolder(action: FolderActionsTypes) {
  try {
    const { payload } = action as IUpdateFolderRequest;
    const folder: Folder = yield call(updateFolder, payload.folder);

    yield put({ type: UPDATE_FOLDER_SUCCESS, payload: { folder } });
    yield put({
      type: ALERT_SUCCESS,
      payload: { message: 'Folder updated successfully!' },
    });
  } catch (error) {
    yield put({ type: UPDATE_FOLDER_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerDeleteFolder(action: FolderActionsTypes) {
  try {
    const { payload } = action as IDeleteFolderRequest;
    yield call(deleteFolder, payload);
    yield put({ type: DELETE_FOLDER_SUCCESS, payload });
    yield put({
      type: ALERT_SUCCESS,
      payload: { message: 'Folder deleted successfully!' },
    });
  } catch (error) {
    yield put({ type: DELETE_FOLDER_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* watchGetAllFolder() {
  yield takeLatest(GET_FOLDER_LIST_REQUEST, workerGetAllFolder);
}

function* watchCreateFolder() {
  yield takeLatest(CREATE_FOLDER_REQUEST, workerCreateFolder);
}

function* watchUpdateFolder() {
  yield takeLatest(UPDATE_FOLDER_REQUEST, workerUpdateFolder);
}

function* watchDeleteFolder() {
  yield takeLatest(DELETE_FOLDER_REQUEST, workerDeleteFolder);
}

export const folderWatchers: ForkEffect[] = [
  fork(watchGetAllFolder),
  fork(watchCreateFolder),
  fork(watchUpdateFolder),
  fork(watchDeleteFolder),
];
