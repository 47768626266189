import { put, takeLatest, fork, ForkEffect, call } from 'redux-saga/effects';

import {
  login,
  social,
  logout,
  forgotPassword,
  resetPassword,
  verifyEmail,
} from '../../services/auth.service';
import { TokenType } from '../../utils';
import { ALERT_ERROR, ALERT_SUCCESS } from '../alert/alert.type';
import { CLEAR_PROFILE } from '../profile/profile.type';
import { authVerifyEmailFailure, authVerifyEmailSuccess } from './auth.action';
import {
  AuthActionsTypes,
  IAuthLoginRequest,
  IAuthForgotPassRequest,
  IAuthResetPassRequest,
  IAuthSocialRequest,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_SOCIAL_REQUEST,
  AUTH_SOCIAL_SUCCESS,
  AUTH_SOCIAL_FAILURE,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_FORGOT_PASS_REQUEST,
  AUTH_FORGOT_PASS_SUCCESS,
  AUTH_FORGOT_PASS_FAILURE,
  AUTH_RESET_PASS_REQUEST,
  AUTH_RESET_PASS_SUCCESS,
  AUTH_RESET_PASS_FAILURE,
  IAuthVerifyEmailRequest,
  AUTH_VERIFY_EMAIL_REQUEST,
} from './auth.type';

function* workerAuthLogin(action: AuthActionsTypes) {
  try {
    const { payload } = action as IAuthLoginRequest;
    const data: TokenType = yield call(login, payload);

    yield put({ type: AUTH_LOGIN_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: AUTH_LOGIN_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerAuthSocial(action: AuthActionsTypes) {
  try {
    const { payload } = action as IAuthSocialRequest;
    const data: TokenType = yield call(social, payload);

    yield put({ type: AUTH_SOCIAL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: AUTH_SOCIAL_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerAuthLogout() {
  try {
    yield call(logout);
    yield put({ type: AUTH_LOGOUT_SUCCESS });
    yield put({ type: CLEAR_PROFILE });
  } catch (error) {
    yield put({ type: AUTH_LOGOUT_SUCCESS });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerAuthForgotPass(action: AuthActionsTypes) {
  try {
    const { payload } = action as IAuthForgotPassRequest;
    yield call(forgotPassword, payload.email);
    yield put({ type: AUTH_FORGOT_PASS_SUCCESS });
    yield put({
      type: ALERT_SUCCESS,
      payload: { message: 'Message sent successfully' },
    });
  } catch (error) {
    yield put({ type: AUTH_FORGOT_PASS_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerAuthResetPass(action: AuthActionsTypes) {
  try {
    const { payload } = action as IAuthResetPassRequest;
    yield call(resetPassword, payload);
    yield put({ type: AUTH_RESET_PASS_SUCCESS });
    yield put({
      type: ALERT_SUCCESS,
      payload: { message: 'Password updated successfully' },
    });
  } catch (error) {
    yield put({ type: AUTH_RESET_PASS_FAILURE });
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* workerVerifyEmail(action: AuthActionsTypes) {
  try {
    const { payload } = action as IAuthVerifyEmailRequest;
    const data: TokenType = yield call(verifyEmail, payload.token);

    yield put(authVerifyEmailSuccess(data));
  } catch (error) {
    yield put(authVerifyEmailFailure());
    yield put({ type: ALERT_ERROR, payload: { message: error } });
  }
}

function* watchAuthLogin() {
  yield takeLatest(AUTH_LOGIN_REQUEST, workerAuthLogin);
}

function* watchAuthSocial() {
  yield takeLatest(AUTH_SOCIAL_REQUEST, workerAuthSocial);
}

function* watchAuthLogout() {
  yield takeLatest(AUTH_LOGOUT_REQUEST, workerAuthLogout);
}

function* watchAuthForgotPass() {
  yield takeLatest(AUTH_FORGOT_PASS_REQUEST, workerAuthForgotPass);
}

function* watchAuthResetPass() {
  yield takeLatest(AUTH_RESET_PASS_REQUEST, workerAuthResetPass);
}

function* watchVerifyEmail() {
  yield takeLatest(AUTH_VERIFY_EMAIL_REQUEST, workerVerifyEmail);
}

export const authWatchers: ForkEffect[] = [
  fork(watchAuthLogin),
  fork(watchAuthSocial),
  fork(watchAuthLogout),
  fork(watchAuthForgotPass),
  fork(watchAuthResetPass),
  fork(watchVerifyEmail),
];
