import ForgotPassword from '../../containers/ForgotPassword/ForgotPassword';
import MainLayout from '../../layout/MainLayout/MainLayout';

const ForgotPasswordPage = () => {
  return (
    <MainLayout>
      <ForgotPassword />
    </MainLayout>
  );
};

export default ForgotPasswordPage;
